import React from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useState, useEffect } from "react";
import { BiSolidLeftArrow, BiSolidRightArrow } from "react-icons/bi";
import "../../Components/AdminCarousel.css";
import uploadImage from "../../Images/UploadImages.png";
import { Loader } from "../../Components/Loader";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
const Gallery = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [currImg, setCurrImg] = useState(0);
  const [photos, setPhotos] = useState([]);
  const [loading,setLoading]=useState(false)
  const [isMobile,setIsMobile]=useState(false);
  const getPhotos = () => {
    axios
      .get("/api/v1/admin/photo/getphoto", {
        headers: {
          user: localStorage.getItem("qwerty"),
          admin: localStorage.getItem("shopify"),
        },
      })
      .then((data) => {
        setPhotos(data.data.data);
      })
      .catch((error) => {
        //console.log(error);
      });
  };
  const submit = (data) => {
    const formData = new FormData();
    var keys = Object.keys(data);
    keys?.map((e) => {
      if (data[e] instanceof FileList) {
        //console.log(e, data[e][0]);
        formData.append("file", data[e][0]);
      } else formData.append(e, data[e]);
      return "";
    });
    axios
      .post("/api/v1/admin/photo/addphoto", formData, {
        headers: {
          user: localStorage.getItem("qwerty"),
          admin: localStorage.getItem("shopify"),
        },
      })
      .then((data) => {
        //console.log(data);
        setLoading(true)
        setPhotos([...photos, data.data.data]);
      })
      .catch((error) => {
        setLoading(true)
        //console.log(error)
      });
  };
  useEffect(() => {
    setLoading(false)
    getPhotos();
    const handleResize=()=> {
      setIsMobile(window.innerWidth <= 768); // Adjust the breakpoint as needed
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const deleteImg = (id, id1) => {
    setLoading(true)
    axios
  .delete(`api/v1/admin/photo/${id.split("/")[7]}`, {
    data: {
      _id: id1,
    },
    headers: {
      user: localStorage.getItem("qwerty"),
      admin: localStorage.getItem("shopify"),
    },
  })
  .then((response) => {
    if (response.status === 200) {
      setLoading(false)
      // console.log("Delete request was successful");
      // console.log(response.data);
      getPhotos()
    } else {
      setLoading(false)
      // console.log("Unexpected response status:", response.status);
    }
  })
  .catch((error) => {
    // console.error("Error deleting:", error);
  });
  };

  const itemData = [
    {
      img: 'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e',
      title: 'Breakfast',
    },
    {
      img: 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d',
      title: 'Burger',
    },
    {
      img: 'https://images.unsplash.com/photo-1522770179533-24471fcdba45',
      title: 'Camera',
    },
    {
      img: 'https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c',
      title: 'Coffee',
    },
    {
      img: 'https://images.unsplash.com/photo-1533827432537-70133748f5c8',
      title: 'Hats',
    },
    {
      img: 'https://images.unsplash.com/photo-1558642452-9d2a7deb7f62',
      title: 'Honey',
    },
    {
      img: 'https://images.unsplash.com/photo-1516802273409-68526ee1bdd6',
      title: 'Basketball',
    },
    {
      img: 'https://images.unsplash.com/photo-1518756131217-31eb79b20e8f',
      title: 'Fern',
    },
    {
      img: 'https://images.unsplash.com/photo-1597645587822-e99fa5d45d25',
      title: 'Mushrooms',
    },
    {
      img: 'https://images.unsplash.com/photo-1567306301408-9b74779a11af',
      title: 'Tomato basil',
    },
    {
      img: 'https://images.unsplash.com/photo-1471357674240-e1a485acb3e1',
      title: 'Sea star',
    },
    {
      img: 'https://images.unsplash.com/photo-1589118949245-7d38baf380d6',
      title: 'Bike',
    },
  ];


  return (
    <>
    {
      loading && <Loader/>
    }
      <div
        className="modal fade "
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog  modal-dialog-centered modal-xl">
          <div className="modal-content">
            <div className="modal-body w-100">
              <img
                src={photos[currImg]?.photoId}
                width="100%"
                height="100%"
                alt="Photos"
              />
            </div>
          </div>
        </div>
      </div>
      {/* <div className="admin__carousel">
        <div
          className="admin__carousel-inner"
          style={{ backgroundImage: `url(${photos[currImg]?.photoId})` }}
        >
          <div
            className="left"
            onClick={() => {
              currImg > 0 && setCurrImg(currImg - 1);
            }}
          >
            <BiSolidLeftArrow />
          </div>
          <div
            className="center"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
          ></div>
          <div
            className="right"
            onClick={() => {
              currImg < photos.length - 1 && setCurrImg(currImg + 1);
            }}
          >
            <BiSolidRightArrow />
          </div>
        </div>
      </div> */}
      <div className="container h-100">
        <div className="d-flex mt-5 grid gap-2 row" id="image-slides">
            
          <p className="col-sm-12 col-md-3 col-lg-1">
            <form
              onSubmit={handleSubmit(submit)}
              className="container h-100"
              encType="multipart/form-data"
            >
              <input
                type="file"
                name="image"
                id="img"
                {...register("image")}
                width="50px"
                height="50px"
                accept="image/"
                style={{ display: "none" }}
              />
              <label htmlFor="img" style={{ cursor: "pointer" }}>
                <img src={uploadImage} alt="UploadedImage" height="100px" width="100px" />
              </label>
              <br />
              <button type="submit" className="btn btn-primary">
                +Add
              </button>
            </form> 
    </p>
          {/* {photos?.map((e, Index) => {
            //console.log(Index);
            return (
              <>
                <img
                  src={e.photoId}
                  alt=""
                  // alt={`Image`}
                  className="previewImage"
                  onClick={() => {
                    setCurrImg(Index);
                  }}
                  width="100px"
                  height="100px"
                />
                <button
                  onClick={() => {
                    deleteImg(e.photoId, e._id);
                  }}
                >
                  Delete
                </button>
              </>
            );
          })} */}

          <div className="col-sm-12 col-md-9 col-lg-9">
            <ImageList 
            // className="w-100 col-md-3 col-sm-4"
             sx={{ height: isMobile ? 250: 500 }}
             cols={isMobile ? 2 : 3}
             rowHeight={130}
            //  cols={3} 
            //  rowHeight={200}
             >
      {photos?.map((item,Index) => (
        <ImageListItem key={item.photoId} >
          <img
            srcSet={`${item.photoId}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
            src={`${item.photoId}?w=164&h=164&fit=crop&auto=format`}
            alt={item.title}
            loading="lazy"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
            onClick={() => {
              setCurrImg(Index);
            }}
          />
        </ImageListItem>
      ))}
    </ImageList>
    </div>
        </div>
      </div>
    </>
  );
};

export default Gallery;
