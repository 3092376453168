import React,{useEffect, useState} from "react";
// import './Accordian.css'
import axios from "axios";
import './Accordian2.css'

const Accordian2 = () => {
    const [service,setService]=useState([]);
  
const getData=()=>{
  // "https://nodebarber.onrender.com/api/v1/slot/getspecificstoreemployeesforslot/" + value
  axios.get("https://nodebarber.onrender.com/api/v1/slot/getspecificstoreserviceforslot/64c21eb6f045e543ef05de28").then((data)=>{
    //console.log("THE DATA is")
    setService(data.data.data);
    //console.log(data);
  })
  .catch((error)=>{
    //console.log(error);
  })
}

useEffect(()=>{
    getData()
},[]);
  return (
    <>
      <div className="abody">
        <div className="collapse1-content tab-content">
          <div className="tab-pane active" id="hair" role="tabpanel">
            <a className="hair" href="#hair">
              <i className="fab fa-instagram"></i> Hair
            </a>
            <div className="content">
              <div className="inner-content">
                <h3>Hair Services</h3>
                {service.map((e)=>{
        return (
          <>
          <div className='container-fluid w-100 d-flex p-2' key={e._id}>
            <p className="col-3">
          {e.serviceName}
            </p>
          <p className="col-3">
            {e.price}
          </p>
          <p className="col-3">
            {e.time}
          </p>
          <button className='col-3'
          >+Add</button>
          </div>
          </>
          )
        })}
                {/* Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Exercitationem hic eos mollitia ratione doloremque, voluptate
                sapiente labore soluta quis dolorem expedita eum tenetur,
                explicabo ipsam! */}
              </div>
            </div>
          </div>
          <div className="tab-pane" id="face" role="tab-panel">
            <a className="face" href="#face">
              <i className="fab fa-twitter"></i>Face
            </a>
            <div className="content">
              <div className="inner-content">
                <h3>Face Services</h3>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero
                nobis iusto deleniti corporis alias quo a quam similique
                cupiditate pariatur aliquid, omnis, officia dicta officiis
                impedit nisi dolores ut, distinctio placeat. Magni dolores
                perferendis ab laborum in neque, non exercitationem!
              </div>
            </div>
          </div>
          <div className="collapse1 tab-pane" role="tab-panel" id="body">
            <a className="body" href="#body">
              <i className="fab fa-dribbble"></i>Body
            </a>
            <div className="content">
              <div className="inner-content">
                <h3>Body Services</h3>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero
                nobis iusto deleniti corporis alias quo a quam similique
                cupiditate pariatur aliquid, omnis, officia dicta officiis
                impedit nisi dolores ut, distinctio placeat. Magni dolores
                perferendis ab laborum in neque, non exercitationem!
              </div>
            </div>
          </div>
          <div className="collapse1 tab-pane" role="tab-panel"  id="arms">
            <a className="arms" href="#arms">
              <i className="fab fa-youtube"></i>Arms
            </a>
            <div className="content">
              <div className="inner-content">
                <h3>Arms Services</h3>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero
                nobis iusto deleniti corporis alias quo a quam similique
                cupiditate pariatur aliquid, omnis, officia dicta officiis
                impedit nisi dolores ut, distinctio placeat. Magni dolores
                perferendis ab laborum in neque, non exercitationem!
              </div>
            </div>
          </div>
          <div className="collapse1 tab-pane" role="tab-panel"  id="feet">
            <a className="feet" href="#feet">
              <i className="fab fa-twitter"></i>Feet
            </a>
            <div className="content">
              <div className="inner-content">
                <h3>Feet Services</h3>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero
                nobis iusto deleniti corporis alias quo a quam similique
                cupiditate pariatur aliquid, omnis, officia dicta officiis
                impedit nisi dolores ut, distinctio placeat. Magni dolores
                perferendis ab laborum in neque, non exercitationem!
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Accordian2;
