import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import jwt_decode from "jwt-decode";
import axios from "axios";
import Swal from "sweetalert2";
import { Loader } from "../../Components/Loader";
import { changePassword } from "../../API/LoginApi";
import Cookies from "js-cookie";

export const ChangePassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  var { token } = useParams();
  const [flag, setFlag] = useState(false);
  const [loading,setLoadin]=useState(false)
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add your password validation logic here
    if (password !== confirmPassword) {
      alert("Passwords do not match");
    } else if (password.length < 8) {
      alert("Password should be at least 8 characters long");
    } else {
      if (flag) {
        setLoadin(true)
        var data = {
          password: password,
        };
        changePassword(data,token)
          .then((data) => {
            // console.log(data);
            localStorage.setItem('qwerty',data?.data?.userToken)
            Cookies.remove('session')
            setLoadin(false)
            Swal.fire({
              icon: "success",
              title: "Password Changed",
            });
          })
          .catch((error) => {
            setLoadin(false)
            // console.log(error);
          });
      } else {
        Swal.fire({
          icon: "error",
          title: "Looks like error from your side",
        });
      }
      // console.log("Password submitted:", password);
    }
  };

  const checkForToken = () => {
    if (token != null) {
      try {
        var decoded = jwt_decode(token);
        // console.log("decoded", decoded);
        const currentTime = Date.now() / 1000;
        // console.log(currentTime);
        if (currentTime < decoded.exp) {
          setFlag(true);
        } else {
          alert("Don't try to change the URL or it may becomes invalid");
        }
      } catch (error) {
        // console.log(error);
      }
    } else {
      alert("Don't try to change the URL");
    }
  };

  useEffect(() => {
    checkForToken();
  }, []);

  return (
    <div>
      {loading && <Loader/>}
      <div className="password-page">
        <form onSubmit={handleSubmit} className="form1">
          <div className="flex flex-col gap-[5px]">
            <label
              htmlFor="email"
              className="text-[#4a5568] font-[700] text-[14px]"
            >
              Password
            </label>
            <input
              type="password"
              value={password}
              onChange={handlePasswordChange}
              required
              className="bg-[#e2e8f0] py-[.8rem] rounded-[4px] px-[1rem] text-[#1a202c] outline-none form-control"
              placeholder="Password"
            />
          </div>
          <div className="flex flex-col gap-[5px]">
            <label
              htmlFor="email"
              className="text-[#4a5568] font-[700] text-[14px]"
            >
              Confirm Password
            </label>
            <input
              type="password"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              required
              className="bg-[#e2e8f0] py-[.8rem] rounded-[4px] px-[1rem] text-[#1a202c] outline-none form-control"
              placeholder="Confirm Password"
            />
          </div>
          <button type="submit" className="subBtn">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};
