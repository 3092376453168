import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import "./Appointments.css";
import axios from "axios";
import CalendarModal from "../../Components/CalendarModal";
import jwt_decode from "jwt-decode";
import { ProfileDropdown } from "../../Components/ProfileDropdown/ProfileDropdown";
import { appointmentDataForAdmin } from "../../API/AdminHomePageApi";
const Appointments = () => {
  const [Hours,setHours] = useState([]);
  const date1 = new Date();
  const [loading, setLoading] = useState(false);
  const setHoursfromStoreTimings=(openTime,closeTime)=>
  {

        const endHour = new Date(date1);
      endHour.setHours(date1.getHours() + 24);
      let current = new Date(date1);
      // console.log(openTime,closeTime);
      const openingHour = parseInt(openTime.split(":")[0]);
        const openingMinute = parseInt(openTime.split(":")[1]);
        const closingHour = parseInt(closeTime.split(":")[0]);
        const closingMinute = parseInt(closeTime.split(":")[1]);
      let startTime=new Date().setHours(openingHour, openingMinute, 0, 0);
      let endTime=new Date().setHours(closingHour, closingMinute, 0, 0);
      // console.log(startTime,endTime);
      let x=[];
      let currentTime=new Date(startTime);
      while(currentTime<=endTime)
      {
        // console.log(currentTime.toLocaleTimeString([], { hour: "2-digit",minute:"2-digit" }))
        // currentTime.setMinutes(currentTime.getMinutes()+15);
        currentTime.setHours(currentTime.getHours()+1);
        // let a=currentTime.toLocaleTimeString([],{hour:"2-digit"});
        let time=new Date(currentTime);
        x.push(time);
      }
      // console.log(x,x.length);
      // while (current <= endHour) {
      //   // current.setMinutes(current.getMinutes() + 15);
      //   current.setHours(current.getHours()+1);
      //   // console.log(current.toLocaleTimeString([], { hour: "2-digit",minute:"2-digit" }))
      //   let a=current.toLocaleTimeString([], { hour: "2-digit" });
      //   // setHours([...Hours,current.toLocaleTimeString([], { hour: "2-digit" })]);
      //   // x.push(a);
      // }  
      setHours(x);
    }
  const WeekDays = {
    0: "Sun",
    1: "Mon",
    2: "Tue",
    3: "Wed",
    4: "Thu",
    5: "Fri",
    6: "Sat",
  };
  const MonthDays = [];

  let x1 = new Date();
  const days30 = new Date(x1.getTime() + 30 * 24 * 60 * 60 * 1000);
  const endofMonth = new Date().setDate(x1.getDate() + 35);
  // console.log(date1, new Date(endofMonth), days30);

  let x2 = new Date(endofMonth).getMilliseconds();
  // console.log(days30, x1);
  // console.log(new Date(x1).getDate(), new Date(days30).getDate());

  for (let i = 0; i < 30; i++) {
    const nextDate = new Date();
    nextDate.setDate(x1.getDate() + i);
    // console.log(nextDate.getDate(),nextDate.getDay());
    let a = { index: nextDate.getDate(), value: nextDate.getDay() };
    MonthDays.push(a);
  }

  const Cell = ({ value }) => {
    return (
      <input
        type="text"
        value={value}
      />
    );
  };

  const [empData, setEmpData] = useState();
  const [openingTime,setOpeningTime]=useState();
  const [closingTime,setClosingTime]=useState();
  const getData = () => {
    axios
      .get("/api/v1/businessform/getbusinessdataforadmin", {
        headers: {
          user: localStorage.getItem("qwerty"),
          admin: localStorage.getItem("shopify"),
        },
      })
      .then((data) => {
        // setLoading(false);
        // console.log(data?.data)
        // console.log(data?.data?.data?.openingTime,data?.data?.data?.closingTime);
        let open=data?.data?.data?.openingTime
        let close=data?.data?.data?.closingTime;
        setOpeningTime(open);
        setClosingTime(close);
        // console.log("------>",open,close);
        setEmpData(data?.data?.employeeData);
        setHoursfromStoreTimings(open,close);
        // // socket.emit("adminCon",data.data.data._id)
        // // socket.emit("adminConn","64c390796f30d9e32ea8ff45")
        // setEmpData(data?.data?.employeeData);
        // setAdminData(data.data.data);
      })
      .catch((error) => {
        // console.log(error);
        // setLoading(false);
        // errors(error, navigate);
      });
  };
  var [decode, setDecode] = useState({});
  var [name,setName]=useState();
  var decoded = null;
  const checkToken = () => {
    var token = localStorage.getItem("qwerty");
    if (token != null) {
      try {
        decoded = jwt_decode(token);
        setDecode(decoded);
              setName(decoded.fullName);
        
      } catch (error) {
        // console.log(error)
      }
  };
}
const [appointmentData, setAppointmentData] = useState([]);
let startTimes=[];
const getAppointmentData = () => {
  setLoading(true);
  appointmentDataForAdmin()
    .then((data) => {
      // console.log("---------->",data.data.data);

      data?.data?.data.map((e)=>{
        startTimes.push(e.startTime);
      })
      setAppointmentData(data?.data?.data);
    })
    .finally(() => setLoading(false));
};
useEffect(() => {
  checkToken();
  getData();
  getAppointmentData();

}, []);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  
  
  
  return (
    <>
      {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DateCalendar', 'DateCalendar']}>
        <DemoItem label="Appointment Calendar">
          <DateCalendar defaultValue={dayjs('2022-04-17')} readOnly />
        </DemoItem>
      </DemoContainer>
    </LocalizationProvider> */}
      {/* <div className='date__picker ms-4'>
        {
          MonthDays.map((e)=>{
            return <div key={e.index} >
            <span>{WeekDays[e.value]}</span>
            {e.index}
            </div>
          })
        }
      </div> */}
      {Hours.length>0 && empData  && 
      <div className="container-fluid calendar">
        <div className="time__col">
          {Hours && Hours.map((e, id) => {
            return (
            
              <div className="hours" key={e.getTime()}>
                <span>
                  {e.toLocaleTimeString([], { hour: "2-digit" })}
                </span>
              </div>
            );
          })}
        </div>
        
        <div className="grid">
          {empData && openingTime &&  Hours &&
            empData?.map((emp, i) => {
              let employeeName=(emp?.employeeName).split(" ")
              let empFirstName=employeeName[0]!==undefined ? employeeName[0][0] : '' 
              let empLastName=employeeName[1]!==undefined ? employeeName[1][0] : ''

              return (
                <div className="appointments">
                  <div className="emp__info sticky-top">
                  <ProfileDropdown name={empFirstName+""+empLastName} decode={decode} 
                    item1={employeeName}
                    item2={"Other Employees Apppointment"}
                    item3={"Past Apppointments of "+employeeName}
                    nav={false}/>
                  </div>
                  {Hours && Hours.map((e, index) => {
                    let time=new Date();
                    return (
                      <div
                        className="hour__slot"
                        key={index*225}
                        onClick={handleOpen}
                      >
                        {[0, 15, 30, 45].map((j, ind) => {
                          const TimeInterval=new Date(e);
                          TimeInterval.setMinutes(e.getMinutes()+j)
                          return (
                            appointmentData.map((appointment)=>{
                              // {console.log(appointment.startTime===TimeInterval.getTime() ? TimeInterval : '')}
                              return (

                                <div className={`grid__cell ${time.getTime()>TimeInterval.getTime() ? 'passedTime' :''} `} key={TimeInterval.getTime()}>
                              <span className='value'>
                              {TimeInterval.toLocaleTimeString([], { hour: "2-digit" ,minute:"2-digit"})}
                              </span>
                            </div>
                            )
                            })
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
              );
            })}
        </div>
        <CalendarModal
          isOpen={open}
          onClose={(isOpen) => {
            setOpen(isOpen);
          }}
        />
        {/* <div className='grid'>
        {
          greed.map((e,index)=>{
             return <div className='grid__cell' key={index}>
              { e.map((cell,index)=>{
                  console.log(cell);
                  return <Cell/>
                })
              }
              
            </div>
          })
        }
      </div> */}
      </div>
      }
    </>
  );
};
export default Appointments;
