import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
import TimerComponent from "./Timer_demo";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import "./userCart.css";
import Swal from "sweetalert2";
import { addFinaldata, removeEverything } from "../../redux/CartSlice";
import { Loader } from "../../Components/Loader";
import io from "socket.io-client";
import {
  addAppointment,
  checkForAlreadyBooked,
  getCartData,
} from "../../API/CartApi";
import { BsCartPlusFill } from "react-icons/bs";
import Payment from "../../Components/Payment";
import Home from "../home/Home";

// import useWindowSize from "react-use/lib/useWindowSize";
// import Confetti from "react-confetti";

// import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupeeSharp';
export const UserCart = () => {
  const store = useSelector((state) => state.cart);
  const [data, setData] = useState([]);
  const [cartData, setCartData] = useState({});
  // const { width, height } = useWindowSize();
  // const [celebrate, setCelebrate] = useState(false);
  var [totalTime, setTotalTime] = useState(0);
  const [loading, setLoading] = useState(false);
  const [orderId, setOrderId] = useState(null);
  const [orderData, setOrderData] = useState(null);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [goForPayment, setGoForPayment] = useState(false);
  const [timerFlag, setTimerFlag] = useState(true);
  // console.log(process.env.REACT_APP_BASE_URL)
  var socket = io(process.env.REACT_APP_MAIN_URL);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  var price = 0;
  //console.log(store);
  var decoded = null;
  const checkToken = () => {
    var token = localStorage.getItem("qwerty");
    if (token != null) {
      decoded = jwt_decode(token);
      //console.log(decoded);
    }
  };
  var countdown_second = 300;
  const [timer, setTimer] = useState({});
  const second_To_M = (second) => {
    const time = new Date();
    let hours = second / (60 * 60);
    let h_to_m = second % 60;
    let m = Math.floor(h_to_m / 60);
    let m_to_s = m % 60;
    let s = Math.ceil(m_to_s);
    setTimer({ minute: m, second: s });
    return { minute: m, second: s };
  };

  useEffect(() => {
    checkToken();
    getData();
    setLoading(true);
  }, []);
  // socket=io(ENDPOINT)
  const [formError, setFormError] = useState();
  const f1 = async () => {
    // socket.emit("appAdded","Data")
    //console.log("CartData", cartData);
    setLoading(true);
    if (
      cartData.adminId == null &&
      cartData.employeeid == null &&
      cartData.startTime == 0 &&
      cartData.totalTime == 0
    ) {
      alert("Please add the slot");
      setLoading(false);
    } else {
      try {
        checkForAlreadyBooked(cartData)
          .then(async (data) => {
            if (data.data.data > 0) {
              alert(data.data.message);
            } else {
              // console.log("CartData", cartData);
              if (cartData?.isOffered !== true) {
                addAppointment(cartData)
                  .then((data) => {
                    socket.emit("bookappointment", cartData);
                    Swal.fire({
                      icon: "success",
                      title: "Appointment booked!!! Please check your mail.",
                    });
                    dispatch(removeEverything({ isOffered: false }));
                    // socket.emit('bookappointment', cartData);
                    // socket.emit("userCon",data.data.data)
                    // setCelebrate(true);
                    // setTimeout(()=>{
                    // },5000);
                    navigate("/");
                  })
                  .catch((error) => {
                    // console.log(error);
                    alert(error.response.data.message);
                  })
                  .finally(() => setLoading(false));
              } else if (cartData?.isOffered === true) {
                // console.log(cartData?.serviceId?.length *100)
                axios
                  .post("/api/v2/payment/order", {
                    totalAmount:
                      cartData?.serviceId?.length *1000,
                    receiptId: cartData?._id,
                  })
                  .then((result) => {
                    // console.log(result.data);
                    const updatedCartData = {
                      ...cartData,
                      paymentOrderId: result?.data?.id,
                    };
                    addAppointment(updatedCartData).then((data) => {
                      // console.log("we are here");
                      setGoForPayment(true);
                      setOrderId(result?.data?.id);
                      setOrderData(result);
                      setCartData(updatedCartData);
                      setTimerFlag(false);
                    });
                  })
                  .finally(() => setLoading(false));
              }
            }
          })
          .then((error) => {})
          .finally(() => setLoading(false));
      } catch (error) {
        //console.log(error);
        setLoading(false);
      }
    }
  };
  const getData = () => {
    //console.log("id", decoded.id);
    setLoading(true);
    // axios
    //   .get("/api/v1/cart/getcart/" + decoded?.id)
    getCartData(decoded?.id)
      .then((data) => {
        // console.log(
        if (data.data.data != null) {
          setData(data.data.data);
          setCartData(data.data.data);
          dispatch(addFinaldata(data.data.data));
        } else {
          setData(null);
          setCartData(null);
          localStorage.removeItem("timerStart");
          setTimerFlag(false);
        }
      })
      .catch((error) => {})
      .finally(() => setLoading(false));
  };

  const handlePaymentSuccess = (data) => {
    // setPaymentSuccess(true);
    // console.log(data)
    // setLoading(true)
    axios
      .put("/api/v2/payment/sucess", {
        payment: data,
        id: btoa(cartData?._id),
        type: process.env.REACT_APP_NODE_ENV,
      })
      .then((data) => {
        // console.log(data)
        if (data?.data?.appointment) {
          // console.log("Bhai Pyament mil gaya");
          setGoForPayment(false);
          dispatch(removeEverything({ isOffered: false }));
          cartData.isOffered=true
          socket.emit("bookappointment", cartData);
          Swal.fire({
            icon: "success",
            title: "Appointment booked!!! Please check your mail.",
          });

          navigate("/");
        } else {
          dispatch(removeEverything({ isOffered: false }));
          navigate(-2);
          Swal.fire({
            icon: "error",
            title:
              "Time limit exceeded. For any discrepancies, please contact bookmycoiffeur@gmail.com ",
          });
          setGoForPayment(false);
        }
      })
      .catch((error) => {
        // console.log(error);
        // console.log("error");
      }).finally(()=>{
        
        // setLoading(false)
      });
  };

  const handleCancelPayment = () => {
    // setLoading(true)
    axios
      .put("/api/v2/payment/cancel", { id: btoa(cartData?._id) })
      .then((data) => {
        // console.log("Bhai Payment nai hua");
        // navigate('/goffer/J3WYIBUX63')
        Swal.fire({
          icon: "error",
          title:
            "Transaction failed! Please select time and services to book again.",
        });
        navigate(-3);
        setGoForPayment(false);
      })
      .catch((error) => {
        // console.log(error)
        // console.log("error");
      }).finally(()=>{});
    // console.log("Cancel");
  };

  const stopPayment = () => {
    setGoForPayment(false);
  };

  // })
  //
  return (
    <>
      {/* servicesContainer */}
      {/* {console.log("paymentFalg",goForPayment)} */}
      {/* {celebrate && <Confetti width={width} height={height} />} */}
      {loading && <Loader />}
      <div className="container-fluid  mt-5 h-100  main__cart">
        <div className="title reporting__time mt-2">
          <div className="row">
            <div className="col-12">
              <center>
                <b>
                  Shopping Cart <BsCartPlusFill />
                </b>
              </center>
            </div>
            {/* <div className="col-5">
                  <b>
                    Total Services : {data?.serviceId?.length}
                  </b>
                </div> */}
          </div>
        </div>
        <center>
          <div className="">
            {store.value.startTime > 0 && timerFlag === true && (
              <p>
                You need to checkout the cart in
                <TimerComponent
                  userId={decoded?.id}
                  cancelPayment={handleCancelPayment}
                  stopPayment={stopPayment}
                />{" "}
                minutes
              </p>
            )}
          </div>
        </center>
        <div className="container-fluid row main__cart">
          <div className="col-md-8 cart col-sm-12">
            {/* <div className="title reporting__time">
              <div className="row">
                <div className="col-12">
                  <center>
                  <b>Shopping Cart <BsCartPlusFill /></b>
                  </center>
                </div>
              </div>
            </div> */}
            {/* <div className="col-5">
                  <b>
                    Total Services : {data?.serviceId?.length}
                  </b>
                </div> */}
            {data?.serviceId?.map((e) => {
              totalTime += e.time;
              return (
                <div className="row border-top border-bottom">
                  <div className="row main align-items-center">
                    {/* <div className="col-2">
                      <img className="img-fluid" src={e?.image} alt="ServiceImage" />
                    </div> */}
                    {/* <div className="col">
                      <div className="row text-muted">
                        {e?.categoryId?.categoryName}
                      </div>
                    </div> */}
                    <div className="col">
                      {e?.serviceName}({e.gender})
                    </div>
                    <div className="col">{e.time} minutes</div>
                    <div className="col row">
                      <p className="col-12 m-0 col-sm-3">
                        <CurrencyRupeeIcon />{" "}
                        {data?.isOffered === true ? (
                          <b>{e.price - e?.bmcDiscount - e?.storeDiscount}</b>
                        ) : (
                          e.price
                        )}{" "}
                      </p>
                      <p className="col-12 m-0 col-sm-3">
                        {data?.isOffered === true &&
                        (e?.bmcDiscount > 0 || e?.storeDiscount > 0) ? (
                          <strike>
                            <CurrencyRupeeIcon /> {e.price}
                          </strike>
                        ) : (
                          ""
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
            <center>
              <div
                style={{ marginTop: 20, fontSize: 25 }}
                className="reporting__time"
              >
                <b>
                  Your Reporting Time is{" "}
                  {store?.value?.startTime == 0
                    ? "****"
                    : new Date(data?.startTime - 5 * 60 * 1000)
                        .toLocaleString("en-IN", {
                          hour: "2-digit",
                          minute: "2-digit",
                        })
                        .toLocaleUpperCase()}
                  *
                </b>
                <br />
                Total Time : {totalTime} minutes (approx)
              </div>
            </center>
          </div>
          <div className="col-md-4 col-sm-12">
            <div className="summaryTxt w-100">
              <center>
                <h2>
                  <b>Summary</b>
                </h2>
              </center>
            </div>
            <hr className="w-100" />
            <div className="summary mt-4">
              {store?.value?.startTime == 0 ? (
                <>
                  <div className="mt-4">
                    <center>
                      <h3>
                        <b>
                          Please select Date ,Employee and Respective Available
                          Slots
                        </b>
                      </h3>
                    </center>
                  </div>
                </>
              ) : (
                <>
                  {/* {console.log("cartdata", data)}
                  {formError} */}
                  <div className="">
                    <h3 className="">
                      <b className=" ps-4">Store Details</b>
                    </h3>
                    <div className="row ps-5 mb-3">
                      <div className="col-12 ">
                        Name : {data?.adminId?.brandName}
                      </div>

                      <div className="col-12">
                        Address : {data?.adminId?.address}
                      </div>
                      <div className="col-12">
                        City : {data?.adminId?.city?.cityName}
                      </div>
                    </div>

                    <div>
                      <h3>
                        <b className="ps-4 mt-5">Contact Details</b>
                      </h3>
                      <div className="row ps-5 mb-3">
                        <div className="col-12">
                          Mobile :{data?.adminId?.contactNumber}
                        </div>
                        <div className="col-12">
                          Email : {data?.adminId?.email}
                        </div>
                      </div>
                    </div>
                    <div>
                      <h3>
                        <b className="ps-4 mt-5">Employee Details</b>
                      </h3>
                      <div className="row ps-5 mb-3">
                        <div className="col-12">
                          Name : {data?.employeeId?.employeeName}
                        </div>
                        <div className="col-12">
                          Mobile : {data?.employeeId?.mobile}
                        </div>
                      </div>
                    </div>
                    {/* </center> */}
                  </div>
                  <h3>
                    <b className="ps-4 mt-5">Pricing </b>
                  </h3>
                  <div className="row ps-5">
                    <div className="col-6">
                      Price of Services
                      {/* {data?.serviceId?.length}  */}
                    </div>
                    <div className="col-6">
                      <CurrencyRupeeIcon /> {data?.totalPrice}
                    </div>
                    <div className="col-6 discount">Discount</div>
                    <div className="col-6 discount">
                      -<CurrencyRupeeIcon /> {data?.discount}
                    </div>
                    <div className="col-6">CGST</div>
                    <div className="col-6 text-right">
                      <CurrencyRupeeIcon /> 0
                    </div>

                    <div className="col-6">SGST</div>
                    <div className="col-6 text-right">
                      <CurrencyRupeeIcon /> 0
                    </div>
                    {data?.discount > 0 ? (
                      <div className="saved col-10 row mt-1">
                        <p className="col-6">You Saved</p>
                        <p className="col-6 text-center">
                          <CurrencyRupeeIcon />
                          {data?.discount}
                        </p>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <hr />
                  <div
                    className="row ps-5"
                    style={{
                      marginTop: "0rem",
                      // borderTop: "1px solid rgba(0,0,0,.1)",
                      paddingTop: "1vh",
                    }}
                  >
                    <div className="col-6 ">TOTAL PRICE</div>
                    <div className="col-6 text-right">
                      <CurrencyRupeeIcon />{" "}
                      {parseFloat(data?.totalPrice) -
                        parseFloat(data?.discount)}
                    </div>
                    {data?.discount > 0 ? (
                      <>
                        <div className="col-6">Advance Amount</div>
                        <div className="col-6 text-right">
                          <CurrencyRupeeIcon /> {data?.serviceId?.length * 10}
                        </div>{" "}
                      </>
                    ) : (
                      ""
                    )}

                    {data?.discount > 0 ? (
                      <div className="saved col-11 row mt-1">
                        <div className="col-8">Amount Payable at store</div>
                        <div className="col-4 ">
                          <CurrencyRupeeIcon />{" "}
                          {parseFloat(data?.totalPrice) -
                            parseFloat(data?.discount) -
                            parseFloat(data?.serviceId?.length * 10)}
                        </div>{" "}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  {
                    goForPayment===false ? 
                  <button className="checkoutBtn" onClick={f1}>
                    {data?.isOffered===true ? "Proceed For Payment" : "Checkout"}
                  </button>
                  :
                  <button className="checkoutBtn">
                    {data?.isOffered===true ? "Proceed For Payment" : "Checkout"}
                  </button>
                  }
                  {goForPayment === true ? (
                    <Payment
                      result={orderData}
                      onPaymentSuccess={(data) => {
                        handlePaymentSuccess(data);
                      }}
                      onPaymentCancel={handleCancelPayment}
                      cartData={cartData}
                      paymentFlag={goForPayment}
                    />
                  ) : (
                    ""
                  )}
                </>
              )}
            </div>
            <div className="mt-4" style={{ cursor: "pointer" }}>
              <a href="#">←</a>
              <span
                className="text-muted "
                onClick={() => {
                  navigate(-1);
                }}
              >
                Back to shop
              </span>
            </div>
            <div className="">
              *change in Timmings rest in the hands of Store Owners
              <br></br>
              *Your cart will be maximum valid for the period of 30 minutes. If
              you paid after then that Please contact to mail
              bookmycoiffeur@gmail.com
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
