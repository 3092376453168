import React from "react";
import { Link } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Stack from "@mui/material/Stack";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import "./pagination.css"

export const Paginations = ({ page, nPages, currentPage, setCurrentPage }) => {
  const pageNumbers = [...Array(nPages + 1).keys()].slice(1);
  // console.log(page)
  const goToNextPage = () => {
    if (currentPage !== nPages) setCurrentPage(currentPage + 1);
  };
  const goToPrevPage = () => {
    if (currentPage !== 1) setCurrentPage(currentPage - 1);
  };
  return (
    <div className="page">
    <Stack spacing={2} className="pagination-stack">
      <Pagination
        count={10}
        renderItem={(item) => (
          <Link
            to={"/" + page + "?page=" + currentPage}
            onClick={() =>
              item.page > 0 &&
              item.page <= nPages &&
              setCurrentPage(item.page)
            }
          >
            <PaginationItem
              slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
              {...item}
            />
          </Link>
        )}
      />
    </Stack>
    {/* <ul className="pagination-list">
        <li className="page-item">
          <Link
            className="page-link"
            onClick={goToPrevPage}
            to={"/" + page + "?page=" + (currentPage - 1)}
          >
            Previous
          </Link>
        </li>
        {pageNumbers.map((pgNumber) => (
          <li
            key={pgNumber}
            className={`page-item ${currentPage === pgNumber ? "active-page" : ""} `}
          >
            <Link
              onClick={() => setCurrentPage(pgNumber)}
              className="page-link"
              to={"/" + page + "?page=" + pgNumber}
            >
              {pgNumber}
            </Link>
          </li>
        ))}
        <li className="page-item">
          <Link
            className="page-link"
            onClick={goToNextPage}
            to={"/" + page + "?page=" + (currentPage + 1)}
          >
            Next
          </Link>
        </li>
      </ul> */}
  </div>
  );
};
