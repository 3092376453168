// import { useState } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import { Home, Code, Notes, Call, LocalLibrary, RssFeed } from "@mui/icons-material";



import { SignUp } from "../signupPage/SignUp";
import Login from "./login";

import { LockOpen } from "@mui/icons-material";

const Register = () => {


  const [val, setVal] = useState('one');

  const handleTab = (e, newVal) => {
    setVal(newVal);
  };

  const renderTabContent = () => {
    switch (val) {
      case "one":
        return <Login/>;
      case "two":
        return <SignUp/>;
      default:
        return <Login/>;
    }
  };
  return (
    <>
      {/* react hook form use karjo na avde to me signup page ma use karelu che joi lejo */}
      {/* and ha tme email nu je karvana cho to ema generalize email ni api banavjo kmk forgot password ma bhi same email ni jarur padse etle api ekaj but khali template alag alag */}
      {/* <div className="login__main container-fluid">
        <div className="login__left ">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Necessitatibus, ratione.
        </div>
        <div className="login__right">
          {formError}
          <form action="" method="post" onSubmit={handleSubmit(submit)}>
            <div className="email__main">
              <label htmlFor="email_id">Email</label>
              <br />
              <input
                type="email"
                name="email"
                id="email_id"
                className="email"
                placeholder="Enter Your Mail"
                required=""
                {...register("email", ValidateSchema.email)}
              />
              {<span>{errors?.email?.message}</span>}
            </div>
            <div className="password__main">
              <label htmlFor="pswd">Password</label>
              <br />
              <input
                type="password"
                name="password"
                id="pswd"
                className="email"
                placeholder="Enter Your Password"
                required=""
                {...register("password", ValidateSchema.password)}
              />
              {<span>{errors?.password?.message}</span>}
            </div>
            <span>
              <Link to="forgotPassword" className="Forgot">
                forgot password
              </Link>
            </span>
            <span className="buttons">
              <button className="submit" type="submit">
                Submit
              </button>
              <button className="submit" type="reset">
                Reset
              </button>
            </span>
            <div>
              New Customer? <Link to="/signup">SignUp Now</Link> {" "}
            </div>
          </form>
        </div>
      </div> */}

      {/* <div>
        <Tabs defaultValue={1}>
          <StyledTabsList>
            <StyledTab value={1}>One</StyledTab>
            <StyledTab value={2}>Two</StyledTab>
            <StyledTab value={3}>Three</StyledTab>
          </StyledTabsList>
          <StyledTabPanel value={1}>First page</StyledTabPanel>
          <StyledTabPanel value={2}>Second page</StyledTabPanel>
          <StyledTabPanel value={3}>Third page</StyledTabPanel>
        </Tabs>
      </div> */}
      <div>
          <div className="container">
            <Box className="login__signup__form">
              <Tabs
                value={val}
                onChange={handleTab}
                textColor="primary"
                indicatorColor="secondary"
                variant="scrollable"
              >
                <Tab icon={<LockOpen/>}
                  value="one" label="Log In" />
                <Tab icon={<LocalLibrary />}
                  value="two" label="Sign Up" />
              </Tabs>
            </Box>
          </div>
          {renderTabContent()}
      </div>
    </>
  );
};

export default Register;
