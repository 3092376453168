import axios from "axios";
import React, { useEffect, useState } from "react";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import createTheme from "@mui/material/styles/createTheme";
import { Card } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { CardActionArea, Grid } from "@mui/material";
import Button from "@mui/material/Button"; // Import Grid from Material-UI
import { useTokenCheckerLogout } from "../../useTokenCheckerLogout";
import ScheduleIcon from "@mui/icons-material/Schedule";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import TimerIcon from "@mui/icons-material/Timer";
import ManIcon from "@mui/icons-material/Man";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import "./past.css";
import Modal from "../../Components/Modal";
import { Loader } from "../../Components/Loader";
import { getShopPastApp } from "../../API/AdditionalApi";
// import ExcelExport from "./ExcelExport";

export const ShopsPastApp = () => {
  const [expanded, setExpanded] = React.useState(false);
  const [loading, setLoading] = useState(false);
  var [pastAppointments, setPastAppointments] = React.useState();
  const [modalData, setModalData] = useState();
  var [bmcDis, setBmcDis] = useState(0);
  var [storeDis, setStoreDis] = useState(0);
  const getData = () => {
    setLoading(true);
    getShopPastApp()
      .then((data) => {
        // setLoading(false);
        setPastAppointments(data.data.data);
        // console.log(data.data);
        // console.log("---");
      })
      .catch((error) => {
        // setLoading(false);
      })
      .finally(() => setLoading(false));
  };
  const theme = createTheme({
    palette: {
      primary: {
        main: "#5c6bc0",
      },
      secondary: {
        main: "#5c6bc0",
      },
    },
  });
  useEffect(() => {
    getData();
  }, []);

  const setDiscounts = (services) => {
    var dis1 = 0;
    var dis2 = 0;
    services?.map((e) => {
      dis1 += e?.bmcDiscount;
      dis2 += e?.storeDiscount;
    });
    setBmcDis(dis1);
    setStoreDis(dis2);
  };
  return (
    <>
      {/* {console.log(pastAppointments)} */}
      {loading && <Loader />}
      <div>
        {/* <ExcelExport
          fileName={"AppointmentData"}
          excelData={pastAppointments}
        /> */}
        <center className="mb-5 mt-3 pastappointments">
          <h2>Appointment History</h2>
        </center>
        <ThemeProvider theme={theme}>
          <Grid container spacing={4}>
            {pastAppointments?.map((e) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={e.id}>
                {/* Define grid item properties */}
                <Card
                  sx={{
                    maxWidth: 385,
                    // maxHeight: "40rem",
                    marginInline: "auto",
                    mb: 5,
                    color: "#ffff",
                  }}
                >
                  <CardActionArea>
                    <CardContent sx={{ backgroundColor: "var(--theme-brown)" }}>
                      <Typography sx={{ color: "#fff", maxHeight: "100px" }}>
                        <p className="row">
                          <p className="pending col-12 ">
                            {e?.isOffered === true && (
                              <div className="coiffeur__offer w-50">
                                <p className="offers">
                                  {e?.isOffered === true ? "Offer" : ""}
                                </p>
                              </div>
                            )}
                            <h6>
                              {e.checkedIn === "false"
                                ? "Not Checkedin"
                                : "Checkedin"}
                            </h6>
                            <h6>
                              {e?.isPaid === true &&
                              e?.payment?.captured === true
                                ? "Token Amount Collected"
                                : ""}
                            </h6>
                          </p>
                        </p>
                        <table>
                          <p
                            className="row"
                            style={{
                              fontSize: "20px",
                              color: "white",
                              display: "flex",
                              paddingBottom: "0px",
                            }}
                          >
                            <p className="col-12">
                              {/* {console.log(e)} */}
                              {e.isAdmin === true
                                ? e.custDet?.name
                                : e.userId?.firstName +
                                  " " +
                                  e.userId?.lastName}
                            </p>
                          </p>
                        </table>
                        <p></p>
                      </Typography>
                    </CardContent>
                    <hr style={{ border: "3px solid black" }} />
                    <CardContent
                      sx={{
                        backgroundColor: "#fff",
                        border: "2px solid var(--primary-hard-color)",
                      }}
                    >
                      <table style={{ color: "var(--primary-hard-color)" }}>
                        <tr>
                          <td>Order ID</td>
                          <td>:</td>
                          <td>
                            <b>{e._id}</b>
                          </td>
                        </tr>
                        <tr>
                          <td>Date</td>
                          <td>:</td>
                          <td>
                            <ScheduleIcon />{" "}
                            {new Date(e.startTime).toLocaleDateString("en-IN", {
                              hour: "2-digit",
                              minute: "2-digit",
                            })}
                          </td>
                        </tr>
                        <tr>
                          <td>Total Items</td>
                          <td>:</td>
                          <td>{e?.serviceId?.length}</td>
                        </tr>
                        <tr>
                          <td>Total Amount</td>
                          <td>:</td>
                          <td>{e.totalPrice - e?.discount}</td>
                        </tr>
                        <tr>
                          <td>Payable Amount</td>
                          <td>:</td>
                          {e?.payment?.captured === true ? (
                            <td>
                              {e.totalPrice -
                                e?.discount -
                                e?.payment?.amount / 100}
                            </td>
                          ) : (
                            <td>{e.totalPrice - e?.discount}</td>
                          )}
                        </tr>
                        <tr>
                          <td colspan="3">
                            <Button
                              variant="contained"
                              data-bs-toggle="modal"
                              style={{
                                marginInline: "auto",
                                backgroundColor: "var(--theme-brown)",
                              }}
                              data-bs-target="#pastapp"
                              onClick={() => {
                                setModalData(e);
                                setDiscounts(e?.serviceId);
                              }}
                            >
                              View Details
                            </Button>
                          </td>
                        </tr>
                      </table>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
          </Grid>
        </ThemeProvider>
        <Modal title={"Order Detail"} subtitle={`Order Id : ${modalData?._id}`}>
          <div className="pastappointments__div">
            <div>
              <p>
                <CalendarTodayIcon />
                Date
              </p>
              <p>
                {new Date(modalData?.startTime).toDateString()} at{" "}
                {new Date(modalData?.startTime).toLocaleTimeString()}
              </p>
            </div>
            <div>
              <p>
                <TimerIcon />
                Total Time
              </p>
              <p>{modalData?.totalTime} minutes </p>
            </div>
            <div>
              <p>
                <ManIcon />
                Employee
              </p>
              <p>{modalData?.employeeId?.employeeName}</p>
            </div>
            <div>
              <p>
                <img
                  src="https://res.cloudinary.com/dpoecdktk/image/upload/v1711880387/shop_bh7rdt.png"
                  height={"20px"}
                  width={"20px"}
                />{" "}
                Service(s)
              </p>
            </div>
            <div className="ms-3 services__list">
              {modalData?.serviceId?.map((e) => {
                return (
                  <div className="">
                    <p> {e.serviceName}</p>
                    <p>
                      <CurrencyRupeeIcon />
                      <span>{e?.price}</span>
                    </p>
                  </div>
                );
              })}
            </div>
            <hr />
            <div>
              <p>Amount</p>
              <p>
                <CurrencyRupeeIcon />
                {modalData?.totalPrice}
              </p>
            </div>
            {modalData?.isPaid === true &&
            modalData?.payment?.captured === true ? (
              <>
                <div>
                  <p>Advance Payment</p>
                  <p>
                    -<CurrencyRupeeIcon /> {modalData?.payment?.amount / 100}
                  </p>
                </div>
              </>
            ) : (
              ""
            )}
            {
              modalData?.isOffered===true ?
              <>
              <div className="discount">
              <p>Discount Given By Website</p>
              <p>
                -<CurrencyRupeeIcon />
                {bmcDis}
              </p>
            </div>
            <div className="discount">
              <p>Discount Given By Store</p>
              <p>
                -<CurrencyRupeeIcon />
                {storeDis}
              </p>
            </div>
              </>
              : ""
            }
            

            <hr />
            <div>
              <p>Payable Amount</p>
              <p>
                {modalData?.payment?.captured === true ? (
                  <p>
                    <CurrencyRupeeIcon />
                    {modalData?.totalPrice -
                      modalData?.discount -
                      modalData?.payment?.amount / 100}
                  </p>
                ) : (
                  <p>
                    <CurrencyRupeeIcon />
                    {modalData?.totalPrice - modalData?.discount}
                  </p>
                )}
              </p>
            </div>
          </div>
          {/* <table className="pastappointments__table">
            <tr>
              <td>
                <TimerIcon />
                Total Time
              </td>
              <td>:</td>
              <td>{modalData?.totalTime} Minutes</td>
            </tr>
            <tr>
              <td align="left">
                <CalendarTodayIcon />
                Date
              </td>
              <td>:</td>
              <td>{modalData?.date}</td>
            </tr>
            <tr>
              <td>
                <ManIcon />
                Employee
              </td>
              <td>:</td>
              <td>{modalData?.employeeId?.employeeName}</td>
            </tr>
            <tr>
              <td>
                <CurrencyRupeeIcon />
                Amount
              </td>
              <td>:</td>
              <td>{modalData?.totalPrice}</td>
            </tr>
            {modalData?.isPaid === true &&
            modalData?.payment?.captured === true ? (
             <> <tr>
                <td>
                  <CurrencyRupeeIcon />
                  Amount Collected By Us
                </td>
                <td>:</td>
                <td>{modalData?.payment?.amount / 100}</td>
              </tr>
              <tr>
                <td>
                  <CurrencyRupeeIcon />
                  Discount Given By Us
                </td>
                <td>:</td>
                <td>
                  {bmcDis}
                </td>
              </tr>
              <tr>
                <td>
                  <CurrencyRupeeIcon />
                  Discount Given By Store
                </td>
                <td>:</td>
                <td>
                  {storeDis}
                </td>
              </tr>
              </>
            ) : (
              ""
            )}
            <tr>
              <td>Service(s)</td>
              <td>:</td>
              <td>
                <ul style={{ listStyleType: "none" }}>
                  {modalData?.serviceId?.map((e) => {
                    return <li>{e.serviceName}</li>;
                  })}
                </ul>
              </td>
            </tr>
          </table> */}
        </Modal>
      </div>
    </>
  );
};
