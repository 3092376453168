import React from 'react'
import { useForm } from 'react-hook-form';

export const Dropdowns = ({ fieldName, validations, firstFieldName, data ,register,onChange }) => {
    //console.log(data)
    const handleDropdownChange = (event) => {
        const selectedValue = event.target.value;
        if (onChange) {
            onChange(selectedValue);
        }
    };
    return (
        <div>
            <form>
                <select
                    // name={fieldName}
                    // className='city__dropdown'
                    className="px-2 py-2 rounded-lg my-1"
                style={{border:"1px solid #4c3f39"}}
                    {...register(fieldName,validations)}
                    onChange={handleDropdownChange}
                >
                    <option >{firstFieldName}</option>
                    {data?.value?.map((cities) => {
                        return (
                            <option value={cities._id}>{cities.cityName}</option>
                        );
                    })}
                </select>
            </form>
        </div>
    )
}
