import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {  removeTime } from '../../redux/CartSlice';
import axios from 'axios';
import jwt_decode from "jwt-decode";
const TimerComponent = (flag) => {
  const initialTimestamp = localStorage.getItem('timerStart') || new Date().getTime()+5*60*1000;
  const [elapsedTime, setElapsedTime] = useState(Math.floor((new Date().getTime() - initialTimestamp) / 1000));
  const [timerActive, setTimerActive] = useState(true);
  const dispatch = useDispatch()
  var decoded = null;
  const checkToken = () => {
    var token = localStorage.getItem("qwerty");
    if (token != null) {
      decoded = jwt_decode(token);
      // //console.log(decoded);
    }
  };
  useEffect(() => {
    checkToken()
      if (timerActive) {
        const interval = setInterval(() => {
          const currentTime = new Date().getTime();
          const elapsedSeconds = Math.floor((initialTimestamp-currentTime) / 1000);
          setElapsedTime(elapsedSeconds);
          // {//console.log("userId",decoded.id)}
          // console.log(elapsedSeconds)
          if (elapsedSeconds <= 0) {
            setTimerActive(false);
            axios.post('/api/v1/cart/updatetimer',{userId:decoded.id}).then((data)=>{
              // console.log(data)
            }).catch((error)=>{
              //console.log(error)
            })
            dispatch(removeTime())
            localStorage.removeItem('timerStart')
            clearInterval(interval);
            // flag?.cancelPayment()
            // flag?.stopPayment()
          }
        }, 1000);

        return () => clearInterval(interval);
      }
  }, [timerActive, initialTimestamp]);

  useEffect(() => {
    localStorage.setItem('timerStart', initialTimestamp.toString());
  }, [initialTimestamp]);

  return (
    <div>
      <h1>{elapsedTime && Math.floor(elapsedTime / 60)}:{(elapsedTime % 60).toString().padStart(2, '0')}</h1>
    </div>
  );
};

export default TimerComponent;