import Cookies from "js-cookie"
import { getApi } from "../APIManager/ApiManager"

const admin=localStorage.getItem('shopify')
const user=localStorage.getItem('qwerty')

const uuid=btoa(Cookies.get('session') || localStorage.getItem('session'))
export const businessData=()=>{
    return getApi("/api/v1/businessform/getbusinessdataforadmin",{user:user,admin:admin,uuid:uuid})
}

export const appointmentDataForAdmin=()=>{
    return getApi("/api/v1/appointment/getthreeappointmentforadmin",{user:user,admin:admin,uuid:uuid})
}

export const pastAppointmentDataForAdmin=()=>{
    return getApi('/api/v1/appointment/getdata',{user:user,admin:admin,uuid:uuid})
}

export const pastAppointmentDataTemp=()=>{
    return getApi('/api/v1/appointment/getPastAdminAppointment/temp',{user:user,admin:admin,uuid:uuid})
}

export const chartData=()=>{
    return getApi('/api/v1/appointment/getdata',{user:user,admin:admin,uuid:uuid})
}

export const pastAppointmentDataForAdmin1=()=>{
    return getApi('/api/v1/appointment/getPastAdminAppointment',{user:user,admin:admin,uuid:uuid})
}