import React from 'react'
import { useState } from 'react';
import Swal from "sweetalert2";
import thankyouImage from "../Images/thank-you.png"
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import formImage from "../Images/surveyForm.png";
// import formImage from "../Images/coffee_1.jpg";

const SurveyFormForAll = () => {
    const [gender, setGender] = useState('');
    const [age,setAge]=useState();
    const navigate=useNavigate();
    const sweetAlertTrigger=()=>{
        Swal.fire({
            imageUrl:thankyouImage,
            imageHeight:180,
            imageWidth:180,
            // icon: "success",
            title: "Please check your mail for rewards !!",
            confirmButtonColor: "#4c3f39",
          });
    }

    const [formData,setFormData]=useState({
        "name":"",
        "college":"",
        "gender":"",
        "age":"",
        "address":"",
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,       // Spread the previous state
            [name]: value,     // Update the specific field
        }));
    };

    const handleSubmit=(e)=>{
        e.preventDefault();
        formData.surveyFormName='collegeStudentsForm'
        // console.log(formData);
        sweetAlertTrigger();
        // setTimeout(()=>{
        // },2000);
        axios.post("/api/v2/info/surveyform",formData).then((data)=>{
            // console.log(data);
            // setTimeout(()=>{
            // },2000);
        }).catch((err)=>{
            console.log(err);
        }).finally(()=>{
            navigate("/");
            window.scrollTo(0, 0);
        })
    }


  return (
    <div className=" min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8  "
    >
        {/* // style={{background:'var(--listofStore-background-color)'}} */}
            <form className="bg-transparent py-3 px-4 sm:px-10  mb-4 border border-x-2 border-gray-500 rounded-lg  w-[90vh] sm:w-[80vh] shadow-lg"
            onSubmit={handleSubmit}
            >
                <div className='h-[30vh] mb-4 rounded-lg flex items-end justify-center'
                style={{backgroundImage:`url(${formImage})`,backgroundSize:"cover",}}
                >
                    {/* <img src={formImage} alt="" /> */}
                <h2 className="text-2xl font-bold py-3 rounded-lg text-white "
                // backgroundPosition:"left center"
                >
                Market Research Form</h2>
                    {/* <p>
                    
                    </p> */}
                </div>
                    <p className='text-xl  px-3 py-2 rounded-lg '
                    style={{background:"#f0f0f0", borderLeft:"4px solid #4c3f39"}}
                    >
                    We’d love to hear your thoughts on our salon appointment system! Just a few quick questions to help us make booking easier and better for you.  
                    </p>
                <div className="my-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
                        Name
                    </label>
                    <input
                        className=" appearance-none  rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="name"
                        type="text"
                        name='name'
                        placeholder="Name"
                        onChange={handleChange}
                        required
                        style={{border:"1px solid #4c3f39"}}
                    />
                </div>
                <div className="my-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                        Email
                    </label>
                    <input
                        className=" appearance-none  rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="email"
                        type="email"
                        name='email'
                        placeholder="xyz@gmail.com"
                        onChange={handleChange}
                        required
                        style={{border:"1px solid #4c3f39"}}
                    />
                </div>
                <div className="my-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="college">
                        College / Organisation Name
                    </label>
                    <input
                        className=" appearance-none  rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="college"
                        name="college"
                        type="text"
                        placeholder="Nirma University"
                        onChange={handleChange}
                        required
                        style={{border:"1px solid #4c3f39"}}
                    />
                </div>
                <div className="mb-4 ">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="gender">
                        Gender
                    </label>
                    <select
                        className=" appearance-none  rounded w-full py-2 px-3 text-gray-700 leading-tight"
                        // style={{background:'var(--listofStore-background-color)'}}
                        id="gender"
                        name="gender"
                        value={gender}
                        required
                        onChange={(e) => {
                            setGender(e.target.value);
                            handleChange(e);
                        }}        
                        style={{border:"1px solid #4c3f39"}}
                                    >
                        <option value="" disabled>Select Gender</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                    </select>
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="age">
                        Age Group
                    </label>
                    <select
                        className=" appearance-none  rounded w-full py-2 px-3 text-gray-700 leading-tight  "
                        // style={{background:'var(--listofStore-background-color)'}}
                        id="age"
                        name="age"
                        value={age}
                        required
                        onChange={(e) => {setAge(e.target.value);handleChange(e)}}
                        style={{border:"1px solid #4c3f39"}}
                    >
                        <option value="">Select Age Group</option>
                        <option value="<18">Less than 18</option>
                        <option value="18-25">18-25</option>
                        <option value="26-35">26-35</option>
                        <option value=">35">Greater than 35</option>
                    </select>
                </div>
                <div className="my-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="address">
                        Residential Area
                    </label>
                    <input
                        className=" appearance-none  rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="address"
                        name="address"
                        type="text"
                        required
                        placeholder="Thaltej"
                        onChange={handleChange}
                        style={{border:"1px solid #4c3f39"}}
                    />
                </div>
                {gender === 'male' ? (
                    <>
                        <div className="my-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="preferredHaircutPlace">
                                {/* Is there a particular area or salon/barbershop you prefer for your haircut or beard grooming? */}
                                Where do you usually go for your hair and grooming related services?
                            </label>
                            <input
                                className=" appearance-none  rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="preferredHaircutPlace"
                                name='preferredHaircutPlace'
                                type="text"
                                placeholder="Sindhu Bhavan Road"
                                onChange={handleChange}
                                required
                                style={{border:"1px solid #4c3f39"}}
                            />
                        </div>
                        <div className="my-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="haircutPlace">
                                {/* Where do you usually get your haircut or beard trimmed? (Salon/Barbershop Name)*/}
                                Which Barber shop do you usually go to for your hair treatments? 
                            </label>
                            <input
                                className=" appearance-none  rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="haircutPlace"
                                name='haircutPlace'
                                type="text"
                                placeholder="Jawed Habib, C.G. Road"
                                onChange={handleChange}
                                style={{border:"1px solid #4c3f39"}}
                                // required
                            />
                        </div>
                    </>)
                :(
                   gender=='female' && <>
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="preferredBeautyPlace">
                                {/* Is there a particular area or beauty parlor you prefer for your beauty treatments? */}
                                Do you have a preferred location where you usually go for your beauty services?
                            </label>
                            <input
                                className=" appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="preferredBeautyPlace"
                                name="preferredHaircutPlace"
                                type="text"
                                placeholder="Sindhu Bhavan Road"
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="beautyPlace">
                                {/* Where do you usually go for your beauty treatments? (Beauty Parlor Name) */}
                                Which beauty parlor do you usually go to for your beauty treatments? 
                            </label>
                            <input
                                className=" appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="beautyPlace"
                                type="text"
                                name='haircutPlace'
                                placeholder="Apsara Beauty Parlor"
                                onChange={handleChange}
                                // required
                            />
                        </div>
                    </>
                )}
                <div className="flex items-center justify-between">
                    <button
                        className="bg-orange-900 hover:bg-orange-700     text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        type="submit"
                    >
                        Submit
                    </button>
                </div>
            </form>
        </div>
  )
}

export default SurveyFormForAll
