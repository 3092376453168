import { UserData } from "./Data";
import BarChart from "./Barchart";
import PieChart from "./Piechart";
import react, { useState, useEffect, useDeferredValue } from "react";
import "./Stats.css";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import axios from "axios";
import { TopUser } from "./TopUser";
import LineChart from "./Linechart";
import { chartData, pastAppointmentDataForAdmin1 } from "../../API/AdminHomePageApi";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const Stats = () => {
  const [statsData, setStatsData] = useState([]);
  const [newUserData, setNewUserData] = useState();
  const [newData, setNewData] = useState({});
  const [employeeData, setEmployeeData] = useState({});
  const [turnOver,setTurnOver]=useState([])
  const [topUsers,setTopUsers]=useState([])
  const [userData, setUserData] = useState({
    labels: UserData.map((data) => data.month),
    datasets: [
      {
        label: "Users Gained",
        data: UserData.map((data) => data.userGain),
        backgroundColor: [
          "rgba(75,192,192,1)",
          "#ecf0f1",
          "#50AF95",
          "#f3ba2f",
          "#2a71d0",
        ],
        borderColor: "black",
        borderWidth: 2,
      },
    ],
  });
  var data = [3, 1, 4, 1, 5, 9, 2, 6, 5, 3, 5, 8, 9, 7, 9, 3, 2, 3, 4, 1];
  const monthName = {
    1: "Jan",
    2: "Feb",
    3: "March",
    4: "April",
    5: "May",
    6: "June",
    7: "July",
    8: "Aug",
    9: "Sep",
    10: "Oct",
    11: "Nov",
    12: "Dec",
  };
  const getStatsData = () => {
    const month = 2592000000;
    const week = 604800000;
    pastAppointmentDataForAdmin1()
      .then((data) => {
        // console.log("1");
        setStatsData(data.data.data);
        // console.log(data);
      })
      .catch((error) => {
        // console.log(error);
      });
    chartData()
      .then((data1) => {
        // console.log("DAADADADATA", data1.data);
        setTopUsers(data1?.data?.TopAppointments)
        setNewData({
          labels: data1.data.checkedIn?.map((e) => monthName[e.month-1]),
          datasets: [
            {
              label: "Appointments Gained",
              data: data1.data?.checkedIn?.map((e) => e?.notCheckIn),
              backgroundColor: [
                "rgba(75,192,192,1)",
                "#ecf0f1",
                "#50AF95",
                "#f3ba2f",
                "#2a71d0",
              ],
              borderColor: "black",
              borderWidth: 2,
            },
            {
              label: "Appointments Checked In",
              data: data1?.data?.checkedIn?.map((e) => e.checkIn),
              backgroundColor: [
                "rgba(75,192,192,1)",
                "#ecf0f1",
                "#50AF95",
                "#f3ba2f",
                "#2a71d0",
              ],
              borderColor: "black",
              borderWidth: 2,
            },
          ],
        });
        setEmployeeData({
          labels: data1.data.employeeTurnover?.map((e) => e.employeeName),
          datasets: [
            {
              label: "Users Gained",
              data: data1.data.employeeTurnover.map((e) => e.totalPrice),
              backgroundColor: [
                "rgba(75,192,192,1)",
                "#ecf0f1",
                "#50AF95",
                "#f3ba2f",
                "#2a71d0",
              ],
              borderColor: "black",
              borderWidth: 2,
            },
          ],
        });
        setTurnOver({
          labels:data1.data.checkedIn?.map((e) => monthName[e.month-1]),
          datasets: [
            {
              label: "This Year Turn Over",
              data: data1.data?.checkedIn?.map((e) => e?.totalPrice),
              backgroundColor: [
                "rgba(75,192,192,1)",
                "#ecf0f1",
                "#50AF95",
                "#f3ba2f",
                "#2a71d0",
              ],
              borderColor: "black",
              borderWidth: 2,
            },
          ],
        })
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  // setMonthlyData
  const monthlyData = [
    { id: 1, month: "Jan", data: 0 },
    { id: 2, month: "Feb", data: 0 },
    { id: 3, month: "March", data: 0 },
    { id: 4, month: "April", data: 0 },
    { id: 5, month: "May", data: 0 },
    { id: 6, month: "June", data: 0 },
    { id: 7, month: "July", data: 0 },
    { id: 8, month: "Aug", data: 0 },
    { id: 9, month: "Sep", data: 0 },
    { id: 10, month: "Oct", data: 0 },
    { id: 11, month: "Nov", data: 0 },
    { id: 12, month: "Dec", data: 0 },
  ];
  const changeStatsData = () => {
    var m = 0;
    var tp = 0;
    statsData.map((e) => {
      const time = e?._id?.month;
      // console.log(time);
      // console.log(e);
      if (time != null && time != undefined && time != "") {
        // console.log(monthName[parseInt(time)]);
        monthlyData[parseInt(time) - 1].data = e?.count;
        // console.log(monthlyData[parseInt(time) - 1].data);
        data.push(e?.count);
      }
    });
    setNewUserData({
      labels: monthlyData.map((data) => data.month),
      datasets: [
        {
          label: "Users Gained",
          data: monthlyData.map((data) => data.data),
          backgroundColor: [
            "rgba(75,192,192,1)",
            "#ecf0f1",
            "#50AF95",
            "#f3ba2f",
            "#2a71d0",
          ],
          borderColor: "black",
          borderWidth: 2,
        },
      ],
    });
  };

  useEffect(() => {
    changeStatsData();
  }, [statsData]);

  useEffect(() => {
    getStatsData();
  }, []);

  const colors = {
    1: "red",
    2: "orange",
    3: "yellow",
    4: "green",
    5: "blue",
    6: "purple",
    7: "pink",
    8: "brown",
    9: "gray",
  };
  return (
    <div>
      {newUserData && (
        <Box sx={{ flexGrow: 1, height: "100%", width: "100%" }}>
          <Grid container spacing={3} columns={12}>
            <Grid item xs={12} md={6} sx={{ margin: "auto" }}>
              <div className="text-align-center">
              THis is a chart which is shows you some....
              </div>
            </Grid>
            <Grid item md={6} xs={12} >
              <Item className="stats__chart">
                {Object.keys(newData).length !== 0 && (
                  <BarChart chartData={userData} Data={newData} />
                )}
              </Item>
            </Grid>

            <Grid item xs={12} md={6}>
              {Object.keys(newData).length !== 0 && (
                <Item className="stats__chart">
                  <PieChart chartData={userData} newData={employeeData} />
                </Item>
              )}
            </Grid>
            <Grid item xs={12} md={6} sx={{ margin: "auto" }}>
              THis is a chart which is shows you some....
            </Grid>

            <Grid item xs={12} md={6}>
              {Object.keys(newData).length !== 0 && (
                <Item className="stats__chart">
                  <LineChart chartData={userData} newData={turnOver} />
                </Item>
              )}
            </Grid>
          </Grid>
        </Box>
      )}
      <TopUser topUsers={topUsers}/>
    </div>
  );
};

export default Stats;
