import React from 'react'
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

const CalendarModal = (props) => {
        // const [show, setShow] = useState(false);
      
        // const handleClose = () => setShow(false);
        // console.log(isOpen);
        // const handleShow = () => setShow(isOpen);
        return (
          <>
            {/* <Button variant="primary" onClick={handleShow}>
              Launch demo modal
            </Button>
       */}
            <Modal show={props.isOpen}
            onHide={()=>props.onClose(false)}
            // show={show} onHide={handleClose}
            >
              <Modal.Header closeButton>
                <Modal.Title>Modal heading</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="name@example.com"
                      autoFocus
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Example textarea</Form.Label>
                    <Form.Control as="textarea" rows={3} />
                  </Form.Group>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={()=>props.onClose(false)}>
                  Close
                </Button>
                <Button variant="primary" onClick={()=>props.onClose(false)}>
                  Save Changes
                </Button>
              </Modal.Footer>
            </Modal>
          </>
        );
}

export default CalendarModal