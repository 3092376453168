import React, { useEffect, useState } from "react";
import "./filter.css";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { clearFilter, filterStore, removeStore } from "../../redux/StoreSlice";
import { useForm } from "react-hook-form";
import errors from "../../Error";
import { useNavigate } from "react-router-dom";
import {
  addCategory,
  addGender,
  clearAll,
  setSelectedCategories,
} from "../../redux/FilterSlice";
import {
  getCityData,
  getLocalityData,
  getStoreCategoryData,
} from "../../API/AdditionalApi";
import filterIcon from "../../Images/filter.png";
import { CiFilter } from "react-icons/ci";


import styles from "./filter.module.css";
export const Filter = () => {
  const {
    register,
    handleSubmit,
    formState: {},
  } = useForm();
  const [city, setCity] = useState([]);
  const [locality, setLocality] = useState([]);
  const [storeCat, setStoreCat] = useState();
  const [categoryFilter, setCategoryFilter] = useState({});
  const [isMobile, setIsMobile] = useState();
  const filter = useSelector((state) => state.filter);
  var [gender, setGender] = useState([]);
  var [trueCat, setTrueCat] = useState([]);
  var [selectCity, setSelectCity] = useState([]);
  var [selectLocality, setSelectLocality] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getStoreCategory = () => {
    var initialCategoryFilter = {};
    getStoreCategoryData()
      .then((data) => {
        setStoreCat(data.data.data);
        if (Object.keys(filter.categories).length === 0) {
          initialCategoryFilter = data.data.data.reduce((filter, product) => {
            filter[product.categoryName] = false;
            return filter;
          }, {});
        } else {
          initialCategoryFilter = data.data.data.reduce((filter1, product) => {
            filter1[product.categoryName] =
              filter?.categories[product.categoryName] || false;
            return filter1;
          }, {});
        }
        setCategoryFilter(initialCategoryFilter);
        dispatch(setSelectedCategories(initialCategoryFilter));
        setGender(filter?.gender || []);
      })
      .catch((error) => {
        errors(error, navigate);
      });
  };
  const handleCategoryChange = (category) => {
    // console.log(category);
    setCategoryFilter((prevFilter) => {
      const updatedFilter = {
        ...prevFilter,
        [category]: !prevFilter[category],
      };
      const selectedCategories = Object.keys(updatedFilter).filter(
        (category) => updatedFilter[category]
      );
      dispatch(
        filterStore({
          category: selectedCategories,
          value: "storeType",
          gender: gender,
          city: selectCity,
          locality: selectLocality,
        })
      );
      dispatch(addCategory(category));
      setTrueCat(selectedCategories);
      return updatedFilter;
    });
  };
  useEffect(() => {
    dispatch(clearFilter());
    getStoreCategory();
    getCity();
    // console.log(filter)
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust the breakpoint as needed
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const clearFilter1 = () => {
    // console.log(categoryFilter)
    dispatch(clearFilter());
    dispatch(clearAll());
    setGender([]);
    const updatedServices = Object.keys(categoryFilter).reduce((acc, key) => {
      acc[key] = false;
      return acc;
    }, {});
    setCategoryFilter(updatedServices);
  };
  const handleGenderChange = (e) => {
    setGender([e.target.value]);
    dispatch(
      filterStore({
        category: trueCat,
        value: "storeType",
        gender: [e.target.value],
        city: selectCity,
        locality: selectLocality,
      })
    );
    dispatch(addGender(e.target.value));
  };

  const getCity = () => {
    getCityData()
      .then((data) => {
        setCity(data.data.data);
      })
      .catch((error) => {
        //console.log(error);
      });
  };
  const getLocality = (e) => {
    // console.log("DDD", e.target.value);
    if (e.target.value == "None") {
      setSelectCity([]);
      dispatch(
        filterStore({
          category: trueCat,
          value: "storeType",
          gender: gender,
          city: [],
          locality: selectLocality,
        })
      );
    } else {
      setSelectCity([e.target.value]);
      dispatch(
        filterStore({
          category: trueCat,
          value: "storeType",
          gender: gender,
          city: [e.target.value],
          locality: selectLocality,
        })
      );
    }
    getLocalityData(e.target.value)
      .then((data) => {
        setLocality(data.data.data);
      })
      .catch((error) => {
        //console.log(error);
      });
  };

    const local = (e) => {
        // console.log("LOCAL", e.target.value);
        if (e.target.value == "None") {
            setSelectLocality([])
            dispatch(filterStore({ category: trueCat, value: "storeType", gender: gender, city: selectCity, locality: [] }))
        }
        else {
            setSelectLocality([e.target.value])
            dispatch(filterStore({ category: trueCat, value: "storeType", gender: gender, city: selectCity, locality: [e.target.value] }))
        }
    }
//     return (
//         <>
//             {/* {console.log(gender)} */}
//             <div id='sidebar'>
//                 {
//                     isMobile ? (
//                         <div className='mobile__filter mobile'>
//                             <button className="btn btn-primary" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">Filter</button>
//                             <div className="offcanvas offcanvas-end w-100" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
//                                 <div className="offcanvas-header">
//                                     <h5 className="offcanvas-title" id="offcanvasRightLabel">Filter</h5>
//                                     <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
//                                 </div>
//                                 <div className="offcanvas-body">
//                                     <form>
//                                         <h2>Categories:</h2>
//                                         {Object.keys(categoryFilter).map((category) => {
//                                             return (
//                                                 <div className="form-check justify-content-center align-items-center">
//                                                     <input
//                                                         className="form-check-input"
//                                                         type="checkbox"
//                                                         id={category}
//                                                         checked={categoryFilter[category]}
//                                                         onClick={() => handleCategoryChange(category)}
//                                                     />
//                                                     <label key={category} className="form-check-label w-100" for={category}>
//                                                         {category}
//                                                     </label>
//                                                 </div>
//                                             )
//                                         })}
//                                         <hr />
//                                         <h2>Gender:</h2>
//                                         <div className="form-check">
//                                             <input type="radio" id="male" className="form-check-input" name="gender" value="male" onClick={handleGenderChange} checked={gender[0] === "male" ? true : false} />
//                                             <label htmlFor="male">
//                                                 Male
//                                             </label>
//                                         </div>
//                                         <div className="form-check">
//                                             <input type="radio" id="female" className="form-check-input" name="gender" value="female" onClick={handleGenderChange} checked={gender[0] === "female" ? true : false} />
//                                             <label htmlFor="female">
//                                                 Female
//                                             </label>
//                                         </div>
//                                         <div className="form-check">
//                                             <input type="radio" id="unisex" className="form-check-input" name="gender" value="unisex" onClick={handleGenderChange} checked={gender[0] === "unisex" ? true : false} />
//                                             <label htmlFor="unisex">
//                                                 Unisex
//                                             </label>
//                                         </div>
//                                         <div className="flex flex-col gap-[5px] ">
//                                             <label htmlFor="name" className="text-[#4a5568] font-[700] text-[14px]">
//                                                 City {<span style={{ color: "red", paddingLeft: 5 }}>{errors?.city?.message}</span>}
//                                             </label>
//                                             <select
//                                                 name="city"
//                                                 {...register("city")}
//                                                 onChange={getLocality}
//                                                 required=""
//                                                 className="bg-[#e2e8f0] py-[.8rem] rounded-[4px] px-[1rem] text-[#1a202c] outline-none"
//                                             >
//                                                 <option value="None">Clear</option>
//                                                 {city?.map((cities) => {
//                                                     return (<option value={cities._id}>{cities.cityName}</option>);
//                                                 })}
//                                             </select>
//                                         </div>
//                                         <div className="flex flex-col gap-[5px]">
//                                             <label htmlFor="name" className="text-[#4a5568] font-[700] text-[14px]">
//                                                 Locality {<span style={{ color: "red", paddingLeft: 5 }}>{errors?.locality?.message}</span>}
//                                             </label>
//                                             <select
//                                                 className="bg-[#e2e8f0] py-[.8rem] rounded-[4px] px-[1rem] text-[#1a202c] outline-none"
//                                                 required=""
//                                                 onChange={local}
//                                                 name="locality"
//                                                 {...register("locality")}
//                                             >
//                                                 <option value="None">Clear</option>
//                                                 {locality?.map((localities) => {
//                                                     return (<option value={localities?.localityName}>{localities.localityName}</option>);
//                                                 })}
//                                             </select>
//                                         </div>
//                                         <div className="d-flex flex-row gap-4">
//                                             {/* <input onClick={clearFilter1} type="reset" value="Clear All filters"></input> */}
//                                             <button onClick={clearFilter1} type="reset" value="Clear All filters"
//                                                 className='btn btn-primary'
//                                             >
//                                                 Clear All filters
//                                             </button>
//                                             <button type="button" className="btn btn-success" data-bs-dismiss="offcanvas" aria-label="Close">Apply</button>
//                                         </div>
//                                     </form>
//                                 </div>
//                             </div>
//                         </div>) : (<form>
//                             <h2>Categories:</h2>
//                             {Object.keys(categoryFilter).map((category) => {
//                                 return (
//                                     <div className="form-check justify-content-center align-items-center">
//                                         <input
//                                             className="form-check-input"
//                                             type="checkbox"
//                                             id={category}
//                                             checked={categoryFilter[category]}
//                                             onClick={() => handleCategoryChange(category)}
//                                         />
//                                         <label key={category} className="form-check-label w-100" for={category}>
//                                             {category}
//                                         </label>
//                                     </div>
//                                 )
//                             })}
//                             <hr className='mt-3 mb-3 border border-dark-subtle rounded ' />
//                             <h2>Gender:</h2>
//                             <div className="form-check">
//                                 <label htmlFor="male">
//                                     Male
//                                 </label>
//                                 <input type="radio" id="male" className="form-check-input" name="gender" value="male" onClick={handleGenderChange} checked={gender[0] === "male" ? true : false} />
//                             </div>
//                             <div className="form-check">
//                                 <label htmlFor="female">
//                                     Female
//                                 </label>
//                                 <input type="radio" id="female" className="form-check-input" name="gender" value="female" onClick={handleGenderChange} checked={gender[0] === "female" ? true : false} />
//                             </div>
//                             <div className="form-check">
//                                 <label htmlFor="unisex">
//                                     Unisex
//                                 </label>
//                                 <input type="radio" id="unisex" className="form-check-input" name="gender" value="unisex" onClick={handleGenderChange} checked={gender[0] === "unisex" ? true : false} />
//                             </div>
//                             <div className="flex flex-col gap-[5px]">
//                                 <label htmlFor="name" className="mt-4 text-[#4a5568] font-[700] text-[14px]">
//                                     City {<span style={{ color: "red", paddingLeft: 5 }}>{errors?.city?.message}</span>}
//                                 </label>
//                                 <select
//                                     name="city"
//                                     {...register("city")}
//                                     onChange={getLocality}
//                                     required=""
//                                     className="bg-[#e2e8f0] py-[.8rem] rounded-[4px] px-[1rem] text-[#1a202c] outline-none "
//                                 >
//                                     <option value="None">--Select City--</option>
//                                     {city?.map((cities) => {
//                                         return (<option value={cities._id}>{cities.cityName}</option>);
//                                     })}
//                                 </select>
//                             </div>
//                             <div className="flex flex-col gap-[5px]">
//                                 <label htmlFor="name" className="text-[#4a5568] font-[700] text-[14px]">
//                                     Locality {<span style={{ color: "red", paddingLeft: 5 }}>{errors?.locality?.message}</span>}
//                                 </label>
//                                 <select
//                                     className="bg-[#e2e8f0] py-[.8rem] rounded-[4px] px-[1rem] text-[#1a202c] outline-none "
//                                     required=""
//                                     onClick={local}
//                                     name="locality"
//                                     {...register("locality")}
//                                 >
//                                     <option value="None">--Select Locality--</option>
//                                     {locality?.map((localities) => {
//                                         return (<option value={localities?.localityName}>{localities.localityName}</option>);
//                                     })}
//                                 </select>
//                             </div>
//                             <button onClick={clearFilter1} type="button"  className='btn btn-danger reset p-2 w-100 rounded'>
//                             Clear All filters
//                             </button>
//                             {/* <input onClick={clearFilter1} type="reset" value="Clear All filters" className='btn btn-danger reset' ></input> */}
//                         </form>)
//                 }
//             </div>
//         </>
//     )
// }
//   const local = (e) => {
//     // console.log("LOCAL", e.target.value);
//     if (e.target.value == "None") {
//       setSelectLocality([]);
//       dispatch(
//         filterStore({
//           category: trueCat,
//           value: "storeType",
//           gender: gender,
//           city: selectCity,
//           locality: [],
//         })
//       );
//     } else {
//       setSelectLocality([e.target.value]);
//       dispatch(
//         filterStore({
//           category: trueCat,
//           value: "storeType",
//           gender: gender,
//           city: selectCity,
//           locality: [e.target.value],
//         })
//       );
//     }
//   };
  return (
    <>
      {/* {console.log(gender)} */}
      <div id="sidebar">
        {isMobile ? (
          <div className={`mobile__filter mobile ${styles.mobileFilter}`}>
            <button
              className={`btn`}
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasRight"
              aria-controls="offcanvasRight"
              >
                <CiFilter className={styles.filterIcon} />
                <h2 className={styles.sideBar}>
              Filter
              </h2>

              {/* <img
                className={styles.filterIcon}
                src={filterIcon}
                alt="filterIcon"
                /> */}
            </button>
            <div
              className={`offcanvas offcanvas-end w-100 ${styles.mobileFilter}`}
              tabindex="-1"
              id="offcanvasRight"
              aria-labelledby="offcanvasRightLabel"
            >
              <div className="offcanvas-header">
                <h5 className="offcanvas-title" id="offcanvasRightLabel">
                <b>Filter</b>
              <img
                className={styles.filterIcon}
                src={filterIcon}
                alt="filterIcon"
                />
                </h5>
                <button
                  type="button"
                  className="btn-close border border-dark rounded-25"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                ></button>
              </div>
              <div className="offcanvas-body">
                <form className={styles.mobileFilter}>
                    <li>
                    Categories:
                    </li>
                  <div className={styles.categories}>
                  {Object.keys(categoryFilter).map((category) => {
                    return (
                      <div className="form-check justify-content-center align-items-center">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id={category}
                          checked={categoryFilter[category]}
                          onClick={() => handleCategoryChange(category)}
                        />
                        <label
                          key={category}
                          className="form-check-label w-100"
                          for={category}
                        >
                          {category}
                        </label>
                      </div>
                    );
                  })}
                  </div>
                    <li className="mt-2">
                    Gender:
                    </li>
                  <div className={styles.gender}>
                  
                  <div className="form-check">
                    <input
                      type="radio"
                      id="male"
                      className="form-check-input"
                      name="gender"
                      value="male"
                      onClick={handleGenderChange}
                      checked={gender[0] === "male" ? true : false}
                    />
                    <label htmlFor="male">Male</label>
                  </div>
                  <div className="form-check">
                    <input
                      type="radio"
                      id="female"
                      className="form-check-input"
                      name="gender"
                      value="female"
                      onClick={handleGenderChange}
                      checked={gender[0] === "female" ? true : false}
                    />
                    <label htmlFor="female">Female</label>
                  </div>
                  <div className="form-check">
                    <input
                      type="radio"
                      id="unisex"
                      className="form-check-input"
                      name="gender"
                      value="unisex"
                      onClick={handleGenderChange}
                      checked={gender[0] === "unisex" ? true : false}
                    />
                    <label htmlFor="unisex">Unisex</label>
                  </div>
                  </div>
                  <div className="flex flex-col gap-[5px] mt-3 ">
                    <label
                      htmlFor="name"
                      className="text-[#4a5568] font-[700] text-[14px]"
                    >
                      <li>
                      City
                      </li>
                      {
                        <span style={{ color: "red"}}>
                          {errors?.city?.message}
                        </span>
                      }
                    </label>
                    <select
                      name="city"
                      {...register("city")}
                      onChange={getLocality}
                      required=""
                      className={`mt-2 ${styles.dropdown}`}
                      // className="bg-[#e2e8f0] py-[.8rem] rounded-[4px] px-[1rem] text-[#1a202c] outline-none"
                    >
                      <option value="None">Select city</option>
                      {city?.map((cities) => {
                        return (
                          <option value={cities._id}>{cities.cityName}</option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="flex flex-col gap-[5px] mt-3">
                    <label
                      htmlFor="name"
                      className="text-[#4a5568] font-[700] text-[14px]"
                    >
                      <li>
                      Locality
                      </li>
                      {
                        <span style={{ color: "red"}}>
                          {errors?.locality?.message}
                        </span>
                      }
                    </label>
                    <select
                    className={`mt-2 ${styles.dropdown}`}
                      // className="bg-[#e2e8f0] py-[.8rem] rounded-[4px] px-[1rem] text-[#1a202c] outline-none"
                      required=""
                      onChange={local}
                      name="locality"
                      {...register("locality")}
                    >
                      <option value="None">Select locality</option>
                      {locality?.map((localities) => {
                        return (
                          <option value={localities?.localityName}>
                            {localities.localityName}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="w-100 mt-3">
                    <button
                      onClick={clearFilter1}
                      type="reset"
                      value="Clear All filters"
                      className={styles.reset}
                    >
                      Clear Search
                    </button>
                    <button
                      type="button"
                      className={`${styles.reset} mt-2`}
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    >
                      Close
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        ) : (
          <form>
            <h2 className={styles.sideBar}>
              <b>Filter</b>
              <img
                className={styles.filterIcon}
                src={filterIcon}
                alt="filterIcon"
              />
            </h2>
            <li>Category:</li>
            {Object.keys(categoryFilter).map((category) => {
              return (
                <div className="form-check justify-content-center align-items-center">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id={category}
                    checked={categoryFilter[category]}
                    onClick={() => handleCategoryChange(category)}
                  />
                  <label
                    key={category}
                    className={`form-check-label w-100 ${styles.grayText}`}
                    for={category}
                  >
                    {category}
                  </label>
                </div>
              );
            })}
            <hr className="mt-3 mb-3 border border-dark-subtle rounded " />
            <li>
              <b>Gender:</b>
            </li>
            <div className="form-check">
              <label htmlFor="male" className={`${styles.grayText}`}>
                Male
              </label>
              <input
                type="radio"
                id="male"
                className="form-check-input"
                name="gender"
                value="male"
                onClick={handleGenderChange}
                checked={gender[0] === "male" ? true : false}
              />
            </div>
            <div className="form-check">
              <label htmlFor="female" className={`${styles.grayText}`}>
                Female
              </label>
              <input
                type="radio"
                id="female"
                className="form-check-input"
                name="gender"
                value="female"
                onClick={handleGenderChange}
                checked={gender[0] === "female" ? true : false}
              />
            </div>
            <div className="form-check">
              <label htmlFor="unisex" className={`${styles.grayText}`}>
                Unisex
              </label>
              <input
                type="radio"
                id="unisex"
                className="form-check-input"
                name="gender"
                value="unisex"
                onClick={handleGenderChange}
                checked={gender[0] === "unisex" ? true : false}
              />
            </div>
            <div className="flex flex-col gap-[11px] mt-3">
              <label htmlFor="name" className="font-[700] text-[14px] mb-0">
                <li>City</li>
                {<span style={{ color: "red" }}>{errors?.city?.message}</span>}
              </label>
              <select
                name="city"
                {...register("city")}
                onChange={getLocality}
                required=""
                className={styles.dropdown}
                // className="bg-[#e2e8f0] py-[.8rem] rounded-[4px] px-[1rem] text-[#1a202c] outline-none "
              >
                <option value="None">Select City</option>
                {city?.map((cities) => {
                  return <option value={cities._id}>{cities.cityName}</option>;
                })}
              </select>
            </div>
            <div className="flex flex-col gap-[1px] mt-4">
              <label
                htmlFor="name"
                className="text-[#4a5568] font-[700] text-[14px]"
              >
                <li>Locality</li>
                {
                  <span style={{ color: "red" }}>
                    {errors?.locality?.message}
                  </span>
                }
              </label>
              <select
                className={styles.dropdown}
                // className="bg-[#e2e8f0] py-[.8rem] rounded-[4px] px-[1rem] text-[#1a202c] outline-none "
                required=""
                onClick={local}
                name="locality"
                {...register("locality")}
              >
                <option value="None">Select Locality</option>
                {locality?.map((localities) => {
                  return (
                    <option value={localities?.localityName}>
                      {localities.localityName}
                    </option>
                  );
                })}
              </select>
            </div>
            <button
              onClick={clearFilter1}
              type="button"
              className={`reset p-2 w-100 mt-3 ${styles.reset}`}
            >
              clear filter
            </button>
            {/* <input onClick={clearFilter1} type="reset" value="Clear All filters" className='btn btn-danger reset' ></input> */}
          </form>
        )}
      </div>
    </>
  );
};
