import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Demo.css";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import BarChart from "./Barchart";

import { useNavigate } from "react-router-dom";
import Paper from "@mui/material/Paper";
import { UserData } from "./Data";
import { Loader } from "../../Components/Loader";
import jwtDecode from "jwt-decode";
import io from "socket.io-client";
import { TopUser } from "./TopUser";
import {
  appointmentDataForAdmin,
  businessData,
  chartData,
  pastAppointmentDataForAdmin,
  pastAppointmentDataTemp,
} from "../../API/AdminHomePageApi";
import Calendar from "../../Components/Calendar";
import { checkInApi } from "../../API/AdditionalApi";
import Swal from "sweetalert2";
import { deleteAppByAdmin } from "../../API/AppointmentApi";

const Demo = () => {
  const [adminData, setAdminData] = useState([]);
  const [appointmentData, setAppointmentData] = useState([]);
  const [value, setValue] = useState(2.5);
  const [pastAppointmentData, setPastAppointmentData] = useState([]);
  const [employeeData, setEmployeeData] = useState("");
  const [selectedEmp, setSelectedEmp] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorE2, setAnchorE2] = useState(null);
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);
  const [appointmentDate, setAppointmentDate] = useState();
  const [empData, setEmpData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [topUsers, setTopUsers] = useState([]);
  const [turnOver, setTurnOver] = useState([]);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const decoded = jwtDecode(localStorage.getItem("shopify"));
  // console.log("qwertg", decoded);
  const handleClick = (event, id) => {
    // //console.log(event.currentTarget)
    setAnchorE2(id);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorE2(null);
  };

  const open = anchorE2;
  const id = open ? "simple-popover" : undefined;

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));
  const today = new Date();
  // const id = open ? 'simple-popover' : undefined;

  const monthName = {
    0: "Jan",
    1: "Feb",
    2: "March",
    3: "April",
    4: "May",
    5: "June",
    6: "July",
    7: "Aug",
    8: "Sep",
    9: "Oct",
    10: "Nov",
    11: "Dec",
  };
  const weekDay = {
    0: "Sun",
    1: "Mon",
    2: "Tue",
    3: "Wed",
    4: "Thur",
    5: "Fri",
    6: "Sat",
  };

  const getData = () => {
    setLoading(true);
    businessData()
      .then((data) => {
        setEmpData(data?.data?.employeeData);
        setAdminData(data?.data?.data);
      })
      .finally(() => setLoading(false));
  };

  const getAppointmentData = () => {
    setLoading(true);
    appointmentDataForAdmin()
      .then((data) => {
        setAppointmentData(data?.data?.data);
      })
      .finally(() => setLoading(false));
  };

  const [WEEKDAYS, setWEEKDAYS] = useState([
    { day: "Sun", data: [] },
    { day: "Mon", data: [] },
    { day: "Tue", data: [] },
    { day: "Wed", data: [] },
    { day: "Thur", data: [] },
    { day: "Fri", data: [] },
    { day: "Sat", data: [] },
  ]);
  const xLabels = ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"];
  const [Data, setData] = useState([]);
  const f1 = () => {
    var days = {};

    const updatedWEEKDAYS = [...WEEKDAYS];
    pastAppointmentData.map((appointment) => {
      const fulldate = appointment.date.split("/");
      const d = parseInt(fulldate[0], 10);
      const m = parseInt(fulldate[1], 10);
      const y = parseInt(fulldate[2]);
      const day = weekDay[new Date(y, m - 1, d).getDay()];
      //console.log(new Date(y, m - 1, d));
      const index = updatedWEEKDAYS.findIndex((d) => d.day === day);
      if (index != -1) {
        updatedWEEKDAYS[index].data.push(appointment);
      }
    });
    // Update the state with the modified copy
    setWEEKDAYS(updatedWEEKDAYS);
    //console.log(WEEKDAYS);
    var arr = [];
    const dataLengths = WEEKDAYS.map((e) => e.data.length);
    setData(dataLengths);

    //console.log("DArARATTA",Data);
    Data.map((e) => {
      // console.log("WEDfzgfegr", e);
    });
  };

  const getPastAppointmentData = () => {
    setLoading(true);
    pastAppointmentDataForAdmin()
      .then((data1) => {
        // console.log("DAADADADATA", data1.data?.TopAppointments);
        // console.log("------------->",data1)
        setTopUsers(data1?.data?.TopAppointments);
        setNewData({
          labels: data1.data.checkedIn?.map((e) => monthName[e.month - 1]),
          datasets: [
            {
              label: "Appointments Gained",
              data: data1.data?.checkedIn?.map((e) => e?.notCheckIn),
              backgroundColor: [
                "rgba(75,192,192,1)",
                "#ecf0f1",
                "#50AF95",
                "#f3ba2f",
                "#2a71d0",
              ],
              borderColor: "black",
              borderWidth: 2,
            },
            {
              label: "Appointments Checked In",
              data: data1?.data?.checkedIn?.map((e) => e.checkIn),
              backgroundColor: [
                "rgba(75,192,192,1)",
                "#ecf0f1",
                "#50AF95",
                "#f3ba2f",
                "#2a71d0",
              ],
              borderColor: "black",
              borderWidth: 2,
            },
          ],
        });
        setEmployeeData({
          labels: data1.data.employeeTurnover?.map((e) => e.employeeName),
          datasets: [
            {
              label: "Users Gained",
              data: data1.data.employeeTurnover.map((e) => e.totalPrice),
              backgroundColor: [
                "rgba(75,192,192,1)",
                "#ecf0f1",
                "#50AF95",
                "#f3ba2f",
                "#2a71d0",
              ],
              borderColor: "black",
              borderWidth: 2,
            },
          ],
        });
        setTurnOver({
          labels: data1.data.checkedIn?.map((e) => monthName[e.month - 1]),
          datasets: [
            {
              label: "This Year Turn Over",
              data: data1.data?.checkedIn?.map((e) => e?.totalPrice),
              backgroundColor: [
                "rgba(75,192,192,1)",
                "#ecf0f1",
                "#50AF95",
                "#f3ba2f",
                "#2a71d0",
              ],
              borderColor: "black",
              borderWidth: 2,
            },
          ],
        });
      })
      .catch((err) => {
        // console.log(err);
      })
      .finally(() => setLoading(false));
    // pastAppointmentDataTemp()
    //   .then((data) => {
    //     setPastAppointmentData(data.data.data);
    //   }).finally(()=>setLoading(false))
  };
  var [totalApp, setTotalApp] = useState();
  useEffect(() => {
    setLoading(true);
    chartData()
      .then((data1) => {
        // console.log("DAADADADATA", data1.data);
        setTotalApp(data1?.data?.thisMonth[0]?.totalAppointments);
        setNewData({
          labels: data1.data.checkedIn?.map((e) => monthName[e.month - 1]),
          datasets: [
            {
              label: "Appointments Gained",
              data: data1.data?.checkedIn?.map((e) => e?.notCheckIn),
              backgroundColor: [
                "rgba(75,192,192,1)",
                "#ecf0f1",
                "#50AF95",
                "#f3ba2f",
                "#2a71d0",
              ],
              borderColor: "black",
              borderWidth: 2,
            },
            {
              label: "Appointments Checked In",
              data: data1?.data?.checkedIn?.map((e) => e.checkIn),
              backgroundColor: [
                "rgba(75,192,192,1)",
                "#ecf0f1",
                "#50AF95",
                "#f3ba2f",
                "#2a71d0",
              ],
              borderColor: "black",
              borderWidth: 2,
            },
          ],
        });
      })
      .finally(() => setLoading(false));
  }, []);

  const [newUserData, setNewUserData] = useState();
  const [newData, setNewData] = useState({});
  const [userData, setUserData] = useState({
    labels: UserData.map((data) => data.month),
    datasets: [
      {
        label: "Users Gained",
        data: UserData.map((data) => data.userGain),
        backgroundColor: [
          "rgba(75,192,192,1)",
          "#ecf0f1",
          "#50AF95",
          "#f3ba2f",
          "#2a71d0",
        ],
        borderColor: "black",
        borderWidth: 2,
      },
    ],
  });
  const [dates, setDates] = useState([]);
  const selectDates = (date) => {
    const dates = document.querySelectorAll(".admin__radio__date");
    for (let i = 0; i < dates.length; i++) {
      if (dates[i].id === date.toString()) {
        dates[i].classList.add("click");
      } else {
        dates[i].classList.remove("click");
      }
    }
  };

  const selectEmp = (id) => {
    const dates = document.querySelectorAll(".admin__radio__emp");
    for (let i = 0; i < dates.length; i++) {
      if (dates[i].id === id) {
        // console.log(dates[i].id, id);
        dates[i].classList.add("click");
      } else {
        dates[i].classList.remove("click");
      }
    }
  };

  const showNotification = (date, name) => {
    if ("Notification" in window) {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          if ("serviceWorker" in navigator) {
            navigator.serviceWorker.ready.then((registration) => {
              registration.showNotification(
                `New appointment at ${date} for employee ${name}`
              );
            });
          }
        }
      });
    } else {
      alert("Browser does not support desktop notification");
    }
  };

  useEffect(() => {
    if ("Notification" in window) {
      Notification.requestPermission();
    }
  }, []);
  const socket = io(process.env.REACT_APP_MAIN_URL);
  useEffect(() => {
    if ("speechSynthesis" in window) {
    } else {
      console.warn("Speech synthesis is not supported in this browser.");
    }
    socket.emit("adminLogin", decoded.adminId);
    setLoading(true);
    getData();
    getAppointmentData();
    getPastAppointmentData();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
    const dayAfterTomorrow = new Date(today);
    dayAfterTomorrow.setDate(today.getDate() + 2);
    setDates([today, tomorrow, dayAfterTomorrow]);
    setAppointmentDate("");
    socket.on("new Appointment", (data) => {
      const message = `New appointment at ${new Date(
        data.startTime
      ).toLocaleString("en-GB")} for employee ${
        data?.employeeId?.employeeName
      }`;
      const utterance = new SpeechSynthesisUtterance(message);
      const voice = window.speechSynthesis.getVoices();
      utterance.voice = voice[1];
      speechSynthesis.speak(utterance);
      setTotalApp(totalApp + 1);
      setAppointmentData((prevAppointmentData) => [
        ...prevAppointmentData,
        data,
      ]);
      showNotification(
        new Date(data.startTime).toLocaleString("en-GB"),
        data?.employeeId?.employeeName
      );
    });
    return () => {
      socket.off("new Appointment");
    };
  }, []);

  const removeFilter = () => {
    setSelectedEmp("");
    setAppointmentDate("");
    const dates = document.querySelectorAll(".admin__radio__date");
    for (let i = 0; i < dates.length; i++) {
      dates[i].classList.remove("click");
    }
    const emp = document.querySelectorAll(".admin__radio__emp");
    for (let i = 0; i < emp.length; i++) {
      emp[i].classList.remove("click");
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "Running":
        return "rgba(144, 238, 144, 0.2)";
      case "Finished":
        return "rgba(255, 160, 122, 0.2)";
      case "Pending":
        return "rgba(255, 255, 153, 0.2)";
      default:
        return "inherit";
    }
  };

  const buttonClick = (id, city, location, brandName) => {
    // navigate("/store", { state: { value: id, isAdmin: true } });
    // console.log(id)
    // console.log(city)
    // console.log(location)
    // console.log(brandName)
    var name = brandName.replaceAll(" ", "-");
    navigate(
      "/ms/" +
        name +
        "/" +
        location?.toLowerCase() +
        "/" +
        city?.toLowerCase() +
        "/" +
        btoa(id),
      { state: { value: id, isAdmin: true, isOffered: false } }
    );
  };

  const checkIn = (param) => {
    // console.log(param);
    checkInApi(param)
      .then((data) => {
        // console.log(data);
        if (data.status === 200) {
          // setLoading(false)
          navigate("/adminhomepage");
          Swal.fire({
            icon: "success",
            title: "CheckedIn",
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: data.data.message,
          });
        }
      })
      .catch((error) => {});
  };

  const cancelApp = (id, appData) => {
    let yn = window.confirm("Do you want to cancel the Appointment");
    if (yn) {
      // console.log(appData)
      setLoading(true);
      deleteAppByAdmin(id)
        .then((data) => {
          setAppointmentData(appointmentData?.filter((e) => e?._id !== id));
          setTotalApp(totalApp - 1);
          // console.log(data)
        })
        .catch((error) => {
          // console.log(error)
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  return (
    <>
      {loading && <Loader />}
      <div className="admin-wrapper">
        <div className="main-admin-profile-banner">
          <div className="main-admin-banner">
            <img src={adminData?.banner} alt="banner" />
          </div>
          <div className="main-admin-profile">
            <img src={adminData?.logo} alt="logo" className="profile__pic" />
            <div className="main-admin-profile-content">
              <h4>{adminData?.brandName}</h4>
              <button
                className="btn btn-primary"
                onClick={() => {
                  buttonClick(
                    adminData?._id,
                    adminData?.city?.cityName,
                    adminData?.locality,
                    adminData?.brandName
                  );
                }}
              >
                My store
              </button>
            </div>
          </div>
        </div>
        <div className="ref-section  h-100">
          <div className="row">
            <div className="col-lg-8 col-sm-12 part_1">
              <div className="chart-main-ref">
                <div className="d-flex justify-content-between">
                  <div className="col-lg-10 col-sm-12">
                    <small>Total Appointments</small>
                    <h3>
                      <b>{totalApp !== undefined ? totalApp : "0"}</b>
                    </h3>
                  </div>
                </div>
                <div className="d-flex pb-3 justify-content-center h-100">
                  <div className="col-lg-9 col-sm-12 pb-5 ">
                    {Object.keys(newData).length !== 0 && (
                      <BarChart chartData={userData} Data={newData} />
                    )}
                    {topUsers && <TopUser topUsers={topUsers} />}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 part_2">
              <h3>Appointments</h3>
              <br />
              <p className="d-flex justify-content-around">
                {/* {console.log("app", appointmentDate)} */}
                {dates.map((e) => {
                  return (
                    <div className="choose__dates row " key={e}>
                      <button
                        className="admin__radio__date col-3 w-70 d-flex flex-col gap-1"
                        id={e.getDate()}
                        onClick={() => {
                          selectDates(e.getDate());
                          setAppointmentDate(e.toLocaleDateString("en-GB"));
                        }}
                        name="date"
                        value={e.getDate()}
                      >
                        <div>{weekDay[e.getDay().toString()]}</div>
                        {/* <div>{monthName[e.getMonth()]}</div> */}
                        <div>{e.getDate()}</div>
                      </button>
                    </div>
                  );
                })}
              </p>

              <p>
                <div className="row container-fluid">
                  {empData?.map((e) => {
                    // {console.log(e)}
                    return (
                      <div className="col-3 choose__emp mb-3" key={e._id}>
                        <button
                          className="admin__radio__emp mt-2"
                          id={e?._id}
                          onClick={() => {
                            selectEmp(e?._id);
                            setSelectedEmp(e._id);
                          }}
                        >
                          {e.employeeName}
                        </button>
                      </div>
                    );
                  })}
                  <button onClick={removeFilter} className="btn btn-primary">
                    Remove Filters
                  </button>
                </div>
              </p>
              {/* {console.log("----", selectedEmp)} */}
              {/* {console.log(appointmentData)} */}
              <p>
                {appointmentData?.map((e) => {
                  let a = new Date(e.startTime);
                  return (
                    <div>
                      {/* {console.log(e)} */}
                      {(appointmentDate === "" && selectedEmp === "") ||
                      (appointmentDate ===
                        new Date(e?.startTime).toLocaleDateString("en-GB") &&
                        selectedEmp === "") ||
                      (appointmentDate ===
                        new Date(e?.startTime).toLocaleDateString("en-GB") &&
                        selectedEmp === e?.employeeId?._id) ||
                      (appointmentDate === "" &&
                        selectedEmp === e?.employeeId?._id) ? (
                        <>
                          <Accordion
                            expanded={expanded === e._id}
                            onChange={handleChange(e._id)}
                          >
                            <AccordionSummary
                              key={e._id}
                              expanded={expanded === e._id}
                              onChange={handleChange(e._id)}
                              style={{
                                backgroundColor: getStatusColor(
                                  e.checkedIn === "true"
                                    ? new Date().getTime() >
                                        e.startTime - 15 * 60 * 1000 &&
                                      new Date().getTime() <=
                                        e.startTime + e.totalTime * 60 * 1000
                                      ? "Running"
                                      : "Finished"
                                    : "Pending"
                                ),
                                border: "1px solid #333",
                                marginBottom: "10px",
                              }}
                            >
                              <Typography className="row w-100 d-flex flex-col gap-0 appointmentsTypo">
                                <p className="col-12 pending">
                                {/* {e?.isOffered === true && <div className="coiffeur__offer w-25">
                                    <p className="offers" >{e?.isOffered === true ? "Offer" : ""}</p>
                                  </div>} */}
                                  {
                                  e?.isOffered===true ? 
                                  <img src={'https://res.cloudinary.com/dpoecdktk/image/upload/v1712292303/output-onlinegiftools_ckbmow.gif'} alt="" srcset="" height="25px" width="25px" />
                                  : ""
                                }
                                
                                  {e.checkedIn === "true" ? (
                                    new Date().getTime() > e.startTime &&
                                    new Date().getTime() <=
                                      e.startTime + e.totalTime * 60 * 1000 ? (
                                      <h3 className="running">Running</h3>
                                    ) : (
                                      <h3 className="finished">Completed</h3>
                                    )
                                  ) : (
                                    <h3 className="pending__admin">Pending</h3>
                                  )}
                                </p>
                                <div>
                                  <p>Date</p>
                                  <p>
                                    {new Date(e.startTime).toLocaleDateString(
                                      "en-GB"
                                    )}
                                  </p>
                                </div>
                                <div>
                                  <p>Start Time</p>
                                  <p>{a.toLocaleTimeString()}</p>
                                </div>
                                <div>
                                  <p>Employee Name</p>
                                  <p>{e.employeeId.employeeName}</p>
                                </div>
                                <div>
                                  <p>Total Time</p>
                                  <p>{e.totalTime} Minutes</p>
                                </div>
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography className="appointmnet__details">
                                <div>
                                  <div>
                                    <p>Customer Name</p>
                                    <p>
                                      {e.isAdmin === true
                                        ? e.custDet?.name
                                        : e.userId?.firstName +
                                          " " +
                                          e.userId?.lastName}
                                    </p>
                                  </div>
                                  <div>
                                    <p>Customer Phone number </p>{" "}
                                    <p>
                                      {e.isAdmin === true
                                        ? e.custDet?.mobile
                                        : e.userId?.phoneNumber}
                                    </p>
                                  </div>
                                  <div>
                                    <p>Employee Name</p>{" "}
                                    <p>{e.employeeId.employeeName}</p>
                                  </div>
                                  <div>
                                    <p>Total Time</p>
                                    <p> {e.totalTime} Minutes</p>
                                  </div>
                                  <div>
                                    <p>services:</p>
                                    <p className="d-flex flex-col gap-2">
                                      {e.serviceId.map((e1, index) => {
                                        return (
                                          <>
                                            <p>
                                              {e1.serviceName} {" -"}
                                              <b> {"Rs " + e1.price} </b>
                                            </p>
                                          </>
                                        );
                                      })}
                                    </p>
                                  </div>
                                  <div>
                                    <p>Total Price</p>
                                    <p> {e.totalPrice} Rupees</p>
                                  </div>
                                  {e?.isOffered === true && e?.discount > 0 ? (
                                    <div>
                                      <p>Discount Provided</p>
                                      <p> -{e?.discount} Rupees</p>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  {e?.payment?.captured === true ? (
                                    <div>
                                      <p>Advance Payment</p>
                                      <p> -{e.payment?.amount / 100} Rupees</p>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  {
                                    e?.discount>0 && e?.payment?.captured===true ? 
                                    <div>
                                    <p>Payable Amount</p>
                                    <p>
                                      {e?.payment?.captured === true
                                        ? e?.totalPrice -
                                          e?.discount -
                                          e?.payment?.amount / 100
                                        : e?.totalPrice - e?.discount}{" "}
                                      Rupees
                                    </p>
                                  </div> : ""
                                  } 
                                  <div className="checkInOrCancel">
                                    {e?.checkedIn === "false" &&
                                    e?.isAdmin === true ? (
                                      <>
                                        {" "}
                                        <button
                                          className="btn btn-primary"
                                          onClick={() => {
                                            checkIn(
                                              e?._id +
                                                "-" +
                                                e?.bookingOtp +
                                                "!!" +
                                                e?.isAdmin
                                            );
                                          }}
                                        >
                                          CHECK IN
                                        </button>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                    {e?.checkedIn === "false" ? (
                                      <button
                                        className="btn btn-danger"
                                        onClick={() => {
                                          cancelApp(e?._id, e);
                                        }}
                                      >
                                        Cancel Appointment
                                      </button>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  );
                })}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Demo;
