import Cookies from "js-cookie"
import { deleteApi, getApi, postApi, updateApi } from "../APIManager/ApiManager"

const admin=localStorage.getItem('shopify')
const user=localStorage.getItem('qwerty')
const uuid=btoa(Cookies.get('session') || localStorage.getItem('session'))

export const getServiceData=()=>{
    return getApi('/api/v1/admin/service/getservice',{user:user,admin:admin,uuid:uuid})
}

export const deleteService=(id)=>{
    return deleteApi("/api/v1/admin/service/deleteservice/"+id,{user:user,admin:admin,uuid:uuid})
}

export const updateService=(data)=>{
    return updateApi('/api/v1/admin/service/updateservice',data,{user:user,admin:admin,uuid:uuid})
}

export const multiAddService=(data)=>{
    return postApi('/api/v1/admin/service/addmultiservice',data,{user:user,admin:admin,uuid:uuid})
}

export const addOneService=(data)=>{
    return postApi('/api/v1/admin/service/addservice',data,{user:user,admin:admin,uuid:uuid})
}