import axios from "axios";
import React, { useEffect, useState } from "react";

export const MultiRowForms = (data) => {
  const [fData, setFData] = useState();
  useEffect(() => {
    setFData(data?.formData);
  }, [data?.formData]);
  return (
    <>
      <h1>
        <center>MultiRowForms</center>
      </h1>
      {data?.formData && (
        <div className="d-flex flex-col gap-5 ">
          {data?.formData?.map((f) => {
            return (
              // {
              <div className="">
                <form className="d-flex flex-row gap-1 multiform" key={f.id} id={f._id} >
                  {data?.fields?.map((e) => {
                    return (
                      <>
                    <p className="d-flex flex-col w-100">
                       <label htmlFor={`name-${e?.fieldName}-${f?._id}`}>
                          {e?.actual}
                          {data?.error[e?.fieldName]?.message}
                        </label>
                        {e.type === "text" ? (
                          <input
                          id={`name-${e?.fieldName}-${f?._id}`}
                          type="text"
                          defaultValue={f[e?.fieldName]}
                          name={e?.fieldName}
                          // {...data.register(e?.fieldName+(f.id === undefined ? f?._id : f?.id),data?.validationSchema[e?.fieldName])}
                          onChange={(e) =>
                            data?.handleInputChange(
                              e,
                              f?.id === undefined ? f?._id : f?.id
                              )
                            }
                            />
                        ) : e.type === "number" ? (
                          <input
                            id={`name-${e?.fieldName}-${f?._id}`}
                            // -${f?.id}
                            type="number"
                            defaultValue={f[e.fieldName]}
                            name={e.fieldName}
                            // {...data?.register(e?.fieldName+(f?.id === undefined ? f?._id : f?.id),data?.validationSchema[e?.fieldName])}
                            onChange={(e) =>
                              data?.handleInputChange(
                                e,
                                f?.id === undefined ? f?._id : f?.id
                              )
                            }
                          />
                        ) : e.type === "select" &&
                          e.fieldName === "categoryId" ? (
                          <select
                          defaultValue={f?._id!==undefined ? `${f[e.fieldName]._id}` : `${f[e.fieldName]}`}
                          name={e.fieldName}
                          id={`${e.fieldName}-${f?._id}`}
                          // {...data?.register(e?.fieldName+(f?.id === undefined ? f?._id : f?.id),data?.validationSchema[e?.fieldName])}
                          onChange={(e) =>
                            data?.handleInputChange(
                              e,
                              f?.id === undefined ? f?._id : f?.id
                              )
                            }
                            >
                            {data?.serviceCategory?.map((j) => {
                              return (
                                <option value={`${j._id}`} key={`id-${j._id}`}>
                                  {j.categoryName}
                                </option>
                              );
                            })}
                          </select>
                        ) : e.type === "select" ? (
                          <select
                          defaultValue={f[e.fieldName]}
                          name={e.fieldName}
                          id={`${e.fieldName}-${f?._id}`}
                          // {...data?.register(e?.fieldName+(f?.id === undefined ? f?._id : f?.id),data?.validationSchema[e?.fieldName])}
                          onChange={(e) =>
                            data?.handleInputChange(
                              e,
                              f?.id === undefined ? f?._id : f?.id
                              )
                            }
                            >
                            {e?.value?.map((j) => {
                              return (
                                <option value={j}>{j.toUpperCase()}</option>
                                );
                              })}
                          </select>
                        ) : (
                          ""
                          )}
                          </p>
                      </>
                    );
                    
                  })}
                  <div className="validation-icons">
                    <span
                      role="img"
                      aria-label="tick"
                      onClick={() =>
                        f?._id === undefined ? data?.Add(f) : data?.Update(f)
                      }
                      className={data.isValid ? "active" : ""}
                    >
                      ✓
                    </span>
                    <span
                      role="img"
                      aria-label="cross"
                      onClick={() => {
                        f?._id === undefined
                          ? data?.Remove(f?.id)
                          : data?.Delete(f?._id);
                      }}
                      // onClick={() =>
                      // }
                      className={!data.isValid ? "active" : ""}
                    >
                      ✗
                    </span>
                  </div>
                </form>
              </div>
              // }
            );
          })}
          <p className="multiform__button">
          <button type="button" onClick={data?.AddRow}  className="">
            Add Row
          </button>
          {data?.mainData?.length > 0 ? (
            <button type="submit" onClick={data?.finalSubmit}>
            Final Submit
          </button>
        ) : (
          ""
          )}
          </p>
        </div>
      )}
    </>
  );
  }

