import React, { useEffect, useState } from "react";
import classes from "./Navbar.module.css";
import { Link, useNavigate } from "react-router-dom";
import { BsCartPlusFill } from "react-icons/bs";
import jwt_decode from "jwt-decode";
import { useDispatch, useSelector } from "react-redux";
import { CitySearchBar } from "./SearchBar/CitySearchBar";
import { ProfileDropdown } from "./ProfileDropdown/ProfileDropdown";
import Button from "@mui/material/Button";
import MenuIcon from "@mui/icons-material/Menu";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import Drawer from "@mui/material/Drawer";
import Logo from "../Images/Final logo-02.png";
import Cookies from "js-cookie";
import axios from "axios";
import mainLogo from "../../src/Images/Brown Logo.png";
import hamburgerMenu from "../../src/Images/hamburger.svg";
import CoiffeurText from "../../src/Images/Coiffeur.svg";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { removeEverything } from "../redux/CartSlice";
import { BsCart } from "react-icons/bs";
import Badge from '@mui/material/Badge';
import { TiShoppingCart } from "react-icons/ti";

const drawerWidth = 240;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Navbar = () => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    const element = document.querySelector("#wrapper");
    element.classList.remove("main-admin-nav");
  };

  const [token, setToken] = useState(true);
  const [admin, setAdmin] = useState(false);
  const [navOpen, setNavopen] = useState(false);
  const navigate = useNavigate();
  const [scrollDown, setScrollDown] = useState(false);
  var decoded = null;
  var [name, setName] = useState("");
  const dispatch = useDispatch();
  var [decode, setDecode] = useState({});
  const store = useSelector((state) => state);
  const checkToken = (passToken) => {
    var token = localStorage.getItem("qwerty");
    if (token != null) {
      try {
        decoded = jwt_decode(token);
        setDecode(decoded);
        // console.log("decoded", decoded);
        const currentTime = Date.now() / 1000;
        setName(decoded.fullName);
        // console.log(passToken);
        // if (passToken !== undefined) {
        axios
          .post("api/v1/login", {
            email: decoded.email,
            passToken: passToken,
          })
          .then((data) => {
            // console.log(data);
          })
          .catch((error) => {
            // console.log(error)
            if (error?.response?.status === 400) {
              Cookies.remove("session");
              Cookies.remove("qwerty");
              localStorage.clear();
              // window.location.reload();
              navigate("/login");
            }
          });
        // }
        // (decoded.email,passToken)

        if (currentTime > decoded.exp) {
          setToken(false);
        }
      } catch (error) {
        // console.log(error)
        Cookies.remove("session");
        Cookies.remove("qwerty");
        localStorage.clear();
        window.location.reload();
        setToken(false);
      }
    } else {
      setToken(false);
    }
  };
  const checkAdmin = () => {
    var token = localStorage.getItem("shopify");
    try {
      if (token != null) {
        var dec = jwt_decode(token);
        if (!dec.message) {
          //console.log("here")
          setAdmin(true);
        } else setAdmin(false);
      }
    } catch (error) {
      //console.log(error)
    }
  };
  useEffect(() => {
    // console.log(process.env.BASE_URL)
    // checkToken(Cookies.get("session"));
    checkAdmin();
  }, [store.user.value]);

  useEffect(() => {
    checkToken(localStorage.getItem("session") || Cookies.get("session"));
    // console.log("1234",Cookies.get("session"))
  }, []);

  const handleStorageChange = () => {
    checkAdmin();
    // checkToken();
  };
  const logout = () => {
    dispatch(removeEverything({isOffered:false}));
    localStorage.removeItem("qwerty");
    localStorage.removeItem("shopify");
    localStorage.removeItem("uuid");
    localStorage.removeItem("session");
    Cookies.remove("session");
    Cookies.remove("qwerty");
    window.location.reload();
    navigate("/");
  };
  const f1 = (text) => {
    // console.log(text);
    if (text === "Stats") {
      navigate("/adminhomepage/stats");
    } else if (text === "Gallery") {
      navigate("/adminhomepage/gallery");
    } else if (text === "Unavailablility of Employee") {
      navigate("/adminhomepage/unavailability");
    } else if (text === "Services") {
      navigate("/adminhomepage/services");
    } else if (text === "Employee") {
      navigate("/adminhomepage/employee");
    } else if (text === "Store Details") {
      navigate("/adminhomepage/updatestore");
    } else if (text === "Appointment History") {
      navigate("/adminhomepage/pastappointment");
    } else if (text === "Appointments") {
      navigate("/adminhomepage/appointments");
    } else if (text === "My Profile") {
      navigate("/userprofile/" + decode?.id);
    } else if (text === "Current Appointment") {
      navigate("/currentappointment");
    } else if (text === "Past Appointment") {
      navigate("/pastappointment/" + decode?.id);
    } else if (text === "Logout") {
      logout();
    }
    else if(text==="Owner Profile")
    {
      navigate('/userprofile/'+decode?.id)
    }
  };
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    handleResize();
    window.addEventListener("resize", handleResize);

    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
      window.removeEventListener("resize", handleResize);
    };
  }, [checkToken]);
  const location = window.location.href.split("/");
  const openDraw = () => {
    setOpen(!open);
    const element = document.querySelector("#wrapper");
    element.classList.add("main-admin-nav");
  };

  return (
    <>
      <div className={`${classes.navWrapper} `}>
        <div className={`${classes.logoMain}`}>
          <div className={`${classes.hambergur} `}>
            {admin === true ? (
              isMobile ? (
                <Box sx={{ display: "flex" }}>
                  <Toolbar>
                    <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      onClick={() => {
                        setOpen(!open);
                      }}
                      edge="start"
                      // onClick={()=>{console.log("------------------------>")}}
                    >
                      <MenuIcon />
                    </IconButton>
                  </Toolbar>
                  <Drawer
                    sx={{
                      width: drawerWidth,
                      flexShrink: 0,
                      "& .MuiDrawer-paper": {
                        width: drawerWidth,
                        boxSizing: "border-box",
                      },
                    }}
                    variant=""
                    anchor="left"
                    open={open}
                  >
                    <DrawerHeader>
                      <IconButton onClick={handleDrawerClose}>
                        {theme.direction === "ltr" ? (
                          <ChevronLeftIcon />
                        ) : (
                          <ChevronRightIcon />
                        )}
                      </IconButton>
                    </DrawerHeader>
                    <Divider />
                    <List>
                      <ListItem disablePadding sx={{ display: "block" }}>
                        <ListItemButton
                          sx={{
                            minHeight: 48,
                            justifyContent: open ? "initial" : "center",
                            px: 2.5,
                          }}
                          onClick={() => {
                            navigate("/adminhomepage");
                            handleDrawerClose();
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: open ? 3 : "auto",
                              justifyContent: "center",
                            }}
                          >
                            <MailIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary="Home"
                            sx={{ opacity: open ? 1 : 0 }}
                          />
                        </ListItemButton>
                      </ListItem>
                    </List>
                    <Divider />
                    <List>
                      {[
                        "Employee",
                        "Store Details",
                        "Services",
                        "Stats",
                        "Appointment History",
                        "Gallery",
                        "Unavailablility of Employee",
                        "Owner Profile",
                        "Logout",
                      ].map((text, index) => (
                        <ListItem key={text} disablePadding>
                          <ListItemButton
                            onClick={() => {
                              f1(text);
                              handleDrawerClose();
                            }}
                          >
                            <ListItemIcon
                              sx={{
                                minWidth: 0,
                                mr: open ? 3 : "auto",
                                justifyContent: "center",
                              }}
                            ></ListItemIcon>
                            <ListItemText
                              primary={text}
                              sx={{ opacity: open ? 1 : 0 }}
                            />
                          </ListItemButton>
                        </ListItem>
                      ))}
                    </List>
                  </Drawer>
                </Box>
              ) : (
                <Box sx={{ display: "flex" }}>
                  <Toolbar>
                    <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      onClick={() => {
                        setOpen(!open);
                      }}
                      edge="start"
                      // onClick={()=>{console.log("------------------------>")}}
                    >
                      <MenuIcon />
                    </IconButton>
                  </Toolbar>
                  <Drawer
                    sx={{
                      width: drawerWidth,
                      flexShrink: 0,
                      "& .MuiDrawer-paper": {
                        width: drawerWidth,
                        boxSizing: "border-box",
                      },
                    }}
                    variant=""
                    anchor="left"
                    open={open}
                  >
                    <DrawerHeader>
                      <IconButton onClick={handleDrawerClose}>
                        {theme.direction === "ltr" ? (
                          <ChevronLeftIcon />
                        ) : (
                          <ChevronRightIcon />
                        )}
                      </IconButton>
                    </DrawerHeader>
                    <Divider />
                    <List>
                      <ListItem disablePadding sx={{ display: "block" }}>
                        <ListItemButton
                          sx={{
                            minHeight: 48,
                            justifyContent: open ? "initial" : "center",
                            px: 2.5,
                          }}
                          onClick={() => {
                            navigate("/adminhomepage");
                            handleDrawerClose();
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: open ? 3 : "auto",
                              justifyContent: "center",
                            }}
                          >
                            <MailIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary="Home"
                            sx={{ opacity: open ? 1 : 0 }}
                          />
                        </ListItemButton>
                      </ListItem>
                    </List>
                    <Divider />
                    <List>
                      {[
                        "Employee",
                        "Store Details",
                        "Services",
                        "Stats",
                        "Appointment History",
                        "Gallery",
                        "Unavailablility of Employee",
                        "Owner Profile",
                        "Logout",
                      ].map((text, index) => (
                        <ListItem key={text} disablePadding>
                          <ListItemButton
                            onClick={() => {
                              f1(text);
                              handleDrawerClose();
                            }}
                          >
                            <ListItemIcon
                              sx={{
                                minWidth: 0,
                                mr: open ? 3 : "auto",
                                justifyContent: "center",
                              }}
                            ></ListItemIcon>
                            <ListItemText
                              primary={text}
                              sx={{ opacity: open ? 1 : 0 }}
                            />
                          </ListItemButton>
                        </ListItem>
                      ))}
                    </List>
                  </Drawer>
                </Box>
              )
            ) : ""
            }
          </div>
          <div className={classes.nvaLogo}>
            <a href="/#" rel="noreferrer noopener">
              <img src={mainLogo} alt="NavLogo" />
            </a>
          </div>
          <div className={classes.navText}>
            <div>
              {isMobile ? (
                <>
                  {name === "" ? (
                    <>
                      <Link
                        className="nav-link active"
                        aria-current="page"
                        to="/login"
                      >
                        <button
                          className={`btn-primary-hard ${classes.loginBtn}`}
                        >
                          Register/Sign In
                        </button>
                      </Link>
                    </>
                  ) : (
                    <>
                      {admin === true ? (
                        <>
                          <Link className="nav-link active" to="/adminhomepage">
                            <button class={`btn btn-primary ${classes.adminBtn}`}>
                              Store Manager
                            </button>
                          </Link>
                          {/* <Link className="nav-link active" to="/adminhomepage">
                            <button class="btn btn-indigo">My Store</button>
                          </Link> */}
                        </>
                      ) : (
                        <>
                          {/* <Link to="/listofstore">
                            <button className="btn btn-primary">Stores</button>
                          </Link> */}
                          <Link
                            className="nav-link active"
                            aria-current="page"
                            to="/cart"
                          >
                                      <Badge badgeContent={store?.cart?.value?.serviceId.length} color="warning">
                                      {/* <BsCartPlusFill /> */}
                                      <TiShoppingCart />

                                </Badge>
                            {/* <span>{store?.cart?.value?.serviceId.length}</span> */}
                          </Link>
                          <ProfileDropdown
              name={name}
              decode={decode}
              item1={"Profile"}
              item2={"Current Apppointment"}
              item3={"Past Apppointment"}
              nav={true}
              changeButton={(nav) => setNavopen(nav)}
              />
                        </>
                      )}
                    </>
                  )}
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div className={classes.searchBar}>
          <CitySearchBar />
          
        </div>
        <div className={classes.navBtn}>
          <>
            {name === "" ? (
              <>
                {/* {console.log(admin)} */}
                <Link
                  className="nav-link active"
                  aria-current="page"
                  to="/login"
                >
                  <button className={`btn-primary-hard ${classes.loginBtn}`}>
                    Register/Sign In
                  </button>
                </Link>
              </>
            ) : (
              <>
                {admin === true ? (
                  <>
                    <Link className="nav-link active" to="/adminhomepage">
                      {/*  class="btn btn-indigo"  */}
                      <button
                        className={`btn-primary-hard ${classes.loginBtn}`}
                      >
                        Store Manager
                      </button>
                    </Link>
                    <Link className="nav-link active" to="/adminhomepage">
                      <button
                        className={`btn-primary-hard ${classes.loginBtn}`}
                        // class="btn btn-indigo"
                      >
                        My Store
                      </button>
                    </Link>
                  </>
                ) : (
                  <>
                    <Link to="/listofstore">
                      <button className="btn btn-primary">Stores</button>
                    </Link>
                    <Link
                      className="nav-link active"
                      aria-current="page"
                      to="/cart"
                    >
                      <BsCartPlusFill />
                      <span>{store?.cart?.value?.serviceId.length}</span>
                    </Link>
                    <ProfileDropdown
              name={name}
              decode={decode}
              item1={"Profile"}
              item2={"Current Apppointment"}
              item3={"Past Apppointment"}
              nav={true}
              changeButton={(nav) => setNavopen(nav)}
              />
                  </>
                )}
              </>
            )}
          </>
        </div>
            
      </div>

      {/* <nav
        className={`navbar navbar-expand-md bg-body-tertiary sticky-top d-none`}
        // bg-body-tertiary
      >
        <div className={classes.navRow}>
          <div className="d-none ml-auto nav__section">
            {localStorage.getItem("shopify") != undefined &&
              (isMobile ? (
                <Box sx={{ display: "flex" }}>
                  <Toolbar>
                    <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      onClick={() => {
                        setOpen(!open);
                      }}
                      edge="start"
                    >
                      <MenuIcon />
                    </IconButton>
                  </Toolbar>
                  <Drawer
                    sx={{
                      width: drawerWidth,
                      flexShrink: 0,
                      "& .MuiDrawer-paper": {
                        width: drawerWidth,
                        boxSizing: "border-box",
                      },
                    }}
                    variant=""
                    anchor="left"
                    open={open}
                  >
                    <DrawerHeader>
                      <IconButton onClick={handleDrawerClose}>
                        {theme.direction === "ltr" ? (
                          <ChevronLeftIcon />
                        ) : (
                          <ChevronRightIcon />
                        )}
                      </IconButton>
                    </DrawerHeader>
                    <Divider />
                    <List>
                      <ListItem disablePadding sx={{ display: "block" }}>
                        <ListItemButton
                          sx={{
                            minHeight: 48,
                            justifyContent: open ? "initial" : "center",
                            px: 2.5,
                          }}
                          onClick={() => {
                            navigate("/adminhomepage");
                            handleDrawerClose();
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: open ? 3 : "auto",
                              justifyContent: "center",
                            }}
                          >
                            <MailIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary="Home"
                            sx={{ opacity: open ? 1 : 0 }}
                          />
                        </ListItemButton>
                      </ListItem>
                    </List>
                    <Divider />
                    <List>
                      {[
                        "Employee",
                        "Store Details",
                        "Services",
                        "Stats",
                        "Appointment History",
                        "Gallery",
                        "Unavailablility of Employee",
                        "Logout"
                      ].map((text, index) => (
                        <ListItem key={text} disablePadding>
                          {text === "Employeess" ? (
                            <ListItemButton
                              data-bs-toggle="modal"
                              data-bs-target={`#${text}`}
                              onClick={() => {
                                handleDrawerClose();
                              }}
                            >
                              <ListItemIcon
                                sx={{
                                  minWidth: 0,
                                  mr: open ? 3 : "auto",
                                  justifyContent: "center",
                                }}
                              >
                                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                              </ListItemIcon>
                              <ListItemText
                                primary={text}
                                sx={{ opacity: open ? 1 : 0 }}
                              />
                            </ListItemButton>
                          ) : (
                            <ListItemButton
                              onClick={() => {
                                f1(text);
                                handleDrawerClose();
                              }}
                            >
                              <ListItemIcon
                                sx={{
                                  minWidth: 0,
                                  mr: open ? 3 : "auto",
                                  justifyContent: "center",
                                }}
                              >
                                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                              </ListItemIcon>
                              <ListItemText
                                primary={text}
                                sx={{ opacity: open ? 1 : 0 }}
                              />
                            </ListItemButton>
                          )}
                        </ListItem>
                      ))}
                    </List>
                  </Drawer>
                </Box>
              ) : (
                <Box sx={{ display: "flex" }}>
                  <Toolbar>
                    {!open && (
                      <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={openDraw}
                        edge="start"
                      >
                        <MenuIcon />
                      </IconButton>
                    )}
                  </Toolbar>
                  <Drawer
                    sx={{
                      width: drawerWidth,
                      flexShrink: 0,
                      "& .MuiDrawer-paper": {
                        width: drawerWidth,
                        boxSizing: "border-box",
                      },
                    }}
                    variant=""
                    anchor="left"
                    open={open}
                  >
                    <DrawerHeader>
                      <IconButton onClick={handleDrawerClose}>
                        {theme.direction === "ltr" ? (
                          <ChevronLeftIcon />
                        ) : (
                          <ChevronRightIcon />
                        )}
                      </IconButton>
                    </DrawerHeader>
                    <Divider />
                    <List>
                      <ListItem disablePadding sx={{ display: "block" }}>
                        <ListItemButton
                          sx={{
                            minHeight: 48,
                            justifyContent: open ? "initial" : "center",
                            px: 2.5,
                          }}
                          onClick={() => {
                            navigate("/adminhomepage");
                            handleDrawerClose();
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: open ? 3 : "auto",
                              justifyContent: "center",
                            }}
                          >
                            <MailIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary="Home"
                            sx={{ opacity: open ? 1 : 0 }}
                          />
                        </ListItemButton>
                      </ListItem>
                    </List>
                    <Divider />
                    <List>
                      {[
                        "Employee",
                        "Store Details",
                        "Services",
                        "Stats",
                        "Appointment History",
                        "Gallery",
                        "Unavailablility of Employee",
                        "Appointments",
                      ].map((text, index) => (
                        <ListItem key={text} disablePadding>
                          {text === "Employeess" ? (
                            <ListItemButton
                              data-bs-toggle="modal"
                              data-bs-target={`#${text}`}
                              onClick={() => {
                                handleDrawerClose();
                              }}
                            >
                              <ListItemIcon
                                sx={{
                                  minWidth: 0,
                                  mr: open ? 3 : "auto",
                                  justifyContent: "center",
                                }}
                              >
                                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                              </ListItemIcon>
                              <ListItemText
                                primary={text}
                                sx={{ opacity: open ? 1 : 0 }}
                              />
                            </ListItemButton>
                          ) : (
                            <ListItemButton
                              onClick={() => {
                                f1(text);
                                handleDrawerClose();
                              }}
                            >
                              <ListItemIcon
                                sx={{
                                  minWidth: 0,
                                  mr: open ? 3 : "auto",
                                  justifyContent: "center",
                                }}
                              >
                                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                              </ListItemIcon>
                              <ListItemText
                                primary={text}
                                sx={{ opacity: open ? 1 : 0 }}
                              />
                            </ListItemButton>
                          )}
                        </ListItem>
                      ))}
                    </List>
                  </Drawer>
                </Box>
              ))}
            {!isMobile && (
              <a href="/#" rel="noreferrer noopener">
                <img
                  src={Logo}
                  style={{ height: "80px", width: "80px" }}
                  className=""
                  alt="Logo"
                />
                
              </a>
            )}
            {isMobile && (
              <>
                <a href="/#" rel="noreferrer noopener">
                  <img
                    src={Logo}
                    style={{ height: "60px", width: "72px" }}
                    className=""
                    alt="Logo"
                  />
                  // {/* <img src='https://res.cloudinary.com/dpoecdktk/image/upload/v1697431813/phoneLogo_jiajgl.png' style={{ height: "60px", width: "70px" }}  /> 
                </a>
                <form
                  className={`me-1`}
                  role="search"
                  style={{
                    width: `${
                      localStorage.getItem("shopify") != undefined
                        ? "70%"
                        : "70%"
                    }`,
                  }}
                >
                  <CitySearchBar />
                </form>
              </>
            )}
            <button
              className="navbar-toggler hamburger"
              type="button"
              id="hamburger"
              role="none"
              // aria-labelledby="nav-toggler"
              onClick={() => {
                setNavopen(!navOpen);
              }}
            >
              <span
                className="navbar-toggler-icon bg-light"
                id="nav-toggler"
              ></span>
            </button>
          </div>
          <div
            className={`collapse navbar-collapse align-items-end
             ${navOpen || 0 ? "show" : ""}`}
            id="navbarSupportedContent"
          >
            {!isMobile && (
              <form className="col-xl-4 nav-left" role="search">
                <CitySearchBar />
              </form>
            )}
            <ul className="navbar-nav nav-right">
              <li
                className="nav-item"
                onClick={() => {
                  setNavopen(false);
                }}
              >
                <Link
                  className="nav-link active"
                  aria-current="page"
                  to="/listofstore"
                >
                  Stores
                </Link>
              </li>
              <li
                className="nav-item "
                onClick={() => {
                  setNavopen(false);
                }}
              >
                <Link
                  className="nav-link active"
                  aria-current="page"
                  to="/cart"
                >
                  <BsCartPlusFill />
                  <span>{store?.cart?.value?.serviceId.length}</span>
                </Link>
              </li>
              <li
                className="nav-item "
                onClick={() => {
                  setNavopen(false);
                }}
              >
              </li>
              <li className="nav-item">
                {name == "" ? (
                  <Button
                    onClick={() => {
                      setNavopen(false);
                    }}
                  >
                    {" "}
                    <Link
                      className="login"
                      to="/login"
                      rel="noreferrer noopener"
                    >
                      <Button variant="contained">Login/Signup</Button>
                    </Link>
                  </Button>
                ) : (
                  <ProfileDropdown
                    name={name}
                    decode={decode}
                    item1={"Profile"}
                    item2={"Current Apppointment"}
                    item3={"Past Apppointment"}
                    nav={true}
                    changeButton={(nav) => setNavopen(nav)}
                  />
                )}
              </li>
            </ul>
          </div>
        </div>
      </nav> */}
    </>
  );
};

export default Navbar;
