import React from 'react'

export const UTS1 = () => {
  return (
    <div><div>
    <h2>Book My Coiffeur Terms of Service</h2>
    <p><strong>Last updated:</strong> 1st January, 2024</p>

    <p>
      Please read these Terms of Service ("Terms") carefully before using the{' '}
      <a href="http://www.bookmycoiffeur.com">http://www.bookmycoiffeur.com</a> website
      (the "Service") operated by Book My Coiffeur ("us", "we", or "our").
    </p>

    <p>
      Your access to and use of the Service is conditioned on your acceptance of and
      compliance with these Terms. These Terms apply to all visitors, users, and others who
      access or use the Service.
    </p>

    <h3>1. Use of the Service</h3>

    <p>
      <strong>1.1 Eligibility:</strong> By using the Service, you affirm that you are at least
      18 years old, or you are an emancipated minor, or possess legal parental or guardian consent,
      and are fully able and competent to enter into the terms, conditions, obligations,
      affirmations, representations, and warranties set forth in these Terms.
    </p>

    <p>
      <strong>1.2 User Account:</strong> To access certain features of the Service, you may be
      required to create a user account. You agree to provide accurate, current, and complete
      information during the registration process and to update such information to keep it
      accurate, current, and complete.
    </p>

    <p>
      <strong>1.3 User Responsibilities:</strong> You are responsible for maintaining the
      confidentiality of your account and password, including but not limited to the restriction
      of access to your computer and/or account. You agree to accept responsibility for all
      activities or actions that occur under your account and/or password.
    </p>

    {/* Additional sections follow the same pattern */}

    <h3>2. User Content</h3>

    <p>
      <strong>2.1 User-Generated Content:</strong> The Service may allow you to post, link,
      store, share, and otherwise make available certain information, text, graphics, videos, or
      other material ("Content"). You are solely responsible for the Content that you post on or
      through the Service, including its legality, reliability, and appropriateness.
    </p>

    <p>
      <strong>2.2 Rights to User Content:</strong> By posting Content on or through the Service,
      you grant us the right and license to use, modify, publicly perform, publicly display,
      reproduce, and distribute such Content on and through the Service.
    </p>

    {/* Additional sections follow the same pattern */}

    <h3>3. Service Availability</h3>

    <p>
      <strong>3.1 Changes:</strong> We reserve the right to modify, suspend, or discontinue the
      Service, or any part thereof, at any time without notice.
    </p>

    <p>
      <strong>3.2 Termination:</strong> We may terminate or suspend your account and bar access
      to the Service immediately, without prior notice or liability, under our sole discretion,
      for any reason whatsoever and without limitation, including but not limited to a breach of
      the Terms.
    </p>

    {/* Additional sections follow the same pattern */}

    <h3>4. Privacy</h3>

    <p>
      <strong>4.1 Privacy Policy:</strong> Your use of the Service is also governed by our Privacy
      Policy. Please review our Privacy Policy at{' '}
      <a href="[link to privacy policy]">[link to privacy policy]</a> for information on how we
      collect, use, and disclose personal information.
    </p>

    {/* Additional sections follow the same pattern */}

    <h3>5. Intellectual Property</h3>

    <p>
      <strong>5.1 Ownership:</strong> The Service and its original content, features, and
      functionality are and will remain the exclusive property of Book My Coiffeur.
    </p>

    <p>
      <strong>5.2 Trademarks:</strong> Book My Coiffeur's name and logo are trademarks and
      service marks of Book My Coiffeur. You agree not to display or use these trademarks in any
      manner without our prior written permission.
    </p>

    {/* Additional sections follow the same pattern */}

    <h3>6. Limitation of Liability</h3>

    <p>
      <strong>6.1 Disclaimer:</strong> The Service is provided "as is" and "as available" without
      any warranties of any kind, either expressed or implied, including, but not limited to, the
      implied warranties of merchantability, fitness for a particular purpose, or non-infringement.
    </p>

    <p>
      <strong>6.2 Limitation of Liability:</strong> In no event shall Book My Coiffeur, nor its
      directors, employees, partners, agents, suppliers, or affiliates, be liable for any
      indirect, incidental, special, consequential, or punitive damages, including without
      limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from
      (i) your access to or use of or inability to access or use the Service; (ii) any conduct or
      content of any third party on the Service; (iii) any content obtained from the Service; and
      (iv) unauthorized access, use, or alteration of your transmissions or content, whether based
      on warranty, contract, tort (including negligence), or any other legal theory, whether or
      not we have been informed of the possibility of such damage, and even if a remedy set forth
      herein is found to have failed of its essential purpose.
    </p>
    <h3>7. Governing Law</h3>

    <p>
      <strong>7.1 Jurisdiction:</strong> These Terms shall be governed and construed in accordance
      with the laws of Jurisdiction of Ahmedabad,Gujarat,India, without regard to its conflict of law provisions.
    </p>

    <h3>8. Changes to the Terms</h3>

    <p>
      <strong>8.1 Updates:</strong> We reserve the right, at our sole discretion, to modify or
      replace these Terms at any time. If a revision is material, we will provide at least 30
      days' notice prior to any new terms taking effect. What constitutes a material change will be
      determined at our sole discretion.
    </p>
    <h3>9. Contact Us</h3>
      <strong>9.1 Questions:</strong> If you have any questions about</div>
    </div>
    
  )
}
