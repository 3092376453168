import { getApi, postApi } from "../APIManager/ApiManager"

const admin=localStorage.getItem('shopify')
const user=localStorage.getItem('qwerty')

export const checkForAlreadyBooked=(data)=>{
    return postApi( "/api/v1/appointment/checkforalreadybooked",data)
}

export const addAppointment=(data)=>{
    return postApi( "/api/v1/appointment/addappointment",data)
}

export const getCartData=(id)=>{
    return getApi("/api/v1/cart/getcart/" + id)
}