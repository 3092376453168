import React from 'react';
import classes from "./Services.module.css"
import hairColorIcon from "../../Images/hair-color.svg"
import beardIcon from "../../Images/beard.svg"
import hairCuttingIcon from "../../Images/hair-cutting.svg"
import stylingIcon from "../../Images/styling.svg"
import makeupIcon from "../../Images/makeup-salon.svg"
import mainLogo from "../../Images/service-main-icon.svg"

const Services = () => {
    return (
        <div className={classes.servicesWrapper}>
            <div className={classes.servicesHeading}>
                SERVICES
            </div>
            <div className={classes.servicesTagLine}>
                We always provide the best <b>service</b> for you
            </div>
            <div className={classes.servicesCards}>
                <div className={classes.mainLogo}>
                    <img src={mainLogo} alt="logo"/>
                </div>
                <div className={classes.hairColor}>
                    <p>HAIR  COLOR</p>
                    <div className={classes.card}>
                        <img src={hairColorIcon} alt="HAIR  COLOR" />
                    </div>
                </div>
                <div className={classes.hairCutting}>
                    <p>HAIR CUTTING</p>
                    <div className={classes.card}>
                        <img src={hairCuttingIcon} alt="HAIR  COLOR" />
                    </div>
                </div>
                <div className={classes.beardGrooming}>
                    <p>BEARD GROOMING</p>
                    <div className={classes.card}>
                        <img src={beardIcon} alt="HAIR  COLOR" />
                    </div>
                </div>
                <div className={classes.styling}>
                    <p>STYLING</p>
                    <div className={classes.card}>
                        <img src={stylingIcon} alt="HAIR  COLOR" />
                    </div>
                </div>
                <div className={classes.makeup}>
                    <p>MAKEUP SALON</p>
                    <div className={classes.card}>
                        <img src={makeupIcon} alt="HAIR  COLOR" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Services;