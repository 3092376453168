import Cookies from "js-cookie"
import { getApi, postApi, updateApi } from "../APIManager/ApiManager"

const admin=localStorage.getItem('shopify')
const user=localStorage.getItem('qwerty')
const uuid=btoa(Cookies.get('session') || localStorage.getItem('session'))

export const updateStoreForAdmin=(data)=>{
    return updateApi('/api/v1/businessform/updatestore',data,{user:user,admin:admin,uuid:uuid})
}

export const AddToStore=(data)=>{
    return postApi('/api/v1/businessform/addstore',data,{user:user,admin:admin,uuid:uuid})
}

export const getBusinessDataByLimit=(fetch,value)=>{
    return getApi('/api/v1/businessform/getbusinessdatabylimit/'+ fetch + "/" + value)
}