import { useEffect } from "react";
// import { Navigate, useNavigate } from "react-router-dom"

const Error = ({error, navigated}) => {

  

  // const navigate = useNavigate();
  // console.log("------------------>",error);
  const statusCode = error.response?.status;
  // console.log(statusCode);
  if (statusCode >= 400) {
    // console.log(400)
    window.location.href = `/error/${statusCode}`;
    // Unauthorized
    // Redirect to login or handle as needed
// } else if (response.status === 403) {
//     // Forbidden
//     // Handle as needed
// } else {
    // Handle other status codes
    // throw new Error(`HTTP error! Status: ${response.status}`);
}

  };
  

  export default Error;