import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Loader } from "../../Components/Loader";
import { addEmployee, deleteEmployee, updateEmployee } from "../../API/EmployeeApi";
import { getEmployeeGeneral } from "../../API/UnAvaibility";
// simport ExcelExport from "./ExcelExport";

const EmployeeModal = () => {
  const [selectEmployee, setSelectEmployee] = useState();
  const [loading, setLoading] = useState(false);
  const deleteService = (id) => {
    setLoading(true);
    deleteEmployee(id)
      .then((data1) => {
        // setLoading(false);
        // console.log(data1);
        setData(data.filter((e) => e._id !== id));
      })
      .catch((err) => {
        // setLoading(false)
      }).finally(()=>setLoading(false));
  };

  const [data, setData] = useState([]);
  const getEmployee = async () => {
    setLoading(true);
    getEmployeeGeneral()
      .then((data) => {
        setLoading(false);
        setData(data?.data?.data)
      }).finally(()=>setLoading(false))
  };

  const submit = (data) => {
    setLoading(true)
    const formData = new FormData();
    var keys = Object.keys(data);
    keys?.map((e) => {
      // console.log(data[e]);
      if (data[e] instanceof FileList) {
        // console.log(e, data[e][0]);
        formData.append("file", data[e][0]);
      } else formData.append(e, data[e]);
    });
    addEmployee(formData)
      .then((data) => {
        setLoading(false)
        getEmployee();
        // reset();
      }).finally(()=>setLoading(false))
  };

  var { register, handleSubmit,reset } = useForm();
  const form2 = useForm();

  useEffect(() => {
    getEmployee();
  }, []);

  const submit2 = (data) => {
    setLoading(true)
    // console.log(data);
    const formData = new FormData();
    var keys = Object.keys(data);
    keys?.map((e) => {
      // console.log(data[e]);
      if (data[e] != "") {
        if (data[e] instanceof FileList) {
          // console.log(e, data[e][0]);
          formData.append("file", data[e][0]);
        } else formData.append(e, data[e]);
      }
    });
    formData.append("_id", selectEmployee._id);
    updateEmployee(formData)
      .then((data) => {
        // console.log(data)
        // var updateButton=window.document.querySelector('#updateEmployeeModal');
        // updateButton.classList.remove("show");
        // updateButton.classList.remove("modal-open");
        // updateButton.style.display='none';
        setLoading(false)
        getEmployee();
      }).finally(()=>setLoading(false));
  };
  return (
    <>
      {loading && <Loader/>}

      {data != null ? (
        <div className="container table-responsive p-1">
          <button
            className="btn btn-success my-2 float-end"
            data-bs-toggle="modal"
            data-bs-target="#addNewEmployees"
          >
            {" "}
            <b>+</b> Add New Employees
          </button>
          <table className="table table-bordered">
            <thead className="bg-dark text-light">
              <tr>
                <th scope="col">No</th>
                <th scope="col">Employee Name</th>
                <th scope="col">Gender</th>
                <th scope="col">Gender Served</th>
                <th scope="col">Mobile</th>
                <th scope="col">status</th>
                <th scope="col">Image</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {data?.map((data,index) => {
                return (
                  <tr key={data._id}>
                    <th>{index+1}</th>
                    <td>{data?.employeeName}</td>
                    <td className="">{data?.gender}</td>
                    <td> {data?.adminId?.genderServed}</td>
                    <td>{data?.mobile}</td>
                    <td>{data?.offlineOnline}</td>
                    <td style={{ width: "10%" }}>
                      <img src={data?.image} className="w-50" alt="p" />
                    </td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-primary"
                        data-bs-toggle="modal"
                        data-bs-target="#updateEmployeeModal"
                        onClick={() => {
                          setSelectEmployee(data);
                        }}
                      >
                        Update
                      </button>
                      <button
                        className="btn btn-danger btn-sm mx-2"
                        onClick={() => {
                          deleteService(data._id);
                        }}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <>
          <div className="container p-1">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </>
      )}
      <div
        className="modal fade"
        id="addNewEmployees"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <form onSubmit={handleSubmit(submit)}>
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Add new Employee
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="mb-3">
                  <label for="service" className="col-form-label">
                    Employee Name:{" "}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="employee"
                    {...register("employeeName")}
                  />
                </div>
                <div className="mb-3">
                  <label for="gender" className="col-form-label">
                    Gender:{" "}
                  </label>

                  <select {...register("gender")}>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </select>
                </div>
                <div className="mb-3">
                  <label for="time" className="col-form-label">
                    Mobile Number:{" "}
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="time"
                    {...register("mobile")}
                  />
                </div>
                <div className="mb-3">
                  <label for="offlineOnline" className="col-form-label">
                    Offline/Online:{" "}
                  </label>

                  <select {...register("offlineOnline")}>
                    <option value="Online" selected>
                      Online
                    </option>
                    <option value="Offline">Offline</option>
                  </select>
                </div>
                <div className="mb-3">
                    <label for="genderServed" className="col-form-label">
                      Gender Served:
                    </label>

                    <select {...register("genderServed")}>
                      <option value="unisex" selected>Unisex</option>
                      <option value="male" >Male</option>
                      <option value="female">Female</option>
                    </select>
                  </div>
                <div className="mb-3">
                  <label for="img" className="col-form-label">
                    Upload image:
                  </label>{" "}
                  <br />
                  <input
                    type="file"
                    name=""
                    id="img"
                    {...register("image")}
                    width="50px"
                    height="50px"
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button type="submit" className="btn btn-primary">
                  Add Employee
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="updateEmployeeModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
          <div className="modal-header">
                  <h1 className="modal-title fs-5" id="exampleModalLabel">
                    Update Employee Details
                  </h1>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
            <div className="modal-body">
              <form onSubmit={form2.handleSubmit(submit2)}>
                <input
                  type="text"
                  className="form-control"
                  id="service"
                  defaultValue={selectEmployee?._id}
                  {...form2.register("id")}
                  hidden
                />
                
                <div className="modal-body">
                  <div className="mb-3">
                    <label for="service" className="col-form-label">
                      Employee Name:{" "}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="employee"
                      defaultValue={selectEmployee?.employeeName}
                      {...form2.register("employeeName")}
                    />
                  </div>
                  <div className="mb-3">
                    <label for="gender" className="col-form-label">
                      Gender:{" "}
                    </label>

                    <select
                      {...form2.register("gender")}
                      defaultValue={selectEmployee?.gender}
                    >
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </select>
                  </div>
                  <div className="mb-3">
                    <label for="time" className="col-form-label">
                      Mobile Number:
                    </label>
                    <input
                      type="tel"
                      className="form-control"
                      id="time"
                      defaultValue={selectEmployee?.mobile}
                      {...form2.register("mobile")}
                    />
                  </div>
                  <div className="mb-3">
                    <label for="offlineOnline" className="col-form-label">
                      Offline/Online:
                    </label>

                    <select
                      {...form2.register("offlineOnline")}
                      defaultValue={selectEmployee?.offlineOnline}
                    >
                      <option value="Online" selected>
                        Online
                      </option>
                      <option value="Offline">Offline</option>
                    </select>
                  </div>
                  <div className="mb-3">
                    <label for="genderServed" className="col-form-label">
                      Gender Served:
                    </label>

                    <select
                      {...form2.register("genderServed")}
                      defaultValue={selectEmployee?.genderServed}
                    >
                      <option value="male" selected>
                        Male
                      </option>
                      <option value="female">Female</option>
                      <option value="unisex">Unisex</option>
                    </select>
                  </div>
                  <div className="mb-3">
                    <label for="img" className="col-form-label">
                      Upload image:
                    </label>{" "}
                    <br />
                    <input
                      type="file"
                      name=""
                      id="img"
                      defaultValue={selectEmployee?.image}
                      {...form2.register("image")}
                      width="50px"
                      height="50px"
                    />
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <button type="submit" className="btn btn-primary" id='update' data-bs-dismiss="modal">
                    Update Employee
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeeModal;
