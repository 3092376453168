import { createSlice } from "@reduxjs/toolkit";

const initialState={
    value:""
}

const userSlice=createSlice({
    name:'user',
    initialState,
    reducers:{
        addUser:(state,action)=>{
            //console.log(action.payload)
            state.value=action.payload
        }
    }
})

export const {addUser}=userSlice.actions
export default userSlice.reducer