import { Route, Routes, useLocation } from "react-router-dom";
// import { BrowserRouter as Router, Route, Link, Prompt } from 'react-router-dom';
import Navbar from "./Components/Navbar";
import Home from "./Pages/home/Home";
import { SignUp } from "./Pages/signupPage/SignUp";
import { ConfirmationMail } from "./Pages/signupPage/ConfirmationMail";
import { ForgotPassword } from "./Pages/loginPage/ForgotPassword";
import { Service } from "./Pages/servicePage/Service";
import { Store } from "./Pages/storePage/Store";
import { Store1 } from "./Pages/store/Store1";
import ProtectedRoutes from "./ProtectedRoutes";
import BussinessForm from "./Pages/businessRegister/BussinessForm";
import AdminProtectedRoutes from "./AdminProtectedRoutes";
import { Error404 } from "./Components/errors/Error404";
import { Error500 } from "./Components/errors/error500/Error500";
import Demo from "./Pages/AdminHomePage/Demo";
import Unavailability from "./Components/Unavailability";
import { UserCart } from "./Pages/UserCart/UserCart";
import { CurrentAppointments } from "./Pages/Appointments/CurrentAppointments";
import { UserProfile } from "./Pages/UserProfile/UserProfile";
import { PastAppointments } from "./Pages/Appointments/PastAppointments";
import Stats from "./Pages/AdminHomePage/Stats";
import Gallery from "./Pages/AdminHomePage/Gallery";
import ServiceModal from "./Pages/AdminHomePage/ServiceModal";
import EmployeeModal from "./Pages/AdminHomePage/EmployeeModal";
import Footer from "./Components/Footer";
import { UpdateStore } from "./Pages/AdminHomePage/UpdateStore";
import { CheckedIn } from "./Pages/CheckedIn/CheckedIn";
import Register from "./Pages/loginPage/Register";
import { ChangePassword } from "./Pages/loginPage/ChangePassword";
import { ShopsPastApp } from "./Pages/AdminHomePage/ShopsPastApp";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { Suspense, lazy, useEffect, useState } from "react";
import { MultiService } from "./Pages/AdminHomePage/MultiService";
import { MultiEmployee } from "./Pages/AdminHomePage/MultiEmployee";
import { Privacy1 } from "./Pages/Privacy/Privacy1";
import { UTS1 } from "./Pages/UserTermsfService/UTS1";
import Appointments from "./Pages/AdminHomePage/Appointments";
import { CancelAppointments } from "./Pages/Appointments/CancelAppointments";
import { GymOffer } from "./Pages/Offers/GymOffer";
import { OfferInformation } from "./Pages/Offers/OfferInformation";
import SurveyFormForAll from "./Components/SurveyFormForAll";
// import { Loader } from "./Components/Loader";
// console.log(process.env)
const firebaseConfig = {
  apiKey: "AIzaSyDfKafeLGBSf8d8-pHSbk0ytK_Lt8ndQas",
  authDomain: "bookmycoiffeur-08141820.firebaseapp.com",
  projectId: "bookmycoiffeur-08141820",
  storageBucket: "bookmycoiffeur-08141820.appspot.com",
  messagingSenderId: "569742225430",
  appId: "1:569742225430:web:344ef755020b04144d8537",
  measurementId: "G-NCTCPN6XGJ",
};
// const Footer=lazy(()=>{
//   return new Promise(resolve=> setTimeout(resolve,5*1000)).then(
//     ()=>import("./Components/Footer"))
//   });

// const Footer= lazy(() =>{
// return new Promise(resolve => setTimeout(resolve,5 * 1000)).then(()=>Math.floor(Math.random() * 10)>=4 ? import("./Components/Footer")
// : Promise.reject(new Error())
// )})
const App = () => {
  // console.log(process.env.REACT_APP_NODE_ENV)
  useEffect(() => {
    if (process.env.REACT_APP_NODE_ENV === "production") {
      // console.log(process.env)
      const app = initializeApp(firebaseConfig);
      const analytics = getAnalytics(app);
    }
  }, []);
  const [history,setHistory]=useState();
  const location = useLocation(); // Get the current location

  // Check if the current path is "/survey"
  const isSurveyPage = location.pathname === '/en/form';
  return (
    // !loader && (
    <>
      {!isSurveyPage &&<Navbar />}
      <Routes>
        <Route path="/privacy" element={<Privacy1/>}/>
        <Route path="/kiosk" element={<OfferInformation />}/>
        <Route path="/usertermsofservice"  element={<UTS1/>}/>
        <Route path="/multi" element={<MultiService/>}/> 
        <Route path="/multiEmp" element={<MultiEmployee/>}/>
        <Route path="/cancelAppointment/:appId" element={<CancelAppointments/>} />
        <Route element={<ProtectedRoutes></ProtectedRoutes>}>
          <Route path="/gotostore" element={<BussinessForm />}></Route>
          <Route path="/cart" element={<UserCart />} />
          <Route path="/currentappointment" element={<CurrentAppointments />} />
          <Route   path="/userprofile/:userId" element={<UserProfile />} />
          <Route
            path="/pastappointment/:userId"
            element={<PastAppointments />}
          />
        </Route>
        <Route element={<AdminProtectedRoutes />}>
          <Route path="/adminhomepage/services" element={<ServiceModal />} />
          <Route path="/adminhomepage/employee" element={<EmployeeModal />} />
          <Route path="/adminhomepage" element={<Demo />} />
          <Route path="/adminhomepage/stats" element={<Stats />}></Route>
          <Route path="/adminhomepage/pastappointment" element={<ShopsPastApp />}/>
          <Route path="/adminhomepage/appointments" element={<Appointments/>} />
          <Route path="/adminhomepage/updatestore" element={<UpdateStore />} />
          <Route path="/adminhomepage/gallery" element={<Gallery />} />
          <Route path="/checkin/:appId" element={<CheckedIn />} />
          <Route
            path="/adminhomepage/unavailability"
            element={<Unavailability />}
          ></Route>
        </Route>
        <Route path="/listofstore" element={<Store />}></Route>
        <Route exact path="/" element={<Home />}></Route>
        <Route path="/signup" element={<SignUp />}></Route>
        {/* <Route path="/login" element={<Login />}></Route> */}
        <Route path="/login" element={<Register setHistory={setHistory} />}></Route>
        <Route path="/confirm" element={<ConfirmationMail />}></Route>
        <Route
          path="/login/forgotPassword"
          element={<ForgotPassword />}
        ></Route>
        <Route path="/service" element={<Service />}></Route>
        <Route path="/store" element={<Store1 />}></Route>
        <Route path="/ms/:storename/:locality/:city/:id" element={<Store1 />}></Route>
        <Route path="/error500" element={<Error500 />} />
        <Route path="/storeqr/:id" element={<Store1 />} />
        <Route path="/goffer/:code" element={<GymOffer/>} />
        {/* <Route path="/payment" element={<Payment/>}/> */}
        <Route
          path="/login/changepassword/:token"
          element={<ChangePassword />}
        />
        <Route path="/error/:code" element={<Error404/>}/>
        <Route path="/*" element={<Error404 />} />
      <Route path='/en/form' element={<SurveyFormForAll/>}/>
      </Routes>
      {!isSurveyPage && <Footer />}
    </>
  );
};

export default App;
