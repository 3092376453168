import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    filter: {},
    gender: [],
    categories: {},
    city: "",
    locallity: ""
}

const filterSlice = createSlice({
    name: "filter",
    initialState,
    reducers: {
        addCategory: (state, action) => {
            // console.log(action.payload)
            const category = action.payload;
            // console.log(state.categories[category])
            state.categories[category]=!state.categories[category]
        },
        setSelectedCategories: (state, action) => {
            // console.log(action.payload)
            state.categories = action.payload;
        },
        addGender:(state,action)=>{
            // console.log(action.payload)
            state.gender[0]=action.payload
        },
        clearAll:(state,payload)=>{
            state.categories={}
            state.gender=[]
        }
    }
})

export const { addCategory, setSelectedCategories,addGender,clearAll } = filterSlice.actions
export default filterSlice.reducer