import React, { useEffect, useState } from "react";
import "./ServiceModal.css";
import axios from "axios";
import { useForm } from "react-hook-form";
import { Loader } from "../../Components/Loader";
import { addOneService, deleteService, getServiceData, updateService } from "../../API/ServiceApi";
import { getServiceCategoryData, getStoreCategoryData } from "../../API/AdditionalApi";
const ServiceModal = (adminId) => {
  const [selectService, setSelectService] = useState();
  const [loading, setLoading] = useState(false);
  const deleteService1 = (id) => {
    setLoading(true)
    deleteService(id)
      .then((data1) => {
        setData(data.filter((e) => e._id !== id));
      })
      .catch((err) => {
      }).finally(()=>setLoading(false));
  };

  const [data, setData] = useState([]);
  const getService = async () => {
    setLoading(true)
    getServiceData()
    .then((data)=>{
      // setLoading(false)
      setData(data?.data?.data);
      // console.log(data);
    }).catch((error)=>{
      // setLoading(false)
    }).finally(()=>setLoading(false))
  };

  const submit = (data) => {
    setLoading(true)
    const formData = new FormData();
    var keys = Object.keys(data);
    keys?.map((e) => {
      // console.log(data[e]);
      if (data[e] instanceof FileList) {
        // console.log(e, data[e][0]);
        formData.append("file", data[e][0]);
      } else formData.append(e, data[e]);
    });
    addOneService(formData)
      .then((data) => {
        getService();
      })
      .catch((error) => {
      }).finally(()=>setLoading(false));
  };

  const [serviceCategory, setserviceCategory] = useState();
  const getStoreCategory = () => {
    getServiceCategoryData()
      .then((data2) => {
        // console.log(data2);
        setserviceCategory(data2?.data?.data);
        // console.log(data2?.data?.data);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  var { register, handleSubmit } = useForm();
  const form2 = useForm();

  useEffect(() => {
    getService();
    getStoreCategory();
  }, []);

  const submit2 = (data) => {
    setLoading(true)
    const formData = new FormData();
    var keys = Object.keys(data);
    keys?.map((e) => {
      if (data[e] != "") {
        if (data[e] instanceof FileList) {
          // console.log(e, data[e][0]);
          formData.append("file", data[e][0]);
        } else formData.append(e, data[e]);
      }
    });
    formData.append("_id", selectService._id);
    updateService(formData)
      .then((data) => {
        getService();
      })
      .catch((error) => {
      }).finally(()=>setLoading(false));
  };
  return (
    <>
      {loading && <Loader/>}
      {data != null ? (
        <div className="container table-responsive p-1">
          <button
            className="btn btn-success my-2 float-end"
            data-bs-toggle="modal"
            data-bs-target="#staticBackdrop"
          >
            {" "}
            <b>+</b> Add Service
          </button>
          <table className="table table-bordered">
            <thead className="bg-dark text-light">
              <tr>
                <th scope="col">No</th>
                <th scope="col">Services</th>
                <th scope="col">Category</th>
                <th scope="col">Expected time</th>
                <th scope="col">price</th>
                <th scope="col">Gender</th>
                <th scope="col">Description</th>
                <th scope="col">image</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {/* {console.log(selectService)} */}
              {data?.map((data,id) => {
                return (
                  <tr key={data._id}>
                    <th>{id+1}</th>
                    <td>{data?.serviceName}</td>
                    <td className="">{data?.categoryId?.categoryName}</td>
                    <td>{data?.time}</td>
                    <td>{data?.price}</td>
                    <td>{data?.gender}</td>
                    <td>{data?.description}</td>
                    <td style={{ width: "10%" }}>
                      <img src={data?.image} className="w-50" alt="p" />
                    </td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-primary"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        onClick={() => {
                          setSelectService(data);
                        }}
                      >
                        Update
                      </button>
                      <button
                        className="btn btn-danger btn-sm mx-2"
                        onClick={() => {
                          deleteService1(data._id);
                        }}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <>
          <div className="container p-1">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </>
      )}
      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <form onSubmit={handleSubmit(submit)}>
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Add new services
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="mb-3">
                  <label for="service" className="col-form-label">
                    Service name:{" "}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="service"
                    {...register("serviceName")}
                  />
                </div>
                <div className="mb-3">
                  <label for="service" className="col-form-label">
                    Service Type:{" "}
                  </label>
                  {/* {console.log(serviceCategory)} */}
                  <select {...register("categoryId")}>
                    {serviceCategory?.map((e) => {
                      return <option value={e._id}>{e.categoryName}</option>;
                    })}
                  </select>
                </div>
                <div className="mb-3">
                  <label for="time" className="col-form-label">
                    Expected time (min.):
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="time"
                    {...register("time")}
                  />
                </div>
                <div className="mb-3">
                  <label for="price" className="col-form-label">
                    Price:{" "}
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="price"
                    {...register("price")}
                  />
                </div>
                <div className="mb-3">
                  <label for="gender" className="col-form-label">
                    Gender:{" "}
                  </label>

                  <select {...register("gender")}>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="unisex">Unisex</option>
                  </select>
                </div>
                <div className="mb-3">
                  <label for="message-text" className="col-form-label">
                    Description:
                  </label>
                  <textarea
                    className="form-control"
                    id="message-text"
                    {...register("description")}
                  ></textarea>
                </div>
                <div className="mb-3">
                  <label for="img" className="col-form-label">
                    Upload image:
                  </label>{" "}
                  <br />
                  <input
                    type="file"
                    name=""
                    id="img"
                    {...register("image")}
                    width="50px"
                    height="50px"
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Add Service
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <form onSubmit={form2.handleSubmit(submit2)}>
                <input
                  type="text"
                  className="form-control"
                  id="service"
                  defaultValue={selectService?._id}
                  {...form2.register("id")}
                  hidden
                />
                <div className="modal-header">
                  <h1 className="modal-title fs-5" id="exampleModalLabel">
                    Add new services
                  </h1>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <div className="mb-3">
                    <label for="service" className="col-form-label">
                      Service name:{" "}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="service"
                      defaultValue={selectService?.serviceName}
                      {...form2.register("serviceName")}
                    />
                  </div>
                  <div className="mb-3">
                    <label for="service" className="col-form-label">
                      Service Type:{" "}
                    </label>
                    {/* {console.log(serviceCategory)} */}
                    <select
                      {...form2.register("categoryId")}
                      defaultValue={selectService?.categoryId}
                    >
                      {serviceCategory?.map((e) => {
                        return <option value={e._id}>{e.categoryName}</option>;
                      })}
                    </select>
                  </div>
                  <div className="mb-3">
                    <label for="time" className="col-form-label">
                      Expected time:{" "}
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="time"
                      defaultValue={selectService?.time}
                      {...form2.register("time")}
                    />
                  </div>
                  <div className="mb-3">
                    <label for="price" className="col-form-label">
                      Price:{" "}
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="price"
                      defaultValue={selectService?.price}
                      {...form2.register("price")}
                    />
                  </div>
                  <div className="mb-3">
                    <label for="gender" className="col-form-label">
                      Gender:{" "}
                    </label>

                    <select
                      {...form2.register("gender")}
                      defaultValue={selectService?.gender}
                    >
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                      <option value="unisex">Unisex</option>
                    </select>
                  </div>
                  <div className="mb-3">
                    <label for="message-text" className="col-form-label">
                      Description:
                    </label>
                    <textarea
                      className="form-control"
                      id="message-text"
                      defaultValue={selectService?.description}
                      {...form2.register("description")}
                    ></textarea>
                  </div>
                  <div className="mb-3">
                    <label for="img" className="col-form-label">
                      Upload image:
                    </label>{" "}
                    <br />
                    <input
                      type="file"
                      name=""
                      id="img"
                      defaultValue={selectService?.image}
                      {...form2.register("image")}
                      width="50px"
                      height="50px"
                    />
                  </div>
                </div>
                <div className="modal-footer">
                  <button className="btn btn-secondary" data-bs-dismiss="modal">
                    Close
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    value="Save Changes"
                    data-bs-dismiss="modal">
                      Save Changes
                    </button>
                  
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceModal;
