import Cookies from "js-cookie"
import { deleteApi, postApi, updateApi } from "../APIManager/ApiManager"

const admin=localStorage.getItem('shopify')
const user=localStorage.getItem('qwerty')
const uuid=btoa(Cookies.get('session') || localStorage.getItem('session'))

export const deleteEmployee=(id)=>{
    return deleteApi('/api/v1/admin/employee/deleteemployee/'+id,{user:user,admin:admin,uuid:uuid})
}

export const addEmployee=(data)=>{
    return postApi("/api/v1/admin/employee/addemployee",data,{user:user,admin:admin,uuid:uuid})
}

export const updateEmployee=(data)=>{
    return updateApi( "/api/v1/admin/employee/updateemployee",data,{user:user,admin:admin,uuid:uuid})
}

export const multiAddEmployee=(data)=>{
    return postApi('/api/v1/admin/employee/addmultiemployee',data,{user:user,admin:admin,uuid:uuid})
}