import React from "react";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";

function LineChart({ chartData,newData }) {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    // scales: {
    //   x: {
    //     type: 'time',
    //     title: {
    //       display: true,
    //       text: 'Month',
    //     },
    //   },
    //   y: {
    //     beginAtZero: true,
    //     title: {
    //       display: true,
    //       text: 'Value',
    //     },
    //   },
    // },
    plugins: {
      legend: {
        display: true,
        position: 'top',
      },
      title: {
        display: true,
        text: 'Line Chart Example',
        padding: {
          top: 10,
          bottom: 30,
        },
      },
    },
  };

  return <Line data={newData} options={options} />;
}

export default LineChart;
