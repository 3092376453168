import classes from "./home.module.css";
import Carousel1 from "../../Components/Carousel";
import Homeservice2 from "../../Components/Homeservice2";
import jwt_decode from "jwt-decode";
import { carousel } from "../../data";
import React from "react";
import { motion, useAnimation, useInView } from "framer-motion";
import { useEffect } from "react";
import { useState, useRef } from "react";
import ReviewForm from "../Review Form/Review";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import CustomButton from "../../Components/CustomButton";
import { AddWebSiteReview, getWebsiteReview } from "../../API/ReviewApi";
import regIcon from "../../Images/register-icon.svg";
import whyIconOne from "../../Images/map_beauty-salon.svg";
import testiIcon from "../../Images/test-1.jpg";
import playIcon from "../../Images/play-icon.svg";
import mainHeroImg from "../../Images/main-hero-img.jpg";
import animateImg from "../../Images/main-img-side.svg";
import Services from "./Services";
const Home = () => {
  var [review, setReview] = useState();
  // console.log(window.speechSynthesis.getVoices())

  const onSubmit = (data) => {
    var user = localStorage.getItem("qwerty");
    if (user) {
      AddWebSiteReview(data)
        .then((data) => {
          Swal.fire({
            icon: "success",
            title: "Thanks For Giving your Valuable Feedback",
          });
        })
        .catch((error) => {
          // console.log(error)
        });
    } else {
      Swal.fire({
        icon: "error",
        title: "Login Required",
      });
    }
  };
  const checkAdmin = () => {
    var token = localStorage.getItem("shopify");
    try {
      if (token != null) {
        var dec = jwt_decode(token);
        if (!dec.message) {
          //console.log("here")
          setAdmin(true);
        } else setAdmin(false);
      }
    } catch (error) {
      //console.log(error)
    }
  };
  const getReviews = () => {
    getWebsiteReview()
      .then((data) => {
        //console.log("djeghfjherg",data)
        setReview(data.data.data);
      })
      .catch((error) => {
        //console.log(error)
      });
  };
  useEffect(() => {
    getReviews();
    checkAdmin();
  }, []);
  const starsContent = [
    "HAIRCUTTING",
    "BEARD GROOMING",
    "STYLING",
    "HAIR COLOR",
    "MAKEUP SALON",
  ];
  // const starSectionRef = useRef(null);
  const leftRightSectionRef = useRef(null);
  const [, setAdmin] = useState(false);
  // const starIsInView = useInView(starSectionRef, { once: true });
  const leftRightSectionIsInView = useInView(leftRightSectionRef, {
    once: true,
  });
  const navigate=useNavigate();
  return (
    <>
      <section className={`container-fluid ${classes.mainHeroSection}`}>
        <div className="container-fluid ">
          <div className="row justify-content-center">
            <div className={` ${classes.mainHeroSectionRow} col-md-11 col-sm-12 col col-12`}>
              <div className={classes.mainHeroSectionContent}>
                <h1>EMPOWERING THE ART OF GROOMING</h1>
                <p>
                  Empowering the Art of GroomingElevate Your Style with Skilled
                  Barbers and Stylists.
                </p>
                <div className={classes.mainHeroSectionBtn}>
                  <button className={classes.playBtn}>
                  {/* <button className={classes.playBtn}> */}
                    <img src={playIcon} className="mx-auto" alt="lt" />
                  </button>
                  <button style={{   background: "none"}}>
                  <Link className="nav-link btn" to='/listofstore'>
                  <button className="btn-primary-hard" >
                    <img src={regIcon} alt="icon" /> 
                    Book Appointment
                    {/* REGISTER SHOP */}
                  </button>
                  </Link>  
                  </button>
                </div>
              </div>

              <div className={classes.mainHeroImage}>
                <div className={classes.mainHeroImageBG}>
                  <img src={mainHeroImg} alt="di" className={classes.curvImg} />
                  <img
                    src={animateImg}
                    alt="anim"
                    className={classes.imgImage}
                  />
                  <div className={classes.circle}>
                    <div className={classes.circleTopContent}>
                      <svg
                        width="155"
                        height="115"
                        viewBox="0 0 155 115"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M32.6224 107.447L23.9076 106.98L24.0708 103.933C24.1033 103.326 24.2351 102.831 24.4661 102.448C24.6943 102.065 24.9895 101.786 25.3516 101.612C25.7109 101.438 26.1033 101.362 26.5288 101.385C26.9032 101.405 27.2089 101.488 27.4457 101.635C27.6827 101.778 27.8663 101.962 27.9966 102.185C28.1271 102.405 28.2197 102.642 28.2744 102.895L28.3595 102.9C28.3908 102.634 28.4987 102.371 28.6834 102.111C28.868 101.85 29.1241 101.638 29.4515 101.473C29.779 101.309 30.1725 101.239 30.6321 101.263C31.0689 101.287 31.4565 101.407 31.7948 101.624C32.1331 101.842 32.3929 102.17 32.5742 102.609C32.7555 103.049 32.8278 103.612 32.791 104.298L32.6224 107.447ZM31.7427 106.342L31.8549 104.248C31.8918 103.559 31.7847 103.062 31.5335 102.758C31.2797 102.452 30.9613 102.288 30.5783 102.268C30.2832 102.252 30.0069 102.313 29.7492 102.45C29.4887 102.586 29.2758 102.79 29.1105 103.06C28.9424 103.329 28.8482 103.654 28.8279 104.035L28.713 106.179L31.7427 106.342ZM27.7939 106.13L27.8987 104.173C27.9157 103.855 27.8687 103.565 27.7575 103.303C27.6465 103.038 27.4816 102.825 27.2627 102.662C27.0439 102.497 26.7814 102.406 26.475 102.389C26.092 102.369 25.7601 102.485 25.4791 102.737C25.1953 102.989 25.0379 103.405 25.0069 103.984L24.9003 105.975L27.7939 106.13Z"
                          fill="black"
                        />
                        <path
                          d="M33.915 94.7881L25.4234 92.7734L26.6394 87.6486L27.5515 87.8651L26.5796 91.9616L29.4488 92.6423L30.3578 88.8112L31.27 89.0276L30.361 92.8587L33.2468 93.5434L34.2345 89.3806L35.1466 89.597L33.915 94.7881Z"
                          fill="black"
                        />
                        <path
                          d="M33.3621 75.5673C32.946 75.441 32.5558 75.4967 32.1914 75.7342C31.827 75.9717 31.5389 76.3325 31.3271 76.8166C31.1723 77.1706 31.094 77.5053 31.0924 77.8209C31.0919 78.1339 31.1594 78.4084 31.2949 78.6444C31.4316 78.8779 31.6274 79.0504 31.8825 79.162C32.0959 79.2553 32.3016 79.2849 32.4996 79.2505C32.6961 79.2125 32.8804 79.135 33.0525 79.018C33.222 78.8999 33.378 78.7666 33.5205 78.6181C33.6604 78.4684 33.7823 78.3279 33.8863 78.1967L34.4602 77.4803C34.6059 77.296 34.7827 77.0973 34.9906 76.8844C35.1997 76.6689 35.4387 76.4804 35.7078 76.319C35.9754 76.1539 36.2716 76.051 36.5964 76.0101C36.9213 75.9693 37.2711 76.0309 37.6459 76.1948C38.0779 76.3838 38.4188 76.6679 38.6685 77.0469C38.9194 77.4233 39.0531 77.8725 39.0697 78.3945C39.0874 78.9139 38.9619 79.4808 38.6932 80.095C38.4427 80.6676 38.1334 81.123 37.7652 81.4612C37.3983 81.7968 36.9971 82.012 36.5618 82.1068C36.1276 82.1991 35.6851 82.1652 35.2342 82.0051L35.6714 81.0056C35.9847 81.1117 36.279 81.1195 36.5542 81.0291C36.828 80.9349 37.0728 80.7722 37.2887 80.541C37.5031 80.3062 37.6797 80.0299 37.8187 79.7124C37.9804 79.3428 38.0657 78.9848 38.0746 78.6383C38.081 78.2907 38.0137 77.9837 37.8728 77.7174C37.7294 77.45 37.5119 77.2525 37.2204 77.125C36.9549 77.0088 36.7064 76.9885 36.4749 77.064C36.2434 77.1395 36.0227 77.2755 35.8127 77.472C35.6027 77.6685 35.3972 77.8902 35.1961 78.1371L34.4846 78.998C34.0317 79.5441 33.559 79.9264 33.0664 80.145C32.5739 80.3637 32.0582 80.3551 31.5194 80.1194C31.0718 79.9236 30.7343 79.6318 30.5071 79.244C30.2783 78.8524 30.1615 78.4106 30.1567 77.9185C30.1503 77.4227 30.2587 76.9197 30.4819 76.4096C30.7074 75.8942 31.0002 75.4766 31.3603 75.1566C31.7179 74.8355 32.1066 74.6273 32.5267 74.532C32.9478 74.434 33.3629 74.4668 33.7721 74.6303L33.3621 75.5673Z"
                          fill="black"
                        />
                        <path
                          d="M36.3721 67.2153L35.5941 66.6923L39.2458 61.2602L40.0239 61.7832L38.4928 64.0607L44.9576 68.4067L44.368 69.2837L37.9032 64.9378L36.3721 67.2153Z"
                          fill="black"
                        />
                        <path
                          d="M56.6112 46.6746C56.3014 46.3695 55.93 46.2375 55.497 46.2785C55.064 46.3196 54.6412 46.5053 54.2288 46.8356C53.9272 47.0771 53.7024 47.3372 53.5544 47.6159C53.4085 47.8928 53.3408 48.1673 53.3511 48.4392C53.3637 48.7094 53.457 48.9532 53.631 49.1705C53.7767 49.3523 53.9451 49.474 54.1364 49.5356C54.3281 49.5932 54.5273 49.6102 54.734 49.5865C54.939 49.5607 55.1391 49.5151 55.3342 49.4498C55.5276 49.3823 55.7009 49.3145 55.8539 49.2465L56.6949 48.8787C56.9096 48.7832 57.1585 48.6895 57.4415 48.5975C57.7267 48.5037 58.0259 48.4479 58.3392 48.43C58.6528 48.4081 58.963 48.4545 59.2696 48.5693C59.5763 48.684 59.8574 48.9011 60.1132 49.2204C60.408 49.5885 60.5779 49.9983 60.6229 50.45C60.6702 50.8998 60.5799 51.3597 60.3521 51.8297C60.1265 52.2979 59.7521 52.7416 59.2288 53.1607C58.7409 53.5514 58.2554 53.8109 57.7724 53.9394C57.2915 54.0661 56.8363 54.0703 56.4067 53.9521C55.9794 53.8321 55.6033 53.5965 55.2783 53.2452L56.1298 52.5633C56.358 52.8028 56.615 52.9464 56.9007 52.9942C57.1869 53.0379 57.4793 53.0076 57.7778 52.9032C58.0768 52.7948 58.3615 52.6323 58.6321 52.4156C58.9469 52.1634 59.1888 51.886 59.3577 51.5833C59.5248 51.2784 59.6078 50.9754 59.6068 50.6741C59.604 50.3706 59.5031 50.0947 59.3042 49.8464C59.1231 49.6202 58.9125 49.4868 58.6724 49.4461C58.4324 49.4054 58.1737 49.4232 57.8965 49.4997C57.6192 49.5762 57.3342 49.677 57.0415 49.8023L56.0115 50.2341C55.3567 50.5073 54.7605 50.6263 54.2228 50.5911C53.685 50.5559 53.2323 50.3087 52.8647 49.8497C52.5593 49.4683 52.396 49.0531 52.3749 48.6041C52.3543 48.1512 52.4561 47.7057 52.6804 47.2676C52.9051 46.8256 53.2347 46.4306 53.6694 46.0825C54.1084 45.7309 54.5617 45.497 55.0293 45.381C55.4951 45.2627 55.9361 45.259 56.3523 45.3697C56.7708 45.4786 57.1232 45.7005 57.4095 46.0353L56.6112 46.6746Z"
                          fill="black"
                        />
                        <path
                          d="M67.2821 47.3012L63.1301 39.6249L67.7629 37.1191L68.2089 37.9437L64.5057 39.9467L65.9086 42.5405L69.372 40.6672L69.818 41.4918L66.3546 43.3651L67.7657 45.9738L71.5289 43.9384L71.9749 44.763L67.2821 47.3012Z"
                          fill="black"
                        />
                        <path
                          d="M78.7534 41.7945L76.0382 33.5004L78.8407 32.583C79.4887 32.3708 80.0568 32.3074 80.5451 32.3927C81.0325 32.4753 81.4388 32.6756 81.7641 32.9935C82.0894 33.3115 82.3321 33.7149 82.492 34.2035C82.652 34.6922 82.694 35.1583 82.6179 35.6017C82.5419 36.0451 82.3327 36.4424 81.9903 36.7936C81.647 37.1421 81.1541 37.4215 80.5115 37.6319L78.2436 38.3743L77.9466 37.4672L80.1821 36.7353C80.6249 36.5904 80.9601 36.4089 81.1877 36.1909C81.4179 35.9721 81.5549 35.724 81.5986 35.4466C81.6442 35.1656 81.6126 34.8591 81.5039 34.527C81.3952 34.1949 81.238 33.925 81.0325 33.7173C80.827 33.5096 80.5658 33.3828 80.2489 33.3371C79.9312 33.2887 79.5482 33.3378 79.1 33.4845L77.3343 34.0626L79.7578 41.4658L78.7534 41.7945ZM81.4378 36.7906L84.6986 39.8483L83.5323 40.2301L80.3038 37.1618L81.4378 36.7906Z"
                          fill="black"
                        />
                        <path
                          d="M90.2334 29.7033L93.755 36.652L93.8564 36.6387L95.4729 29.0182L96.5715 28.8745L94.5255 37.9436L93.4438 38.0851L89.1348 29.8469L90.2334 29.7033Z"
                          fill="black"
                        />
                        <path
                          d="M106.024 28.6669L105.773 37.3906L104.717 37.3602L104.967 28.6366L106.024 28.6669Z"
                          fill="black"
                        />
                        <path
                          d="M121.479 33.3732L120.441 33.1762C120.436 32.8659 120.378 32.5832 120.268 32.328C120.16 32.0733 120.012 31.8486 119.823 31.6538C119.638 31.4567 119.421 31.2942 119.174 31.1663C118.926 31.0384 118.66 30.9474 118.375 30.8934C117.856 30.7949 117.361 30.8369 116.89 31.0193C116.421 31.2022 116.015 31.5255 115.669 31.9892C115.327 32.4534 115.085 33.0567 114.944 33.7991C114.803 34.5416 114.807 35.1915 114.956 35.7489C115.108 36.3068 115.368 36.7566 115.737 37.0984C116.108 37.4407 116.554 37.6611 117.073 37.7596C117.358 37.8136 117.639 37.8264 117.916 37.798C118.193 37.7696 118.454 37.6991 118.698 37.5863C118.945 37.4713 119.165 37.3151 119.359 37.1176C119.556 36.9178 119.713 36.6758 119.831 36.3916L120.869 36.5886C120.708 37.012 120.491 37.3771 120.219 37.6841C119.947 37.991 119.634 38.2367 119.281 38.4213C118.928 38.6031 118.549 38.7205 118.143 38.7736C117.739 38.8272 117.321 38.813 116.889 38.7309C116.157 38.5922 115.541 38.2902 115.039 37.8249C114.538 37.3596 114.186 36.7667 113.984 36.046C113.783 35.3253 113.768 34.5129 113.939 33.6085C114.111 32.7042 114.422 31.9537 114.874 31.3569C115.325 30.7601 115.87 30.3371 116.507 30.0879C117.144 29.8386 117.828 29.7834 118.56 29.9221C118.992 30.0041 119.386 30.144 119.742 30.3416C120.101 30.5397 120.411 30.7893 120.672 31.0904C120.933 31.3887 121.134 31.7305 121.275 32.1157C121.417 32.4982 121.484 32.9174 121.479 33.3732Z"
                          fill="black"
                        />
                        <path
                          d="M126.789 41.1827L129.976 33.0581L134.88 34.9815L134.537 35.8543L130.618 34.3168L129.541 37.062L133.206 38.4999L132.864 39.3726L129.199 37.9348L128.115 40.6959L132.098 42.2582L131.756 43.131L126.789 41.1827Z"
                          fill="black"
                        />
                        <path
                          d="M145.897 43.3344C146.081 42.9401 146.08 42.546 145.897 42.1518C145.713 41.7577 145.396 41.4218 144.946 41.1442C144.617 40.9412 144.297 40.8167 143.985 40.7708C143.675 40.7263 143.394 40.7546 143.141 40.8557C142.891 40.9582 142.692 41.1279 142.546 41.3648C142.424 41.563 142.366 41.7625 142.372 41.9633C142.382 42.1632 142.433 42.3566 142.524 42.5434C142.617 42.7278 142.728 42.901 142.855 43.0629C142.983 43.2224 143.105 43.3629 143.22 43.4842L143.849 44.153C144.011 44.3232 144.183 44.5261 144.365 44.7619C144.549 44.9991 144.702 45.2622 144.824 45.5513C144.95 45.8394 145.01 46.1471 145.005 46.4745C145 46.8019 144.89 47.1396 144.675 47.4876C144.427 47.8889 144.098 48.1865 143.687 48.3805C143.28 48.576 142.816 48.6453 142.297 48.5885C141.78 48.5331 141.236 48.3293 140.666 47.977C140.134 47.6486 139.727 47.2784 139.444 46.8664C139.163 46.456 139.006 46.0286 138.973 45.5843C138.943 45.1415 139.039 44.7081 139.261 44.2841L140.189 44.8573C140.04 45.1526 139.991 45.4429 140.042 45.7281C140.096 46.0124 140.223 46.2776 140.422 46.5238C140.624 46.769 140.873 46.9827 141.168 47.1648C141.511 47.3768 141.853 47.5116 142.195 47.5691C142.538 47.6242 142.852 47.6006 143.135 47.4986C143.42 47.394 143.646 47.2064 143.813 46.9357C143.966 46.6892 144.021 46.446 143.978 46.2062C143.936 45.9664 143.833 45.7288 143.668 45.4933C143.502 45.2578 143.312 45.0231 143.096 44.7894L142.343 43.9641C141.866 43.439 141.554 42.9173 141.406 42.3989C141.259 41.8806 141.34 41.3712 141.649 40.8709C141.906 40.4551 142.242 40.162 142.658 39.9914C143.078 39.8199 143.532 39.7663 144.019 39.8306C144.511 39.8939 144.994 40.0719 145.468 40.3645C145.946 40.66 146.319 41.0086 146.585 41.4101C146.853 41.8092 147.004 42.2233 147.04 42.6526C147.078 43.0833 146.987 43.4897 146.768 43.8718L145.897 43.3344Z"
                          fill="black"
                        />
                      </svg>
                    </div>
                    <div className={classes.simpleDotTop}></div>
                    <div className={classes.circleBottomContent}>
                      <svg
                        width="155"
                        height="115"
                        viewBox="0 0 155 115"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M121.793 8.10526L130.507 8.57204L130.344 11.6188C130.312 12.2259 130.18 12.721 129.949 13.1041C129.721 13.4873 129.426 13.7659 129.063 13.94C128.704 14.1142 128.312 14.1899 127.886 14.1671C127.512 14.1471 127.206 14.0638 126.969 13.9174C126.732 13.7739 126.549 13.5905 126.418 13.3673C126.288 13.1469 126.195 12.9101 126.141 12.6568L126.056 12.6523C126.024 12.918 125.916 13.1811 125.732 13.4415C125.547 13.7019 125.291 13.9143 124.964 14.0789C124.636 14.2434 124.243 14.3134 123.783 14.2887C123.346 14.2653 122.959 14.145 122.62 13.9277C122.282 13.7105 122.022 13.3822 121.841 12.9429C121.66 12.5036 121.587 11.9407 121.624 11.2542L121.793 8.10526ZM122.672 9.21071L122.56 11.3043C122.523 11.9937 122.63 12.4902 122.882 12.7938C123.135 13.1004 123.454 13.264 123.837 13.2845C124.132 13.3003 124.408 13.2397 124.666 13.1027C124.926 12.9659 125.139 12.7625 125.305 12.4926C125.473 12.2228 125.567 11.8978 125.587 11.5176L125.702 9.37299L122.672 9.21071ZM126.621 9.42222L126.516 11.3796C126.499 11.6974 126.546 11.9872 126.658 12.2492C126.769 12.5141 126.933 12.7277 127.152 12.8903C127.371 13.0556 127.634 13.1465 127.94 13.1629C128.323 13.1834 128.655 13.0675 128.936 12.8151C129.22 12.5629 129.377 12.1474 129.408 11.5687L129.515 9.5772L126.621 9.42222Z"
                          fill="black"
                        />
                        <path
                          d="M120.5 20.7641L128.992 22.7788L127.776 27.9036L126.864 27.6872L127.835 23.5907L124.966 22.9099L124.057 26.7411L123.145 26.5246L124.054 22.6935L121.168 22.0088L120.181 26.1716L119.268 25.9552L120.5 20.7641Z"
                          fill="black"
                        />
                        <path
                          d="M121.053 39.985C121.469 40.1112 121.859 40.0556 122.224 39.8181C122.588 39.5806 122.876 39.2198 123.088 38.7357C123.243 38.3817 123.321 38.0469 123.323 37.7313C123.323 37.4184 123.256 37.1439 123.12 36.9078C122.983 36.6744 122.788 36.5019 122.533 36.3903C122.319 36.2969 122.113 36.2674 121.915 36.3017C121.719 36.3398 121.535 36.4173 121.363 36.5343C121.193 36.6524 121.037 36.7857 120.895 36.9342C120.755 37.0838 120.633 37.2243 120.529 37.3556L119.955 38.072C119.809 38.2563 119.632 38.4549 119.424 38.6678C119.215 38.8834 118.976 39.0718 118.707 39.2332C118.44 39.3983 118.143 39.5013 117.819 39.5421C117.494 39.583 117.144 39.5214 116.769 39.3574C116.337 39.1684 115.996 38.8844 115.747 38.5054C115.496 38.1289 115.362 37.6797 115.345 37.1577C115.328 36.6383 115.453 36.0715 115.722 35.4572C115.972 34.8846 116.282 34.4292 116.65 34.0911C117.017 33.7555 117.418 33.5403 117.853 33.4454C118.287 33.3532 118.73 33.3871 119.181 33.5472L118.744 34.5466C118.43 34.4405 118.136 34.4327 117.861 34.5232C117.587 34.6174 117.342 34.78 117.126 35.0112C116.912 35.2461 116.735 35.5223 116.596 35.8398C116.435 36.2094 116.349 36.5675 116.34 36.914C116.334 37.2616 116.401 37.5685 116.542 37.8348C116.686 38.1023 116.903 38.2997 117.195 38.4273C117.46 38.5434 117.709 38.5637 117.94 38.4883C118.172 38.4128 118.392 38.2768 118.602 38.0803C118.812 37.8838 119.018 37.6621 119.219 37.4151L119.93 36.5543C120.383 36.0082 120.856 35.6258 121.349 35.4072C121.841 35.1886 122.357 35.1971 122.896 35.4328C123.343 35.6287 123.681 35.9205 123.908 36.3083C124.137 36.6998 124.254 37.1417 124.258 37.6337C124.265 38.1295 124.156 38.6325 123.933 39.1427C123.708 39.658 123.415 40.0757 123.055 40.3956C122.697 40.7167 122.308 40.925 121.888 41.0203C121.467 41.1182 121.052 41.0854 120.643 40.922L121.053 39.985Z"
                          fill="black"
                        />
                        <path
                          d="M118.043 48.3369L118.821 48.8599L115.169 54.2921L114.391 53.769L115.922 51.4915L109.457 47.1456L110.047 46.2685L116.512 50.6144L118.043 48.3369Z"
                          fill="black"
                        />
                        <path
                          d="M97.8038 68.8776C98.1136 69.1827 98.4851 69.3147 98.9181 69.2737C99.3511 69.2327 99.7738 69.047 100.186 68.7167C100.488 68.4752 100.713 68.215 100.861 67.9363C101.007 67.6594 101.074 67.385 101.064 67.113C101.051 66.8428 100.958 66.5991 100.784 66.3817C100.638 66.1999 100.47 66.0782 100.279 66.0166C100.087 65.9591 99.8878 65.9421 99.681 65.9657C99.4761 65.9915 99.276 66.0371 99.0808 66.1024C98.8874 66.17 98.7142 66.2377 98.5611 66.3057L97.7201 66.6735C97.5054 66.769 97.2566 66.8627 96.9736 66.9547C96.6883 67.0485 96.3891 67.1043 96.0759 67.1222C95.7622 67.1442 95.4521 67.0977 95.1454 66.983C94.8388 66.8682 94.5576 66.6512 94.3019 66.3319C94.0071 65.9638 93.8372 65.5539 93.7921 65.1023C93.7449 64.6524 93.8351 64.1925 94.0629 63.7225C94.2885 63.2543 94.663 62.8107 95.1863 62.3916C95.6741 62.0009 96.1596 61.7413 96.6427 61.6128C97.1236 61.4861 97.5788 61.4819 98.0083 61.6001C98.4356 61.7202 98.8118 61.9558 99.1367 62.307L98.2852 62.9889C98.0571 62.7495 97.8001 62.6058 97.5143 62.5581C97.2281 62.5143 96.9358 62.5446 96.6372 62.649C96.3383 62.7575 96.0535 62.92 95.783 63.1366C95.4681 63.3888 95.2262 63.6662 95.0573 63.9689C94.8902 64.2738 94.8072 64.5769 94.8082 64.8781C94.8111 65.1816 94.9119 65.4575 95.1108 65.7059C95.2919 65.9321 95.5025 66.0655 95.7426 66.1062C95.9826 66.1469 96.2413 66.129 96.5186 66.0525C96.7958 65.9761 97.0808 65.8752 97.3736 65.75L98.4036 65.3182C99.0583 65.045 99.6545 64.926 100.192 64.9612C100.73 64.9964 101.183 65.2435 101.55 65.7025C101.856 66.0839 102.019 66.4991 102.04 66.9481C102.061 67.4011 101.959 67.8466 101.735 68.2846C101.51 68.7266 101.18 69.1217 100.746 69.4697C100.307 69.8214 99.8533 70.0552 99.3857 70.1712C98.9199 70.2895 98.4789 70.2933 98.0627 70.1826C97.6443 70.0736 97.2919 69.8518 97.0055 69.5169L97.8038 68.8776Z"
                          fill="black"
                        />
                        <path
                          d="M87.1329 68.251L91.2849 75.9274L86.6521 78.4332L86.2061 77.6085L89.9093 75.6055L88.5064 73.0118L85.0431 74.885L84.5971 74.0604L88.0604 72.1872L86.6494 69.5784L82.8862 71.6139L82.4402 70.7892L87.1329 68.251Z"
                          fill="black"
                        />
                        <path
                          d="M75.6616 73.7577L78.3768 82.0519L75.5743 82.9693C74.9263 83.1814 74.3582 83.2448 73.8699 83.1596C73.3826 83.077 72.9762 82.8767 72.6509 82.5587C72.3256 82.2407 72.083 81.8374 71.923 81.3487C71.763 80.86 71.7211 80.394 71.7971 79.9506C71.8732 79.5072 72.0824 79.1099 72.4248 78.7587C72.768 78.4101 73.261 78.1307 73.9035 77.9203L76.1715 77.1779L76.4685 78.0851L74.2329 78.8169C73.7901 78.9619 73.455 79.1433 73.2274 79.3613C72.9971 79.5802 72.8601 79.8283 72.8164 80.1057C72.7709 80.3866 72.8025 80.6931 72.9112 81.0252C73.0199 81.3573 73.177 81.6272 73.3825 81.835C73.588 82.0427 73.8492 82.1694 74.1661 82.2152C74.4839 82.2636 74.8669 82.2144 75.315 82.0677L77.0808 81.4897L74.6573 74.0865L75.6616 73.7577ZM72.9773 78.7616L69.7164 75.7039L70.8828 75.3221L74.1112 78.3904L72.9773 78.7616Z"
                          fill="black"
                        />
                        <path
                          d="M64.1816 85.849L60.6601 78.9003L60.5587 78.9136L58.9421 86.5341L57.8435 86.6777L59.8895 77.6086L60.9712 77.4672L65.2802 85.7053L64.1816 85.849Z"
                          fill="black"
                        />
                        <path
                          d="M48.3914 86.8853L48.642 78.1616L49.6984 78.192L49.4478 86.9157L48.3914 86.8853Z"
                          fill="black"
                        />
                        <path
                          d="M32.9361 82.179L33.9744 82.376C33.9791 82.6863 34.0368 82.969 34.1475 83.2242C34.2553 83.4789 34.4035 83.7037 34.5921 83.8985C34.7774 84.0955 34.9938 84.258 35.2413 84.386C35.4888 84.5139 35.755 84.6049 36.0397 84.6589C36.5588 84.7573 37.054 84.7154 37.5252 84.533C37.9937 84.35 38.4005 84.0267 38.7458 83.563C39.0884 83.0989 39.33 82.4956 39.4709 81.7531C39.6117 81.0107 39.6078 80.3608 39.459 79.8034C39.3075 79.2455 39.0473 78.7956 38.6784 78.4539C38.3067 78.1115 37.8613 77.8911 37.3421 77.7927C37.0575 77.7387 36.7765 77.7258 36.4993 77.7542C36.2221 77.7826 35.9615 77.8532 35.7175 77.9659C35.4701 78.0809 35.2496 78.2372 35.056 78.4347C34.8591 78.6344 34.7019 78.8764 34.5843 79.1606L33.546 78.9636C33.7073 78.5403 33.9241 78.1751 34.1962 77.8682C34.4684 77.5613 34.7811 77.3155 35.1342 77.1309C35.4867 76.9491 35.8661 76.8317 36.2723 76.7786C36.6758 76.725 37.0938 76.7393 37.5264 76.8214C38.2577 76.9601 38.8741 77.2621 39.3757 77.7273C39.8774 78.1926 40.229 78.7856 40.4306 79.5062C40.6322 80.2269 40.6472 81.0394 40.4757 81.9437C40.3041 82.848 39.9926 83.5986 39.5411 84.1953C39.0896 84.7921 38.5452 85.2151 37.9081 85.4644C37.2709 85.7136 36.5867 85.7689 35.8554 85.6302C35.4228 85.5481 35.0286 85.4083 34.6728 85.2107C34.3143 85.0125 34.0045 84.7629 33.7435 84.4618C33.482 84.1636 33.2809 83.8218 33.14 83.4365C32.9985 83.054 32.9306 82.6349 32.9361 82.179Z"
                          fill="black"
                        />
                        <path
                          d="M27.6258 74.3696L24.4388 82.4941L19.5355 80.5707L19.8779 79.698L23.7973 81.2354L24.8742 78.4902L21.2086 77.0524L21.5509 76.1796L25.2165 77.6175L26.2996 74.8564L22.3166 73.294L22.659 72.4213L27.6258 74.3696Z"
                          fill="black"
                        />
                        <path
                          d="M8.51767 72.2177C8.33427 72.612 8.33456 73.0062 8.51855 73.4003C8.70254 73.7944 9.01933 74.1303 9.46891 74.4079C9.79763 74.611 10.1181 74.7354 10.4303 74.7814C10.7401 74.8258 11.0214 74.7975 11.2741 74.6964C11.5244 74.5939 11.7227 74.4242 11.869 74.1874C11.9914 73.9892 12.0495 73.7896 12.0434 73.5888C12.0332 73.3889 11.9824 73.1956 11.8907 73.0087C11.7976 72.8243 11.6875 72.6512 11.5605 72.4892C11.4319 72.3297 11.31 72.1893 11.1946 72.0679L10.5659 71.3991C10.4039 71.2289 10.232 71.026 10.0504 70.7903C9.86633 70.553 9.7133 70.2899 9.5913 70.0009C9.4654 69.7127 9.40503 69.405 9.41021 69.0776C9.41539 68.7503 9.52546 68.4126 9.74043 68.0645C9.98823 67.6633 10.3173 67.3656 10.7276 67.1716C11.1355 66.9761 11.5991 66.9068 12.1182 66.9636C12.635 67.019 13.1786 67.2228 13.749 67.5751C14.2808 67.9036 14.6882 68.2737 14.9714 68.6857C15.2521 69.0962 15.4089 69.5235 15.4417 69.9679C15.472 70.4107 15.3763 70.8441 15.1545 71.2681L14.2264 70.6948C14.3754 70.3995 14.4244 70.1092 14.3735 69.824C14.3187 69.5397 14.192 69.2745 13.9935 69.0283C13.791 68.7831 13.5423 68.5694 13.2474 68.3873C12.9042 68.1753 12.5617 68.0406 12.2199 67.9831C11.8766 67.928 11.5632 67.9515 11.2798 68.0536C10.9949 68.1581 10.7688 68.3457 10.6016 68.6164C10.4494 68.8629 10.3943 69.1061 10.4366 69.3459C10.4788 69.5857 10.5825 69.8233 10.7475 70.0588C10.9126 70.2944 11.1033 70.529 11.3195 70.7627L12.072 71.588C12.5491 72.1131 12.8613 72.6348 13.0086 73.1532C13.1559 73.6716 13.0751 74.1809 12.766 74.6813C12.5093 75.097 12.173 75.3901 11.7571 75.5607C11.3373 75.7322 10.8835 75.7858 10.3956 75.7215C9.9038 75.6582 9.42102 75.4803 8.94727 75.1877C8.46869 74.8921 8.09628 74.5436 7.83004 74.1421C7.5623 73.743 7.41073 73.3288 7.37532 72.8996C7.33748 72.4688 7.42822 72.0624 7.64752 71.6803L8.51767 72.2177Z"
                          fill="black"
                        />
                      </svg>
                    </div>
                    <div className={classes.simpleDotBottom}></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* --------- Experience the Art --------- */}
      <div className={classes.exportArt}>
        <div className={classes.exportArtContainer}>
          <div className={`d-block d-md-flex justify-content-between`}>
            <div className={`${classes.secondSectionRow}`}>
              <div className={classes.imgOne}></div>
              <div className={classes.imgTwo}></div>
            </div>
            <div className={`${classes.secondSectionContent}`}>
              <h3>Experience the Art of Timeless Grooming</h3>
              <p>
                Welcome to BookMyCoiffeur, your gateway to a world of effortless
                grooming and elevated styling.
              </p>
              <p>
                For customers, it's about securing your preferred time slots
                effortlessly with just a few clicks.
              </p>
              <p>
                For salon and barbershop owners, it's about the advantage of
                gaining enhanced visibility and efficient client management,
                streamlining and optimizing business operations.
              </p>
              <p>
                Join us to elevate your style, where convenience and efficiency
                redefine the grooming experience for all.
              </p>
              <div className={classes.btnSection}>
                <button className="btn-secondary-outline">LEARN MORE</button>
                <button className="btn-primary-hard">
                  <img src={regIcon} alt="icon" /> REGISTER SHOP
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* --------- Experience the Art --------- */}
      {/* --------- Services --------- */}
      <Services />
      {/* --------- Services --------- */}
      {/* --------- Why Section --------- */}
      <div className={`${classes.whySectionWrapper}`}>
        <div className={classes.whySectionHeader}>
          <h3>
            Why <b>BookMyCoiffeur</b> is the best for your shop and beauty
            salon.
          </h3>
        </div>
        <div className={classes.whySectionGrid}>
          <div className={classes.whySectionCard}>
            <div className={classes.icon}>
              <img src={whyIconOne} alt="icon" />
            </div>
            <div className={classes.content}>
              <h5>Booking Made Simple</h5>
              <p>Effortless appointment scheduling for every customer.</p>
            </div>
          </div>
          <div className={classes.whySectionCard}>
            <div className={classes.icon}>
              <img src={whyIconOne} alt="icon" />
            </div>
            <div className={classes.content}>
              <h5>Comprehensive Salon Services</h5>
              <p>From haircuts to spa treatments, we've got it all.</p>
            </div>
          </div>
          <div className={classes.whySectionCard}>
            <div className={classes.icon}>
              <img src={whyIconOne} alt="icon" />
            </div>
            <div className={classes.content}>
              <h5>Real-Time Availability</h5>
              <p>
                Instant access to available appointment slots, ensuring
                flexibility.
              </p>
            </div>
          </div>
          <div className={classes.whySectionCard}>
            <div className={classes.icon}>
              <img src={whyIconOne} alt="icon" />
            </div>
            <div className={classes.content}>
              <h5>Client Management Tools</h5>
              <p>Efficiently handle your salon's appointments and clients.</p>
            </div>
          </div>
          <div className={classes.whySectionCard}>
            <div className={classes.icon}>
              <img src={whyIconOne} alt="icon" />
            </div>
            <div className={classes.content}>
              <h5>Easy-to-Use Dashboard</h5>
              <p>Manage your salon effortlessly from one place.</p>
            </div>
          </div>
          <div className={classes.whySectionCard}>
            <div className={classes.icon}>
              <img src={whyIconOne} alt="icon" />
            </div>
            <div className={classes.content}>
              <h5>Reviews and Ratings</h5>
              <p>
                See what your customers have to say about their experiences.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* --------- Why Section --------- */}
      {/* --------- Testi --------- */}
      <div className={classes.testiWrapper}>
        <div className={classes.testiContent}>
          “I am very happy with this beauty clinic. They really pamper their
          customers and give everything with the best”
        </div>
        <div className={classes.testiIcon}>
          <img src={testiIcon} alt="ico" />
        </div>
        <div className={classes.testiName}>
          <h6>REENA KAPOOR</h6>
          <span>Artist / Singer</span>
        </div>
      </div>
      {/* --------- Testi --------- */}
      {/* --------- Subscription --------- */}
      <div className={classes.subscriptionWrapper}>
        <div className={classes.subscriptionContent}>
          <h2>Start subscribing to the newsletter via your email</h2>
        </div>
        <div className={classes.subscriptionForm}>
          <input
            name="email"
            type="email"
            placeholder="Enter your e-mail address"
          />
          <button className="btn-primary-hard">SUBSCRIBE</button>
        </div>
       </div>

       {/* <ReviewForm/> */}
      {/* --------- Subscription --------- */}

      {/* <div className="star-section container my-5" ref={starSectionRef}>
        <p className="star-contents d-flex justify-content-between text-light">
          {starsContent.map((content, idx) => (
            <motion.span
              key={idx}
              className="star text-light mx-2"
              initial={{ x: -100, opacity: 0, scale: 0, y: 0 }}
              whileHover={{ y: 5 }}
              animate={
                starIsInView
                  ? {
                      x: 0,
                      opacity: 1,
                      scale: 1,
                      transition: { duration: 0.3, delay: idx * 0.2 },
                    }
                  : {}
              }
            >
              <PiStarFourFill className="star text-light mx-2" />
              {content}
            </motion.span>
          ))}
        </p>
      </div> */}

      {/* <div
        className="container-fluid left-right-content d-flex justify-content-between pt-2"
        ref={leftRightSectionRef}
      >
        <motion.div
          className="left-content mx-5"
          initial={{ x: "-100vw", opacity: 0, scale: 0 }}
          animate={
            leftRightSectionIsInView
              ? {
                  x: "0vw",
                  opacity: 1,
                  scale: 1,
                  transition: { duration: 0.5, delay: 0.3 },
                }
              : {}
          }
        >
          <h1 className="left-heading gold">
            Experience the Art of Timeless Grooming
          </h1>
          

          <p>
            {" "}
            Welcome to BookMyCoiffeur, your gateway to a world of effortless
            grooming and elevated styling.
            <br />
            For customers, it's about securing your preferred time slots
            effortlessly with just a few clicks.
            <br />
            For salon and barbershop owners, it's about the advantage of gaining
            enhanced visibility and efficient client management, streamlining
            and optimizing business operations.
        
            <br />
            Join us to elevate your style, where convenience and efficiency
            redefine the grooming experience for all.
          </p>
          <div className="d-flex gap-5">
            <Link className="nav-link active" to="/listofstore">
              <CustomButton className={"btn btn-primary"} text={"Book Appointments"} />
              
            </Link>
            <Link className="nav-link active" to="/gotostore">
            <CustomButton className={"btn btn-success"} text={"Register Your Store"} />
              
            </Link>
          </div>
        </motion.div>
        <div className="right">
          <motion.img
            fluid
            src="https://res.cloudinary.com/dpoecdktk/image/upload/v1697429552/salon_b68sqj.png"
            alt=""
            initial={{ x: "100%", opacity: 0 }}
            animate={
              leftRightSectionIsInView
                ? {
                    x: 0,
                    opacity: 1,
                    transition: { duration: 0.5, delay: 0.8 },
                  }
                : {}
            }
          />
        </div>
      </div> */}

      {/* <Homeservice2 /> */}
      {/* <h1 className="my-5 mx-3">
        What Our <span className="gold">Clients Say</span>
      </h1> */}
      {/* <ReviewForm onSubmit={onSubmit} review={review} /> */}
      {/* <ReviewForms onSubmit={onSubmit} /> */}
    </>
  );
};

export default Home;
