import Cookies from "js-cookie"
import { getApi, postApi } from "../APIManager/ApiManager"

const admin=localStorage.getItem('shopify')
const user=localStorage.getItem('qwerty')
const uuid=btoa(Cookies.get('session') || localStorage.getItem('session'))

export const getspecificstoreemployeesforslot=(id)=>{
    return getApi('/api/v1/slot/getspecificstoreemployeesforslot/'+id)
}

export const getBusinnessDataForUser=(id)=>{
    return getApi('/api/v1/businessform/getbusinessdata/'+id)
}

export const getThreeDayAppointment=(id)=>{
    return getApi('/api/v1/appointment/getthreedayappointment/'+id)
}

export const addToCartApi=(data)=>{
    return postApi('/api/v1/cart/addtocart',data,{user:user,admin:admin,uuid:uuid})
}

export const directAppointment=(data)=>{
    return postApi('/api/v1/appointment/directapp',data,{user:user,admin:admin,uuid:uuid})
}

