import Cookies from "js-cookie"
import { getApi, postApi } from "../APIManager/ApiManager"

const admin=localStorage.getItem('shopify')
const user=localStorage.getItem('qwerty')
const uuid=btoa(Cookies.get('session') || localStorage.getItem('session'))

export const AddWebSiteReview=(data)=>{
    return postApi('/api/v1/website/review/addwebsitereview',data,{user:user,admin:admin,uuid:uuid})
}

export const getWebsiteReview=()=>{
    return getApi('/api/v1/website/review/getwebsitereview')
}

export const addShopReview=(data)=>{
    return postApi('/api/v1/store/review/addshopreview',data,{user:user,admin:admin,uuid:uuid})
}

export const getShopReview=(id)=>{
    return getApi('/api/v1/store/review/getshopreview/'+id)
}