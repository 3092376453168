import React from 'react'
import './error404.css'
import { Link, useParams } from 'react-router-dom'

export const Error404 = () => {
    const { code } = useParams();
    const erors={
        400 : 'Bad Request',
        401: 'Unauthorized',
        402 :'Payment Required',
        403 :'Forbidden',
        404 : 'Not Found',
        405 :'Method Not Allowed',
        500: 'Internal Server Error',
        502 :'Bad Gateway'
      }
    return (
        <>
        {/* col-sm-10 col-sm-offset-1 */}
            <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Arvo" />
            <section className="page_404 mt-5">
                <div className=" h-100">
                    
                        <div className="col-12 ">
                            <div className="  text-center">
                                <div className="four_zero_four_bg">
                                    <h1 className="text-center ">{code}</h1>
                                {/* </div>
                                <div className="contant_box_404"> */}
                                    <h3 className="h2">Look like you're lost</h3>
                                    <p>{erors[code]}</p>
                                    <Link to='/' className="link_404">
                                        Go to Home
                                    </Link>
                                </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
