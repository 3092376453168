import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Demo2 from "../DemoSlot/Demo2";
import { addToCart, removeFromCart, removeTime } from "../../redux/CartSlice";
import jwt_decode from "jwt-decode";
import Slider from "@mui/material/Slider";
import styled from "@mui/material/styles/styled";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { removeStore } from "../../redux/StoreSlice";
import { useTokenCheckerLogout } from "../../useTokenCheckerLogout";
import { getSpecificStoreService, updateTime } from "../../API/UserServiceApi";
import { getServiceCategoryData } from "../../API/AdditionalApi";
import styles from "./UserServicePage.module.css";
import img1 from "../../Images/haricut.jpg";
import { IoTime } from "react-icons/io5";
import { FaMale } from "react-icons/fa";
import { FaFemale } from "react-icons/fa";
import Swal from "sweetalert2";
const PrettoSlider = styled(Slider)({
  color: "#053B50",
  height: 8,
  "& .MuiSlider-track": {
    border: "2px solid #053B50",
  },
  "& .MuiSlider-thumb": {
    height: 24,
    width: 24,
    backgroundColor: "#64CCC5",
    border: "2px solid #053B50",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "unset",
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: "50% 50% 50% 0",
    backgroundColor: "#64CCC5",
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&:before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
});

const UserServicePage = (props) => {
  // console.log(props)
  var user = useTokenCheckerLogout();
  // console.log(user);
  const navigate = useNavigate();
  const [token, setToken] = useState({});
  const [service, setService] = useState([]);
  const dispatch = useDispatch();
  const store = useSelector((state) => state.cart);
  var [error, setError] = useState("");
  const [serviceInStore, setServiceInStore] = useState([]);
  const [serviceCat, setServiceCat] = useState();
  var [category, setcategory] = useState("all");
  const [priceValue, setPriceValue] = useState([0, 100]);
  const [max, setMax] = useState(100);
  var [gender, setGender] = useState([]);
  const [isMobile, setIsMobile] = useState();
  const [expanded, setExpanded] = useState(false);
  const handleChangeAccordian = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const getData = () => {
    getSpecificStoreService(props.value)
      .then((data) => {
        setService(data.data.data);
        //console.log("dattta", data.data.maxPrice);
        setPriceValue([0, data.data.maxPrice[0].maxPrice]);
        setMax(data.data.maxPrice[0].maxPrice);
      })
      .catch((error) => {
        //console.log(error);
      });
  };
  var decoded = null;
  const checkToken = () => {
    if (localStorage.getItem("qwerty") != null) {
      decoded = jwt_decode(localStorage.getItem("qwerty"));
      setToken(decoded);
    }
  };

  const getStoreCategory = () => {
    getServiceCategoryData()
      .then((data) => {
        setServiceCat(data.data.data);
        //console.log(data.data.data);
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  var arr = [];
  useEffect(() => {
    getStoreCategory();
    localStorage.removeItem("timerStart");
    getData();
    checkToken();
    store.value.serviceId?.map((e) => {
      arr.push(e._id);
    });
    setServiceInStore(arr);
    updateTime({
      userId: decoded?.id,
    })
      .then((data) => {
        //console.log("timerUpdate", data);
      })
      .catch((error) => {
        //console.log(error);
      });
    dispatch(removeTime());
    dispatch(removeStore());
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust the breakpoint as needed
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const {
    formState: { errors },
    setValue,
  } = useForm({});
  const addService = (id, data) => {
    // console.log(data);
    if (user) {
      const service = document.querySelectorAll(".service__button");
      for (let i = 0; i < service.length; i++) {
        if (service[i].id === id.toString() && service[i].innerHTML === "ADD") {
          if (store?.value?.serviceId?.length <= 4) {
            service[i].innerHTML = "Remove";
            // console.log("thisssss");
            // data.isOffered = props?.isOffered;
            setServiceInStore([...serviceInStore, id]);
            dispatch(addToCart({ data: data, user: token.id }));
            service[i].classList.remove("btn-brown");
            service[i].classList.add("btn-gray");
          } else {
            alert("you can Book Only 5 Appointments per day");
          }
        } else if (
          service[i].id === id.toString() &&
          service[i].innerHTML === "Remove"
        ) {
          dispatch(removeFromCart({ data: id, user: token.id }));
          const updatedItems = serviceInStore.filter((item) => item !== id);
          setServiceInStore(updatedItems);
          service[i].innerHTML = "ADD";
          service[i].classList.add("btn-brown");
          service[i].classList.remove("btn-gray");
        }
      }
    } else {
      Swal.fire({
        icon: "warning",
        title: "Please Login In order to Book Appointment",
        button: true,
      }).then((e) => {
        const urlpart = window.location.href.split("/");
        navigate("/login", {
          state: {
            fromServicePage: urlpart.slice(3).join("/"),
            isOffered: props?.isOffered,
          },
        });
      });
      // alert("Please Login In order to see the Slots");
    }
  };
  const handleChange = (event, newValue) => {
    setPriceValue(newValue);
  };

  const clearFilter = () => {
    setPriceValue([priceValue[0], max]);
    setGender([]);
  };

  return (
    <>
      {/* {console.log("service",service)} */}
      <div className={`servicesContainer h-100 mt-5 mb-4`}>
        <div className="h-100">
          {error}
          <div className="row">
            <div className="col-md-6 col-lg-6 col-sm-12 h-100 row ms-5 service__filter">
              <div>
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label d-flex">
                    Gender
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    sx={{ display: "flex", justifyContent: "space-between" }}
                    value={gender}
                  >
                    <FormControlLabel
                      value="female"
                      control={<Radio />}
                      onClick={() => {
                        setGender(["female"]);
                      }}
                      label="Female"
                    />
                    <FormControlLabel
                      value="male"
                      control={<Radio />}
                      onClick={() => {
                        setGender(["male"]);
                      }}
                      label="Male"
                    />
                    <FormControlLabel
                      value="unisex"
                      control={<Radio />}
                      onClick={() => {
                        setGender(["unisex"]);
                      }}
                      label="Unisex"
                    />
                    <button onClick={clearFilter} className="btn btn-secondary">
                      Clear Filter
                    </button>
                  </RadioGroup>
                </FormControl>
                {/* <button onClick={clearFilter} className="btn btn-secondary">
                  Clear Filter
                </button>  */}
              </div>
              <div className={`col-lg-12  searviceTab ${styles.serviceTab}`}>
                <ul
                  className="nav nav-pills "
                  aria-orientation="horizontal"
                  id="experienceTab"
                  role="tablist"
                >
                  <li className="nav-item" role="tab">
                    <a
                      className="nav-link active"
                      id="list-home-list"
                      data-bs-toggle="list"
                      href="#list-home"
                      role="tab"
                      aria-controls="home"
                      aria-selected="true"
                      onClick={() => {
                        setcategory("all");
                      }}
                    >
                      All Category
                    </a>
                  </li>
                  {serviceCat?.map((e, index) => {
                    return (
                      <li className="nav-item" role="tab">
                        <a
                          className="nav-link"
                          id="list-home-list"
                          data-bs-toggle="list"
                          href="#list-home"
                          aria-controls="home"
                          aria-selected="true"
                          onClick={() => {
                            setcategory(e._id);
                          }}
                        >
                          {e?.categoryName}
                        </a>
                      </li>
                    );
                  })}
                </ul>
                {/* <span>Price</span>
                  <PrettoSlider
                    valueLabelDisplay="auto"
                    aria-label="range slider"
                    onChange={handleChange}
                    step={50}
                    max={max + 100}
                    min={0}
                    value={priceValue}
                  />
                  <p className="d-flex flex-row justify-content-between">
                  <p>Min:{priceValue[0]}</p>
                  <p>Max:{priceValue[1]}</p>
                  </p> */}

                {/* <div>
                  <FormControl>
                    <FormLabel id="demo-radio-buttons-group-label d-flex">
                      Gender
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      name="radio-buttons-group"
                      sx={{ display: "flex", justifyContent: "space-between" }}
                      value={gender}
                    >
                      <FormControlLabel
                        value="female"
                        control={<Radio />}
                        onClick={() => {
                          setGender(["female"]);
                        }}
                        label="Female"
                      />
                      <FormControlLabel
                        value="male"
                        control={<Radio />}
                        onClick={() => {
                          setGender(["male"]);
                        }}
                        label="Male"
                      />
                      <FormControlLabel
                        value="unisex"
                        control={<Radio />}
                        onClick={() => {
                          setGender(["unisex"]);
                        }}
                        label="Unisex"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
                <button onClick={clearFilter} className="btn btn-secondary">
                  Clear Filter
                </button> */}
              </div>
              {/* )} */}
              <div className="col-lg-12 col-sm-12 searviceList">
                {isMobile ? (
                  <div className="tab-content" id="nav-tabContent">
                    <div
                      className="tab-pane fade show active "
                      id="list-home"
                      role="tabpanel"
                      aria-labelledby="list-home-list"
                    >
                      <div
                        className=" w-100 d-flex listView"
                        style={{ overflowY: "scroll", maxHeight: "60vh" }}
                        // style={{ overflowY: "none", maxHeight: "50vh" }}
                      >
                        <table style={{ margin: "0px" }} className="w-100">
                          <tbody>
                            {service.map((e) => {
                              return (
                                <>
                                  {(category === e?.categoryId?._id ||
                                    category === "all") &&
                                  e.price >= priceValue[0] &&
                                  e.price <= priceValue[1] &&
                                  (gender?.length == 0 ||
                                    gender?.includes(
                                      e.gender.toLowerCase()
                                    )) ? (
                                    <>
                                      <tr className="d-flex flex-row my-1">
                                        <td>
                                          <img
                                            src={img1}
                                            alt="DEMO IMAGE"
                                            srcset=""
                                            className="serviceImage h-20 w-44 my-auto"  
                                          />
                                        </td>
                                        <td className="w-100">
                                          <div
                                            className={`${styles.textColor} ${styles.serviceSection}`}
                                          >
                                            <p className={`${styles.serviceName} `}>
                                              <b>{e.serviceName}</b>
                                              {props?.isOffered == true &&
                                              (e?.bmcDiscount ||
                                                e?.storeDiscount) ? (
                                                <img
                                                  src={
                                                    "https://res.cloudinary.com/dpoecdktk/image/upload/v1712292303/output-onlinegiftools_ckbmow.gif"
                                                  }
                                                  alt="GIF"
                                                  height="25px"
                                                  width="25px"
                                                />
                                              ) : (
                                                ""
                                              )}
                                            </p>
                                            <p className="flex gap-2 items-center">
                                              <IoTime /> {e.time} Minutes
                                            </p>

                                            <p className="flex gap-1 items-center">
                                              {e.gender.toLowerCase() ===
                                              "male" ? (
                                                <>
                                                  <FaMale /> Male
                                                </>
                                              ) : e.gender.toLowerCase() ===
                                                "female" ? (
                                                <>
                                                  <FaFemale /> Female
                                                </>
                                              ) : (
                                                "Unisex"
                                              )}
                                            </p>
                                          </div>
                                          <div
                                            className={`d-flex flex-row ${styles.priceAllignment} items-center`}
                                          >
                                            <p
                                              className={`${
                                                props?.isOffered == true &&
                                                (e?.bmcDiscount ||
                                                  e?.storeDiscount)
                                                  ? "d-flex gap-2"
                                                  : ""
                                              }`}
                                            >
                                              {props?.isOffered == true ? (
                                                <b>
                                                  &#8377;
                                                  {e.price -
                                                    e?.bmcDiscount -
                                                    e?.storeDiscount}
                                                </b>
                                              ) : (
                                                <span>&#8377;{e.price}</span>
                                              )}
                                              {props?.isOffered == true &&
                                              (e?.bmcDiscount ||
                                                e?.storeDiscount) ? (
                                                <strike>
                                                  &#8377;{e.price}
                                                </strike>
                                              ) : (
                                                ""
                                              )}
                                              {/* {e.price} */}
                                            </p>
                                            <p> 
                                              {serviceInStore.includes(
                                                e._id
                                              ) ? (
                                                <button
                                                  className="btn btn-gray service__button"
                                                  key={e._id}
                                                  id={e._id}
                                                  onClick={() => {
                                                    addService(e._id, e, true);
                                                  }}
                                                >
                                                  Remove
                                                </button>
                                              ) : (
                                                <button
                                                  className="btn btn-brown service__button"
                                                  key={e._id}
                                                  id={e._id}
                                                  onClick={() => {
                                                    addService(e._id, e, false);
                                                  }}
                                                >
                                                  ADD
                                                  {/* + */}
                                                </button>
                                              )}
                                            </p>
                                          </div>
                                        </td>
                                      </tr>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="tab-content" id="nav-tabContent">
                    <div
                      className="tab-pane fade show active "
                      id="list-home"
                      role="tabpanel"
                      aria-labelledby="list-home-list"
                    >
                      <div
                        className=" w-100 d-flex listView "
                        style={{ overflowY: "scroll", maxHeight: "50vh" }}
                      >
                        <table>
                          <thead>
                            <tr className="sticky-top listView__table">
                              {/* <th> </th> */}
                              <th>Service Name</th>
                              <th>Gender</th>
                              <th>Price</th>
                              <th>Time</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {service.map((e) => {
                              return (
                                <>
                                  {(category === e?.categoryId?._id ||
                                    category === "all") &&
                                  e.price >= priceValue[0] &&
                                  e.price <= priceValue[1] &&
                                  (gender?.length == 0 ||
                                    gender?.includes(
                                      e.gender?.toLowerCase()
                                    )) ? (
                                    <>
                                      <tr className="mb-5 pb-3">

                                        <td className={styles.serviceName}>
                                          {e.serviceName}
                                          {props?.isOffered == true &&
                                          (e?.bmcDiscount ||
                                            e?.storeDiscount) ? (
                                            <img
                                              src={
                                                "https://res.cloudinary.com/dpoecdktk/image/upload/v1712292303/output-onlinegiftools_ckbmow.gif"
                                              }
                                              alt="GIF"
                                              height="30px"
                                              width="30px"
                                            />
                                          ) : (
                                            ""
                                          )}
                                        </td>
                                        <td>
                                          {e.gender.toLowerCase() === "male"
                                            ? "M"
                                            : e.gender.toLowerCase() ===
                                              "female"
                                            ? "F"
                                            : "M/F"}
                                        </td>
                                        <td>
                                          &#8377;
                                          {props?.isOffered == true &&
                                          (e?.bmcDiscount || e?.storeDiscount)
                                            ? e.price -
                                              e?.bmcDiscount -
                                              e?.storeDiscount
                                            : e.price}
                                          {/* <strike>{servicePriceforOffer}</strike>  */}
                                          {props?.isOffered == true &&
                                          (e?.bmcDiscount ||
                                            e?.storeDiscount) ? (
                                            <strike>&#8377;{e.price}</strike>
                                          ) : (
                                            ""
                                          )}
                                          {/* {e.price} */}
                                        </td>
                                        <td>{e.time}</td>
                                        <td>
                                          {serviceInStore.includes(e._id) ? (
                                            <button
                                              className="btn btn-secondary service__button"
                                              key={e._id}
                                              id={e._id}
                                              onClick={() => {
                                                addService(e._id, e, true);
                                              }}
                                            >
                                              Remove
                                            </button>
                                          ) : (
                                            <button
                                              className="btn btn-primary service__button"
                                              key={e._id}
                                              id={e._id}
                                              onClick={() => {
                                                addService(e._id, e, false);
                                              }}
                                            >
                                              {/* + */}
                                              ADD
                                            </button>
                                          )}
                                        </td>
                                      </tr>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                      {/* <div className="d-flex row">
                        {store?.value?.serviceId?.map((e) => {
                          return (
                            <>
                              <div
                                className="serviceList col-3"
                                style={{
                                  borderBottom: "none",
                                  backgroundColor: "#64ccc5",
                                  color: "#053b50",
                                  fontSize: "20px",
                                  padding: "10px",
                                  width: "fit-content",
                                  borderRadius: "7px",
                                  marginRight: "5px",
                                  marginTop: "10px",
                                }}
                              >
                                {e?.serviceName}
                              </div>
                            </>
                          );
                        })}
                      </div> */}
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="col-lg-5 col-md-6 h-100 pe-2 col-sm-12 timeSlot">
              <Demo2
                value={props.value}
                isAdmin={props?.isAdmin === undefined ? false : true}
                address={props?.address}
                isOffered={props?.isOffered}
                services={store?.value?.serviceId}
                owner={props?.owner}
                phNumber={props?.phNumber}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserServicePage;
