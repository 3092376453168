import Cookies from "js-cookie"
import { postApi } from "../APIManager/ApiManager"

const uuid=btoa(Cookies.get('session') || localStorage.getItem('session'))

export const changePassword=(data,token)=>{
    return postApi('/api/v1/login/change',data,{user:token,admin:null,uuid:uuid})
}

export const forgotPassword=(data)=>{
    return postApi('/api/v1/login/forgotpassword',data)
}

export const getUser=(data)=>{
    return postApi('/api/v1/login/getUser',data)
}

export const confirmation=(mail,data)=>{
    return postApi('/api/v1/signup/checkOtp/'+mail,data)
}

export const signUpAddUser=(data)=>{
    return postApi('/api/v1/signup/adduser',data)
}