import React, { useEffect, useState } from "react";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import { Card } from "@mui/material";
import createTheme from "@mui/material/styles/createTheme";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea, Grid } from "@mui/material";
import { Rating } from "@mui/material";
import { useNavigate } from "react-router-dom";
import styles from "./ListOfStore.module.css";
import UnisexGender from "../../Images/GenderUnisex.png";
import FemaleGender from "../../Images/FemaleGender.png";
import location from "../../Images/location.png";
import { IoLocation } from "react-icons/io5";
import { FaMale } from "react-icons/fa";
import { FaFemale } from "react-icons/fa";
import { BiSolidOffer } from "react-icons/bi";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export const ListOfStore = ({ data, isOffered, offerDetails }) => {
  // console.log(offerDetails)
  // const [items, setItems] = useState(data);
  const theme = createTheme({
    palette: {
      primary: {
        main: "#5c6bc0",
      },
      secondary: {
        main: "#5c6bc0",
      },
    },
  });
  const navigate = useNavigate();

  const notify = () =>
  // toast("🌚 Exclusive Offers for members of RAMA'S FITNESS", {
  toast("🌟 Unlock Exclusive Offers at Rama's Fitness Today! 💪", {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
    // transition: "Flip",
    });


  const buttonClick = (id, brandName, city, location) => {
    // navigate("/store", { state: { value: id } });
    // console.log(city);
    var name = brandName.replaceAll(" ", "-");
    // navigate('/ms/name/location/city/'+btoa(id))
    navigate(
      "/ms/" +
        name +
        "/" +
        location?.toLowerCase() +
        "/" +
        city?.toLowerCase() +
        "/" +
        btoa(id),
      { state: { isOffered: isOffered } }
    );
  };

  const [expanded, setExpanded] = useState([]);

  const handleExpandClick = (index) => {
    // console.log(index);
    if (expanded.includes(index)) {
      setExpanded(expanded.filter((item) => item !== index));
    } else {
      setExpanded([...expanded, index]);
    }
  };
  
  useEffect(()=>{
    if(isOffered==true){
      notify();
    }
  })

  const goBackTwoPages = () => {
    // Navigate back two pages
    navigate(-2);
  };
  return (
    <div className="col-lg-10 main_storepage col-sm-12 h-50 col col-12 ">
      {/* <button onClick={goBackTwoPages}>Go Back Two Pages</button> */}
      <ThemeProvider theme={theme}>
      <ToastContainer
          position="top-right"
          style={{marginTop:"2rem"}}
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
        <Grid container spacing={0} className="listofStore d-flex flex-wrap ">
          {data && data?.length > 0 ? (
            data?.map((e, i) => (
              <Grid item xs={12} sm={12} md={4} lg={4} key={e.id}>
                <Card
                  sx={{
                    // maxWidth: 385,
                    height: "200",
                    minHeight: "400px",
                    width: "200",
                    m: 3,
                    color: "#ffffff",
                    textAlign: "center",
                    borderRadius: "12px",
                    lineHeight: 2,
                    // margin:"20px 5px"
                  }}
                  className="listofStore__card"
                  onClick={() => {
                    buttonClick(
                      e._id,
                      e?.brandName,
                      e?.city?.cityName,
                      e?.location
                    );
                  }}
                >
                  <CardActionArea>
                    <div className="listofStore__img">
                      <CardMedia
                        component="img"
                        className="store__image "
                        sx={{ height: "20rem", objectFit: "contain" }}
                        image={e.logo}
                        loading="lazy"
                        alt="Image not found"
                      />
                      <h6 className="flex gap-3"
                        style={{
                          background: "rgba(0, 0, 0, 0.8)",
                          color: "white",
                        }}
                      >
                        <IoLocation />
                        {/* <GrLocation className={`${styles.locationIcon}`} /> */}
                        {e?.locality},{e?.city?.cityName}
                        {/* {e.avgRating !== null
                      ? e.avgRating?.toFixed(1)
                      : "0 Reviews"      } */}
                      </h6>
                      {isOffered === true && (
                        <p>
                        <img src={'https://res.cloudinary.com/dpoecdktk/image/upload/v1712292303/output-onlinegiftools_ckbmow.gif'} alt="" srcset="" height="40px" width="40px" />
                        </p>
                        // <p>
                        //   <BiSolidOffer />
                        // </p>
                      )}
                      {/*  <Rating name="read-only" value={3.2} readOnly style={{color:"#fff",fontWeight:800}}  /> */}
                    </div>
                    <CardContent
                      sx={{
                        backgroundColor: "#fff",
                        borderTop: "2px solid black",
                        padding: "2px 15px",
                      }}
                    >
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{
                          color: "black",
                          textAlign: "left",
                          marginTop: "2px",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <p className="text-xl">{e.brandName}</p>
                        <Rating
                          name="read-only"
                          value={
                            e.avgRating !== null ? e.avgRating?.toFixed(1) : 0
                          }
                          readOnly
                          style={{
                            color: "rgba(253, 181, 64, 1)",
                            fontWeight: 800,
                          }}
                        />
                        {/* {e.avgRating !== null
                      ? e.avgRating?.toFixed(1)
                      : "0 Reviews"      } */}
                      </Typography>
                    </CardContent>
                    <CardContent
                      sx={{
                        backgroundColor: "#fff",
                        marginTop: "0",
                        padding: "4px 16px",
                      }}
                    >
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ color: "black", textAlign: "left" }}
                      >
                        {/* <h6 style={{ height: 190,height:"150",maxHeight:"220",textOverflow:"revert"}}> */}
                        {/*
                           <h6>
                            Rating:
                            {e.avgRating !== null
                              ? e.avgRating?.toFixed(1)
                              : "No Reviews"}
                          </h6> */}
                        <p className="flex gap-2 text-lg">
                          {e?.genderServed === "male" ? (
                            <FaMale />
                          ) : e?.genderServed === "female" ? (
                            <FaFemale />
                          ) : (
                            <>
                              {" "}
                              <FaFemale /> <FaMale />{" "}
                            </>
                          )}

                          {/* <img src={FemaleGender}  alt="GenderIcon" /> */}
                          {e?.genderServed[0].toUpperCase() +
                              e?.genderServed.slice(1)}
                        </p>
                        <p className="text-lg">
                          {e.location || e?.locality},{e?.city?.cityName}
                        </p>
                        <div className="flex gap-3 text-base py-1 ">
                          <img
                            src={location}
                            alt="Icon"
                            className={` h-6 w-6`}
                          />
                          <p className="overflow-hidden whitespace-nowrap text-ellipsis  ">
                          {e?.address}
                          </p>
                        </div>
                        {/* <div
                            style={{
                              color: "black",
                              fontWeight: "bold",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              display: "-webkit-box",
                              WebkitLineClamp: expanded.includes(i)
                                ? "unset"
                                : 4,
                              WebkitBoxOrient: "vertical",
                              maxHeight: expanded.includes(i) ? "none" : 30,
                              overflowY: expanded.includes(i)
                                ? "visible"
                                : "hidden",
                            }}
                          >
                            {expanded.includes(i)
                              ? e?.address
                              : `${e?.address?.substring(0, 20)}...`}
                          </div>
                          {e.address.length > 20 && (
                            <Button
                              size="small"
                              sx={{ color: "black" }}
                              onClick={() => handleExpandClick(i)}
                            >
                              {expanded.includes(i) ? "Read Less" : "Read More"}
                            </Button>
                          )} */}
                        {/* </h6> */}
                        {/* <Button
                          size="large"
                          sx={{
                            p: 1,
                            mt: 1,
                            fontWeight: "",
                            marginInline: "auto",
                          }}
                          onClick={() => {
                            buttonClick(e._id);
                          }}
                          variant="contained"
                          color="primary"
                        >
                          Book Now
                        </Button> */}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            ))
          ) : (
            <center>
              <p>
                <h2>Nothing to load.</h2>
              </p>
            </center>
          )}
        </Grid>
      </ThemeProvider>
    </div>
  );
};
