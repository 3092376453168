import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    value: []
}

const storeSlice = createSlice({
    name: 'store',
    initialState,
    reducers: {
        addStore: (state, action) => {
            action.payload?.map((e) => {
                state.value.push(e)
            })
        },
        removeStore: (state, action) => {
            // console.log("remove")
            state.value = []
            state.FilteredValue=[]
        },
        filterStore: (state, action) => {
            // console.log(action.payload.category)
            // console.log(action.payload.gender)
            // console.log(action.payload.city)
            state.FilteredValue = state.value.filter((store) => {
                const categoryMatch = action.payload.category.length === 0 || action.payload.category.includes(store.storeType?.categoryName);
                const genderMatch = action.payload.gender.length === 0 || action.payload.gender.includes(store.genderServed);
                const cityMatch=action.payload.city.length===0 || action.payload.city.includes(store.city?._id)
                const localMatch=action.payload.locality?.length===0 || action.payload.locality?.includes(store?.location)
                // console.log("category",categoryMatch)
                // console.log("gender",genderMatch)
                // console.log("city", action.payload.city.includes(store.city?._id))
                // console.log("both",categoryMatch && genderMatch && cityMatch)
                return categoryMatch && genderMatch && cityMatch && localMatch;
              });
            //   console.log(state.FilteredValue)
              
        },
        clearFilter: (state, action) => {
            state.FilteredValue = null
        },
    }
})

export const { addStore, removeStore, filterStore, clearFilter } = storeSlice.actions
export default storeSlice.reducer