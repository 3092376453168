import React, { useEffect, useState } from "react";
import "./forgotPassword.css";
import { useForm } from "react-hook-form";
import axios from "axios";
import { Loader } from "../../Components/Loader";
import Swal from "sweetalert2";
import { forgotPassword } from "../../API/LoginApi";
export const ForgotPassword = () => {
  const { register, handleSubmit } = useForm();
  const [formError, setFormError] = useState();
  const [loading,setLoading]=useState(false)

  const submit = (data) => {
    // console.log(data);
    setLoading(true)
    forgotPassword(data)
      .then((data) => {
        setLoading(false)
        // console.log(data)
        Swal.fire({
          icon: "success",
          title: "Email Sent to entered mail address",
        });
        setResendClicked(true);
        localStorage.setItem(
          "forgot",
          Math.floor(Date.now() / 1000).toString()
        );
        let timeLeft = timer;
        const timerId = setInterval(() => {
          setTimer((prev) => {
            if (prev > 1) {
              return prev - 1;
            }
            setResendClicked(false);
            localStorage.removeItem("forgot");
            clearInterval(timerId);
            return 0;
          });
        }, 1000);
      })
      .catch((error) => {
        // console.log(error);
        setLoading(false)
        setFormError(error.response.data.message);
      });
  };

  const [resendClicked, setResendClicked] = useState(false);
  const [timer, setTimer] = useState(30);

  const handleResendClick = (data) => {
    setFormError("");
    submit(data);
    if (data.email.trim() !== "") {
      setResendClicked(true);
      setTimeout(() => {
        setResendClicked(false);
      }, 30000);
    }
  };

  useEffect(() => {
    const timerInterval = setInterval(() => {
      const lastClickedTime = parseInt(localStorage.getItem("forgot"));
      if (lastClickedTime) {
        const currentTime = Math.floor(Date.now() / 1000);
        const timeDiff = currentTime - lastClickedTime;
        if (timeDiff < 30) {
          setResendClicked(true);
          setTimer(30 - timeDiff);
        } else {
          setResendClicked(false);
          setTimer(30);
          localStorage.removeItem("forgot");
        }
      }
    }, 1000);
    return () => clearInterval(timerInterval);
  }, []);

  return (
    <div>
      {loading && <Loader/>}
      <>
        <div className="container forgot__main">
          <h1>Forgot your password?</h1>
          <hr />
          {formError}
          <h3>Enter your email address to reset your password</h3>
        </div>
        <form
          className="forgot__form"
          method="post"
          onSubmit={handleSubmit(handleResendClick)}
        >
          <label htmlFor="mail">Email</label>
          <br />
          <input
            type="email"
            id="name"
            name="name"
            placeholder="Enter your email address"
            required=""
            {...register("email")}
          />
          <button type="submit" className="submit" disabled={resendClicked}>
            {resendClicked ? `try after ${timer}s` : "Send Mail"}
          </button>
          <span id="nameError" style={{ display: "none" }}>
            There was an error with your email
          </span>
        </form>
      </>
    </div>
  );
};
