import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import "./Demo1.css";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addFinaldata,
  addToCart,
  removeEverything,
} from "../../redux/CartSlice";
import useAdminAuth from "../../useAdminAuth";
import {
  addToCartApi,
  directAppointment,
  getBusinnessDataForUser,
  getThreeDayAppointment,
  getspecificstoreemployeesforslot,
} from "../../API/SlotBookingApi";
import { Button } from "@mui/material";
import InputTag from "../../Components/InputTag";
import { direct_booking_Api } from "../../API/WhatsAppApi";
import { IoMdPerson } from "react-icons/io";
import { Loader } from "../../Components/Loader";
import Swal from "sweetalert2";

const Demo2 = (store) => {
  // console.log(store)
  const navigate = useNavigate();
  const [employees, setEmployees] = useState([]);
  const [unavailable, setUnavailable] = useState([]);
  const [slot, setSlot] = useState([]);
  const [userSelectSlot, setUserSelectSlot] = useState("");
  var [openTime, setOpenTime] = useState("");
  var [closeTime, setCloseTime] = useState("");
  const [isAvailable, setIsAvailable] = useState("");
  var admin = useAdminAuth();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({});
  const ValidateSchema = {
    mobile: {
      required: {
        value: true,
        message: "Mobile No. is Required",
      },
      pattern: {
        value: /^[6-9][0-9]{9}$/,
        message: "Mobile No. should be in proper format",
      },
    },
  };
  const monthName = {
    0: "Jan",
    1: "Feb",
    2: "March",
    3: "April",
    4: "May",
    5: "June",
    6: "July",
    7: "Aug",
    8: "Sep",
    9: "Oct",
    10: "Nov",
    11: "Dec",
  };
  const weekDay = {
    0: "Sun",
    1: "Mon",
    2: "Tue",
    3: "Wed",
    4: "Thur",
    5: "Fri",
    6: "Sat",
  };
  var today = new Date();
  const [date, setDate] = useState(today.getTime());
  var unavailableEmployee = "";
  const [dates, setDates] = useState([]);
  const [employee, setEmployee] = useState("");
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  var myStore = useSelector((state) => state.cart);
  const [cartData, setCartData] = useState(null);
  var [slotTaken, setSlotTaken] = useState([]);
  const [timeTaken, setTimeTaken] = useState([]);
  const [timeSlot, setTimeSlot] = useState(0);
  var [daysOpen, setDaysOpen] = useState([]);
  var [storeName, setStoreName] = useState("");
  const [lunchTime, setLunchTime] = useState({});
  const [luncTimeInMilli, setLunchTimeInMilli] = useState({});
  const [loading, setLoading] = useState(false);
  const [thisSlotIsTakenByUser,setThisSlotIsTakenByUser]=useState()
  var totalTime1 = 0;
  var servicePriceforOffer = 0;
  const [maleServices,setMaleServices]=useState(false);
  const [femaleServices,setFemaleServices]=useState(false);
  function timeToMilliseconds(openingTime, closingTime, todate) {
    const openingHour = parseInt(openingTime.split(":")[0]);
    const openingMinute = parseInt(openingTime.split(":")[1]);
    const closingHour = parseInt(closingTime.split(":")[0]);
    const closingMinute = parseInt(closingTime.split(":")[1]);
    const fulldate = todate.split("/");
    const d = parseInt(fulldate[0], 10);
    const m = parseInt(fulldate[1], 10);
    const y = parseInt(fulldate[2]);
    const openingDate = new Date(y, m - 1, d);
    openingDate.setHours(openingHour, openingMinute, 0, 0);

    const closingDate = new Date(y, m - 1, d);
    closingDate.setHours(closingHour, closingMinute, 0, 0);

    const openingTimeMilliseconds = openingDate.getTime();
    const closingTimeMilliseconds = closingDate.getTime();

    return {
      opening: openingTimeMilliseconds,
      closing: closingTimeMilliseconds,
    };
  }

  const constructSlots = async (opening, closing, totalTime, todate, date) => {
    var slots = [];
    while (opening <= closing) {
      if (
        todate == date.toLocaleDateString("en-GB") &&
        (totalTime <= 15 ? date.getTime() + 15 * 60 * 1000 : date.getTime()) <
        opening &&
        date.getTime() < closing &&
        opening + totalTime * 60 * 1000 <= closing
      ) {
        slots.push(opening);
      } else if (
        (todate.split("/")[0] > date.getDate() ||
          todate.split("/")[1] > date.getMonth() + 1 ||
          todate.split("/")[2] > date.getFullYear()) &&
        opening + totalTime * 60 * 1000 <= closing
      ) {
        slots.push(opening);
      }
      opening += totalTime * 60 * 1000;
    }
    return slots;
  };

  const timming = async (open, close, todate) => {
    var slots = [];
    var { opening, closing } = timeToMilliseconds(open, close, todate);
    var totalTime1 = 0;
    var slots = [];
    var date = new Date();
    if (myStore?.value?.serviceId?.length > 0) {
      myStore?.value.serviceId.map((e) => {
        totalTime1 += e.time;
      });
      setTimeSlot(totalTime1);
    } else {
      totalTime1 = 30;
      setTimeSlot(0);
    }
    var lunchTimeFrom = convertToMilli(lunchTime.from, new Date(opening));
    var lunchTimeTo = convertToMilli(lunchTime?.to, new Date(opening));
    if (lunchTime?.from !== undefined && lunchTime?.to !== undefined) {
      slots.push(
        await constructSlots(opening, lunchTimeFrom, totalTime1, todate, date)
      );
      slots.flat();
      opening = lunchTimeTo;
      if (date.getTime() < lunchTimeTo) slots.push(111);
      slots.push(
        await constructSlots(opening, closing, totalTime1, todate, date)
      );
      setSlot(slots.flat());
    } else {
      slots.push(
        await constructSlots(opening, closing, totalTime1, todate, date)
      );
      setSlot(slots.flat());
    }
  };

  const isInRange = (time, emptime) => {
    return time >= emptime.starttime && time <= emptime.endtime;
  };
  // <<<<<<< perin
  //   const isInLunchRange = (time, lunchTime, totalTime) => {
  //     // console.log(new Date(totalTime*60*1000).getHours(),time)
  //     // console.log(lunchTime.from>=time+totalTime*60*1000 && time+totalTime*60*1000<=lunchTime.to,"----------->",(totalTime*60*1000));
  //     // console.log(totalTime)
  //     // 2  - 4 (lunchyime)
  //     // console.log(time >= lunchTime.from && time < lunchTime.to)
  //     return time >= lunchTime.from && time < lunchTime.to;
  //     // || time+totalTime*60*1000>=lunchTime.from && time+totalTime*60*1000<=lunchTime.to;
  const isInLunchRange = (time, lunchTime) => {
    return time >= lunchTime.from && time + timeSlot * 60 * 1000 < lunchTime.to;
  };

  const timeInRange = (time, slot, totaltime) => {
    var arr = [];
    timeTaken?.map((e) => {
      var start = e?.starttime;
      var end = e?.totaltime * 60 * 1000;
      //   if(time==1707561000000)
      //   {
      //   console.log("time",new Date(time))
      //   console.log("time",time)
      //   console.log("slot",slot," slot",new Date(slot[1]))
      //   console.log("totalTime",timeSlot)
      //   console.log("timeTaken",timeTaken)
      //   console.log(start,"  ",end);
      //   console.log("------->",new Date(time)," start", new Date(start)," start+end",new Date(start + end), " Current Total time",new Date(time + timeSlot * 60 * 1000));
      // }
      arr.push(
        (start <= time && time < end + start) ||
        (time <= start && start + end < time + timeSlot * 60 * 1000) ||
        (time + timeSlot * 60 * 1000 > start &&
          time + timeSlot * 60 * 1000 < start + end)
      );
    });

    if (arr.includes(true)) {
      return true;
    }
    return false;
  };

  const getEmployeeData = () => {
    // setEmployees([]);
    getspecificstoreemployeesforslot(store.value)
      .then((data) => {
        var count=0;
        // console.log(femaleServices,maleServices)
        // data.data.availableEmployee?.map((e=>{
        //   console.log(e.genderServed==='female')
        //    if(maleServices===false  &&( femaleServices!==false && (e.genderServed==='female' || e.genderServed==='unisex'))){
        //     console.log("eeeeeeeeeeeeee")
        //     setEmployees(prev=>[...prev,e])
        //   }
        //   else if(femaleServices===false  &&( maleServices!==false && (e.genderServed==='male' || e.genderServed==='unisex'))){
        //     setEmployees(prev=>[...prev,e])
        //   }
        //   else if(femaleServices!==false && maleServices!==false && e.genderServed==='unisex')
        //   {
        //     setEmployees(prev=>[...prev,e])
        //   }
        //   else{
        //     setEmployees(prev=>[...prev,e]);
        //     count+=1;
        //   }
        // }));
        // console.log(count);
        setEmployees(data.data.availableEmployee);
        setUnavailable(data.data.unAvailable);
      })
      .catch((error) => {
        //console.log(error);
        errorss(error);
      });
  };

  const getData = () => {
    if (store.value === undefined) {
    } else {
      getBusinnessDataForUser(store.value)
        .then((response) => {
          setStoreName(response?.data?.data?.brandName);
          setDaysOpen(response?.data?.data?.daysOpen);
          setOpenTime(response.data.data.openingTime);
          setCloseTime(response.data.data.closingTime);
          setLunchTime({
            from: response?.data?.data?.lunchFrom,
            to: response?.data?.data?.lunchTo,
          });
          timming(
            response.data.data.openingTime,
            response.data.data.closingTime,
            new Date().toLocaleDateString("en-GB")
          );
        })
        .catch((error) => {
          errorss(error);
        });
      getThreeDayAppointment(store.value)
        .then((data) => {
          console.log(data.data.data);
          setCartData(data.data.data);
        })
        .catch((error) => {
          errorss(error);
        });
    }
  };

  const errorss = (error) => {
    if (error.response) {
      // The request was made, but the server responded with an error status code
      if (error.response.status === 500) navigate("/error500");
      // //console.log("Response Data:", error.response.data);
    } else if (error.request) {
      // The request was made, but no response was received
      // //console.log("No response received:", error.request);
      navigate("/error500");
    } else {
      // Something else happened while setting up the request
      //console.log("Error:", error.message);
    }
  };
  const convertToMilli = (time, date) => {
    return date.setHours(time?.split(":")[0], time?.split(":")[1], 0, 0);
  };
  const selectDates = (date) => {
    const dates = document.querySelectorAll(".radio__date");
    for (let i = 0; i < dates.length; i++) {
      if (dates[i].id === date.toString()) {
        dates[i].classList.add("click");
      } else {
        dates[i].classList.remove("click");
      }
    }
  };
  const operationForLunch = (i) => {
    if (lunchTime?.from !== undefined && lunchTime?.to !== undefined)
      setLunchTimeInMilli({
        from: convertToMilli(lunchTime.from, i),
        to: convertToMilli(lunchTime.to, i),
      });
  };
  useEffect(() => {
    getData();
    console.log(store.services)
    getEmployeeData();
  },[]);
// useEffect(()=>{
//   var malecount=0;
//   var femalecount=0;
//   store.services.map((e)=>{
//     if(e.gender=='male')
//     {
//       malecount+=1;
//     }
//     else if(e.gender=='female'){
//       femalecount+=1;
//     }
//   })
//   console.log(malecount,femalecount)
//   if(malecount!=0 && femalecount==0){
//     console.log(employees)
//     var d=employees.filter((e2)=> (e2.genderServed==='male' || e2.genderServed== 'unisex'))
//     setEmployees(d);
//   }
//   else if(femalecount!=0 && malecount==0){
//     var d=employees.filter((e2)=> (e2.genderServed==='female' || e2.genderServed== 'unisex'))
//     setEmployees(d);
//   }
//   else if(femalecount!=0 && malecount!=0){
//     var d=employees.filter((e2)=> e2.genderServed== 'unisex')
//     setEmployees(d);
//   }

// },[store.services])
  // useEffect(()=>{
  //   getEmployeeData();
  // },[femaleServices,maleServices])

  useEffect(() => {
    timming(openTime, closeTime, new Date(date).toLocaleDateString("en-GB"));
  }, [myStore.value?.serviceId?.length]);

  useEffect(() => {
    var a = [];
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
    const dayAfterTomorrow = new Date(today);
    dayAfterTomorrow.setDate(today.getDate() + 2);
    a.push(today, tomorrow, dayAfterTomorrow);
    setDates([today, tomorrow, dayAfterTomorrow]);
    for (let i of [today, tomorrow, dayAfterTomorrow]) {
      if (daysOpen.includes(i.toDateString().slice(0, 3))) {
        operationForLunch(i);
        selectDates(i.getDate());
        setDate(i);
        setValue("date", i.toLocaleDateString("en-GB"));
        setBookedSlot(i.toLocaleDateString("en-GB"), "date");
        timming(openTime, closeTime, i.toLocaleDateString("en-GB"));
        break;
      }
    }
  }, [daysOpen]);

  const selectEmp = (empId) => {
    const empid = empId;

    const emp = document.querySelectorAll(".radio__emp");
    unavailableEmployee = unavailable.filter((e) => e.employeeId == empid);
    if (unavailableEmployee.length > 0) {
      setIsAvailable(unavailableEmployee);
    } else {
      setIsAvailable(null);
    }
    for (let i = 0; i < emp.length; i++) {
      if (emp[i].id === empid) {
        emp[i].classList.add("click");
      } else {
        emp[i].classList.remove("click");
      }
    }
  };
  const handleButtonClick = (id) => {
    if (employee == "") {
      setUserSelectSlot("");
      setError("Please Select Employee and Then Go further");
    } else {
      setError("");
      const buttons = document.querySelectorAll(".radio__btn");
      for (let i = 0; i < buttons.length; i++) {
        if (buttons[i].id === id.toString()) {
          buttons[i].classList.add("click");
        } else {
          buttons[i].classList.remove("click");
        }
      }
    }
  };
  const submit = (data) => {
    // console.log("/n",userSelectSlot.length===0 || myStore?.value?.serviceId?.length === 0);
    // console.log(data);
    // console.log(userSelectSlot)
    
    if (userSelectSlot.length === 0 || myStore?.value?.serviceId?.length === 0) {
      Swal.fire({
        icon: "warning",
        title: userSelectSlot.length === 0 ? "Choose time slot" :"Choose atleast one service",
      });
      setError("Please Select any one of the Available slot");
    } else {
      setLoading(true);
      var servicePrice = 0;
      var appointmentTime = 0;
      var actualDis = 0
      const appointmentData = myStore.value.serviceId?.map((e) => {
        // console.log("Services", e)
        servicePrice += e.price;
        appointmentTime += e.time;
        if (store?.isOffered === true) {
          actualDis += parseInt(e.bmcDiscount)
          actualDis += parseInt(e.storeDiscount)
        }
        return 1;
      });
      data = {
        serviceId: myStore.value.serviceId,
        employeeId: data.employee,
        adminId: store.value,
        date: data.date,
        startTime: parseInt(data?.startTime),
        totalTime: appointmentTime,
        totalPrice: servicePrice,
        discount: actualDis,
        isAdmin: store?.isAdmin,
        custDet: {
          name: (data?.customer)?.toUpperCase(),
          mobile: data?.mobileNo
        },
        isOffered: store?.isOffered
      };
      dispatch(addFinaldata(data));
      if (store?.isAdmin === true) {
        dispatch(removeEverything({ isOffered: false }));
        directAppointment(data)
          .then((data) => {
            if (process.env.REACT_APP_NODE_ENV === "production") {
              const weekday = [
                "Sunday",
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday",
              ];
              var d = new Date(data?.data?.data?.startTime);
              var wpData = {
                messaging_product: "whatsapp",
                to: data?.data?.data?.custDet?.mobile,
                type: "template",
                template: {
                  name: "appointment_confirmation",
                  language: {
                    code: "en",
                  },
                  components: [
                    {
                      type: "body",
                      parameters: [
                        {
                          type: "text",
                          text:
                            d.toLocaleDateString("en-GB") +
                            "," +
                            weekday[d.getDay()],
                        },
                        {
                          type: "text",
                          text: d.toLocaleTimeString("en-GB"),
                        },
                        {
                          type: "currency",
                          currency: {
                            fallback_value: data?.data?.data?.totalPrice,
                            code: "INR",
                            amount_1000: data?.data?.data?.totalPrice * 1000,
                          },
                        },
                        {
                          type: "text",
                          text: storeName,
                        },
                        {
                          type: "text",
                          text: store?.address,
                        },
                        {
                          type:"text",
                          text:store?.owner,
                        },
                        {
                          type:"text",
                          text:store?.phNumber
                        }
                      ],
                    },
                  ],
                },
              };
              direct_booking_Api(wpData, {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${process.env.REACT_APP_USER_TOKEN}`,
                },
              })
            }
            navigate('/adminhomepage')
          }).finally(() => {
            setLoading(false)
          })
      }
      else {
        // console.log("data",data)
        // setLoading(false)
        addToCartApi(data)
          .then((data) => {
            // console.log("cart", data)
            navigate("/cart", { state: { flag: 1 } });
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  };
  var [bookDate, setBookDate] = useState("");
  const setBookedSlot = (e, type) => {
    // console.log("eeeee",type)
    // to check the slot of selected date only this function is made for that purpose only
    if (type == "date") {
      setBookDate(e);
      // setSlotTaken(
      //   cartData
      //     ?.filter((item) => item?.date === e)
      //     ?.map((filteredItem) => filteredItem?.startTime)
      // );
      // setTimeTaken(
      //   cartData
      //     ?.filter((item) => item?.date === e)
      //     ?.map((filteredItem) => ({
      //       starttime: filteredItem?.startTime,
      //       totaltime: filteredItem?.totalTime,
      //     }))
      // );
    } else {
      // console.log("------------------------------------------")
      setSlotTaken(
        cartData
          ?.filter(
            (item) => item.date === bookDate && item.employeeId._id === e
          )
          ?.map((filteredItem) => filteredItem.startTime)
      );
      setTimeTaken(
        cartData
          ?.filter(
            (item) => item.date === bookDate && item.employeeId._id === e
          )
          ?.map((filteredItem) => ({
            starttime: filteredItem.startTime,
            totaltime: filteredItem.totalTime,
          }))
      );
    }
  };
  return (
    <>
      {loading && <Loader />}
    {/* {console.log(maleServices)} */}
      <div className="scheduleList">
        <h3 className="w-100 title-inner">Select Date</h3>
        <div className="d-flex flex-row all__dates">
          {dates.map((e, i) => {
            var c = 0;
            return daysOpen.includes(e.toDateString().slice(0, 3)) ? (
              <div className="choose__dates" key={i}>
                <button
                  className="radio__date"
                  id={e.getDate()}
                  onClick={() => {
                    selectDates(e.getDate());
                    setDate(e);
                    setValue("date", e.toLocaleDateString("en-GB"));
                    setBookedSlot(e.toLocaleDateString("en-GB"), "date");
                    operationForLunch(e);
                    timming(openTime, closeTime, e.toLocaleDateString("en-GB"));
                    setUserSelectSlot("");
                  }}
                  name="date"
                  value={e.getDate()}
                  {...register("date")}
                >
                  <div>{weekDay[e.getDay().toString()]}</div>
                  <div>{e.getDate()}</div>
                </button>
              </div>
            ) : (
              <div className="choose__dates" key={i}>
                <button
                  className="radio__date"
                  id={e.getDate()}
                  name="date"
                  value={e.getDate()}
                  {...register("date")}
                  disabled
                >
                  <div>{weekDay[e.getDay().toString()]}</div>
                  <div>{e.getDate()}</div>
                </button>
              </div>
            );
          })}
        </div>

        <div className="choose__employee d-flex flex-row">
          <span style={{ color: "red", marginTop: "20px" }}>{error}</span>
          <h3 className="w-100">Select Employee</h3>
          {employees.length === 0 ? (
            <h1
              style={{
                alignItems: "left",
                marginLeft: "0%",
                width: "580px",
                color: "#053b50",
                fontSize: 25,
              }}
            >
              No Barber Available in Store
            </h1>
          ) : (
            <div className="empList">
              {employees?.map((emp) => {
                return (
                  <button
                    key={emp._id}
                    onClick={() => {
                      selectEmp(emp._id);
                      setEmployee(emp._id);
                      setBookedSlot(emp._id, "emp");
                      setValue("employee", emp._id);
                    }}
                    name="employee"
                    className="radio__emp d-flex flex-col align-items-center"
                    id={emp._id}
                    value={emp._id}
                    {...register("employee")}
                  >
                    <IoMdPerson />
                    {emp.employeeName}
                  </button>
                );
              })}
            </div>
          )}
        </div>
        <h3 className="w-100 title-inner">Choose Time Slot</h3>
        <div className="choose__time">
          {slot?.length > 0 ?
            <div className="timeSlotList">
              {slot?.map((e) => {
                if (isAvailable != null) {
                  var time = {
                    starttime: isAvailable[0]?.startTime,
                    endtime: isAvailable[0]?.endTime,
                  };
                } else {
                  time = "";
                }
                const slot1 = new Date(e)
                  .toLocaleTimeString("en-IN", {
                    hour: "2-digit",
                    minute: "2-digit",
                  })
                  .toLocaleUpperCase();
                return (time != "" && isInRange(e, time)) ||
                  slotTaken?.includes(e) ||
                  timeInRange(e, slotTaken, timeTaken) ||
                  e === 111 ? (
                  <button
                    type="radio"
                    name="init"
                    id={e}
                    key={e}
                    value={e}
                    className="radio__btn disable__btn"
                    {...register("init")}
                    onClick={() => {
                      setValue("init", e);
                    }}
                    disabled
                  >
                    {/* {slot1} */}

                    {e !== 111 ? (
                      slot1
                    ) : (
                      <>
                        <p
                          style={{ fontSize: "8px", marginBottom: "2px" }}
                          className="d-flex flex-column"
                        >
                          <span>
                            {new Date(luncTimeInMilli?.from).toLocaleTimeString(
                              "en-IN",
                              { hour: "2-digit", minute: "2-digit" }
                            )}
                            -
                            {new Date(luncTimeInMilli?.to).toLocaleTimeString(
                              "en-IN",
                              { hour: "2-digit", minute: "2-digit" }
                            )}
                          </span>
                          <span style={{ fontSize: "10px", marginBottom: "2px" }}>
                            LUNCH🕛
                          </span>
                        </p>
                      </>
                    )}
                    {/* {isInLunchRange(e, luncTimeInMilli, timeSlot) ? <><p style={{ fontSize: "8px", marginBottom: "2px" }}>LUNCH🕛</p></> : slot1} */}
                  </button>
                ) : (
                  <button
                    type="radio"
                    name="init"
                    id={e}
                    value={slot1}
                    className="radio__btn"
                    {...register("startTime")}
                    onClick={() => {
                      setValue("startTime", e);
                      // console.log(e)
                      setUserSelectSlot(slot1);
                      handleButtonClick(e);
                    }}
                  >
                    {slot1}
                    {/* {e !== 111 ? slot1 : <><p style={{ fontSize: "8px", marginBottom: "2px" }}>LUNCH🕛</p></>} */}
                  </button>
                );
              })}
            </div>
            :
            <>No slots available for selected date</>
          }

        </div>
        <div className="mt-2">
          {store?.isAdmin === true ? (
            <>
              <InputTag
                label="Customer Name"
                name="customer"
                register={register}
                require={true}
              />
              <InputTag
                type="tel"
                label="Mobile No."
                name="mobileNo"
                register={register}
                ValidationSchema={ValidateSchema}
                require={true}
              />
              {<span className="error">{errors?.mobile?.message}</span>}
            </>
          ) : (
            ""
          )}
        </div>
      </div>
      {/* {console.log(admin, store?.isAdmin)} */}
      {store?.services?.length > 0 ? (
        <div className={`addToCart sticky-bottom"`}>
          <div className="footerSchedule servicesAdded">
            <div>
              <h5>{store?.services.length} Services Added</h5>
              {/* <p>{new Date(date).toLocaleDateString('en-GB')}</p>
          <h5>{userSelectSlot}</h5> */}
              {
                myStore.value.serviceId?.map((e) => {
                  store?.isOffered === true ? servicePriceforOffer += (e.price - e?.bmcDiscount - e?.storeDiscount) :
                    servicePriceforOffer += e.price;
                })
              }
            </div>
            {admin === false || store.isAdmin === true ? (
              <>
                <form onSubmit={handleSubmit(submit)}>
                  <p>

                    {servicePriceforOffer} Rupees
                  </p>
                  <input type="submit" value="book" />
                </form>
              </>
            ) : (
              <>
                <form onSubmit={handleSubmit(submit)}>
                  <p>

                    {/* <strike>{servicePriceforOffer}</strike>  */}
                    {/* {servicePriceforOffer-200} */}
                    {servicePriceforOffer} Rupees
                  </p>
                  {/* <input type="submit" value="book" /> */}
                </form>
              </>
            )}
          </div>
        </div>
      ) : (
        <div className="footerSchedule">
          <div>
            <h5>Please Add the Services</h5>
            {/* <p>{new Date(date).toLocaleDateString('en-GB')}</p>
            <h5>{userSelectSlot}</h5> */}
          </div>
          {/* {console.log(admin)} */}
          {admin === false || store.isAdmin === true ? (
            <>
              {/* {store?.value?.serviceId?.map((e) => {
                            return (
                              <>
                                <p
                                  className="serviceList"
                                  style={{
                                    borderBottom: "none",
                                    backgroundColor: "#64ccc5",
                                    color: "#053b50",
                                    fontSize: "20px",
                                    padding: "10px",
                                    borderRadius: "7px",
                                    marginRight: "5px",
                                  }}
                                >
                                  {e?.serviceName}
                                </p>
                              </>
                            );
                          })} */}
              <form onSubmit={handleSubmit(submit)}>
                <input type="submit" value="book" />
              </form>
            </>
          ) : (
            ""
          )}
        </div>
      )}
    </>
  );
};

export default Demo2;
