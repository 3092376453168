import React, { useState } from "react";
import "./OfferInformation.css";
import { useForm } from "react-hook-form";
import InputTag from "../../Components/InputTag";
import { addInformation } from "../../API/infoApi";
import { Loader } from "../../Components/Loader";
import { useLocation, useParams } from "react-router-dom";

export const OfferInformation = () => {
  const { handleSubmit, register, setValue,formState:{errors} } = useForm();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const storeName = searchParams.get("refer");
  const submit = (data) => {
    setLoading(true);
    data.referenceName=storeName
    console.log(data);
    addInformation(data)
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const ValidationSchema = {
    name: {
      required: {
        value: true,
        message: "Name is required",
      },
      minLength: {
        value: 1,
        message: "should be minimum 1 length",
      },
    },
    phoneNumber: {
      required: {
        value: true,
        message: "required",
      },
      pattern: {
        value: /^(0|91)?[6-9][0-9]{9}$/,
        message: "Phone Number should be in correct format only",
      },
    },
  };

  return (
    <>
      {loading && <Loader />}
      <div className="information-kiosk">
        <h1>Information Kiosk</h1>
        <form onSubmit={handleSubmit((data) => submit(data))}>
          <label htmlFor="name">
            Your Name:
            <InputTag
              type="text"
              id="name"
              name="name"
              register={register}
              ValidationSchema={ValidationSchema.name}
              //   value={name}
              //   onChange={handleChange}
              required
            />
            {<span className="error">{errors?.name?.message}</span>}
          </label>
          <label htmlFor="phoneNumber">
            Phone Number:
            <InputTag
              type="tel"
              id={"phoneNumber"}
              name={"phoneNumber"}
              register={register}
              ValidationSchema={ValidationSchema.phoneNumber}
              required
            />
            {<span className="error">{errors?.phoneNumber?.message}</span>}
          </label>
          <button type="submit">Submit</button>
        </form>
      </div>
    </>
  );
};
