import { configureStore } from "@reduxjs/toolkit";
// import citySlice from "./redux/CitySlice";
// import LocationSlice from "./redux/LocationSlice";
// import CartSlice from "./redux/CartSlice";
// import ServiceSlice from "./redux/ServiceSlice";
import storage from 'reduxjs-toolkit-persist/lib/storage'
import { persistStore, persistReducer } from 'reduxjs-toolkit-persist'
import rootReducer from './reducer'

const persistConfig = {
    key: 'root', // Key for the persisted data
    storage, // Storage engine to use
    whitelist: ['cart', 'user','city','locality','filter']
  };
const persistedReducer = persistReducer(persistConfig,rootReducer);

export const store=configureStore({
    reducer:persistedReducer
})

export const persistor = persistStore(store);