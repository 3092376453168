import React from 'react'

const About = (props) => {
  return (
    <div className='w-75 m-auto'>
      <h2 className='m-3'>
      <center>
        About  {props?.name}
      </center>
      </h2>
        <p className=''>
          {props?.about}
        </p>
    </div>  
  )
}

export default About