import React, { useEffect, useState } from "react";
import "./conformationMail.css";
import { useForm } from "react-hook-form";
import jwt_decode from "jwt-decode";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { confirmation } from "../../API/LoginApi";
import Cookies from "js-cookie";
export const ConfirmationMail = () => {
  const historyLength = window.history.length;
  // console.log(historyLength)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const [otpError, setOtpError] = useState("");
  const [value,setValue]=useState("")
  const location = useLocation();

  const fromServicePage=location.state?.fromServicePage;

  console.log("-------->",fromServicePage);
  const submit = (data) => {
    if (value !== null) {
      confirmation(value, data)
        .then((data) => {
          localStorage.setItem("qwerty", data?.data?.data);
          localStorage.setItem('session',data?.data?.passToken)
          Cookies.set('session',data?.data?.passToken)
          Cookies.set("qwerty", data?.data?.data);
          // navigate("/");
          if(location.state && location.state.fromServicePage){
            navigate("/"+fromServicePage, { state: { isOffered: location.state?.isOffered } })
            window.location.reload();
          }
          window.history.go(-3)
          window.history.forward();
          // window.location.reload();
        })
        .catch((error) => {
          setOtpError(error.response.data.message);
        });
    }
  };
  useEffect(() => {
    setValue(location.state?.value);
    // console.log(value)

    
    if (location.state?.value === "") {
      window.location.reload();
      navigate("/");
      // console.log('perin')
    }
    // const decoded = jwt_decode(token);
    // setEmail(decoded.email);

    const timerInterval = setInterval(() => {
      const lastClickedTime = parseInt(
        localStorage.getItem("resendClickedTime")
      );
      if (lastClickedTime) {
        const currentTime = Math.floor(Date.now() / 1000);
        const timeDiff = currentTime - lastClickedTime;
        if (timeDiff < 30) {
          setResendClicked(true);
          setTimer(30 - timeDiff);
        } else {
          setResendClicked(false);
          setTimer(30);
          localStorage.removeItem("resendClickedTime");
        }
      }
    }, 1000);
    return () => clearInterval(timerInterval);
  }, []);

  const [resendClicked, setResendClicked] = useState(false);
  const [timer, setTimer] = useState(30);

  const handleResendClick = () => {
    resend();
    setResendClicked(true);
    setTimeout(() => {
      setResendClicked(false);
    }, 30000); // 30 seconds
  };

  const resend = () => {
    // const token = localStorage.getItem("qwerty");
    if (value !== "") {
      // const decoded = jwt_decode(token);
      axios.get(`/api/v1/signup/resendEmail/${value}`);
      setResendClicked(true);
      localStorage.setItem(
        "resendClickedTime",
        Math.floor(Date.now() / 1000).toString()
      );
      let timeLeft = timer;
      const timerId = setInterval(() => {
        setTimer((prev) => {
          if (prev > 1) {
            return prev - 1;
          }
          setResendClicked(false);
          localStorage.removeItem("resendClickedTime");
          clearInterval(timerId);
          return 0;
        });
      }, 1000);
    }
  };
  return (
    <>
      {value && (
        <div>
          <div className="email-verification">
            <h1>Email Verification</h1>
            <p>Thank you for signing up for bookmycoiffeur account.</p>
            <p>Verify your email address</p>
            <p>Verification email has been sent to:</p>
            <p className="email">{value}</p>
            <p>
              <h4>Please Enter Your Otp Here</h4>
              {otpError}
              <form onSubmit={handleSubmit(submit)}>
                <input
                  type="text"
                  name="otp"
                  required
                  {...register("otp")}
                ></input>
                <br></br>
                <button className="button">Activate</button>
              </form>
            </p>
            <p>
              <button
                className="btn btn"
                onClick={handleResendClick}
                disabled={resendClicked}
              >
                {resendClicked ? `Resending in ${timer}s` : "Resend email"}
              </button>
            </p>
          </div>
        </div>
      )}
    </>
  );
};
