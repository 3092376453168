import React, { useEffect, useRef, useState } from "react";
import "./Businessform.css";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { Error500 } from "../../Components/errors/error500/Error500";
import { Loader } from "../../Components/Loader";
import {
  getCityData,
  getLocalityData,
  getStoreCategoryData,
} from "../../API/AdditionalApi";
import { updateStoreForAdmin } from "../../API/BussinessApi";

const BussinessForm = (props) => {
  const [token, setToken] = useState({});
  const [city, setCity] = useState([]);
  const [locality, setLocality] = useState([]);
  const [storeCat, setStoreCat] = useState();
  const [loading, setLoading] = useState(false);
  const [enabled, setEnabled] = useState(true);
  var [week, setweeks] = useState({
    mon:
      props?.data !== undefined
        ? props?.data?.daysOpen.includes("Mon")
          ? true
          : false
        : true,
    tue:
      props?.data !== undefined
        ? props?.data?.daysOpen.includes("Tue")
          ? true
          : false
        : true,
    wed:
      props?.data !== undefined
        ? props?.data?.daysOpen.includes("Wed")
          ? true
          : false
        : true,
    thur:
      props?.data !== undefined
        ? props?.data?.daysOpen.includes("Thu")
          ? true
          : false
        : true,
    fri:
      props?.data !== undefined
        ? props?.data?.daysOpen.includes("Fri")
          ? true
          : false
        : true,
    sat:
      props?.data !== undefined
        ? props?.data?.daysOpen.includes("Sat")
          ? true
          : false
        : true,
    sun:
      props?.data !== undefined
        ? props?.data?.daysOpen.includes("Sun")
          ? true
          : false
        : true,
  });
  const navigate = useNavigate();
  const checkToken = () => {
    if (props?.update !== true) {
      if (localStorage.getItem("uuid")) {
        navigate("/adminhomepage");
      }
      if (localStorage.getItem("qwerty") !== null) {
        var decoded = jwt_decode(localStorage.getItem("qwerty"));
        setToken(decoded);
      } else {
        navigate("/");
      }
    }
  };

  const getCity = () => {
    getCityData()
      .then((data) => {
        setCity(data.data.data);
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  const getLocality = (e) => {
    //console.log(e.target.value);
    getLocalityData(e.target.value)
      .then((data) => {
        //console.log(data.data.data);
        setLocality(data.data.data);
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  const getStoreCategory = () => {
    getStoreCategoryData()
      .then((data) => {
        // //console.log("service Cat",data)
        setStoreCat(data.data.data);
      })
      .catch((error) => {
        //console.log(error)
      });
  };

  useEffect(() => {
    checkToken();
    getCity();
    getStoreCategory();
    // console.log(props.data);
  }, []);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const ValidationSchema = {
    ownerName: {
      required: {
        value: true,
        message: "OWner's Name is Required",
      },
      pattern: {
        value: /^[a-zA-Z\s]+$/,
        message: "only letters are allowed",
      },
    },
    brandName: {
      required: {
        value: true,
        message: "Brand Name is Required",
      },
      pattern: {
        value: /^[0-9a-zA-Z\s]+$/,
        message: "No special characters are allowed",
      },
    },
    genderServed: {
      required: {
        value: true,
        message: "Gender is required",
      },
    },
    email: {
      required: {
        value: true,
        message: "required",
      },
      pattern: {
        value:
          /^(?:(?:[^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@(?:(?:\[(?:[0-9]{1,3}\.){3}[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        message: "Email should be in proper format",
      },
    },
    contactNumber: {
      required: {
        value: true,
        message: "Required",
      },
      pattern: {
        value: /^(0|91)?[6-9][0-9]{9}$/,
        message: "Should be in correct format only",
      },
    },
    locality: {
      required: {
        value: true,
        message: "locality is Required",
      },
    },
    city: {
      required: {
        value: true,
        message: "City is Required",
      },
    },
    address: {
      required: {
        value: true,
        message: "Address is Required",
      },
    },
    openingTime: {
      required: {
        value: true,
        message: "openingtime is Required",
      },
    },
    closingTime: {
      required: {
        value: true,
        message: "closingtime is Required",
      },
    },
    lunchFrom: {
      required: {
        value: true,
        message: "Lunch time is Required",
      },
    },
    lunchTo: {
      required: {
        value: true,
        message: "Lunch time is Required",
      },
      validate: (value) => {
        // console.log(watch("lunchFrom"))
        // console.log(value)
        // console.log(watch("lunchFrom").split(":"[0]) > value.split(":")[0])
        if (watch("lunchFrom").split(":")[0] - value.split(":")[0]<0) {
          return "Select Proper Lunch Time";
        }
        else{
          return true
        }
      },
    },
  };
  const [formError, setFormError] = useState("");
  const submit=(data)=>{
    // console.log(data)
    setLoading(true)
    if (parseInt(data?.openingTime?.split(":")[0]) >parseInt(data?.closingTime?.split(":")[0]))
    {
      // console.log(data)
      alert("Closing Time must be greater than Opeining Time");
      setLoading(false)
    }
    else{
      // setLoading(false)
      const formData = new FormData();
      var keys = Object.keys(data);
      keys?.map((e) => {
        // console.log(e, data[e]);
        if (data[e] instanceof FileList) {
          formData.append("file", data[e][0]);
        } else if (data[e] !== undefined) {
          formData.append(e, data[e]);
        }
      });
      if (props.update === true) {
        updateStoreForAdmin(formData)
          .then((data) => {
            localStorage.setItem("shopify", data.data.data);
            setEnabled(!enabled)
          })
          .catch((error) => {
          })
          .finally(() => setLoading(false));
      } else {
        // console.log("Reached")
        // setLoading(false)
        axios
          .post("/api/v1/businessform/addstore", formData, {
            headers: {
              token: localStorage.getItem("qwerty"),
            },
          })
          .then((data) => {
            localStorage.setItem("shopify", data.data.data);
            localStorage.setItem("uuid", true);
            window.location.reload();
            navigate("/");
          })
          .catch((error) => {
            if (error?.response?.data?.message?.name === "ZodError")
              setFormError("Give Proper Input");
            else setFormError(error.response.data.message);
            // if (error.response.status === 500) <Error500 />;
          }).finally(()=>{
            setLoading(false)
          });
     }


    }
  }
  const handleChange = (e) => {
    var id = e.target.id;
    const fileUploader = document.querySelector(`#${e.target.id}`);
    const getFile = fileUploader.files;
    // console.log("ertgfdfvbfhgf", getFile);
    if (getFile.length !== 0) {
      const uploadedFile = getFile[0];
      readFile(uploadedFile, id);
    }
  };

  // Inside the component
  const readFile = (uploadedFile, type) => {
    if (uploadedFile) {
      const reader = new FileReader();
      reader.onload = () => {
        if (type == "banner") {
          const banner = document.querySelector(".preview-box");
          banner.innerHTML = `<img className="preview-content" src=${reader.result} alt="banner" />`;
        } else if (type == "logo") {
          const logo = document.querySelector(".preview-box1");
          logo.innerHTML = `<img className="preview-content" src=${reader.result} alt="logo" />`;
        } else {
          const certi = document.querySelector(".preview-box2");
          certi.innerHTML = `<img className="preview-content" src=${reader.result} alt="certi"/>`;
        }
      };

      reader.readAsDataURL(uploadedFile);
    }
  };

  useEffect(() => {
    // console.log(234)
    if (props.update) {
      // console.log(345)
      if (props.data.banner) {
        // console.log(3456)
        const banner = document.querySelector(".preview-box");
        banner.innerHTML = `<img className="preview-content" src=${props.data.banner} alt="banner" />`;
      }
      if (props.data.logo) {
        const logo = document.querySelector(".preview-box1");
        logo.innerHTML = `<img className="preview-content" src=${props.data.logo}  alt="logo" />`;
      }
      if (props.data.certificate) {
        const certi = document.querySelector(".preview-box2");
        certi.innerHTML = `<img className="preview-content" src=${props.data.certificate} alt="certi" />`;
      }
    }
  }, [props.update, props.banner, props.logo, props.certificate]);

  const toggleField = (day) => {
    setweeks((prevWeek) => ({
      ...prevWeek, // Copy the previous state
      [day]: !prevWeek[day], // Toggle the value for the specified day
    }));
  };

  const ref = useRef(null);

  return (
    <>
      {loading && <Loader/>}
      <div className="servicesContainer md-10 h-100 ">
        <section className="bg-white p-[2rem] flex flex-col lg:w-[34%] rounded-r-[1.2rem] rounded-t-[1.2rem] BusinessForm">
          {props.update === true ? (
            <h1 className="text-[#083e9e] text-[1.5rem] font text-center">
              Your Shop Details
            </h1>
          ) : (
            <>
              <h1 className="text-[#083e9e] text-[1.5rem] font text-center">
                Register Your Shop
              </h1>
              <p
                className="text-[#4a5568] text-[17px] text-center welcome__text"
                style={{ color: "grey" }}
              >
                Welcome to the World of Digitization
              </p>
            </>
          )}

          <form
            action=""
            className="mt-[4rem] flex flex-col gap-[1.7rem]"
            onSubmit={handleSubmit(submit)}
            encType="multipart/form-data"
          >
            <div className="flex flex-col gap-[5px]">
              <label
                htmlFor="name"
                className="text-[#4a5568] font-[700] text-[14px]"
              >
                Brand Name
                {
                  <span style={{ color: "red", paddingLeft: 5 }}>
                    {errors?.brandName?.message}
                  </span>
                }
              </label>
              <input
                type="text"
                name="brandName"
                id="brandName"
                ref={ref}
                placeholder={`name of your brand `}
                defaultValue={props.data?.brandName}
                disabled={props.update === true ? enabled : false}
                {...register("brandName",props.update !== true ? ValidationSchema?.brandName:"")}
                className="bg-[#e2e8f0] py-[.8rem] rounded-[4px] px-[1rem] text-[#1a202c] outline-none form-control"
              />
            </div>
            <div className="flex flex-col gap-[5px]">
              <label
                htmlFor="name"
                className="text-[#4a5568] font-[700] text-[14px]"
              >
                Owner Name
                {
                  <span style={{ color: "red", paddingLeft: 5 }}>
                    {errors?.ownerName?.message}
                  </span>
                }
              </label>
              <input
                type="text"
                name="ownerName"
                defaultValue={
                  props.update === true ? props.data.ownerName : token.fullName
                }
                id="ownername"
                placeholder="ownername"
                {...register(
                  "ownerName",
                  props.update !== true ? ValidationSchema.ownerName : ""
                )}
                disabled={props.update === true ? enabled : false}
                className="bg-[#e2e8f0] py-[.8rem] rounded-[4px] px-[1rem] text-[#1a202c] outline-none form-control"
              />
            </div>
            <div className="flex flex-col gap-[5px]">
              <label
                htmlFor="name"
                className="text-[#4a5568] font-[700] text-[14px]"
              >
                Gender Served{" "}
                {
                  <span style={{ color: "red", paddingLeft: 5 }}>
                    {errors?.genderServed?.message}
                  </span>
                }
              </label>
              <select
                {...register(
                  "genderServed",
                  props.update !== true ? ValidationSchema.genderServed : ""
                )}
                className="bg-[#e2e8f0] py-[.8rem] rounded-[4px] px-[1rem] text-[#1a202c] outline-none"
                defaultValue={props.data?.genderServed}
                disabled={props.update === true ? enabled : false}
              >
                <option value="unisex">Unisex</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
            </div>
            <div className="flex flex-col gap-[5px]">
              <label
                htmlFor="name"
                className="text-[#4a5568] font-[700] text-[14px]"
              >
                Store Type
                {
                  <span style={{ color: "red", paddingLeft: 5 }}>
                    {errors?.storeType?.message}
                  </span>
                }
              </label>
              <select
                {...register("storeType")}
                className="bg-[#e2e8f0] py-[.8rem] rounded-[4px] px-[1rem] text-[#1a202c] outline-none"
                defaultValue={props.data?.storeType._id}
                disabled={props.update === true ? enabled : false}
              >
                <option value="None">Select Store</option>
                {storeCat?.map((e) => {
                  // console.log(e._id)
                  return <option value={e._id}>{e.categoryName}</option>;
                })}
              </select>
            </div>
            <div className="flex flex-col gap-[5px]">
              <label
                htmlFor="email"
                className="text-[#4a5568] font-[700] text-[14px]"
              >
                Email Address
                {
                  <span style={{ color: "red", paddingLeft: 5 }}>
                    {errors?.email?.message}
                  </span>
                }
              </label>
              <input
                type="email"
                name="email"
                defaultValue={
                  props.update === true ? props.data.email : token.email
                }
                disabled={props.update === true ? enabled : false}
                id="email"
                {...register(
                  "email",
                  props.update !== true ? ValidationSchema.email : ""
                )}
                className="bg-[#e2e8f0] py-[.8rem] rounded-[4px] px-[1rem] text-[#1a202c] outline-none form-control"
                placeholder="Email Address"
              />
            </div>
            <div className="flex flex-col gap-[5px]">
              <label
                htmlFor="tel"
                className="text-[#4a5568] font-[700] text-[14px]"
              >
                Phone Number
                {
                  <span style={{ color: "red", paddingLeft: 5 }}>
                    {errors?.contactNumber?.message}
                  </span>
                }
              </label>
              <input
                type="tel"
                name="ContactNumber"
                id="contact"
                placeholder="Contact Number"
                defaultValue={props.data?.contactNumber}
                disabled={props.update === true ? enabled : false}
                {...register(
                  "contactNumber",
                  props.update !== true ? ValidationSchema.contactNumber : ""
                )}
                className="bg-[#e2e8f0] py-[.8rem] rounded-[4px] px-[1rem] text-[#1a202c] outline-none form-control"
              />
            </div>
            <div className="flex flex-col gap-[5px]">
              <label
                htmlFor="name"
                className="text-[#4a5568] font-[700] text-[14px]"
              >
                City{" "}
                {
                  <span style={{ color: "red", paddingLeft: 5 }}>
                    {errors?.city?.message}
                  </span>
                }
              </label>
              <select
                name="city"
                {...register(
                  "city",
                  props.update !== true ? ValidationSchema.city : ""
                )}
                defaultValue={props.data?.city?._id}
                disabled={props.update === true ? enabled : false}
                onChange={getLocality}
                required=""
                className="bg-[#e2e8f0] py-[.8rem] rounded-[4px] px-[1rem] text-[#1a202c] outline-none"
              >
                <option>Select City</option>
                {city?.map((cities) => {
                  return <option value={cities._id}>{cities.cityName}</option>;
                })}
              </select>
            </div>
            <div className="flex flex-col gap-[5px]">
              <label
                htmlFor="name"
                className="text-[#4a5568] font-[700] text-[14px]"
              >
                Locality
                {
                  <span style={{ color: "red", paddingLeft: 5 }}>
                    {errors?.locality?.message}
                  </span>
                }
              </label>
              <select
                className="bg-[#e2e8f0] py-[.8rem] rounded-[4px] px-[1rem] text-[#1a202c] outline-none"
                required=""
                name="locality"
                {...register(
                  "locality",
                  props.update !== true ? ValidationSchema.locality : ""
                )}
                disabled={props.update === true ? enabled : false}
              >
                <option>Select Locality</option>
                {locality?.map((localities) => {
                  return (
                    <option value={localities.localityName}>
                      {localities.localityName}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="flex flex-col gap-[5px]">
              <label
                htmlFor="name"
                className="text-[#4a5568] font-[700] text-[14px]"
              >
                Full Address
                {
                  <span style={{ color: "red", paddingLeft: 5 }}>
                    {errors?.address?.message}
                  </span>
                }
              </label>
              <textarea
                name="address"
                id="fulladdress"
                row="30"
                placeholder="Enter Full Address"
                defaultValue={props.data?.address}
                {...register(
                  "address",
                  props.update !== true ? ValidationSchema.address : ""
                )}
                disabled={props.update === true ? enabled : false}
                className="bg-[#e2e8f0] py-[.8rem] rounded-[4px] px-[1rem] text-[#1a202c] outline-none"
              ></textarea>
            </div>
            <div className="flex flex-col gap-[5px]">
              <label
                htmlFor="name"
                className="text-[#4a5568] font-[700] text-[14px]"
              >
                About Store
              </label>
              <textarea
                name="about"
                id="about"
                row="30"
                placeholder="Tell us something about your store"
                defaultValue={props.data?.about}
                {...register("about")}
                disabled={props.update === true ? enabled : false}
                className="bg-[#e2e8f0] py-[.8rem] rounded-[4px] px-[1rem] text-[#1a202c] outline-none"
              ></textarea>
            </div>
            <div className="flex flex-col gap-[5px]">
              <label
                htmlFor="password"
                className="text-[#4a5568] font-[700] text-[14px]"
              >
                Opening Time in (24hrs)
                {
                  <span style={{ color: "red", paddingLeft: 5 }}>
                    {errors?.openingTime?.message}
                  </span>
                }
              </label>
              <input
                type="time"
                name="openingtime"
                id="openning_time"
                {...register(
                  "openingTime",
                  props.update !== true ? ValidationSchema.openingTime : ""
                )}
                disabled={props.update === true ? enabled : false}
                defaultValue={props.data?.openingTime}
                placeholder="Password"
                className="bg-[#e2e8f0] py-[.8rem] rounded-[4px] px-[1rem] text-[#1a202c] outline-none form-control"
              />
            </div>
            <div className="flex flex-col gap-[5px]">
              <label
                htmlFor="password"
                className="text-[#4a5568] font-[700] text-[14px]"
              >
                Closing Time in (24hrs)
                {
                  <span style={{ color: "red", paddingLeft: 5 }}>
                    {errors?.closingTime?.message}
                  </span>
                }
              </label>
              <input
                type="time"
                name="closingtime"
                id="closing_time"
                placeholder="Closing Time "
                {...register(
                  "closingTime",
                  props.update !== true ? ValidationSchema.closingTime : ""
                )}
                disabled={props.update === true ? enabled : false}
                defaultValue={props.data?.closingTime}
                className="bg-[#e2e8f0] py-[.8rem] rounded-[4px] px-[1rem] text-[#1a202c] outline-none form-control"
              />
            </div>
            <div className="flex flex-col gap-[5px]">
              <label
                htmlFor="lunchTimeFrom"
                className="text-[#4a5568] font-[700] text-[14px]"
              >
                Lunch Time From (24hrs)
                {
                  <span style={{ color: "red", paddingLeft: 5 }}>
                    {errors?.lunchFrom?.message}
                  </span>
                }
              </label>
              <input
                type="time"
                name="lunchTimeFrom"
                id="lunchTimeFrom"
                placeholder="Lunch Time(from)"
                {...register(
                  "lunchFrom",
                  props.update !== true ? ValidationSchema.lunchFrom : ""
                  // {validate:(value)=>{if(getValues("lunchFrom").split(":")[0]<value.split(":")[0]){console.log("vinti");return true}}}
                )}
                disabled={props.update === true ? enabled : false}
                defaultValue={props.data?.lunchFrom}
                className="bg-[#e2e8f0] py-[.8rem] rounded-[4px] px-[1rem] text-[#1a202c] outline-none form-control"
              />
              {watch("lunchFrom")?.split(":")[0] >
              watch("lunchTo")?.split(":")[0] ? (
                <span style={{ color: "red", paddingLeft: 5 }}>
                  Select appropriate From and To value
                </span>
              ) : (
                ""
              )}
            </div>
            <div className="flex flex-col gap-[5px]">
              <label
                htmlFor="lunchTimeTo"
                className="text-[#4a5568] font-[700] text-[14px]"
              >
                Lunch Time To (24hrs)
                {
                  <span style={{ color: "red", paddingLeft: 5 }}>
                    {errors?.lunchTo?.message}
                  </span>
                }
              </label>
              <input
                type="time"
                name="lunchTimeTo"
                id="lunchTimeTo"
                placeholder="Lunch Time (to)"
                {...register(
                  "lunchTo",
                  props.update !== true ? ValidationSchema.lunchTo : ""
                )}
                disabled={props.update === true ? enabled : false}
                defaultValue={props.data?.lunchTo}
                className="bg-[#e2e8f0] py-[.8rem] rounded-[4px] px-[1rem] text-[#1a202c] outline-none form-control"
              />
            </div>
            {/* {console.log(week)} */}
            <div className="weekdays">
              <p>
                <label
                  htmlFor="day1"
                  className="text-[#4a5568] font-[700] text-[14px]"
                >
                  Monday
                </label>
                <input
                  type="checkbox"
                  name="days"
                  checked={week["mon"]}
                  value="Mon"
                  {...register("daysOpen")}
                  disabled={props.update === true ? enabled : false}
                  onClick={() => {
                    toggleField("mon");
                  }}
                />
              </p>
              <p>
                <label
                  htmlFor="day2"
                  className="text-[#4a5568] font-[700] text-[14px]"
                >
                  Tuesday
                </label>
                <input
                  type="checkbox"
                  name="days"
                  checked={week["tue"]}
                  value="Tue"
                  {...register("daysOpen")}
                  disabled={props.update === true ? enabled : false}
                  onClick={() => {
                    toggleField("tue");
                  }}
                />
              </p>
              <p>
                <label
                  htmlFor="day3"
                  className="text-[#4a5568] font-[700] text-[14px]"
                >
                  Wednesday
                </label>
                <input
                  type="checkbox"
                  name="days"
                  checked={week["wed"]}
                  value="Wed"
                  {...register("daysOpen")}
                  disabled={props.update === true ? enabled : false}
                  onClick={() => {
                    toggleField("wed");
                  }}
                />
              </p>
              <p>
                <label
                  htmlFor="day4"
                  className="text-[#4a5568] font-[700] text-[14px]"
                >
                  Thursday
                </label>
                <input
                  type="checkbox"
                  name="days"
                  checked={week["thur"]}
                  value="Thu"
                  {...register("daysOpen")}
                  disabled={props.update === true ? enabled : false}
                  onClick={() => {
                    toggleField("thur");
                  }}
                />
              </p>
              <p>
                <label
                  htmlFor="day5"
                  className="text-[#4a5568] font-[700] text-[14px]"
                >
                  Friday
                </label>
                <input
                  type="checkbox"
                  name="days"
                  checked={week["fri"]}
                  value="Fri"
                  {...register("daysOpen")}
                  disabled={props.update === true ? enabled : false}
                  onClick={() => {
                    toggleField("fri");
                  }}
                />
              </p>
              <p>
                <label
                  htmlFor="day6"
                  className="text-[#4a5568] font-[700] text-[14px]"
                >
                  Saturday
                </label>
                <input
                  type="checkbox"
                  name="days"
                  checked={week["sat"]}
                  value="Sat"
                  {...register("daysOpen")}
                  disabled={props.update === true ? enabled : false}
                  onClick={() => {
                    toggleField("sat");
                  }}
                />
              </p>
              <p>
                <label
                  htmlFor="day7"
                  className="text-[#4a5568] font-[700] text-[14px]"
                >
                  Sunday
                </label>

                <input
                  type="checkbox"
                  name="days"
                  checked={week["sun"]}
                  value="Sun"
                  {...register("daysOpen")}
                  disabled={props.update === true ? enabled : false}
                  onClick={() => {
                    toggleField("sun");
                  }}
                />
              </p>
            </div>

            <div className="flex flex-col gap-[5px]">
              <label
                htmlFor="password"
                className="text-[#4a5568] font-[700] text-[14px]"
              >
                Upload Banner
                {
                  <span style={{ color: "red", paddingLeft: 5 }}>
                    {errors?.banner?.message}
                  </span>
                }
              </label>
              <input
                type="file"
                name="banner"
                id="banner"
                {...register("banner")}
                accept="image/*"
                onChange={handleChange}
                disabled={props.update === true ? enabled : false}
                hidden=""
              />
              <div className="preview-box" />
            </div>
            <div className="flex flex-col gap-[5px]">
              <label
                htmlFor="password"
                className="text-[#4a5568] font-[700] text-[14px]"
              >
                Upload Logo
                {
                  <span style={{ color: "red", paddingLeft: 5 }}>
                    {errors?.logo?.message}
                  </span>
                }
              </label>
              <input
                type="file"
                name="logo"
                id="logo"
                {...register("logo")}
                accept="image/*"
                onChange={handleChange}
                disabled={props.update === true ? enabled : false}
                hidden=""
              />
              {/* <label className="btn-upload" htmlFor="input-file" role="button">
                Upload Photo
              </label> */}

              <div className="preview-box1" />
            </div>
            <div className="flex flex-col gap-[5px]">
              <label
                htmlFor="password"
                className="text-[#4a5568] font-[700] text-[14px]"
              >
                Upload Certificate
                {
                  <span style={{ color: "red", paddingLeft: 5 }}>
                    {errors?.certificate?.message}
                  </span>
                }
              </label>
              <input
                type="file"
                name="certificate"
                id="certificate"
                {...register("certificate")}
                disabled={props.update === true ? enabled : false}
                accept="image/*"
                onChange={handleChange}
                hidden=""
              />
              {/* <label className="btn-upload" htmlFor="input-file" role="button">
                Upload Photo
              </label> */}

              <div className="preview-box2" />
            </div>
            {props?.update === true ? (
              <>
                <button
                  type="button"
                  className="subBtn"
                  onClick={() => {
                    setEnabled(!enabled);
                  }}
                >
                  Edit Details
                </button>
                <button type="submit" className="subBtn">
                  Update Details
                </button>
              </>
            ) : (
              <button type="submit" className="subBtn">
                Register Shop
              </button>
            )}
          </form>
        </section>
      </div>
      <div className="hidden px-[10px] py-[20px] rounded-[5px] text-[white] fixed items-center gap-[7px] bg-[#2894f4] top-[3%] right-[2%] error">
        <p>
          <h2 style={{ color: "red" }}>{formError}</h2>
        </p>
      </div>
    </>
  );
};

export default BussinessForm;
