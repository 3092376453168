import styles from "./Store.module.css";
import {  Paginations } from "../../Components/Pagination";
import { useEffect, useState } from "react";
import axios from "axios";
import { ListOfStore } from "./ListOfStore";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Filter } from "../../Components/filter/Filter";
import { addStore, filterStore, removeStore } from "../../redux/StoreSlice";
import { Loader } from "../../Components/Loader";
import { type } from "@testing-library/user-event/dist/type";
import { removeEverything } from "../../redux/CartSlice";
import { getBusinessDataByPage, removeAllData } from "../../API/StoreApi";
import { useTokenCheckerLogout } from "../../useTokenCheckerLogout";
import "./ListOfStore.module.css"

export const Store = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [currentPage, setCurrentPage] = useState(
    parseInt(searchParams.get("page")) || 1
  );
  const storeName = searchParams.get("store") || undefined;
  // console.log(storeName);
  const decoded = useTokenCheckerLogout();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [recordsPerPage] = useState(10);
  var myStores = useSelector((state) => state.store);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = data?.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(data.length / recordsPerPage);
  const [noOfStores,setNoofStore]=useState();
  const [mobile, setIsMobile] = useState(false);
  const dispatch = useDispatch();
  const filter = useSelector((state) => state.filter);
  const getData = () => {
    setLoading(true);
    // axios
    //   .get(
    //     "/api/v1/businessform/getbusinessdatabypage/" +
    //       currentPage +
    //       "/" +
    //       storeName
    //   )
    getBusinessDataByPage(currentPage,storeName)
      .then((res) => {
        setData(res.data.data);
        dispatch(addStore(res.data.data));
        filterData();
        // console.log(res.data.data);
        setNoofStore(res.data.data);
        // setLoading(false);
        // console.log(res.data.data);
      })
      .catch((error) => {
        // console.log(error);
        alert("There was an error while retrieving the data");
      }).finally(()=>setLoading(false));
  };
  const removeData = () => {
    // axios
    //   .post("/api/v1/cart/removealldata", {
    //     userId: decoded?.id,
    //   })
    removeAllData({userId: decoded?.id,})
      .then((data) => {
        //console.log("DataRemoved", data);
      })
      .catch((error) => {
        //console.log(error);
      });
  };
  const filterData = () => {
    setLoading(true);
    // console.log(filter?.gender);
    dispatch(
      filterStore({
        category: Object.keys(filter?.categories).filter(
          (category) => filter?.categories[category]
        ),
        gender: filter?.gender || [],
        city: [],
        locality: [],
      })
    );
    setLoading(false)
  };
  useEffect(() => {
    filterData();
  }, [filter?.categories, filter?.gender]);
  useEffect(() => {
    dispatch(removeStore());
    setData([]);
    getData();
  }, [currentPage, storeName]);
  useEffect(() => {
    // console.log("Finally", myStores?.FilteredValue);
    if (myStores?.FilteredValue?.length >= 0) {
      setData(myStores?.FilteredValue);
    } else {
      setData(myStores?.value);
    }
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [myStores?.FilteredValue]);

  useEffect(() => {
    removeData();
    const newSearchParams = new URLSearchParams(location.search);
    newSearchParams.set("page", currentPage);
    dispatch(removeEverything({isOffered:false}))
  }, []);
  return (
    <>
      {loading && <Loader />}
      <div className={`h-100 row ${styles.mobileStore} w-100`}>
        <div className={`col col-12 col-lg-2 col-sm-12 ${styles.mobileStore}`}>
          <Filter />
        </div>
        {data && <ListOfStore data={data} isOffered={false} offerDetails={null}/>}      

        {/* {noOfStores && console.log(noOfStores.length,parseInt(noOfStores.length)%5)} */}
        <div className=" pages">

        {(storeName === undefined && noOfStores )? (
          <Paginations
            page="listofstore"
            // nPages={parseInt(noOfStores.length)%5}
            nPages={10}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        ) : (
          ""
          )}
          </div>
      </div>
    </>
  );
};
