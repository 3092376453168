import Cookies from "js-cookie"
import { getApi, updateApi } from "../APIManager/ApiManager"

const admin=localStorage.getItem('shopify')
const user=localStorage.getItem('qwerty')
const uuid=btoa(Cookies.get('session') || localStorage.getItem('session'))
export const searchBar=(value)=>{
    return getApi('/api/v1/businessform/searchbar/'+value,null)

}

export const getServiceCategoryData=()=>{
    return getApi('/api/v1/superadmin/category/getservicecategory')
}

export const getShopPastApp=()=>{
    return getApi('/api/v1/appointment/shoppastapp',{user:user,admin:admin,uuid:uuid})
}

export const getCityData=()=>{
    return getApi('/api/v1/city/getcity')
}

export const getLocalityData=(id)=>{
    return getApi('/api/v1/locality/getbyspecificcity/'+id)
}


export const getStoreCategoryData=()=>{
    return getApi('/api/v1/superadmin/category/getstorecategory')
}

export const getUserDetails=(id)=>{
    return getApi("/api/v1/login/getuserdet/"+id)
}

export const updateUser=(data)=>{
    return updateApi('/api/v1/login/updateuser',data)
}

export const checkInApi=(id)=>{
    return getApi('/api/v1/appointment/checkedin/'+id)
}

export const getParticularApp=(id)=>{
    return getApi('/api/v1/appointment/getparticularapp/'+id)
}