import axios from "axios";
import Error from "../Error";

const App_Key = process.env.REACT_APP_APP_KEY
const instance = axios.create({
    baseURL: process.env.REACT_APP_MAIN_URL,
});


const createHeaders = (headersToPass) => {
    const defaultHeaders = {
        'Content-Type': 'application/json',
        'App_Key': App_Key,
    };

    return headersToPass
        ? { ...defaultHeaders, admin: headersToPass.admin, user: headersToPass.user,uuid:headersToPass.uuid }
        : defaultHeaders;
};


export const getApi = async (apiURL, headersToPass) => {
    try {
        const headers = createHeaders(headersToPass);
        const response = await instance.get(apiURL, { headers: headers })
        return response
    }
    catch (error) {
        // console.log(error)
        Error({error})
    }
}

export const postApi = async (apiURL, data, headersToPass) => {
    try {
        const headers = createHeaders(headersToPass);
        return instance.post(apiURL, data, { headers: headers })
    }
    catch (error) {
        // console.log(error)
        Error({error})
    }
}

export const wpPostApi=async(apiURL, data, headersToPass)=>{
    try {
        return instance.post(apiURL, data,headersToPass)
    }
    catch (error) {
        // console.log(error)
        Error({error})
    }
}

export const deleteApi = async (apiURL, headersToPass) => {
    try {
        const headers = createHeaders(headersToPass);
        return instance.delete(apiURL, { headers: headers })
    }
    catch (error) {
        // console.log(error)
        Error({error})
    }
}

export const updateApi = (apiURL, data, headersToPass) => {
    try {
        const headers = createHeaders(headersToPass);
        return instance.put(apiURL, data, { headers: headers })
    }
    catch (error) {
        // console.log(error)
        Error({error})
    }
}