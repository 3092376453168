import React from "react";
// import "./topUser.css";

export const TopUser = (topUsers) => {
  return (
    <div>
        <h2>Your Shop's Top 10 Customers</h2>
      <div className="container mt-3 mb-4">
        <div className="col-lg-9 mt-4 mt-lg-0">
          <div className="row">
            <div className="col-md-12">
              <div className="user-dashboard-info-box table-responsive mb-0 bg-white p-4 shadow-sm">
                <table className="table manage-candidates-top mb-0">
                  <thead>
                    <tr>
                      <th>Customer Name</th>
                      <th className="text-center">Phone Number</th>
                      <th className="action text-right">Appointments Booked</th>
                    </tr>
                  </thead>
                  <tbody>
                    {topUsers?.topUsers?.map((e) => {
                      return (
                        <>
                          <tr className="candidates-list">
                            <td className="title">
                              <div className="candidate-list-details">
                                <div className="candidate-list-info">
                                  <div className="candidate-list-title">
                                    <h5 className="mb-0">
                                      {e?.userId?.firstName}{" "}
                                      {e?.userId?.lastName}
                                    </h5>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td className="candidate-list-favourite-time text-center">
                              <span className="candidate-list-time order-1">
                                {e?.userId?.phoneNumber}
                              </span>
                            </td>
                            <td className="candidate-list-favourite-time text-center">
                              <span className="candidate-list-time order-1">
                                {e?.totalAppointments}
                              </span>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
