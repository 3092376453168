import React from 'react'
// import "../Pages/Appointments/pastAppointments.css"
const Modal = ({title,subtitle,children,footer}) => {
  return (
    <>
    <div
        className="modal fade "
        id="pastapp"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog  modal-dialog-centered ">
          <div className="modal-content">
            <div className="modal-header">
              <div>
              <h1 className="modal-title" id="exampleModalLabel" style={{marginInline:"auto"}}>
                {title}
              </h1>
              {/* <h5>
                {subtitle}
             </h5> */}
              {/* Order Id:{modalData?.cartId} */}
              </div>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ></button>
            </div>
            <div className="modal-header">
                <h5>{subtitle}</h5>
              </div>
            <div className="modal-body">
              {children}
            </div>
            <div className="modal-footer">
              {footer}
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    
    </>
  )
}

export default Modal