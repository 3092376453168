import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./UserProfile.css";
import { useDispatch } from "react-redux";
import { Loader } from "../../Components/Loader";
import { addUser } from "../../redux/UserSlice";
import { Button } from "react-bootstrap";
import { getCityData, getLocalityData, getUserDetails, updateUser } from "../../API/AdditionalApi";
export const UserProfile = () => {
  const { userId } = useParams();
  //   //console.log(userId);
  var [maindata, setData] = useState();
  const [editData, setEditData] = useState(false);
  const [city, setCity] = useState([]);
  const [locality, setLocality] = useState([]);
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false);
  const navigate=useNavigate()
  const [formData1, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    city: ""
  });
  const getData = () => {
    setLoading(true)
   getUserDetails(userId)
      .then((data) => {
        // setLoading(false)
        // console.log(data.data);
        setData(data.data.data)
        setFormData({
          firstName: data.data.data.firstName,
          lastName: data.data.data.lastName,
          email: data.data.data.email,
          city: data.data.data.city.cityName
        });
        getLocality()
        //console.log(formData1.firstName);
        //   //console.log(formData1)
      })
      .catch((error) => {
        // setLoading(false)
        //console.log(error);
      }).finally(()=>setLoading(false));
    };
    

  useEffect(() => {
    getCity()
    getData();
    // getLocality()
  }, []);



  const ref = useRef(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    // defaultValues: formData1,
  });

  const submit = (data) => {
    // console.log(data)
    setLoading(true)
    //console.log("HERE ISDATA");
    //console.log(data);
    updateUser({ id: maindata._id, data: data }).then((data) => {
      //console.log(data)
      // setLoading(false)
      localStorage.setItem('qwerty', data.data.userToken)
      dispatch(addUser(data.data.userToken))
    }).catch((error)=>{
      // setLoading(false)
    }).finally(()=>setLoading(false))
  };
  const editForm = () => {
    setEditData(!editData);
  };

  const getCity = () => {
   getCityData()
      .then((data) => {
        // //console.log(data)
        setCity(data.data.data);
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  const getLocality = (e) => {
    if (e === undefined)
      e = maindata?.city?._id
    else
      e = e?.target?.value
    if (e !== undefined) {
      getLocalityData(e)
        .then((data) => {
          // //console.log(data.data.data);
          setLocality(data.data.data);
        })
        .catch((error) => {
          //console.log(error);
        });
    }

  };

  function successFunction(value) {
    var lat = value.coords.latitude;
    var long = value.coords.longitude;

    // console.log(value)
    document.getElementById('error')
      .innerHTML = "";
    document.getElementById('latitude')
      .innerHTML = `Latitude: ${lat}`;
    document.getElementById('longitude')
      .innerHTML = `Latitude: ${long}`;
}

// Error Function
function errorFunction(err) {
    document.getElementById('latitude')
      .innerHTML = "";
    document.getElementById('longitude')
      .innerHTML = "";
    document.getElementById('error')
      .innerHTML = `Error occurred: ${err.message}`;
}
  
// Main function to get location
function getLocation() {
    navigator.geolocation
      .getCurrentPosition(
        successFunction, errorFunction
      );
}
const con=(email)=>{
  navigate("/confirm", { state: { value: email} });
}
  return (
    <div>
      {loading && <Loader/>}
      <section className="bg-white p-[2rem] flex flex-col w-[90%] lg:w-[34%] rounded-r-[1.2rem] rounded-t-[1.2rem] mainSection">
        <form onSubmit={handleSubmit(submit)} className="mt-[4rem] flex flex-col gap-[1.7rem]">
          <h1 className="text-[#083e9e] text-[1.5rem] font text-center">
            Personal Information
          </h1>
          <div className="flex flex-col gap-[5px]">
            <label htmlFor="name" className="text-[#4a5568] font-[700] text-[14px]">
              First Name
            </label>
            <input
              type="text"
              name="firstName"
              defaultValue={formData1.firstName}
              id="firstName"
              ref={ref}
              placeholder={`First Name `}
              {...register("firstName")}
              disabled={!editData}
              className={`${editData ? "bg-white"  : "bg-[#e2e8f0]"}  py-[.8rem] rounded-[4px] px-[1rem] text-[#1a202c] outline-none form-control"`}
            />
          </div>
          <div className="flex flex-col gap-[5px]">
            <label htmlFor="name" className="text-[#4a5568] font-[700] text-[14px]">
              Last Name
            </label>
            <input
              type="text"
              name="lastName"
              id="lastName"
              defaultValue={formData1.lastName}
              placeholder="Last Name"
              {...register("lastName")}
              disabled={!editData}
              className={`" ${editData ? "bg-white"  : "bg-[#e2e8f0]"}  py-[.8rem] rounded-[4px] px-[1rem] text-[#1a202c] outline-none form-control"`}
            />
          </div>
          <div className="flex flex-col gap-[5px]">
            <label htmlFor="name" className="text-[#4a5568] font-[700] text-[14px]">
              Email
            </label>
            {/* {console.log(maindata)} */}
            {maindata?.enabled===0 ? <span><button onClick={()=>con(formData1?.email)}>Verify your email account</button></span> : ""}
            <input
              type="email"
              name="email"
              id="email"
              defaultValue={formData1.email}
              disabled
              placeholder="Last Name"
              {...register("email")}
              className={`  ${editData ? "bg-white"  : "bg-[#e2e8f0]"}  py-[.8rem] rounded-[4px] px-[1rem] text-[#1a202c] outline-none form-control"`}
            />
          </div>
          <div className="flex flex-col gap-[5px]">
            <label htmlFor="name" className="text-[#4a5568] font-[700] text-[14px]">
              City {<span style={{ color: "red", paddingLeft: 5 }}>{errors?.city?.message}</span>}
            </label>
            <select
              name="city"
              defaultValue={formData1.city}
              id="city"
              {...register("city")}
              onChange={getLocality}
              required=""
              disabled={!editData}
              className={` ${editData ? "bg-white"  : "bg-[#e2e8f0]"}  py-[.8rem] rounded-[4px] px-[1rem] text-[#1a202c] outline-none`}
            >
              <option>Select City</option>
              {maindata?.city?._id}
              {city?.map((cities) => {
                return <option value={cities._id} selected={cities._id == maindata?.city?._id}>{cities.cityName}</option>;
              })}
            </select>
          </div>
          <div className="flex flex-col gap-[5px]">
            <label htmlFor="name" className="text-[#4a5568] font-[700] text-[14px]">
              Locality {<span style={{ color: "red", paddingLeft: 5 }}>{errors?.locality?.message}</span>}
            </label>
            <select
              className={`${editData ? "bg-white"  : "bg-[#e2e8f0]"}  py-[.8rem] rounded-[4px] px-[1rem] text-[#1a202c] outline-none`}
              required="" name="locality" {...register("locality")} disabled={!editData}
            >
              <option>Select Locality</option>
              {locality?.map((localities) => {
                return (
                  <option selected={localities.localityName == maindata?.locality}>
                    {localities.localityName}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="part__one">
          </div>
          <div className="buttons">
            {editData ?<button type="submit"
              value="submit Form"
              className="subBtn"
              onClick={() => {
                setEditData(false);
              }}>
               Submit Form
            </button>
            : <button onClick={editForm} type="button" className="subBtn">
            Edit
          </button>}
          </div>
        </form>
      </section>

    </div>
  );
};
