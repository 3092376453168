import React, { useEffect, useState } from "react";
import AdminCarousel from "../../Components/AdminCarousel";
import { useLocation, useParams, Link, useNavigate } from "react-router-dom";
import "./Store1.css";
import styles from "./StorePage.module.css";
import UserServicePage from "../UserServicePage/UserServicePage";
import About from "./About";
import Review from "../Review Form/Review";
import axios from "axios";
import MaleIcon from "@mui/icons-material/Male";
import FemaleIcon from "@mui/icons-material/Female";
import { Rating } from "@mui/material";
import io from "socket.io-client";
import Swal from "sweetalert2";
import { Error404 } from "../../Components/errors/Error404";
import { Loader } from "../../Components/Loader";
import { getBusineesDataForUser, getPhotos } from "../../API/StoreApi";
import { addShopReview, getShopReview } from "../../API/ReviewApi";
import { IoLocation } from "react-icons/io5";


import { FaClock } from "react-icons/fa";
import { style } from "d3";
export const Store1 = ({props}) => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const { id } = useParams();
  var [valueToBe, setValueToBe] = useState("");
  var [imagesData, setImagesData] = useState([]);
  var [store, setStore] = useState();
  var [review, setReview] = useState();
  var [avg, setAvg] = useState();
  var [value, setValue] = useState("");
  var [noFound, setNoFound] = useState(false);
  const navigate=useNavigate()
  const isAdmin = location.state?.isAdmin;
  const isOffered=location.state?.isOffered
  const getImages = (value) => {
    getPhotos(value)
      .then((data) => {
        // console.log("IMAGES",data.data.data);
        // console.log(Array.isArray(data.data.data));
        // console.log((data.data.data).length);
        if (data.data.data.length == 0) {
          setImagesData([]);

        } else setImagesData(data.data.data);
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  const getStoreData = (value) => {
    setLoading(true);
    getBusineesDataForUser(value)
      .then((data) => {
        // console.log(data.data.data.banner);
        // try{

          // setImagesData([...imagesData,data.data.data?.banner]);

        // }
        // catch(e){
        //   console.log(e)
        // }
        setStore(data.data.data);
      })
      .catch((error) => {
        // setLoading(false);
        setNoFound(true);
        //console.log(error);
      })
      .finally(() => setLoading(false));
  };

  const getReview = (value) => {
    setLoading(true);
    getShopReview(value)
      .then((data) => {
        setReview(data.data.data);
        setAvg(data?.data?.avgRating[0]?.avgReview);
      })
      .catch((error) => {
        //console.log(error);
      })
      .finally(() => setLoading(false));
  };

  const [showComponent1, setShowComponent1] = useState(false);
  const [showComponent2, setShowComponent2] = useState(false);
  const [showComponent3, setShowComponent3] = useState(false);

  const renderComponent1 = () => {
    setShowComponent1(true);
    setShowComponent2(false);
    setShowComponent3(false);
  };

  const renderComponent2 = () => {
    setShowComponent1(false);
    setShowComponent2(true);
    setShowComponent3(false);
  };

  const renderComponent3 = () => {
    setShowComponent1(false);
    setShowComponent2(false);
    setShowComponent3(true);
  };
  const socket = io(process.env.REACT_APP_MAIN_URL);
  const [isMobile,setIsMobile]=useState(false);
  useEffect(() => {
    try {
      // const value = location.state?.value;
      // setValue(location?.state?.value);
      // setValue(atob(id))
      renderComponent1();
      // console.log(location.pathname?.split("/"));
      if (location.pathname?.split("/")[1] === "ms") {
        socket.emit("joinStoreRoom", atob(id));
        // console.log(atob(id));
        setValueToBe(atob(id));
        getImages(atob(id));
        getStoreData(atob(id));
        getReview(atob(id));
      } 
      else if(location.pathname==='/store')
      {
        socket.emit("joinStoreRoom", location?.state?.value);
        setValueToBe(location?.state?.value);
        getImages(location?.state?.value);
        getStoreData(location?.state?.value);
        getReview(location?.state?.value);
      }
      else {
        socket.emit("joinStoreRoom", id);
        setValueToBe(id);
        getImages(id);
        getStoreData(id);
        getReview(id);
      }
    } catch (error) {
      console.log(error);
      navigate("error404")
    }
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };

  }, [id]);

  const handleReviewSubmit = (data) => {
    setLoading(true);
    var user = localStorage.getItem("qwerty");
    if (user) {
      data.adminId = value;
      addShopReview(data)
        .then((data) => {
          // console.log(data);
          // setLoading(false);
          Swal.fire({
            icon: "success",
            title: "Thanks For Giving your Valuable Feedback",
          });
        })
        .catch((error) => {
          // setLoading(false);
          Swal.fire({
            icon: "error",
            title: error?.response?.data?.message,
          });
        })
        .finally(() => setLoading(false));
    } else {
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Login Required",
      });
    }
  };

  const clickOnButton = (idx) => {
    const buttons = document.querySelectorAll(".button1");
    for (let i = 0; i < buttons.length; i++) {
      if (buttons[i].id == idx) {
        buttons[i].classList.add("click");
      } else {
        buttons[i].classList.remove("click");
      }
    }
  };

  return (
<>
      {loading && <Loader />}
      {noFound === false ? (
      <div className="store__bg">
        <div className="servicesContainer stores h-100 w-75 m-auto mb-2">          {
            isMobile ? 
            <>
            <div className={`${styles.storeDetails} row`}>
            <div
              className="col-lg-8 col-12 col-md-8 m-auto"
              style={{ paddingLeft: "0", paddingRight: "0" }}
            >

              {/* {imagesData && <AdminCarousel Images={imagesData} f={0} />} */}
              {/* <AdminCarousel Images={imagesData } f={0} />  */}
              {imagesData.length!=0 ? <AdminCarousel Images={imagesData} f={0} /> : 
              <AdminCarousel Images={[{photoId:store?.banner}]} f={0} />}
               {/* <img src={store?.banner} alt="Store banner" width="100%" 
               loading="lazy" height="300px" />
               : <AdminCarousel Images={imagesData.push(store?.banner)} f={0} />} */}
            </div>
            <div className="col col-11 m-auto">
              <div className={`d-flex ${styles.title} mb-0`}>
              <img src={store?.logo} alt="Store Logo" className={styles.logo} />
              <p style={{alignItems:"center"}}>
              {avg && (
                <Rating name="read-only" value={avg?.toFixed(0)} readOnly />
              )}
              {avg !== undefined ? avg?.toFixed(1) : 
              <>
              <Rating name="read-only" value={0} readOnly />
              </>
              } 
              </p>
              </div>
              <div className="store__desc">
              <h1>
                {store?.brandName}
                <b>
                </b>
                </h1>
              <h4 className="flex gap-2 items-center my-1">  
              <IoLocation className="h-8 w-8" />
                <Link
                to={`https://www.google.com/maps/search/?api=1&query=${String(store?.address).replace(',','+')}`}
                target="_blank"
                >
                  {store?.address}
                </Link>
              </h4>
              <h5>
                <MaleIcon />/
                <FemaleIcon />: {store?.genderServed}
              </h5>
              <h5 className="flex items-center gap-2 my-1"> <FaClock /> {store?.openingTime} - {store?.closingTime}</h5>
              </div>
            </div>
          </div>
            </>
         :
         <>
         <div className="storeDetails row">
            <div
              className="col-lg-8 col-sm-12 col-md-8"
              style={{ paddingLeft: "0", paddingRight: "0" }}
            >
              {/* {imagesData && <AdminCarousel Images={imagesData} f={0} />} */}
              {imagesData.length!=0 ? <AdminCarousel Images={imagesData} f={0} /> : 
              <AdminCarousel Images={[{photoId:store?.banner}]} f={0} />}
              {/* {imagesData!=null ? <AdminCarousel Images={imagesData} f={0} /> : <img src={store?.banner} alt="Store banner" width="100%"
              loading="lazy"
                      height="300px" />} */}
            </div>
            <div className="col-lg-4 col-sm-12 pt-3 col-md-4">
              {avg && (
                <Rating name="read-only" value={avg?.toFixed(0)} readOnly />
              )}
              {avg !== undefined ? avg?.toFixed(1) : 
              <>
              <Rating name="read-only" value={0} readOnly />
              (No Reviews)
              </>
              } 
              <h1>
                <b>{store?.brandName}</b>
              </h1>
              <br />
              <p className={``}>  
                <Link
                to={`https://www.google.com/maps/search/?api=1&query=${String(store?.address).replace(',','+')}`}
                target="_blank"
                >
                  {store?.address}
                </Link>
              </p>
              <br />
              <p>
                <MaleIcon />/<FemaleIcon />: {store?.genderServed}
              </p>

              <h5>Opening Time : {store?.openingTime}</h5>
              <h5>Closing Time : {store?.closingTime}</h5>
            </div>
          </div>
          </>
}
          <div className="mt-5 servicesContainer">
            {/* <div className="ms-5 store__options">
              <button
              id="menu"
                onClick={(e)=>{renderComponent1();clickOnButton("menu")}}
                className="button1 btn me-2 ms-3 "
              >
                Menu
              </button>
              <button 
              id="about"
              onClick={()=>{renderComponent2();clickOnButton("about")}} className="button1 btn me-2 ">
                About
              </button>
              <button 
              id="reviews"
              onClick={()=>{renderComponent3();clickOnButton("reviews")}} className="button1 btn me-2">
                Reviews
              </button>
              
            </div> */}
            {showComponent1 && <UserServicePage value={valueToBe} isAdmin={isAdmin} address={store?.address} isOffered={isOffered} owner={store?.ownerName} phNumber={store?.contactNumber}/>}
            {showComponent2 && (
              <About about={store?.about} name={store?.brandName} />
            )}
            {showComponent3 && (
              <>
                <h1 className="my-5 mx-3">
                  What Your <span className="gold">Customer Say</span>
                  </h1>
                  <Review
                    onSubmit={handleReviewSubmit}
                    review={review}
                    value={valueToBe}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      ) : (
        <Error404 />
      )}
    </>
  );
};
