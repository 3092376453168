export const UserData = [
    {
      id: 1,
      month: "Jan",
      userGain: 80000,
      userLost: 823,
    },
    {
      id: 2,
      month: "Feb",
      userGain: 45677,
      userLost: 345,
    },
    {
      id: 3,
      month: "March",
      userGain: 78888,
      userLost: 555,
    },
    {
      id: 4,
      month: "April",
      userGain: 90000,
      userLost: 4555,
    },
    {
      id: 5,
      month: "May",
      userGain: 4300,
      userLost: 234,
    },
  ];
  