import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getStoreList } from "../../API/OffersApi";
import { ListOfStore } from "../storePage/ListOfStore";
import { useDispatch } from "react-redux";
import { removeEverything } from "../../redux/CartSlice";
import { Loader } from "../../Components/Loader";

export const GymOffer = ({ props }) => {
  const { code } = useParams();
  var [data,setData]=useState()
  const [loading,setLoading]=useState(false)
  var [offerDetails,setOfferDetails]=useState()
  const dispatch = useDispatch();

  const getData = () => {
    setLoading(true)
    getStoreList(code)
      .then((data) => {
        // console.log(data);
        setData(data?.data?.data);
        setOfferDetails(data?.data?.offerDetails);
      })
      .catch((error) => {
        console.log(error);
      }).finally(()=>{
        setLoading(false)
      });
  };
 

  useEffect(() => {
    dispatch(removeEverything({ isOffered: true }));
    getData();

  }, []);

  return (
    <>
      {/* <div>GymOffer</div> */}
      {loading && <Loader/>}
      {/* {console.log(data)} */}
      {data && <ListOfStore data={data} isOffered={true} offerDetails={offerDetails}/>}
    </>
  );
};
