import React, { useEffect, useState } from "react";
import "./multiForm.css";
import { useForm } from "react-hook-form";
import axios from "axios";
import { Loader } from "../../Components/Loader";
import { MultiRowForms } from "../../Components/reusableForms/MultiRowForms";
import { deleteService, getServiceData, multiAddService, updateService } from "../../API/ServiceApi";
import { getServiceCategoryData, getStoreCategoryData } from "../../API/AdditionalApi";

export const MultiService = () => {

  var fields=[
    {
      fieldName:"serviceName",
      type:"text",
      actual:"Service Name"
    },
    {
      fieldName:"price",
      type:"number",
      actual:"Price"
    },
    {
      fieldName:"time",
      type:"number",
      actual:"Time(mins)"
    },
    {
      fieldName:"categoryId",
      type:"select",
      actual:"Category"
    },
    {
      fieldName:"gender",
      type:"select",
      actual:"Gender",
      value:["male","female","unisex"]
    }
  ]

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const ValidationSchema = {
    serviceName: {
      required: {
        value: true,
        message: "Service Name is required",
      },
    },
    price: {
      required: {
        value: true,
        message: "Price is required",
      },
    },
    time: {
      required: {
        value: true,
        message: "Time is required",
      },
    },
    categoryId: {
      required: {
        value: true,
        message: "Category Id is required",
      },
    },
    gender: {
      required: {
        value: true,
        message: "Gender is required",
      },
    },
  };


  const [mainData, setMainData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [d,setD]=useState(false);
  //   var { register, handleSubmit,reset } = useForm();
  const [formData, setFormData] = useState([
    {
      id: 1,
      serviceName: "",
      price: "",
      time: "",
      gender: "male",
      description: "",
      image: "",
      categoryId: "",
    },
  ]);

  const handleInputChange = (e, id) => {
    // console.log(e.target.value);
    // console.log(e.target.name);
    // console.log(id);
    const { name, value, type, checked } = e.target;

    setFormData((prevData) =>
      prevData.map((data) => {
        // console.log(data._id);
        return data._id === id || data.id === id
          ? { ...data, [name]: value }
          : data;
      })
    );

    // console.log(formData);
  };

  const handleAddRow = () => {
    // console.log("inHandle",formData)
    setFormData((prevData) => [
      ...prevData,
      {
        id: Date.now(),
        serviceName: "",
        price: "",
        time: "",
        gender: "male",
        description: "",
        image: "",
        categoryId: serviceCategory[0]._id,
      },
    ]);
  };

  const addService = (data) => {
    // console.log(data);
    // validation(data)
    setMainData((prevArray) => {
      const index = prevArray.findIndex((item) => item.id === data.id);

      if (index !== -1) {
        // If the service is present, update it
        return prevArray.map((item, i) => (i === index ? data : item));
      } else {
        // If the service is not present, append the new object
        return [...prevArray, data];
      }
    });
  };

  const validation=(data)=>{
    var newErrors=[]
    // console.log(data)
  }
  const removeService = (id) => {
    // console.log(id);
    // Do something with the form data, e.g., send it to the server
    setFormData((prevData) => prevData.filter((data) => data?.id !== id));
    setMainData(mainData.filter((e) => e.id != id));
    // console.log("Form Data:", formData);
    // handleValidation(id);
  };

  const getData = (flag) => {
    setLoading(true);
    getServiceData()
      .then((data) => {
        mainData?.length>0 && flag!==true ? setFormData([...data?.data?.data,...mainData]) : setFormData(data?.data?.data)
      })
      .catch((error) => {
      }).finally(()=>setLoading(false))
  };

  const [serviceCategory, setserviceCategory] = useState();
  const getStoreCategory = () => {
    setLoading(true);
    getServiceCategoryData()
      .then((data2) => {
        setD(true);
        setserviceCategory(data2?.data?.data);
      })
      .catch((err) => {
      }).finally(()=>setLoading(false))
  };

  const finalDelete = (id) => {
    setLoading(true);
    setFormData((prev)=>prev.filter((e)=>e._id!=id));
      deleteService(id)
      .then((data1) => {
        setFormData((prevData) => prevData.filter((data) => data._id !== id));
      })
      .catch((err) => {
        // console.log(err);
      }).finally(()=>setLoading(false));
  };

  const finalUpdate = (data) => {
    // console.log("1234567890987654321----",data);
    setLoading(true);
    if (typeof data?.categoryId === "object")
      data.categoryId = data?.categoryId?._id;
    else data.categoryId = data?.categoryId;
    // console.log("Final Update", data);
    const formData = new FormData();
    var keys = Object.keys(data);
    keys?.map((e) => {
      // console.log(data[e]);
      if (data[e] instanceof FileList) {
        // console.log(e, data[e][0]);
        formData.append("file", data[e][0]);
      } else formData.append(e, data[e]);
    });
    updateService(formData)
      .then((data) => {
        getData();
      })
      .catch((error) => {
      }).finally(()=>setLoading(false));
  };

  const finalSubmit = () => {
    setLoading(true);
    // console.log(mainData)
    multiAddService(mainData)
      .then((data) => {
        setMainData([])
        getData(true);
      })
      .catch((error) => {
      }).finally(()=>setLoading(false));
  };

  useEffect(() => {
    getStoreCategory();
    getData();
  }, []);

  return (
    
    <>
    {
      loading && <Loader/>
    }
    {/* {console.log(formData)} */}
    
    {formData[0]?.serviceName!=="" && formData[0]?._id!==undefined && <MultiRowForms fields={fields}
    Update={data=>finalUpdate(data)} 
    Delete={data=>finalDelete(data)}
    Remove={data=>removeService(data)}
    Add={data=>addService(data)}
    AddRow={handleAddRow}
    serviceCategory={serviceCategory}
    mainData={mainData}
    finalSubmit={finalSubmit}
    formData={formData} 
    handleInputChange={(e,id)=>{handleInputChange(e,id)}}
    handleSubmit={handleSubmit}
    validationSchema={ValidationSchema}
    register={register}
    error={errors}
    />
   
    }
    </>
  );
};
