import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getParticularApp } from "../../API/AdditionalApi";
import { Loader } from "../../Components/Loader";
// import './cancelAppointment.css'
import { deleteAppByAdmin, deleteAppointmentByUser } from "../../API/AppointmentApi";

export const CancelAppointments = () => {
  const { appId } = useParams();
  const [modalData, setModalData] = useState();
  const [loading, setLoading] = useState(false);
  const navigate=useNavigate()
  const getData = () => {
    try{
      setLoading(true);
      getParticularApp(atob(appId))
        .then((data) => {
          setModalData(data.data.data);
        })
        .catch((error) => {
          // console.log(error);
        }).finally(()=>{setLoading(false)});
    }
    catch(error)
    {
      // console.log(error)
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const cancel = (id) => {
    let yn = window.confirm("Do you want to cancel the Appointment");
    if (yn) {
      // console.log(appData)
      setLoading(true);
      deleteAppointmentByUser(id)
        .then((data) => {navigate("/")})
        .catch((error) => {
          // console.log(error)
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  return (
    <>
      {/* {console.log(admin)} */}
      {loading && <Loader />}
      {modalData !== null ? (
        <>
          <div className="shop-info">
            <div className="header">
              <h1 className="det">
                Appointment Details
              </h1>
              <p className="location">
                Order Id : <b>{modalData?._id}</b>
              </p>
            </div>
            <div className="info">
              <div className="time">
                Start Time :{" "}
                {new Date(modalData?.startTime).toLocaleString("en-GB")}
              </div>
              <p className="employee">
                Employee: {modalData?.employeeId?.employeeName}
              </p>
              <p className="amount">
                Total Amount: {modalData?.totalPrice} INR
              </p>
              <p>
                Status :{" "}
                {modalData?.checkedIn == "true" ? "Checked In" : "Pending"}
              </p>
            </div>
            <div className="services">
              <h3>Services</h3>
              <ul>
                {modalData?.serviceId.map((service, index) => (
                  <li key={index} className="service-item">
                    <div className="service-left">
                      <div className="service-details">
                        <span className="service-name">
                          {service.serviceName}
                        </span>
                        <span className="start-time">
                          Time to take: {service.time}
                        </span>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <button
              type="submit"
              value="submit Form"
              className="btn btn-danger"
              onClick={() => {
                cancel(atob(appId));
              }}
            >
              Cancel Appointment
            </button>
          </div>
        </>
      ) : (
        <>
          <h1>No Appointment Found</h1>
        </>
      )}
    </>
  );
};
