import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { addService, removeService } from "../API/UserServiceApi";

const initialState = {
    value: {
        serviceId: []
    }
}

const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        addToCart: (state, action) => {
            //console.log(action.payload.data)
            var check = state.value?.serviceId?.findIndex((e) => e._id === action.payload.data._id)
            //console.log(check)
            if (action.payload != null && check === -1) {
                state.value.serviceId.push(action.payload.data); // Corrected this line
                addService({ data: action.payload.data, user: action.payload.user }).then((data) => {
                    // console.log(data)
                }).then((error) => {
                    // console.log(error)
                })
            }
        },
        removeFromCart: (state, action) => {
            //console.log(action.payload.data)
            let index = state.value?.serviceId?.findIndex((e) => e._id === action.payload.data)
            //console.log(index)
            if (index > -1) {
                state.value.serviceId.splice(index, 1)
                removeService({ data: action.payload.data, user: action.payload.user}).then((data) => {
                    //console.log(data)
                }).then((error) => {
                    //console.log(error)
                })
            }


        },
        removeEverything: (state, action) => {
            // console.log(action)
            state.value.adminId = ""
            state.value.cartToAppoitment = false
            state.value.createdAt = ""
            state.value.date = ""
            state.value.discount = 0
            state.value.employeeId = ""
            state.value.serviceId = []
            state.value.startTime = 0
            state.value.totalPrice = 0
            state.value.totalTime = 0
            state.value.updatedAt = ""
            state.value.userId = ""
            state.value._id = ""
            state.value.isOffered=action.payload.isOffered
        },
        addFinaldata: (state, action) => {
            state.value = action.payload
        },
        removeTime: (state, action) => {
            state.value.employeeId = ""
            state.value.startTime = 0
            state.value.totalPrice = 0
            state.value.totalTime = 0
            state.value.date = ""
            state.value.employeeId = ""
            state.value.adminId = ""
        }

    }
}
)



export const { addToCart, removeFromCart, removeEverything, addFinaldata, removeTime } = cartSlice.actions
export default cartSlice.reducer