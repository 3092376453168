import { combineReducers } from "@reduxjs/toolkit";
import CitySlice from "./redux/CitySlice";
import LocationSlice from "./redux/LocationSlice";
import CartSlice from "./redux/CartSlice";
import UserSlice from "./redux/UserSlice";
import StoreSlice from "./redux/StoreSlice";
import FilterSlice from "./redux/FilterSlice";

const rootReducer=combineReducers({
    city:CitySlice,
    locality:LocationSlice,
    cart:CartSlice,
    user:UserSlice,
    store:StoreSlice,
    filter:FilterSlice
})

export default rootReducer;