import React, { useRef, useState } from "react";
import "./signup.css";
import { useForm } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { addCity } from "../../redux/CitySlice";
import { addLocation } from "../../redux/LocationSlice";
import { Dropdowns } from "../../Components/reusableForms/Dropdowns";
import { Loader } from "../../Components/Loader";
import InputTag from "../../Components/InputTag";
import { signUpAddUser } from "../../API/LoginApi";
import { getCaptchaData, getCityData } from "../../API/AdditionalApi";
import captch_bg from '../../Images/captch-bg.jpg'
import { IoMdRefreshCircle } from "react-icons/io";

// import { useCookies } from 'react-cookie';
import Cookies from 'js-cookie';
import { image } from "d3";
export const SignUp = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const location=useLocation();
  const fromServicePage=location.state?.fromServicePage;

  // console.log("-------->",fromServicePage);

  const [formError, setFormError] = useState("");
  const [city, setCity] = useState("");
  const [locality, setLocality] = useState([]);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const store = useSelector((state) => state);
  // const [cookies, setCookie, removeCookie] = useCookies(['session']);/
  const [captcha,setCaptcha]=useState('');
  
  const submit = (data) => {
    data.captchaEntered=captcha
    setLoading(true);
    signUpAddUser(data)
      .then((data) => {
        // console.log(data.data)
        // Cookies.set('session',data?.data?.session)
        setLoading(false);
        navigate("/confirm", { state: { value: data?.data?.data, fromServicePage: fromServicePage, isOffered: location.state?.isOffered } });
        // window.location.reload();
      })
      .catch((error) => {
        setLoading(false);
        // console.log(error);
        setFormError(error.response.data.message);
      });
  };

  const getCity = () => {
    if (store.city.value.length <= 0) {
      getCityData()
        .then((data) => {
          dispatch(addCity(data.data.data));
        })
        .catch((error) => {
          //console.log(error);
        });
    }
  };

  const getLocality = async (e) => {
    setCity(e);
    if (store.locality.value.length <= 0) {
      axios
        .get("/api/v1/locality/getlocality")
        .then((data) => {
          dispatch(addLocation({ locality: data.data.data, city: e }));
        })
        .catch((error) => {
          //console.log(error);
        });
    }
  };

  const getCaptcha=()=>{
    // getCaptchaData().then((data)=>{
    //   console.log("data",data);
    // }).catch((err)=>{
    //   console.log(err);
    // })
    axios.get("/api/v1/signup/captcha").then((data)=>{
      // console.log(data);
      setCaptcha(data.data.encoded);
      eraseCanvas();
      drawCanvas(data.data.encoded);
    }).catch((err)=>{
      // console.log(err);
    })
  }
  const canvasRef = useRef(null);
  const eraseCanvas=()=>{
    const canvas=canvasRef.current;
    const ctx=canvas.getContext("2d");
    ctx.clearRect(0,0,canvas.width,canvas.height);
  }
  const drawCanvas=(data)=>{
    const img=new Image();
    const canvas = canvasRef.current;
    img.src=require("../../Images/captch-bg.jpg");
    const canvasElement=document.getElementById("captcha__canvas");
    img.onload=()=>
    {

      
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);
      ctx.font="italic 50px verdana";
      canvasElement.style.textDecorationLine="underline";
      canvasElement.style.letterSpacing="0.51cap";
      canvasElement.style.color="#691e28";
      // console.log(canvasElement);
      ctx.fillText(atob(data),40,100)
      ctx.moveTo(0,50);
      ctx.lineTo(canvas.width,100);
      ctx.stroke();
      ctx.strokeStyle='red';
      
      // ctx.fillStyle="rgb(105, 40, 40)";
      // ctx.textAlign='center';
    }
  }
  
  useEffect(() => {
    // drawCanvas();
    getCity();
    getCaptcha();
  }, []);

  const ValidationSchema = {
    firstName: {
      required: {
        value: true,
        message: "First Name Is required",
      },
      minLength: {
        value: 1,
        message: "should be minimum 1 length",
      },
    },
    lastName: {
      required: {
        value: true,
        message: "Last Name Is required",
      },
      minLength: {
        value: 1,
        message: "should be minimum 1 length",
      },
    },
    city: {
      required: {
        value: "true",
        message: "City Is required",
      },
    },
    locality: {
      required: {
        value: true,
        message: "locality Is required",
      },
    },
    phoneNumber: {
      required: {
        value: true,
        message: "required",
      },
      pattern: {
        value: /^(0|91)?[6-9][0-9]{9}$/,
        message: "should be in correct format only",
      },
    },
    email: {
      required: {
        value: true,
        message: "required",
      },
      pattern: {
        value:
          /^(?:(?:[^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@(?:(?:\[(?:[0-9]{1,3}\.){3}[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        message: "Email should be in proper format",
        
      },
    },
    password: {
      required: {
        value: true,
        message: "required",
      },
    },
    tandc: {
      required: {
        value: true,
        message: "Agree with terms and conditions as well as privacy policy",
      },
    },
    captcha:{
      required:{
        value:true,
        message:"Captcha is required"
      }
    }
  };

  return (
    <div>
      <>
        {/* {console.log(loading)} */}
        {loading && <Loader />}
        {/* <div className="register-page row container m-auto"> */}
        <div className=" min-h-screen flex items-center justify-center py-12 sm:px-6 lg:px-8  ">
          <form
          className="bg-transparent py-3 px-4 sm:px-10  mb-4 border border-x-2 border-gray-500 rounded-lg  w-[60vh] sm:w-[80vh] shadow-lg"
            // className="register-form col-lg-6 col-sm-12"
            onSubmit={handleSubmit(submit)}
          >
            {/* <h2>Sign Up</h2> */}
            <h1 className="my-1" style={{ color: "red" }}>{formError}</h1>
            <div className="text-center my-1">
            <h1 className="text-2xl my-1 font-bold">Create an Account</h1>
            <h5 className="text-xl myz-2 font-bold" >Welcome to the BookMyCoiffeur!</h5>
            </div>
            <div className="form-group">
              {<span className="error">{errors?.firstName?.message}</span>}
              <InputTag
                name={"firstName"}
                label={"FirstName"}
                type={"text"}
                register={register}
                ValidationSchema={ValidationSchema.firstName}
                require={true}
              />
              {<span className="error">{errors?.lastName?.message}</span>}
              <InputTag
                type={"text"}
                label={"Last Name"}
                name={"lastName"}
                register={register}
                ValidationSchema={ValidationSchema.lastName}
                require={true}
              />
            </div>
            <div className="form-group">
              <Dropdowns
                fieldName="city"
                validations={ValidationSchema.city}
                firstFieldName="Select City"
                data={store.city}
                register={register}
                onChange={(selected) => {
                  getLocality(selected);
                }}
              />
              {<span>{errors?.city?.message}</span>}
              <select
                required=""
                name="locality"
                className="px-2 py-2 rounded-lg my-1"
                style={{border:"1px solid #4c3f39"}}
                {...register("locality", ValidationSchema.locality)}
              >
                <option>Select Locality</option>
                {store?.locality?.value?.map((localities) => {
                  return localities.cityId._id == city ? (
                    <option value={localities.localityName}>
                      {localities.localityName}
                    </option>
                  ) : null;
                })}
              </select>
              {<span>{errors?.locality?.message}</span>}
            </div>
            <div className="form-group">
              {<span className="error">{errors?.phoneNumber?.message}</span>}
              <InputTag
                type={"tel"}
                label={"Phone Number"}
                name={"phoneNumber"}
                register={register}
                ValidationSchema={ValidationSchema.phoneNumber}
              />
              {/* <input
                type="number"
                placeholder="Phone Number"
                required=""
                name="phoneNumber"
                {...register("phoneNumber", ValidationSchema.phoneNumber)}
              /> */}
            </div>
            <div className="form-group">
              {/* <input
                type="email"
                placeholder="Your email"
                required=""
                name="email"
                {...register("email", ValidationSchema.email)}
              /> */}
              {<span className="error">{errors?.email?.message}</span>}
              <InputTag
                name={"email"}
                label={"Email"}
                type={"email"}
                register={register}
                ValidationSchema={ValidationSchema.email}
                require={true}
              />
            </div>
            <div className="form-group">
              {/* <input
                type="password"
                minLength={5}
                maxLength={15}
                className="pswrdup"
                placeholder="Your password"
                required=""
                name="password"
                {...register("password", ValidationSchema.password)}
              /> */}
              {<span className="error">{errors?.password?.message}</span>}
              <InputTag
                name={"password"}
                label={"Password"}
                type={"password"}
                register={register}
                ValidationSchema={ValidationSchema.password}
                require={true}
              />
            </div>
            <p className="tandc form-check">
              <input
                type="checkbox"
                name="tandc"
                id="tandc"
                className="form-check-input"
                {...register("tandc", ValidationSchema.tandc)}
              />
              <label htmlFor="tandc" className="form-check-label">
                {/* I Agree To <Link to='/privacy' className="link-opacity-100-hover">Privacy Policy</Link> /and <Link className="link-opacity-100-hover" to="/usertermsofservice">Terms and Conditions</Link> */}
                I Agree To <a href="/privacy" target="_blank" className="link-opacity-100-hover text-primary">Privacy Policy</a> and <a href="/usertermsofservice" target="_blank" className="link-opacity-100-hover text-primary">Terms and Conditions</a>
                {<p style={{ color: "red" }}>{errors?.tandc?.message}</p>}
              </label>
            </p>
            <div className="captcha__wrapper">
              <div className="captcha">
                <p >
                {/* <img src={captch_bg} alt="Captch" /> */}
                {<canvas ref={canvasRef} id="captcha__canvas"></canvas>}                {/* <span className="captch-text" >
                  {captcha!='' && atob(captcha)}
                </span> */}
                </p>
                <IoMdRefreshCircle onClick={getCaptcha}/>
                {/* <button className="retry_captcha">
                </button> */}
              </div>
              <div className="">
                <InputTag type="text" name='captcha' label={"Captcha"} register={register} 
                ValidationSchema={ValidationSchema.captcha}
                />
              </div>
            </div>
            <div className="form-group">
              <button type="submit" className="btn btn-primary w-100">
                Register
              </button>
            </div>
          </form>
        </div>
          {/* <div className="left-side col-lg-5 col-sm-0 d-none">
            <h1>Create an Account</h1>
            <h5>Welcome to the BookMyCoiffeur Barber Community!</h5>
            <br />
            <p>
              <ol>
                At BookMyCoiffeur, we're thrilled to have you join our network
                of skilled and passionate barbers.
              </ol>{" "}
              <ol>
                As a member of our platform, you're not just signing up; you're
                becoming a part of a vibrant community dedicated to enhancing
                the grooming and styling experience for clients.
              </ol>
            </p>
            <br />
            <p>
              Now that you have a glimpse of what to expect, it's time to create
              your profile. We're excited to have you as part of our growing
              community, and we're confident you'll make a significant impact.
              Begin by filling out the form below to get started on your journey
              with BookMyCoiffeur.
            </p>
          </div> */}
      </>
    </div>
  );
};
