// import { useState } from "react";
import "./login.css";
import axios from "axios";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Error500 } from "../../Components/errors/error500/Error500";
// import Oauth from "../signupPage/Oauth";
import React, { useEffect, useState } from "react";
// import {Home,Code,Notes,Call,LocalLibrary,RssFeed,} from "@mui/icons-material";
// import { LockOpen } from "@mui/icons-material";
import { Loader } from "../../Components/Loader";
import { useGoogleLogin } from "@react-oauth/google";
import InputTag from "../../Components/InputTag";
import { getUser } from "../../API/LoginApi";
import Cookies from "js-cookie";
const Login = ({history}) => {
  // console.log(window.location.href)
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors, touched },
  } = useForm();
  const [formError, setFormError] = useState("");
  const navigate = useNavigate();
  const location=useLocation();
  const fromServicePage=location.state?.fromServicePage;
  const [googleAuth, setGoogleAuth] = useState();
  const [user, setUser] = useState([]);
  const googleLogin = useGoogleLogin({
    onSuccess: (codeResponse) => setUser(codeResponse),
    // onError: (error) => console.log("Login Failed:", error),
    
  });
  const submit = (data) => {
    setLoading(true);
    // console.log(fromServicePage);
    getUser(data)
      .then((data) => {
        // console.log(data?.data)
        // console.log(data?.data?.passToken)
        setFormError("");
        setLoading(false);
        localStorage.setItem("qwerty", data.data.userToken);
        localStorage.setItem('session',data?.data?.passToken)
        Cookies.set("session", data?.data?.passToken);
        Cookies.set("qwerty", data.data.userToken);
        if (data.data.adminToken != null) {
          localStorage.setItem("shopify", data.data.adminToken);
          localStorage.setItem("uuid", true);
          navigate('/adminhomepage')
        }
        //console.log(data);
        
        else if(location.state && location.state.fromServicePage){
          // console.log(fromServicePage);
          navigate("/"+fromServicePage, { state: { isOffered: location.state?.isOffered } })
        //     // history.goBack();
        }
        else  
        {navigate('/');}
        window.location.reload();
        
      })
      .catch((error) => {
        setLoading(false);
        // console.log(error);
        // console.log(error?.response?.status)
        // console.log(error?.response?.redirect)
        if (error?.response?.data?.message?.name === "ZodError")
          setFormError("Give Proper Input");
        else if (
          error?.response?.status === 401 &&
          error?.response?.data?.redirect === true
        )
          navigate("/confirm", { state: { value: error?.response?.data?.data } });
        else if (error?.response?.status === 500) navigate("/error500");
        else setFormError(error.response?.data?.message);
        //console.log(error);
      });
  };

  useEffect(() => {
    if (user) {
      try {
        // console.log(user.access_token);
        axios
          .get(
            `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
            {
              headers: {
                Authorization: `Bearer ${user.access_token}`,
                Accept: "application/json",
              },
            }
          )
          .then((res) => {
            // console.log(res);
            // setProfile(res.data);
            // submit({ email: "modiperin@gmail.com", password: "12345678" });
            // console.log(res);
          })
          .catch((err) => console.log(err));
      } catch (err) {
        // console.log(err);
      }
    }
  }, [user]);

  const ValidateSchema = {
    email: {
      required: {
        value: true,
        message: "Email is Required",
      },
      pattern: {
        value:
          /^(?:(?:[^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@(?:(?:\[(?:[0-9]{1,3}\.){3}[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        message: "Email should be in proper format",
      },
    },
    password: {
      required: {
        value: true,
        message: "Passsword is required",
      },
    },
    tandc: {
      required: {
        value: true,
        message: "Agree with terms and conditions as well as privacy policy",
      },
    },
  };

  // const googleLogin=()=>{

  // }

  return (
    <>
      {loading && <Loader />}
      {/* <div className="signup-page row  container m-auto"> */}
        {/* <div className="col-md-5 rounded-lg">
          <p
          style={{backgroundImage:`url(${barberImage})`}}
          >
            <img src={barberImage} className="h-[50vh] w-100" alt="" />
          </p> */}
          {/* <p className="justify-center items-center">
            <h1 className="text-3xl text-center my-4 font-bold">
              Login
            </h1>
            <h1 className="text-center text-2xl">Welcome Back</h1>
            <p className="text-center text-xl" >Get the perfect haircut and grooming experience.
            We are glad that you joined our community of stylish individuals.

            </p>
            <p className="font-bold text-center" >No longer waiting in lines.</p>
          </p> */}
            {/* <p>
              We are glad that you joined our community of stylish individuals.
            </p> */}
        {/* </div> */}
        <div className=" min-h-screen flex items-center justify-center py-6 px-4 sm:px-6 lg:px-8 ">
        {/* <div className="right-side col-md-6 col-sm-12"> */}
          
          <form
          className="bg-transparent py-3 px-4 sm:px-10  mb-4 border border-x-2 border-gray-500 rounded-lg  w-[90vh] sm:w-[80vh] shadow-lg"
            // className="signup-form"
            method="post"
            onSubmit={handleSubmit(submit)}
            action=""
          ><div>
            <h1 style={{ color: "red" }}>{formError}</h1>
          <p className="justify-center items-center">
            <h1 className="text-3xl text-center my-4 font-bold">
              Login
            </h1>
            {/* <h1 className="text-center text-2xl">Welcome Back</h1>
            <p className="text-center text-xl" >Get the perfect haircut and grooming experience.
            </p>
            <p className="font-bold text-center" >No longer waiting in lines.</p> */}
            {/* We are glad that you joined our community of stylish individuals. */}
          </p>
          </div>
            {/* <h2>Log In</h2> */}
            <div className="form-group">
              {/* <label htmlFor="email" className="label1">
                Email
              </label> */}
              {<span className="error">{errors?.email?.message}</span>}
              <InputTag
                name={"email"}
                label={"Email"}
                type={"email"}
                register={register}
                ValidationSchema={ValidateSchema.email}
                require={true}
              />
              {/* <input
                type="email"
                id="email"
                name="email"
                placeholder="Enter your email"
                {...register("email", ValidateSchema.email)}
                required
              /> */}
            </div>
            <div className="form-group">
              {/* <label htmlFor="password" className="label1">
                Password
    c          </label> */}
              {/* <input
                type="password"
                id="password"
                name="password"
                placeholder="Enter Your Password"
                {...register("password", ValidateSchema.password)}
                required
              /> */}
              {<span className="error">{errors?.password?.message}</span>}
              <InputTag
                name={"password"}
                label={"Password"}
                type={"password"}
                register={register}
                ValidationSchema={ValidateSchema.password}
                require={true}
              />
            </div>
            <div className="form-group">
              <span>
                <Link to="forgotPassword" className="Forgot">
                  Forgot Password
                </Link>
              </span>
            </div>
            <div className="form-group">
              <button type="submit" className="btn btn-primary w-100">
                Submit
              </button>
            </div>
            <div className="form-group">
              <p>
                New Customer? <Link to="/signup">SignUp Now</Link>
              </p>
            </div>
            <p className="tandc form-check">
              <label htmlFor="tandc" className="form-check-label">
                {/* I Agree To <Link to='/privacy' className="link-opacity-100-hover">Privacy Policy</Link> /and <Link className="link-opacity-100-hover" to="/usertermsofservice">Terms and Conditions</Link> */}
                By login up you Agree To our
                <a
                  href="/privacy"
                  target="_blank"
                  className="link-opacity-100-hover text-primary"
                >
                  Privacy Policy
                </a>{" "}
                as well as{" "}
                <a
                  href="/usertermsofservice"
                  target="_blank"
                  className="link-opacity-100-hover text-primary"
                >
                  Terms and Conditions
                </a>
                {/* {<p style={{ color: "red" }}>{errors?.tandc?.message}</p>} */}
              </label>
             
            </p>
          </form>
          {/* <button onClick={googleLogin} className="btn btn-danger w-100 mt-2">
            Google
          </button> */}
        </div>
      {/* </div> */}
    </>
  );
};

export default Login;
