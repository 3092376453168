import { getApi, postApi } from "../APIManager/ApiManager"

const admin=localStorage.getItem('shopify')
const user=localStorage.getItem('qwerty')

export const getSpecificStoreService=(id)=>{
    return getApi('/api/v1/slot/getspecificstoreserviceforslot/'+id)
}

export const updateTime=(data)=>{
    return postApi('/api/v1/cart/updatetimer',data)
}

export const addService=(data)=>{
    return postApi('/api/v1/cart/addservices',data)
}

export const removeService=(data)=>{
    return postApi('/api/v1/cart/removeservices',data)
}