import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./checkin.css";
import jwt_decode from "jwt-decode";
import Swal from "sweetalert2";
import { Loader } from "../../Components/Loader";
import { checkInApi, getParticularApp } from "../../API/AdditionalApi";

export const CheckedIn = () => {
  const { appId } = useParams();
  const [modalData, setModalData] = useState();
  const [admin, setAdmin] = useState(false);
  const [loading, setLoading] = useState(false);
  const getData = () => {
    setLoading(true);
    // axios
    //   .get("/api/v1/appointment/getparticularapp/" + appId.split("-")[0])
    getParticularApp(appId.split("-")[0])
      .then((data) => {
        // console.log(data);
        setLoading(false);
        setModalData(data.data.data);
      })
      .catch((error) => {
        setLoading(false);
        // console.log(error);
      });
  };
  const checkAdmin = () => {
    var token = localStorage.getItem("shopify");
    try {
      if (token != null) {
        var dec = jwt_decode(token);
        // console.log(dec);
        if (dec.adminId === appId.split("!!")[1]) {
          setAdmin(true);
        } else {
          setAdmin(false);
        }
      }
    } catch (error) {
      //console.log(error)
    }
  };

  useEffect(() => {
    checkAdmin();
    getData();
  }, []);

  const checkIn = () => {
    setLoading(true);
    checkInApi(appId)
      .then((data) => {
        if (data.status === 200) {
          setLoading(false);
          Swal.fire({
            icon: "success",
            title: "CheckedIn",
          });
        } else {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: data.data.message,
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.message,
        });
      });
  };

  return (
    <>
      {/* {console.log(admin)} */}
      {/* {console.log(modalData)} */}
      {loading && <Loader />}
      {admin === true ? (
        <div className="shop-info">
          <div className="header">
            <h1 className="det">
              Appointment Details for{" "}
              {modalData?.userId?.firstName + " " + modalData?.userId?.lastName}
            </h1>
            <p className="location">
              Order Id : <b>{modalData?._id}</b>
            </p>
          </div>
          <div className="info">
            <div className="time">
              Start Time :{" "}
              {new Date(modalData?.startTime).toLocaleString("en-GB")}
            </div>
            <p className="employee">
              Employee: {modalData?.employeeId?.employeeName}
            </p>
            <p className="amount">Total Amount: {modalData?.totalPrice} INR</p>
            {modalData?.discount > 0 && modalData?.payment?.captured === true ? (
              <div>
                <p>Payable Amount</p>
                <p>
                  {modalData?.payment?.captured === true
                    ? modalData?.totalPrice - modalData?.discount - modalData?.payment?.amount / 100
                    : modalData?.totalPrice - modalData?.discount}{" "}
                  INR
                </p>
              </div>
            ) : (
              ""
            )}
            <p>
              Status :{" "}
              {modalData?.checkedIn == "true" ? "Checked In" : "Pending"}
            </p>
          </div>
          <div className="services">
            <h3>Services</h3>
            <ul>
              {modalData?.serviceId?.map((service, index) => (
                <li key={index} className="service-item">
                  <div className="service-left">
                    <div className="service-details">
                      <span className="service-name">
                        {service.serviceName}
                      </span>
                      <span className="start-time">
                        Time to take: {service.time} Min.
                      </span>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          {modalData?.checkedIn == "false" ? (
            <button
              type="submit"
              value="submit Form"
              className="subBtn"
              onClick={() => {
                checkIn();
              }}
            >
              Check In
            </button>
          ) : (
            <button
              type="submit"
              disabled
              value="submit Form"
              className="subBtn"
              onClick={() => {
                checkIn();
              }}
            >
              Check In
            </button>
          )}
        </div>
      ) : (
        <>
          <center>
            <h1>
              You are not Authorized to Check In Other store's Appointment
            </h1>
          </center>
        </>
      )}
    </>
  );
};
