import React from "react";
import { useState } from "react";
import { BiSolidLeftArrow, BiSolidRightArrow } from "react-icons/bi";
import "./AdminCarousel.css";
// import "./Carousel.css";
const AdminCarousel = ({ Images, f = 1 }) => {
  const [currImg, setCurrImg] = useState(0);
  var photos = Images;
  //console.log("1234",photos);
  // console.log(i)
  return (
    <>
      {Images?.length > 0 ? (
        <>
          <div className="admin__carousel">
            <div
              className="admin__carousel-inner"
              style={{ backgroundImage: `url(${photos[currImg]?.photoId})` }}
            >
              <div
                className="left"
                onClick={() => {
                  currImg > 0 && setCurrImg(currImg - 1);
                }}
              >
                <BiSolidLeftArrow />
              </div>
              <div className="center"></div>
              <div
                className="right mt-0"
                onClick={() => {
                  setCurrImg((currImg + 1) % photos.length);
                }}
              >
                <BiSolidRightArrow />
              </div>
            </div>
          </div>
          {f == 1 && (
            <div className="container h-100">
              <div className="d-flex mt-5 grid gap-2" id="image-slides">
                {Images?.map((e, Index) => {
                  //console.log(Index);
                  return (
                    <img
                      src={e.photoId}
                      alt="Image"
                      className="previewImage"
                      onClick={() => {
                        setCurrImg(Index);
                      }}
                      width="200px"
                      height="200px"
                    />
                  );
                })}
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          <div className="" style={{fontSize:"20px"}}>
            <center>
           No Images Found
            </center>
          </div>
        </>
      )}
    </>
  );
};

export default AdminCarousel;
