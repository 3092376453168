import React, { useEffect, useState } from "react";
import { MultiRowForms } from "../../Components/reusableForms/MultiRowForms";
import axios from "axios";
import { Loader } from "../../Components/Loader";
import { getEmployeeGeneral } from "../../API/UnAvaibility";
import { deleteEmployee, multiAddEmployee, updateEmployee } from "../../API/EmployeeApi";
import { useForm } from "react-hook-form";

export const MultiEmployee = () => {
  var fields = [
    {
      fieldName: "employeeName",
      type: "text",
      actual: "Employee Name",
    },
    {
      fieldName: "mobile",
      type: "number",
      actual: "Mobile No.",
    },
    {
      fieldName: "offlineOnline",
      type: "select",
      actual: "Offline/Online",
      value: ["Online","Offline"],
    },
    {
      fieldName: "gender",
      type: "select",
      actual: "Gender",
      value: ["male", "female", "unisex"],
    },
  ];

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const ValidationSchema = {
    employeeName: {
      required: {
        value: true,
        message: "Employee Name is required",
      },
    },
    mobile: {
      required: {
        value: true,
        message: "Mobile No is required",
      },
    },
    offlineOnline: {
      required: {
        value: true,
        message: "Mode is required",
      },
    },
    gender: {
      required: {
        value: true,
        message: "Gender Id is required",
      },
    },
  };

  const [mainData, setMainData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [d, setD] = useState(false);
  const [formData, setFormData] = useState([
    {
      id: 1,
      employeeName: "",
      mobile: "",
      offlineOnline: "Online",
      gender: "male"
    },
  ]);

  const handleInputChange = (e, id) => {
    // console.log(e.target.value);
    // console.log(e.target.name);
    // console.log(id);
    const { name, value, type, checked } = e.target;

    setFormData((prevData) =>
      prevData.map((data) => {
        // console.log(data._id);
        return data._id === id || data.id === id
          ? { ...data, [name]: value }
          : data;
      })
    );

    // console.log(formData);
  };

  const getData = (flag) => {
    setLoading(true);
   getEmployeeGeneral()
      .then((data) => {
        setLoading(false);
        // console.log("123", data.data.data);
        mainData?.length>0 && flag!==true ? setFormData([...data?.data?.data,...mainData]) : setFormData(data?.data?.data)
        
        // console.log(data);
      }).finally(()=>setLoading(false))
  };

  const handleAddRow = () => {
    setFormData((prevData) => [
      ...prevData,
      {
        id:  Date.now(),
        employeeName: "",
        mobile: "",
        offlineOnline: "Online",
        gender: "male"
      },
    ]);
  };

  const addEmployee = (data) => {
    // console.log(data);
    setMainData((prevArray) => {
      const index = prevArray.findIndex((item) => item.id === data.id);

      if (index !== -1) {
        // If the service is present, update it
        return prevArray.map((item, i) => (i === index ? data : item));
      } else {
        // If the service is not present, append the new object
        return [...prevArray, data];
      }
    });
  };
  const removeEmployee = (id) => {
    // console.log(id);
    // Do something with the form data, e.g., send it to the server
    setFormData((prevData) => prevData.filter((data) => data?.id !== id));
    setMainData(mainData.filter((e) => e.id != id));
    // console.log("Form Data:", formData);
    // handleValidation(id);
  };

  const finalDelete = (id) => {
    // console.log(id)
    setLoading(true);
    setFormData((prev)=>prev.filter((e)=>e._id!=id));
    deleteEmployee(id)
      .then((data1) => {
        // console.log(data1);
        setFormData((prevData) => prevData.filter((data) => data._id !== id));
        // setData(data.filter((e) => e._id !== id));
        setLoading(false);
      }).finally(()=>setLoading(false))
  };

  const finalUpdate = (data) => {
    // console.log("1234567890987654321----",data);
    setLoading(true);
    const formData = new FormData();
    var keys = Object.keys(data);
    keys?.map((e) => {
      // console.log(data[e]);
      if (data[e] instanceof FileList) {
        // console.log(e, data[e][0]);
        formData.append("file", data[e][0]);
      } else formData.append(e, data[e]);
    });
    // console.log(localStorage.getItem("qwerty"));
    updateEmployee(formData)
      .then((data) => {
        // console.log(data);
        setLoading(false);
        // getService();
      }).finally(()=>setLoading(false))
  };

  const finalSubmit = () => {
    setLoading(true);
    // console.log(mainData);
    // console.log("Final Submit");
    // axios
    //   .post("/api/v1/admin/employee/addmultiemployee", mainData, {
    //     headers: {
    //       user: localStorage.getItem("qwerty"),
    //       admin: localStorage.getItem("shopify"),
    //     },
    //   })
    multiAddEmployee(mainData)
      .then((data) => {
        setLoading(false);
        setMainData([])
        getData(true);
      }).finally(()=>setLoading(false))
  };

  useEffect(() => {
    getData();
  }, []);
  return <>
    {
      loading && <Loader/>
    }
    {/* {console.log(formData)} */}
    {formData[0]?.employeeName!=="" && formData[0]?._id!==undefined && <MultiRowForms fields={fields}
    Update={data=>finalUpdate(data)} 
    Delete={data=>finalDelete(data)}
    Remove={data=>removeEmployee(data)}
    Add={data=>addEmployee(data)}
    AddRow={handleAddRow}
    mainData={mainData}
    finalSubmit={finalSubmit}
    formData={formData} 
    handleInputChange={(e,id)=>{handleInputChange(e,id)}}
    handleSubmit={handleSubmit}
    validationSchema={ValidationSchema}
    register={register}
    error={errors}
    />}
  </>;
};
