import React from "react";

export const Privacy1 = () => {
  return (
    <>
      <div className="container mt-5">
        <h2 className="mb-4">Book My Coiffeur Privacy Policy</h2>
        <p>
          <strong>Last updated:</strong> 21st December, 2023
        </p>

        <p>
          Book My Coiffeur ("us", "we", or "our") operates{" "}
          <a href="http://www.bookmycoiffeur.com">
            http://www.bookmycoiffeur.com
          </a>{" "}
          (the "Site"). This page informs you of our policies regarding the
          collection, use, and disclosure of Personal Information we receive
          from users of the Site.
        </p>

        <p>
          We use your Personal Information only for providing and improving the
          Site. By using the Site, you agree to the collection and use of
          information in accordance with this policy.
        </p>

        <h3>Information Collection and Use</h3>

        <p>
          While using our Site, we may ask you to provide us with certain
          personally identifiable information that can be used to contact or
          identify you. Personally identifiable information may include, but is
          not limited to your name, email, and phone number.
        </p>

        {/* Additional sections follow the same pattern */}

        <h3>Log Data</h3>

        <p>
          Like many site operators, we collect information that your browser
          sends whenever you visit our Site.
        </p>

        <p>
          This Log Data may include information such as your computer's Internet
          Protocol ("IP") address, browser type, browser version, the pages of
          our Site that you visit, the time and date of your visit, the time
          spent on those pages, and other statistics.
        </p>

        {/* Additional sections follow the same pattern */}

        <h3>Communications</h3>

        <p>
          We may use your Personal Information to contact you with newsletters,
          marketing, or promotional materials and other information.
        </p>

        {/* Additional sections follow the same pattern */}

        <h3>Cookies</h3>

        <p>
          Cookies are files with a small amount of data, which may include an
          anonymous unique identifier. Cookies are sent to your browser from a
          website and stored on your computer's hard drive.
        </p>

        <p>
          Like many sites, we use "cookies" to collect information. You can
          instruct your browser to refuse all cookies or to indicate when a
          cookie is being sent. However, if you do not accept cookies, you may
          not be able to use some portions of our Site.
        </p>

        {/* Additional sections follow the same pattern */}

        <h3>Security</h3>

        <p>
          The security of your Personal Information is important to us, but
          remember that no method of transmission over the Internet or method of
          electronic storage is 100% secure. While we strive to use commercially
          acceptable means to protect your Personal Information, we cannot
          guarantee its absolute security.
        </p>

        {/* Additional sections follow the same pattern */}

        <h3>Sensitive and Restricted Scopes</h3>

        <p>
          Book My Coiffeur requests specific scopes during the sign-up process
          to enhance your user experience. The requested scopes include:
        </p>

        <ul>
          <li>
            <strong>.../auth/userinfo.email:</strong> This allows us to access
            your primary Google Account email address.
          </li>
          <li>
            <strong>.../auth/userinfo.profile:</strong> This allows us to access
            your personal info, including any personal info you've made publicly
            available.
          </li>
          <li>
            <strong>openid:</strong> This allows us to associate you with your
            personal info on Google.
          </li>
          <li>
            <strong>BigQuery API:</strong> This allows us to view and manage
            your data in Google BigQuery and see the email address for your
            Google Account.
          </li>
        </ul>

        <p>
          The information accessed through these scopes is utilized solely for
          the purpose of providing you with personalized services and improving
          your overall user experience. We take the security and privacy of your
          data seriously and adhere to Google's policies and guidelines.
        </p>

        {/* Additional sections follow the same pattern */}

        <h3>Changes To This Privacy Policy</h3>

        <p>
          This Privacy Policy is effective and will remain in effect except with
          respect to any changes in its provisions in the future, which will be
          in effect immediately after being posted on this page.
        </p>

        <p>
          We reserve the right to update or change our Privacy Policy at any
          time, and you should check this Privacy Policy periodically. Your
          continued use of the Service after we post any modifications to the
          Privacy Policy on this page will constitute your acknowledgment of the
          modifications and your consent to abide and be bound by the modified
          Privacy Policy.
        </p>

        <p>
          If we make any material changes to this Privacy Policy, we will notify
          you either through the email address you have provided us or by
          placing a prominent notice on our website.
        </p>

        {/* Additional sections follow the same pattern */}

        <h3>Contact Us</h3>

        <p>
          If you have any questions about this Privacy Policy, please contact us
          at{" "}
          <a href="mailto:bookmycoiffeur@gmail.com">bookmycoiffeur@gmail.com</a>
          .
        </p>
      </div>
    </>
  );
};
