import Cookies from "js-cookie"
import { getApi, postApi } from "../APIManager/ApiManager"
const admin=localStorage.getItem('shopify')
const user=localStorage.getItem('qwerty')
const uuid=btoa(Cookies.get('session') || localStorage.getItem('session'))
export const getEmployeeGeneral=()=>{
    return getApi('/api/v1/admin/employee/getemployee',{user:user,admin:admin,uuid:uuid})
}

export const addUnavailability=(data)=>{
    return postApi('/api/v1/admin/employee/addunavailablity',data,{user:user,admin:admin,uuid:uuid})
}

