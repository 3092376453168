import React, { useState } from "react";
import "./ReviewForm.css"; // Import your CSS file for styling
// import img from "../../Images/Haircut.jpg";
import { useForm } from "react-hook-form";
import { Rating } from "@mui/material";
import TestinomialCardComponent from "../../Components/TestinomialCardComponent";
import InputTag from "../../Components/InputTag";

const ReviewForm = ({ onSubmit, review }) => {
  const { handleSubmit, register, setValue } = useForm();
  const [rate, setRate] = useState(0);
  const [filled,setFilled]=useState('');
  const submit = (e) => {
    // console.log(e?.rating)
    if(e?.rating ===undefined)
    {
      alert('We appreciate your detailed feedback and would also love to know your rating to better understand your overall experience')
    }
    else{
      onSubmit(e);
    }
  };

  const handleRatingChange = (rating) => {
    setValue("rating", rating.target.value);
    setRate(rating.target.value);
  };

  return (
    <>
    <div className="container-fluid mb-5 mainFormDiv">
      <div className="formBG">

      </div>
      <div className="container ">
        <div className="form row">
          <div className="col-md-8 mt-5 col-sm-12">
            <h1>Kindly Review our website</h1>
            <form
            className="mt-3"
            onSubmit={handleSubmit((data) => submit(data))}
          >
            <div className="form-group w-100 ">
            <div className="col-12 sm:col-6 my-2">
              <InputTag
              name={"name"} label={"name"}
              type="text"
              register={register}
              require={true}
              />
            </div>
            <div className="col-12 sm:col-6 my-2">
              <InputTag
              name={"mail"} label={"email"}
              type="text"
              register={register}
              require={true}
              />
            </div>
            </div>
            <div className={`form-group review-input  ${filled}`}>
              <InputTag 
              name={"description"} label={"Review"}
              type={"text"}
              register={register}
              require={true}
              />
            </div>
            <div className="form-group w-50">
              <button type="submit" className="btn btn-secondary"
              onClick={()=>{setFilled('filled')}}
              >
                Submit Review
              </button>
            </div>
          </form>
          </div>
          <div className="col-md-4 col-sm-12 py-3 rounded-lg text-white bg-orange-900">
            <h1>Contact Information</h1>
            <div>
              bookmycoiffeur@gmail.com
            </div>
          </div>
        </div>
      </div>
    </div>
      {/* <div className="review-form-container container">
        <div className="form-container ">
          <div className="form-header">
            <h2>Review Form</h2>
            <p>Kindly enter your valuable feedback</p>
          </div>
          <div className="review-rating">
            <p>
              <Rating
                name="rating"
                defaultValue={0}
                precision={0.5}
                onChange={handleRatingChange}
              />
            </p>

            <p className="rate">{rate}</p>
          </div>
          <form
            className="review-form w-75 mt-3"
            onSubmit={handleSubmit((data) => submit(data))}
          >
            <div className={`form-group review-input  ${filled} `}>
              <textarea
                className={`textarea border border-dark rounded `}
                // placeholder="Your Review"
                name="description"
                rows="10"
                cols="20"
                id="description"
                {...register("description")}
                required
              />
              <label htmlFor="">Review</label>
            </div>
            <div className="form-group">
              <button type="submit" className="subBtn"
              onClick={()=>{setFilled('filled')}}
              >
                Submit Review
              </button>
            </div>
          </form>
        </div>
        <div className="">
          <img
            src="https://res.cloudinary.com/dpoecdktk/image/upload/v1697428991/Haircut_ddqyd3.webp"
            alt="Review Image"
            className="review-image"
          />
        </div>
        </div> */}
        {/* <TestinomialCardComponent review={review} /> */}
    </>
  );
};

export default ReviewForm;
