import ThemeProvider from "@mui/material/styles/ThemeProvider";
import createTheme from "@mui/material/styles/createTheme";
import { Card } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { CardActionArea, Grid } from "@mui/material";
import Button from "@mui/material/Button";
import ScheduleIcon from "@mui/icons-material/Schedule";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import TimerIcon from "@mui/icons-material/Timer";
import ManIcon from "@mui/icons-material/Man";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import "./pastAppointments.css";
import React, { useEffect, useState } from "react";
import { useTokenCheckerLogout } from "../../useTokenCheckerLogout";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import QRCode from "qrcode";
import { Loader } from "../../Components/Loader";
import {
  deleteAppointmentByUser,
  getCurrentAppointment,
} from "../../API/AppointmentApi";
export const CurrentAppointments = () => {
  const decoded = useTokenCheckerLogout();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [current, setCurrent] = useState();
  const [imgURL, setImgURL] = useState();
  const [expanded, setExpanded] = React.useState(false);
  const [modalData, setModalData] = useState();
  const [qr, setqr] = useState();
  const errorss = (error) => {
    if (error.response) {
      // The request was made, but the server responded with an error status code
      //console.log("Status Code:", error.response.status);
      if (error.response.status === 500) navigate("/error500");
      //console.log("Response Data:", error.response.data);
    } else if (error.request) {
      // The request was made, but no response was received
      //console.log("No response received:", error.request);
      navigate("/error500");
    } else {
      // Something else happened while setting up the request
      //console.log("Error:", error.message);
    }
  };

  const generateQr = async (id) => {
    // console.log(process.env);
    const response = await QRCode.toDataURL(
      process.env.REACT_APP_WEBSITE_URL + "/checkin/" + id
    );
    //console.log(response)
    setImgURL(response);
  };
  const getAllCurrent = () => {
    //console.log("decoat current ",decoded)
    try {
      setLoading(true);
      getCurrentAppointment(decoded.id)
        .then((data) => {
          setCurrent(data.data.data);
          // console.log(data.data);
        })
        .catch((error) => {
          errorss(error);
        })
        .finally(() => setLoading(false));
    } catch (error) {
      alert("Please login to view your appointments");
    }
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: "#5c6bc0",
      },
      secondary: {
        main: "#5c6bc0",
      },
    },
  });

  useEffect(() => {
    getAllCurrent();
  }, []);

  const deleteApp = (id) => {
    var con = window.confirm("Are you sure");
    // console.log(con);
    if (con) {
      setLoading(true);
      // console.log(decoded.id);
      deleteAppointmentByUser(id)
        .then((data) => {
          // setLoading(false);
          // console.log(data);
          getAllCurrent();
        })
        .catch((error) => {
          // setLoading(false);
          // console.log(error);
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <>
      <div>
        {loading && <Loader />}
        <>
          {/* {console.log(current)} */}
          <div>
            <ThemeProvider theme={theme}>
              <Grid container spacing={2}>
                {current?.length > 0 ? (
                  <>
                    {current?.map((e) => (
                      <Grid item xs={12} sm={6} md={4} lg={3} key={e.id}>
                        <Card
                          sx={{
                            maxWidth: 385,
                            // maxHeight: "40rem",
                            mb: 5,
                            color: "#ffff",
                            margin: "0 auto",
                          }}
                        >
                          <CardActionArea>
                            <CardContent
                              sx={{ backgroundColor: "var(--theme-brown)" }}
                            >
                              <Typography
                                sx={{
                                  color: "#fff",
                                }}
                              >
                                <div className="row">
                                  <p className="pending col-12 ">
                                  {e?.isOffered === true && <div className="coiffeur__offer w-50">
                                    <p className="offers" >{e?.isOffered === true ? "Offer" : ""}</p>
                                  </div>}
                                    <h6>
                                      {e.checkedIn === "false"
                                        ? "Not Checkedin"
                                        : "Checkedin"}
                                    </h6>
                                    
                                  </p>
                                </div>
                                  <div
                                    className="current__appointment__order__detail"
                                    style={{
                                      fontSize: "25px",
                                      color: "white",
                                      display: "flex",
                                    }}
                                  >
                                    <div className="shop__img">
                                      <img
                                        src={e?.adminId?.banner}
                                        alt="store Photo"
                                      />
                                    </div>
                                    <div>
                                      <h3>
                                        {e?.adminId?.brandName},
                                        {e?.adminId?.locality}
                                      </h3>
                                    </div>
                                  </div>
                                  {/* <div className="current__appointment__order__detail">
                      <div className="shop__img">
                        <img
                          src={modalData?.adminId?.banner}
                          alt="store Photo"
                        />
                      </div>
                      <div>
                        <h3>{e?.adminId?.brandName},
                                      {e?.adminId?.locality}</h3>
                      </div>
                    </div> */}
                                <p></p>
                              </Typography>
                            </CardContent>
                            <hr style={{ border: "3px solid black" }} />
                            <CardContent
                              sx={{
                                backgroundColor: "#fff",
                                border: "2px solid var(--primary-hard-color)",
                              }}
                            >
                              <table
                                style={{ color: "var(--primary-hard-color)" }}
                              >
                                <tr>
                                  <td>Order ID</td>
                                  <td>:</td>
                                  <td>
                                    <b>{e._id}</b>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Date</td>
                                  <td>:</td>
                                  <td>
                                    <ScheduleIcon />{" "}
                                    {new Date(e.startTime).toLocaleString(
                                      "en-GB"
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Total Items</td>
                                  <td>:</td>
                                  <td>{e?.serviceId?.length}</td>
                                </tr>
                                <tr>
                                  <td>Total Amount</td>
                                  <td>:</td>
                                  <td>{e.totalPrice - e?.discount}</td>
                                </tr>
                                <tr>
                                  <td>Amount Payable</td>
                                  <td>:</td>
                                  {e?.payment?.captured === true ? (
                                    <td>
                                      {e.totalPrice -
                                        e?.discount -
                                        e?.payment?.amount / 100}
                                    </td>
                                  ) : (
                                    <td>{e.totalPrice - e?.discount}</td>
                                  )}
                                </tr>
                                <tr>
                                  <td colspan="3">
                                    <Button
                                      variant="contained"
                                      data-bs-toggle="modal"
                                      style={{
                                        marginInline: "auto",
                                        backgroundColor: "var(--gray-text)",
                                      }}
                                      data-bs-target="#pastapp"
                                      onClick={() => {
                                        setModalData(e);
                                      }}
                                    >
                                      View Details
                                    </Button>
                                    <Button
                                      variant="contained"
                                      data-bs-toggle="modal"
                                      style={{
                                        marginInline: "auto",
                                        backgroundColor: "var(--gray-text)",
                                        margin: "10px",
                                      }}
                                      data-bs-target="#qr"
                                      onClick={() =>
                                        generateQr(
                                          e?._id +
                                            "-" +
                                            e?.bookingOtp +
                                            "!!" +
                                            e?.adminId?._id
                                        )
                                      }
                                    >
                                      Scan QR Code
                                    </Button>
                                    <Button
                                      style={{
                                        marginInline: "auto",
                                        backgroundColor: "var(--danger-color)",
                                        // margin: "10px",
                                      }}
                                      variant="contained"
                                      onClick={() => {
                                        deleteApp(e?._id);
                                      }}
                                    >
                                      Cancel Appointment
                                    </Button>
                                  </td>
                                </tr>
                              </table>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </Grid>
                    ))}
                  </>
                ) : (
                  <>
                    <div className="flex-container">
                      <center>
                        <h1>No Current Appointments Found</h1>
                        <button>
                          <Link to="/listofstore">
                            Book Your Appointment Now
                          </Link>
                        </button>
                      </center>
                    </div>
                  </>
                )}
              </Grid>
            </ThemeProvider>
            <div
              className="modal fade"
              id="pastapp"
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog  modal-dialog-centered ">
                <div className="modal-content">
                  <div className="modal-header">
                    <div>
                      <h1
                        className="modal-title"
                        id="exampleModalLabel"
                        style={{ marginInline: "auto" }}
                      >
                        Order Detail
                      </h1>
                    </div>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-header">
                    <div>
                      <h5>Order Id: {modalData?._id}</h5>
                    </div>
                    {/* <div className="current__appointment__order__detail">
                      <div className="shop__img">
                        <img
                          src={modalData?.adminId?.banner}
                          alt="store Photo"
                        />
                      </div>
                      <div>
                        <h3>{modalData?.adminId?.brandName}</h3>
                      </div>
                    </div> */}
                  </div>

                  <div className="modal-body">
                    <div className="pastappointments__div">
                      <div>
                        <p>
                          <CalendarTodayIcon />
                          Date
                        </p>
                        <p>
                          {new Date(modalData?.date).toDateString()} at{" "}
                          {new Date(modalData?.startTime).toLocaleTimeString()}
                        </p>
                      </div>
                      <div>
                        <p>
                          <TimerIcon />
                          Total Time
                        </p>
                        <p>{modalData?.totalTime} minutes </p>
                      </div>
                      <div>
                        <p>
                          <ManIcon />
                          Employee
                        </p>
                        <p>{modalData?.employeeId?.employeeName}</p>
                      </div>
                      <div>
                        <p><img src="https://res.cloudinary.com/dpoecdktk/image/upload/v1711880387/shop_bh7rdt.png" height={"20px"} width={"20px"}/> Service(s)</p>
                      </div>
                      <div className="ms-5 w-100 services__list">
                          {modalData?.serviceId?.map((e) => {
                            return (
                              <>
                              <div className="services w-75">
                                <p> {e.serviceName}</p>
                                <p>
                                <CurrencyRupeeIcon /><span>{e?.price}</span>
                                </p>
                              </div>
                              </>
                            );
                          })}
                      </div>
                      <hr />
                      <div>
                        <p>Total</p>
                        <p>
                          <CurrencyRupeeIcon />
                          {modalData?.totalPrice}
                        </p>
                      </div>
                      <div className="discount">
                        <p>Offer</p>
                        <p>
                          -<CurrencyRupeeIcon />
                          {modalData?.discount}
                        </p>
                      </div>
                      {modalData?.isPaid === true &&
                      modalData?.payment?.captured === true ? (
                        <>
                          <div>
                            <p>Online Amount Paid</p>
                            <p>
                              -<CurrencyRupeeIcon />
                              {modalData?.payment?.amount / 100}
                            </p>
                          </div>
                          {
                            modalData?.discount>0 ? 
                            <div className="saved">
                            <p>Your Total Savings</p>
                            <p>
                              <CurrencyRupeeIcon />{modalData?.discount}
                            </p>
                          </div>
                          :
                          ""
                          }
                        </>
                      ) : (
                        ""
                      )}
                      <hr />
                      <div>
                        <p>Payable Amount</p>
                        <p>
                        {modalData?.payment?.captured === true ? (
                                    <p><CurrencyRupeeIcon />
                                      {modalData?.totalPrice -
                                        modalData?.discount -
                                        modalData?.payment?.amount / 100}
                                    </p>
                                  ) : (
                                    <p><CurrencyRupeeIcon />{modalData?.totalPrice - modalData?.discount}</p>
                                  )}
                        </p>
                      </div>
                    </div>
                    <div>
                      {/*{<table className="pastappointments__table">
                        <tr>
                          <td align="left">
                            <CalendarTodayIcon />
                            Date
                          </td>
                           <td>:</td> 
                          <td>{modalData?.date}</td>
                        </tr>
                        <tr>
                          <td>
                            <TimerIcon />
                            Total Time
                          </td>
                           <td>:</td> 
                          <td>{modalData?.totalTime} minutes</td>
                        </tr>
                        <tr>
                          <td>
                            <ManIcon />
                            Employee
                          </td>
                           <td>:</td> 
                          <td>{modalData?.employeeId?.employeeName}</td>
                        </tr>
                        <tr>
                          <td>Service(s)</td>
                        </tr>
                        <tr>
                          <td>
                            <ul style={{ listStyleType: "none" }}>
                              {modalData?.serviceId?.map((e) => {
                                return (
                                  <li className="current__appointment__modal">
                                    <span> {e.serviceName}</span>{" "}
                                    <span>
                                      {e?.price -
                                        e?.storeDiscount -
                                        e?.bmcDiscount}
                                    </span>
                                  </li>
                                );
                              })}
                            </ul>
                          </td>
                        </tr>

                        <tr>
                          <td>
                            
                            Total
                          </td>
                           <td>:</td> 
                          <td><CurrencyRupeeIcon /> {modalData?.totalPrice}</td>
                        </tr>
                        <tr className="discount">
                          <td>
                            Discount
                          </td>
                          <td>
                          <CurrencyRupeeIcon />
                            100
                          </td>
                        </tr>
                        {modalData?.isPaid === true &&
                        modalData?.payment?.captured === true ? (
                          <>
                            <tr>
                              <td>
                                Advance amount
                              </td>
                               <td>:</td> 
                              <td><CurrencyRupeeIcon /> {modalData?.payment?.amount / 100}</td>
                            </tr>
                          </>
                        ) : (
                          ""
                        )}

                        {modalData?.isPaid === true &&
                        modalData?.payment?.captured === true ? ( 
                        <tr className="saved"> 
                          <td>
                          You Saved
                          </td>
                          <td>
                          <CurrencyRupeeIcon /> 100
                          </td>
                        </tr>)
                        : ""}
                          <></>

                      </table> */}
                    </div>
                  </div>

                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </div>

      <div
        className="modal fade"
        id="qr"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-sm">
          <div className="modal-content">
            <div className="modal-header">
              <div>
                <h1
                  className="modal-title"
                  id="qr"
                  style={{ marginInline: "auto" }}
                >
                  QR Code
                </h1>
                <h5>
                  <h1>{qr?._id}</h1>
                  <h6>Please show this code to your Barber</h6>
                </h5>
              </div>
            </div>
            <div className="modal-body" style={{ marginInline: "auto" }}>
              <img src={imgURL} alt="QR code" />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
    // </div>
    // </>
  );
};
