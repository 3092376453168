import Cookies from "js-cookie"
import { deleteApi, getApi } from "../APIManager/ApiManager"

const admin=localStorage.getItem('shopify')
const user=localStorage.getItem('qwerty')
const uuid=btoa(Cookies.get('session') || localStorage.getItem('session'))

export const getCurrentAppointment=(id)=>{
    return getApi('/api/v1/appointment/getcurrentuserappointment/'+id)
}

export const deleteAppointmentByUser=(id)=>{
    return deleteApi('/api/v1/appointment/deleteAppointment/'+id)
}

export const deleteAppByAdmin=(id)=>{
    return deleteApi('/api/v1/appointment/deleteappointment/'+id,{user:user,admin:admin,uuid:uuid})
}

export const pastAppointmentForUser=(id)=>{
    return getApi('/api/v1/appointment/pastappointments/'+id)
}