import React from "react";
import classes from "./Footer.module.css";
import logo from "../Images/Brown Logo.png";
import facebook from "../Images/Facebook.svg";
import twitter from "../Images/Twitter.svg"
import insta from "../Images/Instagram.svg"
import whatsApp from "../Images/Whatsapp.svg"

const Footer = () => {
  return (
    
    <div className={`${classes.footerWrapper} container-fluid row w-100 mt-5`}>
      {/* {console.log("123445677")} */}
      <div className={`${classes.footerRow} row col col-12 col-sm-12 w-100`}>
        <div className={`${classes.footerFeedback} col col-md-4 col-sm-12`}>
          <img src={logo} alt="img" height={100} width={100} className="m-auto" />
          <h2>Give Us Feedback!</h2>
          <p>
            We welcome your suggestions and ideas. With over a million members
            we constantly improve our services.
          </p>
        </div>
        <div className={`${classes.footerLink} col col-md-3 col-6`}>
            <h5>About Us</h5>
            <ul>
                <li>
                About Us
                </li>
                <li>
                Contact Us
                </li>
                <li>
                Locate Us
                </li>
                <li>
                Pricing
                </li>
                <li>
                User Guide
                </li>
                
            </ul>
        </div>
        <div className={`${classes.footerLink} col col-md-3 col-6`}>
            <h5>Privacy policy</h5>
            <ul>
                <li>
                Join our community
                </li>
                <li>
                <a href="/usertermsofservice" target="_blank" className="nav-link ">Terms And Conditions</a>
                {/* Terms and condition. */}
                </li>
                <li>
                Platform
                </li>
            </ul>
        </div>
        <div className={`${classes.footerLink} ${classes.followUs} col col-md-2 col-12`}>
            <h5>Follow Us</h5>
            <div className={classes.footerIcon}>
                <a href="#" >
                    <img src={facebook} alt="" />
                </a>
                <a href="#" >
                    <img src={twitter} alt="" />
                </a>
                <a rel="stylesheet" href="https://www.instagram.com/bookmycoiffeur/" className="nav-link">
                    <img src={insta} alt="" />
                          </a>
                <a rel="stylesheet" href="https://wa.me/+917228806111" target="_blank">
                    <img src={whatsApp} alt="" />
                </a>

            </div>
        </div>
      </div>
      <div className={`${classes.copyRight} mt-3`}>
            {/* © Copyright by AltDesain-Studio  All right reserved. */}
            © Copyright by BookMyCoiffeur  All right reserved. 
      </div>
    </div>
  );
};

export default Footer;
