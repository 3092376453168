import * as React from "react";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import createTheme from "@mui/material/styles/createTheme";
import { Card } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { CardActionArea, Grid } from "@mui/material";
import Button from "@mui/material/Button"; // Import Grid from Material-UI
import { useTokenCheckerLogout } from "../../useTokenCheckerLogout";
import ScheduleIcon from "@mui/icons-material/Schedule";
import { useEffect } from "react";
import axios from "axios";
import { useState } from 'react';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import TimerIcon from '@mui/icons-material/Timer';
import ManIcon from '@mui/icons-material/Man';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import './pastAppointments.css'
import Modal from "../../Components/Modal";
import { Loader } from "../../Components/Loader";
import { Link } from "react-router-dom";
import { pastAppointmentForUser } from "../../API/AppointmentApi";
export const PastAppointments = () => {
  const [expanded, setExpanded] = React.useState(false);
  const [loading, setLoading] = useState(false);
  var [pastAppointments, setPastAppointments] = React.useState();
  const [modalData, setModalData] = useState();
  const decoded = useTokenCheckerLogout();

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const getData = () => {
    setLoading(true)
    pastAppointmentForUser(decoded.id)
      .then((data) => {
        // setLoading(false)
        setPastAppointments(data.data.data);
        // console.log("---")
        // console.log(data)
      })
      .catch((error) => {
        // setLoading(false)
        //console.log(error)
      }).finally(() => setLoading(false));
  };
  const theme = createTheme({
    palette: {
      primary: {
        main: "#5c6bc0",
      },
      secondary: {
        main: "#5c6bc0",
      },
    }
  });
  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      {
        loading && <Loader />
      }
      {/* {console.log(pastAppointments)} */}
      <div>
        <center className="mb-5 mt-3 pastappointments">
          <h2>Appointment History</h2>
        </center>
        <ThemeProvider theme={theme}>
          <Grid container spacing={4}>
            {
              pastAppointments?.length > 0 ?
                <>
                  {pastAppointments?.map((e) => (
                    <Grid item xs={12} sm={12} md={4} lg={4} key={e.id}>
                      {/* Define grid item properties */}
                      <Card
                        sx={{
                          // maxWidth: 400,
                          width: "100%",
                          maxHeight: "40rem",
                          mb: 5,
                          color: "#ffff",
                          textAlign: "center",
                          justifyContent: "center",
                          margin: "0 auto",
                          padding: "0 auto",
                          alignItems: 'center'
                        }}
                      >
                        <CardActionArea>
                          <CardContent
                            sx={{ backgroundColor: "var(--theme-brown)", textAlign: "center" }}>
                            <Typography
                              sx={{ color: "#fff", maxHeight: "100px" }}>
                              <div className="row">
                                <p className="pending col-12 ">
                                  {e?.isOffered === true && <div className="coiffeur__offer w-50">
                                    <p className="offers" >{e?.isOffered === true ? "Offer" : ""}</p>
                                  </div>}
                                  {/* <h6>
                                      {e?.isOffered === true ? "Offer" : ""}
                                    </h6> */}
                                  <h6>
                                    {e.checkedIn === "false"
                                      ? "Not Checkedin"
                                      : "Checkedin"}
                                  </h6>
                                </p>
                              </div>
                              <div
                                className="current__appointment__order__detail"
                                style={{
                                  fontSize: "25px",
                                  color: "white",
                                  display: "flex",
                                }}
                              >
                                <div className="shop__img">
                                  <img
                                    src={e?.adminId?.banner}
                                    alt="store Photo"
                                  />
                                </div>

                                <div>
                                  <h3>
                                    {e?.adminId?.brandName},
                                    {e?.adminId?.locality}
                                  </h3>
                                </div>
                              </div>
                              {/* <table>
                                <p className="row"
                                  style={{
                                    fontSize: "20px",
                                    color: "white",
                                    display: "flex",
                                    paddingBottom: "0px"
                                  }}
                                >
                                  <img
                                    src={e?.adminId?.banner}
                                    height="50"
                                    width="50"
                                    className="col-3"
                                    alt="Banner"
                                    style={{ borderRadius: "15px" }}
                                  />
                                  <p className="col-9">
                                    {e?.adminId?.brandName},{e?.adminId?.locality}
                                  </p>
                                </p>
                              </table> */}
                              <p></p>
                            </Typography>
                          </CardContent>
                          <hr style={{ border: "3px solid black" }} />
                          <CardContent
                            sx={{
                              backgroundColor: "#fff",
                              border: "2px solid var(--primary-hard-color)",
                            }}
                          >

                            <table style={{ color: "var(--primary-hard-color)" }}>
                              <tr>
                                <td>Order ID</td>
                                <td>:</td>
                                <td><b>{e._id}</b></td>
                              </tr>
                              <tr>
                                <td>Date</td>
                                <td>:</td>
                                <td>
                                  <ScheduleIcon />{" "}
                                  {new Date(e.startTime).toLocaleString("en-GB")}
                                </td>
                              </tr>
                              <tr>
                                <td>Total Items</td>
                                <td>:</td>
                                <td>{e?.serviceId?.length}</td>
                              </tr>
                              <tr>
                                <td>Total Amount</td>
                                <td>:</td>
                                <td>{e.totalPrice - e?.discount}</td>
                              </tr>
                              <tr>
                                <td>Amount Payable</td>
                                <td>:</td>
                                {e?.payment?.captured === true ? <td>{e.totalPrice - e?.discount - (e?.payment?.amount) / 100}</td> : <td>{e.totalPrice - e?.discount}</td>}
                              </tr>
                              <tr >
                                <td colSpan="3">
                                  <Button variant="contained" data-bs-toggle="modal" style={{ marginInline: "auto", backgroundColor: "var(--gray-text)" }}
                                    data-bs-target="#pastapp" onClick={() => { setModalData(e) }}>View Details</Button>
                                </td>
                              </tr>
                            </table>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grid>
                  ))}
                </>
                :
                <div className="flex-container">
                  <center>
                    <h1>No Appointments History Found</h1>
                    <button>
                      <Link to="/listofstore">
                        Book Your Appointment Now
                      </Link>
                    </button>
                  </center>
                </div>
            }
          </Grid>
        </ThemeProvider>
        <Modal title={"Order Detail"} subtitle={`Order Id : ${modalData?._id}`}>
          <h4> {modalData?.adminId?.brandName}</h4>
          <div className="pastappointments__div">
            <div>
              <p>
                <LocationOnIcon />Locality
              </p>
              <p>
                {modalData?.adminId?.locality}
              </p>
            </div>
            <div>
              <p>
                <CalendarTodayIcon />
                Date
              </p>
              <p>
                {new Date(modalData?.date).toDateString()} at{" "}
                {new Date(modalData?.startTime).toLocaleTimeString()}
              </p>
            </div>
            <div>
              <p>
                <TimerIcon />
                Total Time
              </p>
              <p>{modalData?.totalTime} minutes </p>
            </div>
            <div>
              <p>
                <ManIcon />
                Employee
              </p>
              <p>{modalData?.employeeId?.employeeName}</p>
            </div>
            <div>
              <p><img src="https://res.cloudinary.com/dpoecdktk/image/upload/v1711880387/shop_bh7rdt.png" height={"20px"} width={"20px"} /> Service(s)</p>
            </div>
            <div className="ms-5 w-100 services__list">
              {modalData?.serviceId?.map((e) => {
                return (
                  <div className="services w-75">
                    <p> {e.serviceName}</p>
                    <p>
                      <CurrencyRupeeIcon /><span>{e?.price}</span>
                    </p>
                  </div>
                );
              })}
            </div>
            <hr />
            <div>
              <p>Total</p>
              <p>
                <CurrencyRupeeIcon />
                {modalData?.totalPrice}
              </p>
            </div>
            <div className="discount">
              <p>Offer</p>
              <p>
                -<CurrencyRupeeIcon />
                {modalData?.discount}
              </p>
            </div>
            {modalData?.isPaid === true &&
              modalData?.payment?.captured === true ? (
              <>
                <div>
                  <p>Online Amount Paid</p>
                  <p>
                    -<CurrencyRupeeIcon />
                    {modalData?.payment?.amount / 100}
                  </p>
                </div>
                {
                  modalData?.discount > 0 ?
                    <div className="saved">
                      <p>Your Total Savings</p>
                      <p>
                        <CurrencyRupeeIcon />{modalData?.discount}
                      </p>
                    </div>
                    :
                    ""
                }
              </>
            ) : (
              ""
            )}
            <hr />
            <div>
              <p>Payed Amount</p>
              <p>
                {modalData?.payment?.captured === true ? (
                  <p><CurrencyRupeeIcon />
                    {modalData?.totalPrice -
                      modalData?.discount -
                      modalData?.payment?.amount / 100}
                  </p>
                ) : (
                  <p><CurrencyRupeeIcon />{modalData?.totalPrice - modalData?.discount}</p>
                )}
              </p>
            </div>
          </div>
          {/* <table className="pastappointments__table">
            <tr>
              <td colSpan={3}>
                <h4> {modalData?.adminId?.brandName}</h4>
              </td>
            </tr>
            <tr>
              <td>
                <LocationOnIcon />Locality
              </td>
              <td>
                :
              </td>
              <td>
                {modalData?.adminId?.locality}
              </td>
            </tr>
            <tr>
              <td>
                <TimerIcon />Total Time
              </td>
              <td>
                :
              </td>
              <td>
                {modalData?.totalTime}
              </td>
            </tr>
            <tr>
              <td align="left">
                <CalendarTodayIcon />Date
              </td>
              <td>
                :
              </td>
              <td>
                {modalData?.date}
              </td>
            </tr>
            <tr>
              <td>
                <ManIcon />Employee
              </td>
              <td>
                :
              </td>
              <td>
                {modalData?.employeeId?.employeeName}
              </td>
            </tr>
            <tr>
              <td>
                <CurrencyRupeeIcon />Amount
              </td>
              <td>
                :
              </td>
              <td>
                {modalData?.totalPrice}
              </td>
            </tr>
            {modalData?.isPaid === true &&
              modalData?.payment?.captured === true ? (
              <> <tr>
                <td>
                  <CurrencyRupeeIcon />
                  Advance amount
                </td>
                <td>:</td>
                <td>{modalData?.payment?.amount / 100}</td>
              </tr>
              </>
            ) : (
              ""
            )}
            <tr>
              <td>
                Service(s)
              </td>
              <td>
                :
              </td>
              <td>
                <ul style={{ listStyleType: "none" }}>
                  {modalData?.serviceId?.map((e) => {
                    return (<li>{e.serviceName}</li>)
                  })}
                </ul>
              </td>
            </tr>
          </table> */}
        </Modal>
      </div>
    </>
  );
};
