import React, { useState, useEffect, useRef } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import { Navigate, useNavigate } from 'react-router-dom';
import errors from "../../Error";
import ApiManager from '../../APIManager/ApiManager';
import { searchBar } from '../../API/AdditionalApi';
import classes from "./citySearchBar.module.css"

export const CitySearchBar = ({ cities }) => {
    const navigate = useNavigate()
    var [search, setSearch] = useState([])
    const handleInputChange = (event) => { };
    const callSearch = async (e) => {
        e.preventDefault()
        handleInputChange(e)
        if (e.target.value != "") {
            searchBar(e.target.value).then((data)=>{
                setSearch(data?.data?.business)
            })
        }
    }
    const f1 = (e) => {
        if (e) {
            navigate("/listofstore?store=" + e);
        }
    }

    return (
        <div className={classes.navAddressWrapper}>
            <Autocomplete
                freeSolo
                id="free-solo-2-demo"
                className={classes.navCityText}
                disableClearable
                options={search?.map((option) => option)}
                onKeyUp={callSearch}
                onChange={(event, value) => {
                    if (value) {
                        f1(value)
                    }
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        className={`text-white ${classes.navCityText}`}
                        // sx={{color:"white"}}
                        label="Search Shop"
                        InputProps={{
                            ...params.InputProps,
                            type: 'search',
                        }}
                    />
                )}
            />
        </div>
    );
};
