import React, { useEffect, useRef, useState } from 'react'
import './InputTag.css'
const InputTag = ({type="text",label,name,register,ValidationSchema,require,message}) => {
    // const [value, setValue] = useState('');
    // const {  register: registerFunction } = register(name, ValidationSchema);
    const [filled,setFilled]=useState('');
  const handleChange=(e)=>{
    if(e.target.value)
    setFilled("filled");
  else
    setFilled('');
  } 

  // const { ref, ...rest } = register(name,ValidationSchema);
  const inputField=register(name,ValidationSchema);
 return (
    <div className={`input-container   ${filled}`}>
    <input 
    type={type} 
    name={name}
    id={name}
    // {...rest}
    // ref={(e) => {
    //   ref(e);
    //   inputRef.current=e;
    // }}
    className='my-2 rounded-lg'
    style={{border:"1px solid #4c3f39"}}
    {...register(name,ValidationSchema)}
    onChange={(e)=>{return handleChange(e),inputField.onChange(e)}}
    />
    <label htmlFor={name}>
      {label}
    </label>
  </div>
  )
}

export default InputTag