import React, { useEffect } from "react";
import axios from "axios";

const Payment = ({ result,onPaymentSuccess,onPaymentCancel,cartData,paymentFlag}) => {
  useEffect(() => {
    // console.log(cartData)
    const displayRazorpay = async () => {
      const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

      if (!res) {
        alert("Razorpay SDK failed to load. Are you online?");
        return;
      }
      // console.log(result.data)
      const { amount, id, currency } = result.data;

      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY_ID, // Enter the Key ID generated from the Dashboard
        amount: amount.toString(),
        currency: currency,
        name: "Book My Coiffeur",
        order_id: result?.data?.id,
        handler: async function (response) {
          const data = {
            orderCreationId: result?.data?.id,
            razorpayPaymentId: response.razorpay_payment_id,
            razorpayOrderId: response.razorpay_order_id,
            razorpaySignature: response.razorpay_signature,
          };

          // console.log("razorData",data)
          onPaymentSuccess(data);
          //razorpay.payments.fetch

          // const result = await axios.post("http://localhost:5000/payment/success", data);

          // alert(result.data.msg);
        },
        modal: {
          ondismiss: function () {
            // Handle the case when user closes the tab during payment
            onPaymentCancel(); // Call onPaymentCancel function
          }
        },
        prefill: {
          name: cartData?.userId?.name,
          email: cartData?.userId?.email,
          contact: cartData?.userId?.phoneNumber,
        },
        theme: {
          color: "#61dafb",
        },
      };

      const paymentObject = new window.Razorpay(options);
      // console.log("payment",paymentFlag)
      // if(paymentFlag===false)
      // {
      //   console.log("Paymenttts")
      // }
      paymentObject.open();
    };

    displayRazorpay();
  }, [onPaymentSuccess, onPaymentCancel]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = ""; // Some browsers require this line
      onPaymentCancel()
      // Make an API call to handle the case when user closes the tab during payment
      // Example: axios.post("/payment/cancel", { orderId });
      // You can pass orderId or any relevant data to identify the payment
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);


  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };
};

export default Payment;