import { getApi, postApi } from "../APIManager/ApiManager"

const admin=localStorage.getItem('shopify')
const user=localStorage.getItem('qwerty')

export const getPhotos=(id)=>{
    return getApi('/api/v1/admin/photo/getphotos/'+id)
}

export const getBusineesDataForUser=(id)=>{
    return getApi('/api/v1/businessform/getbusinessdata/'+id)
}

export const removeAllData=(data)=>{
    return postApi('/api/v1/cart/removealldata',data)
}

export const getBusinessDataByPage=(currentPage,storeName)=>{
    return getApi("/api/v1/businessform/getbusinessdatabypage/" +
    currentPage +
    "/" +
    storeName)
}