import React, { useEffect, useState } from 'react'
import BussinessForm from '../businessRegister/BussinessForm'
import axios from 'axios';
import { Loader } from '../../Components/Loader';
import { businessData } from '../../API/AdminHomePageApi';

export const UpdateStore = () => {
    const [data,setData]=useState()
    const [loading,setLoading]=useState(false)
    const getData = () => {
        setLoading(true)
        businessData()
          .then((data) => {
            // setLoading(false)
            setData(data.data.data)
          })
          .catch((error) => {
          }).finally(()=>setLoading(false));
      };

      useEffect(() => {
        getData()
      }, [])
      
  return (
    <div>
        {loading && <Loader/>}
        {data && <BussinessForm update={true} data={data}/>}
    </div>
  )
}
