import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import axios from 'axios';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { persistor, store } from './strore';
import { PersistGate } from 'reduxjs-toolkit-persist/integration/react';
import { GoogleOAuthProvider } from '@react-oauth/google';
axios.defaults.baseURL = process.env.REACT_APP_MAIN_URL
// console.log(process.env)
// axios.defaults.baseURL = 'http://localhost:9999'
// axios.defaults.baseURL='https://nodebarber.onrender.com'
// console.log(process.env)
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <GoogleOAuthProvider clientId={process.env.REACT_APP_CLIENT_ID}>
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </BrowserRouter>
  </GoogleOAuthProvider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(//console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
