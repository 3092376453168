import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { removeEverything } from '../../redux/CartSlice';
import Cookies from "js-cookie";
import Avatar from '@mui/material/Avatar';
import Logout from '@mui/icons-material/Logout';
import ListItemIcon from '@mui/material/ListItemIcon';
import { RiAccountPinCircleFill } from "react-icons/ri";
import { FaCalendarCheck } from "react-icons/fa";
import { FaRegCalendarAlt } from "react-icons/fa";
import { LuLogOut } from "react-icons/lu";

// import { color } from 'framer-motion';

export const ProfileDropdown = (props) => {
    // console.log(props)
    const navigate = useNavigate();
    const dispatch=useDispatch()
    const logout = () => {
        //console.log('hhhh')
        dispatch(removeEverything({isOffered:true}))
        localStorage.removeItem('qwerty')
        localStorage.removeItem('shopify')
        localStorage.removeItem('uuid')
        localStorage.removeItem('session')
        Cookies.remove('session')
        Cookies.remove('qwerty')
        window.location.reload();
        navigate('/')
    }
    return (
        <>

            {
                props.name != ""  && <PopupState variant="popover" popupId="demo-popup-menu">
                    {/* {console.log(props)} */}
                    {(popupState) => (
                        <React.Fragment>
                                {
                                    props.nav==true ? 
                                    <>
                                    {/* <Button  {...bindTrigger(popupState)} sx={{border:"2px solid black",borderRadius:"50%",padding:"15px 0px"}}> */}
                                {/* {props.name.trim()[0].toUpperCase()}  */}

                            {/* </Button> */}
            <Avatar sx={{ width: 32, height: 32,backgroundColor:"var(--theme-brown)",color:"White" }} {...bindTrigger(popupState)} >
            {props.name.trim()[0].toUpperCase()} 
            </Avatar>
                                {/* props?.name.trim().split(' ')[1][0].toUpperCase() */}
                                    <Menu {...bindMenu(popupState)} className='profileMenu'>
                                    <Link to={"/userprofile/"+props.decode.id}><MenuItem onClick={()=>{popupState.close();props.changeButton(false)}}> 
                                    <RiAccountPinCircleFill />
 {props.item1}</MenuItem></Link>
                                    <Link to={"/currentappointment"}><MenuItem onClick={()=>{popupState.close();props.changeButton(false)}}>
                                    <FaRegCalendarAlt />
                                        {props.item2}</MenuItem></Link>
                                    <Link to={"/pastappointment/"+props.decode.id}>
                                        <MenuItem onClick={()=>{popupState.close();props.changeButton(false)}}>
                                        <FaCalendarCheck /> {props.item3}</MenuItem></Link>
                                    <MenuItem onClick={logout}>
                                    <LuLogOut /> Logout </MenuItem>
                                    </Menu>
                                    </>
                                    :  
                                    <>
                                    <div className="">
                                    <span {...bindTrigger(popupState)}>{props.name}</span>
                                     <Menu {...bindMenu(popupState)}>
                                <Link to={"/"} style={{marginTop:"0.8rem"}} ><MenuItem onClick={()=>{popupState.close();}}>{props.item1}</MenuItem></Link>
                                <Link to={"/"}><MenuItem onClick={()=>{popupState.close();}}>{props.item2}</MenuItem></Link>
                                <Link to={"/"}><MenuItem onClick={()=>{popupState.close();}}>{props.item3}</MenuItem></Link>
                                </Menu>
                                    </div>
                                    </>
                                
                                }
                            
                            
                        </React.Fragment>
                    )}
                </PopupState>
            }
        </>
    )
}
