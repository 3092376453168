import React from "react";
import { Bar } from "react-chartjs-2";
function BarChart({ chartData,Data }) {
  const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
  // const options={responsive:true}
    const options = {
      plugins:{
        datalabels: {
            color:'#000000',
            anchor: "start",
            align:"center",
            width:"100%",
            formatter: function(value, context) {
                    return context.chart.data.labels[context.dataIndex];
}
        }
    } 
    }
  return <Bar data={Data}/>;
}

export default BarChart;
