import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { Loader } from './Loader';
import ApiManager from '../APIManager/ApiManager';
import { addUnavailability, getEmployeeGeneral } from '../API/UnAvaibility';

const Unavailability = () => {
  const [employee, setEmployee] = useState([]);
  const [loading, setLoading] = useState(false)
  const doSubmit = (e) => {
    setLoading(true)
    addUnavailability(e).finally(()=>setLoading(false))
  }
  const getEmployee = () => {
    setLoading(true)
    getEmployeeGeneral().then((data)=>{
        setEmployee(data.data.data);
    }).finally(()=>{
      setLoading(false)
    })
  };
  // Use useEffect to fetch employee data when the component mounts
  useEffect(() => {
    getEmployee();
  }, []); // The empty array [] ensures that this effect runs only once, similar to componentDidMount

  const { register, handleSubmit } = useForm();
  return (
    <>
      {loading && <Loader />}
      <div>
        <form onSubmit={handleSubmit(doSubmit)}>
          <label htmlFor="employeeId1">
            Select employee <br />
            <select style={{ width: '200px' }} {...register("employeeId")}>
              <option name="employeeId" id="employeeId1" value="notselected">Select a employee</option>
              {
                employee?.map((e) => {
                  return (
                    <option value={e._id}>{e.employeeName}</option>
                  )
                })
              }
            </select>
          </label>
          <label htmlFor='startTime1'>
            select unavailability starting date and time <br />
            <input style={{ width: '200px' }} type="datetime-local" name="startTime" id="startTime1" {...register("startTime")} />
          </label>
          <label htmlFor="endTime">
            select unavailability ending date and Time <br />
            <input style={{ width: '200px' }} type="datetime-local" name="endTime" id="endTime"  {...register("endTime")} />
          </label>
          <button type="submit" onSubmit={doSubmit}>Submit</button>
        </form>
      </div>
    </>
  );
};

export default Unavailability;